import React from "react";
// import { identifier } from "@babel/types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import EditStaff from "./StaffEdit";

export const STAFFCOLUMNS = [
  {
    header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {" "}
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    header: "First Name",
    accessor: "firstName",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    header: "Last Name",
    accessor: "lastName",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    header: "Company",
    accessor: "company.companyName",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    header: "Contact Phone",
    accessor: "phone",
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    header: "Contact Email",
    accessor: "email",
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    header: "status",
    accessor: "status",
    Cell: (props) => <div>{checkStatus(props.value)}</div>,
  },
  {
    header: "action",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic">
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            <Link
              to={{
                pathname: `/corporates/staffs/show`}}
                state={{data: props.row.original}}
              className="dropdown-item">
              View
            </Link>

            <Dropdown.Item>
              <EditStaff placement="end" data={props.row.original} />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

function checkStatus(status) {
  if (status) status = status.toLowerCase();
  switch (status) {
    case "active":
      return (
        <div className="status">
          <div className="dot"></div>
          Active
        </div>
      );

    default:
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Inactive
        </div>
      );
  }
}

function checkCategory(categoryId) {
  switch (categoryId) {
    case 1:
      return <div className="text-capitalize">NewsPaper</div>;

    case 2:
      return <div className="text-capitalize">Magazine</div>;

    default:
      return <div className="text-capitalize">Book</div>;
  }
}
