import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams} from "react-router-dom";
import DashboardWrapper from '../../../components/DashboardWrapper';
import { fetchReaderDetails, fetchReaders } from '../../../redux-store/Actions/reader-action';
import './styles.css';
import UserDetailNavs from './userDetailNavs';

function UserDetails() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params   = useParams();
  const id = params.ref

  let options = {year: 'numeric', month: 'short', day: '2-digit' };


  // const { readers, isLoading } = useSelector(
  //   (state) => state.readers 
  // );  

  const { reader, isLoading } = useSelector(
    (state) => state.reader
  );  


  

  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }


  useEffect(() => {
    dispatch(fetchReaders());
  }, [dispatch]);


  useEffect(() => {
    dispatch(fetchReaderDetails(id));
  }, [id,  dispatch]);



  return (
      <DashboardWrapper>
         <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">

              <div className="header mt-md-3">
                <nav aria-label="breadcrumb mb-2">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/dashboard" onClick={toPage("/dashboard")} >Dashboard</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="/users/readers" onClick={toPage("/users/readers")} >Users</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">User Details</li>
                  </ol>
                </nav>
              </div>

            

              { isLoading ? (
                <div className="content">
                  <div className="card">
                    <div className="card-body">
                        Loading  ... 
                    </div>
                  </div>
                </div>
              ) : (

                <div>
                { reader && (

                  <div className="content">
                    <div className="row mt-3">

                    
                      <div className="col-md-7">

                          <div className="card card-fill-sm">
                            <div className="px-4 pt-4">
                              <div className="row">
                                <div className="col">

                                  <h1 className="header-title">
                                    User Overview
                                  </h1>
                                </div>

                                <div className="col-auto">
                                  <div className='badge-app px-4'>Reader</div>
                                </div>      

                              </div>

                              <hr />

                            </div>


                            { reader && reader.user &&(

                            <div className="card-body pb-5">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="user-details-image">
                                    <div className="udi-avatar">
                                      <img src="/avatar.png" alt="" />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-8">
                                  <p className='ud-p'>{ reader.firstName} { reader.lastName}</p>
                                  <p className='ud-p'>Reference Number : <span className='text-success bold'>{ reader.refNo}</span></p>
                                  <p className='ud-p'>Account Created : <span className='text-success'> { new Date(reader.createdAt).toLocaleString('en-US', options)}</span></p>
                                  <div className='d-flex ud mt-3'>
                                    <div className='mr-2'>
                                      <i className='fe fe-smartphone'></i> { reader.user.phone }
                                    </div>

                                    <div className='mr-2'>
                                      <i className='fe fe-mail'></i> { reader.user.email}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            )}
                          </div>

                      </div>

                      <div className="col-md-5">
                        <div className="card card-fill-sm">
                          <div className="px-4 pt-4">
                              <div className="row">
                                <div className="col">

                                  <h1 className="header-title">
                                    Subscription
                                  </h1>
                                </div>  

                              </div>

                              
                              <hr />


                          </div>

                          <div className='card-body'>
                            <div className='row'>

                              <div className='col-12'>
                                <div className='usd my-4'>
                                  <span className='usd-unit'>0</span>
                                  <span className='unit'>units</span>
                                </div>
                              </div>

                              <div className='col-12'> 
                                <div className='mx-2'>
                                  <p className='usd-p'>Subscription Name:   <span className='text-success bold'>-</span></p>
                                  <p className='usd-p'>Activation Date:  <span className='text-success bold'>-</span></p>
                                  <p className='usd-p'>Expiration Date:   <span className='text-success bold'>-</span></p>                                  
                                </div>                                
                                
                              </div>

                            </div>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                    <UserDetailNavs id={id} />
                  </div>

                )}

                </div>
              )}

            </div>
          </div>
          </div>

      </DashboardWrapper>
  );
}

export default UserDetails;
