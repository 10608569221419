import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { createTopupsApi } from "../../../../api/reports";

const schema = yup
  .object({
    username: yup.string().required().min(3).max(255).label("User Name"),
    referenceId: yup
      .mixed()
      .required()
      .typeError("Reference Number must be a number")
      .test(
        "is-number",
        "Reference Number must be a number",
        (value) => !isNaN(value)
      )
      .label("Reference Number"),
    amount: yup
      .number()
      .required()
      .typeError("Amount must be a number")
      .label("Amount"),
    phone: yup
      .number()
      .required()
      .typeError("Phone Number must be a number")
      .label("Phone Number"),
    currency: yup.string().required().label("Selected Currency"),
    channel: yup.string().required().min(3).max(255).label("channel"),
    description: yup.string().required().min(3).max(500).label("description"),
    extra: yup.string().required().min(3).max(255).label("extra"),
  })
  .required();

function TopUpsReports() {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);

  useEffect(() => {
    const apiUrl = `https://open.er-api.com/v6/latest`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setCurrencyList(Object.keys(data.rates));
      })
      .catch((error) => {
        console.error("Error fetching currency list:", error);
      });
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    data.userId = data.referenceId - 400000;
    data.currentUserId = user.user_id;

    createTopupsApi(data)
      .then((res) => {
        setLoading(false);
        console.log("data sent to database",res.data);

        var path = "/reports/topup";
        navigate(path);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const navigate = useNavigate();

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/reports")}>
                      Reports
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create Top Up
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card pt-2 mt-2">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Create Top Up</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-12">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="username"
                                    className="form-label"
                                  >
                                    Personnel
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.username
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="user_name"
                                    placeholder="Enter User name"
                                    autoFocus
                                    required
                                    {...register("username")}
                                  />

                                  <p className="errors">
                                    {errors.username?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="referenceId"
                                    className="form-label"
                                  >
                                    reference Number
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.referenceId
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="referenceId"
                                    placeholder="Enter reference number"
                                    required
                                    {...register("referenceId")}
                                  />

                                  <p className="errors">
                                    {errors.referenceId?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="phoneNumber"
                                    className="form-label"
                                  >
                                    Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.phone
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="phone_number"
                                    placeholder="Enter Phone Number"
                                    required
                                    {...register("phone")}
                                  />

                                  <p className="errors">
                                    {errors.phone?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="amount"
                                    className="form-label"
                                  >
                                    Amount
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.amount
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="amount"
                                    placeholder="Enter Amount"
                                    required
                                    {...register("amount")}
                                  />

                                  <p className="errors">
                                    {errors.amount?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="currency"
                                    className="form-label"
                                  >
                                    Select Currency
                                  </label>
                                  <select
                                    className="form-control"
                                    id="currency"
                                    {...register("currency")}
                                  >
                                    <option value="" disabled>
                                      Select Currency
                                    </option>
                                    {currencyList.map((currency) => (
                                      <option key={currency} value={currency}>
                                        {currency}
                                      </option>
                                    ))}
                                  </select>

                                  <p className="errors">
                                    {errors.currency?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="channel"
                                    className="form-label"
                                  >
                                    Channel
                                  </label>
                                  <select
                                    className="form-control"
                                    id="channel"
                                    {...register("channel")}
                                  >
                                    <option value="" disabled>
                                      Select Channel
                                    </option>
                                    <option value="Android">Android</option>
                                    <option value="iOS">iOS</option>
                                    <option value="Web">Web</option>
                                  </select>
                                  <p className="errors">
                                    {errors.channel?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <label
                                    htmlFor="description"
                                    className="form-label"
                                  >
                                    Reason for TopUp
                                  </label>
                                  <textarea
                                    className={
                                      errors.amount
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="description"
                                    placeholder="Enter Description"
                                    required
                                    {...register("description")}
                                  />

                                  <p className="errors">
                                    {errors.description?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="extra" className="form-label">
                                    Transaction Number
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.extra
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="extra"
                                    placeholder="Enter Transaction Number"
                                    required
                                    {...register("extra")}
                                  />

                                  <p className="errors">
                                    {errors.extra?.message}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default TopUpsReports;
