export const ERROR_NOTIFICATION   = "ERROR_NOTIFICATION";
export const SUCCESS_NOTIFICATION = "SUCCESS_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

export const presentNotification = (notification) => {
  return (dispatch) => {
    let type = notification.type;
    switch (type) {
      case "success":
        dispatch(successNotification(notification));
        break;

      case "error":
        dispatch(errorNotification(notification));
        break;

      default:
        dispatch(clearNotification());
        break;
    }
  }
}


const successNotification = (data) => {

  return {
    type: SUCCESS_NOTIFICATION,
    payload: data
  };
  
};

const errorNotification = () => {
  return {
    type: ERROR_NOTIFICATION,
  };
};

const clearNotification = () => {
  return {
    type: CLEAR_NOTIFICATION,
  };
};