import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, usePagination } from "react-table";
import EmptyData from "../../../../components/EmptyData/Index";
// import { ReactComponent as Empty  } from '../../../../assets/empty.svg'
import { CAMPAIGNCOLUMNS } from "./CampaignColumns";
import { fetchcampaigns } from "../../../../redux-store/Actions/campaign-action";
import { fetchCampaignsApi } from "../../../../api/promotion";
import Loader from "react-loader-spinner";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

function CampaignsTable() {
  const columns = useMemo(() => CAMPAIGNCOLUMNS, []);
  const dispatch = useDispatch();
  const { reduxcampaigns } = useSelector((state) => state.campaigns) || {};
  const [campaigns, setCampaign] = useState([]);
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));

  useEffect(() => {
    fetchCampaignsApi(user.token)
      .then((res) => {
        console.log(res.data.data);
        setCampaign(res.data.data ? res.data.data : []);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const data = campaigns || [];

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    let modifiedData = data.map((d) => {
      let r = d;
      delete r.createdBy;
      delete r.updatedAt;
      delete r.createdAt;
      delete r.id;
      delete r.performance;
      return r;
    });
    let ws = XLSX.utils.json_to_sheet(modifiedData);
    let wb = { Sheets: { modifiedData: ws }, SheetNames: ["modifiedData"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `Rifaly Campaigns ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,

    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    dispatch(fetchcampaigns(user.token));
  }, [dispatch]);

  return (
    <div>
      <div className="row align-items-end my-4">
        <div className="col"></div>

        <div className="col-auto">
          <Link to="/campaigns/create" className="btn btn-primary lift">
            <span>
              <i className="fe fe-plus"></i>
            </span>
            Create Campaign
          </Link>
        </div>
      </div>

      <div className="row mt-2 body-content">
        {campaigns && campaigns.length >= 1 ? (
          <div className="col-md-12">
            {/* the table here */}

            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">All Campaigns</h4>

                <div>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handleExport}
                  >
                    Export
                  </button>
                </div>
              </div>

              <table {...getTableProps()} className="table table-sm card-table">
                <thead>
                  {headerGroups.map((headerGroups) => (
                    <tr {...headerGroups.getHeaderGroupProps()}>
                      {headerGroups.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                    Showing {pageIndex === 0 ? 1 : pageIndex * pageSize + 1} to{" "}
                    {pageIndex > 10
                      ? campaigns.length
                      : pageIndex * pageSize + pageSize > campaigns.length
                      ? campaigns.length
                      : pageIndex * pageSize + pageSize}{" "}
                    of {campaigns.length} elements
                  </span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => previousPage()}
                  >
                    <i className="fe fe-chevron-left"></i>
                  </button>
                  <span className="table-stat">Page {pageIndex + 1}</span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => nextPage()}
                  >
                    <i className="fe fe-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name="Campaign" path="/campaigns/create" />
        )}
      </div>
    </div>
  );
}

export default CampaignsTable;
