import React, {useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, usePagination, useGlobalFilter} from 'react-table';

import { AnimatePresence, motion } from "framer-motion"
import { TRANSACTIONCOLUMNS } from './archiveColumns/TransactionColumns';
import { fetchReaderTransactionArchives } from '../../../../redux-store/Actions/archive-action';
import ArchiveFilter from './ArchiveFilter';
import { ReactComponent as Empty  } from '../../../../assets/empty.svg';





function TransactionArchiveTable() {


  const columns = useMemo(() => TRANSACTIONCOLUMNS, [])
  const dispatch = useDispatch();

  const { transactions } = useSelector(
    (state) => state.transactions
  );  

  const data = transactions;

  const tableInstance = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  }, useGlobalFilter, useSortBy, usePagination);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter, 

    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter },

  } = tableInstance

  const spring = useMemo(
    () => ({
      type: 'spring',
      damping: 50,
      stiffness: 100,
      rotate: 360 
    }),
    []
  )

  useEffect(() => {
    window.scrollTo(0,0);
    dispatch(fetchReaderTransactionArchives());
  }, [dispatch]);


  return (
    <div>





      <div className="row align-items-end my-4">
        <div className="col">
          <ArchiveFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
          />

        </div>

        <div className="col-auto">
         
        </div>

      </div> 


      <div className="row mt-2 body-content">
        { data && data.length >= 1 ? ( 
          <div className="col-md-12">
          
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">
                        Registered  
                      </h4>
                      
                      <div>
                        <button className="btn btn-outline-primary btn-sm">Export</button>
                      </div>
                  </div>



                  <table {...getTableProps()} className="table table-sm card-table">
                      <thead>
                              {
                                headerGroups.map((headerGroups) => (
                                  <tr {...headerGroups.getHeaderGroupProps()}>
                                      {
                                        headerGroups.headers.map(column => (
                                          <th {...column.getHeaderProps(column.getSortByToggleProps())}> 
                                            {column.render('header')}
                                          
                                          </th>
                                        ))
                                      }
                                      
                                    </tr>
                                ))
                              }

                        </thead>

                        <tbody {...getTableBodyProps()}>
                        <AnimatePresence>
                          { page.map((row) => {
                            prepareRow(row)
                            return (
                              <tr {...row.getRowProps({
                                layoutTransition: spring,
                                exit: { opacity: 0, maxHeight: 0 },
                              })}>
                                { row.cells.map((cell) => {
                                  return <motion.td {...cell.getCellProps({
                                    layoutTransition: spring,
                                    exit: { opacity: 0.5, maxHeight: 0 },
                                  })}>{cell.render('Cell')}</motion.td>
                                })}
                              </tr>
                            )}
                          )}
                          </AnimatePresence>
                        </tbody>

                  </table>


                </div>
              </div>
            </div>

            <div className='row mb-5'>
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                    Showing  { pageIndex === 0 ? 1 : (pageIndex * pageSize) + 1 } to { pageIndex > 10 ?  data.length  : ((pageIndex * pageSize) + pageSize) > data.length ? data.length : (pageIndex * pageSize) + pageSize } of { data.length } {' '} elements 
                  </span>
                  <button className="btn btn-round btn-sm btn-pagination"  onClick={() => previousPage()}>
                    <i className="fe fe-chevron-left"></i>
                  </button>
                  <span className="table-stat">
                    Page {pageIndex + 1}
                  </span>
                  <button className="btn btn-round btn-sm btn-pagination" onClick={() => nextPage()}> 
                          <i className="fe fe-chevron-right"></i>
                  </button>
                </div>                     
              </div>
            </div>

          </div>
        ) : (
          <div className="page-empty">
          <div className="card card-fill">
            <div className="card-body text-center">
    
              <Empty />
    
              <h3 className="mt-4">No Transaction Archive</h3>
    
              <p className="text-muted m-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae ut venenatis nisl, elit, ipsum enim pretium. Quam a, feugiat pharetra tortor 
              </p>
    
              
            </div>
          </div>
        </div>
        )}
        </div> 


      
      
    </div>
  )
}

export default TransactionArchiveTable