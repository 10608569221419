import { 
  FETCH_STAFF_DETAILS_LOADING,
  FETCH_STAFF_DETAILS_SUCCESS
 } from "../Actions/staff-action";


const staffDetailInitialState = {
  staff: [],
  isLoading: false,
};


export default function staffDetailReducer(state = staffDetailInitialState, action) {
  switch (action.type) {
    case FETCH_STAFF_DETAILS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_STAFF_DETAILS_SUCCESS: {
      return {
        ...state,
        staff: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}