import { fetchTelcosApi, fetchAdminDetailsApi } from "../../api/user";

export const FETCH_TELCOS_LOADING = "FETCH_TELCOS_LOADING";
export const FETCH_TELCOS_SUCCESS = "FETCH_TELCOS_SUCCESS";
export const FETCH_TELCO_DETAILS_LOADING = "FETCH_TELCO_DETAILS_LOADING";
export const FETCH_TELCO_DETAILS_SUCCESS = "FETCH_TELCO_DETAILS_SUCCESS";



export const fetchTelcoPatners = () => {
  return (dispatch) => {
    dispatch(fetchTelcosLoading());
    return fetchTelcosApi()
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchTelcosSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};



export const fetchTelcoDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchTelcoDetailsLoading());
    return fetchAdminDetailsApi(id)
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchTelcoDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


//  staff 

const fetchTelcosLoading = () => {
  return {
    type: FETCH_TELCOS_LOADING,
  };
};


const fetchTelcosSuccess = (data) => {
  return {
    type: FETCH_TELCOS_SUCCESS,
    payload: data,
  };
};


//  staff details

const fetchTelcoDetailsLoading = () => {
  return {
    type: FETCH_TELCO_DETAILS_LOADING,
  };
};

const fetchTelcoDetailsSuccess = (data) => {
  return {
    type: FETCH_TELCO_DETAILS_SUCCESS,
    payload: data,
  };
};
