import React ,  { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Smile  } from '../../../assets/smile.svg';
import { useForm } from "react-hook-form"; 
import {
    updateCorporateSubscriptionGroupsApi,
    updateCorporateSubscriptionGroupsApi_,
    createCorporateSubscriptionGroupsApi,
    createStaffsApi,
    fetchCorporateSubscriptionGroupsApi,
    fetchCorporatesApi,
  } from "../../../api/corporate";
import { fetchCompany } from '../../../redux-store/Actions/company-action';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";




function UpdateSubscriptionGroup(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const action = props.data.status  === "ACTIVE" ? "Suspend" : "Activate";

  console.log(props.data);

  const { 
    handleSubmit
  } = useForm();


  const onSubmit = (data) => {
    setLoading(true);

    data.id = props.data.id;
    data.status = (props.data.status === "ACTIVE" ? "INACTIVE" : "ACTIVE");
    console.table(data)
    console.log("check id",props.data.id)
    console.log("check data",props.data)


    updateCorporateSubscriptionGroupsApi_(data).then(res => {
      setLoading(false)
      console.log(res.data)
      handleClose()

      fetchCorporateSubscriptionGroupsApi();

      toast.success(`Subscription Group ${ action === 'Suspend' ? 'Suspended' : 'Activated'} successfully`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate("/corporates/subscription-groups", { replace: true });

      
    }).catch(error => {
      setLoading(false)
      console.log(error)
    })
  }


  return (


  <div>
      <span className='update-link' onClick={handleShow}>
        { action }
      </span>

      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="modal-90w"
      >

        <div className="row">
          <div className="col"></div>
          <div className="col-auto">
            <div className='close-btn'>
            <button type="button" className="btn-close mr-5" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div>
          </div>
        </div>
      
         
      


        <Modal.Body>
          

          <div className='modal-image-holder'>
            <Smile />
          </div>

          <div className="modal-details">
            <h2>
              Are You Sure ?
            </h2>
            <p>Are you sure you want to {action}: <b>{props.data.companyName}</b></p>
          </div>



          <div className="bottom-space">
            <div className="left">
              <button className='btn btn-outline-danger btn-small text-black' onClick={handleClose}>
                Cancel
              </button>
            </div>
            <div className="col-auto">
              <form onSubmit={handleSubmit(onSubmit)} className="p-3">

                { loading ? (
                  <span className="btn-primary btn-small">
                    <Loader
                      type="TailSpin" 
                      color="#ffffff"
                      height={20} 
                      width={20} />
                  </span>
                ) : (
                  <button className="btn btn-primary btn-small" type="submit">Yes, {action}</button>
                )}

              </form>
            </div>
          </div>
          
        </Modal.Body>



     
         
         
      
      </Modal>
  </div>
  );
}

export default UpdateSubscriptionGroup;

