import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Offline } from "react-detect-offline";
import DashboardWrapper from "../../../components/DashboardWrapper";
import { createPublisherUserApi, createAdminUserApi } from "../../../api/auth";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchPublisherApi } from "../../../api/company";
import { fetchCompany } from "../../../redux-store/Actions/company-action";

const schema = yup
  .object({
    firstName: yup.string().required().min(3).max(255).label("First Name"),
    lastName: yup.string().required().min(3).max(255).label("Last Name"),
    email: yup.string().required().min(3).max(255).label("Email Address"),
    // phone: yup.string().required().min(3).max(255).label('Phone Number')
  })
  .required();

function CreateUser() {
  var user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const dispatch = useDispatch();

  const [phone, setPhone] = useState();
  const [role, setRole] = useState();
  const { companies, isLoading } = useSelector((state) => state.companies);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(fetchCompany());
  }, [dispatch]);

  const onSubmit = (data, e) => {
    setLoading(true);
    data.phone = phone;
    if (role === 2) {
      data.companyId = e.target.companyId.value;
      data.fullName = data.firstName + " " + data.lastName;
      createPublisherUserApi(data, user.token)
        .then((res) => {
          setLoading(false);

          if (res.data.error && res.data.error != null) {
            alert("error creating user");
          } else {
            e.target.reset();
            toast.success("User Created successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            toPage("/users/admins");
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            toast.error(error.response.data.error.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    } else if (role === 1) {
      createAdminUserApi(data, user.token)
        .then((res) => {
          setLoading(false);
          if (res.data.error && res.data.error != null) {
            alert("error creating user");
          } else {
            e.target.reset();
            toast.success("User Created successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            toPage("/users/admins");
          }
        })
        .catch((error) => {
          setLoading(false);

          if (error.response) {
            // client received an error response (5xx, 4xx)

            if (error.response.status === 500) {
              toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }

            if (error.response.status === 422) {
              const respErrors = error.response.data.errors;

              respErrors.forEach((object, index) => {
                var values = Object.keys(object).pop();
                toast.error(`${values}:  ${object[values]}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
            }

            if (error.response.status === 303) {
              toast.error(error.response.data.error.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          } else {
            toast.error(error.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  };

  const navigate = useNavigate();

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  function goBack(e) {
    e.preventDefault();
    navigate(-1);
  }

  const handleRoleChange = (e) => {
    setRole(parseInt(e.target.value));
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={goBack}>
                      Users
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create User
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card mt-2">
                    <Offline>
                      <div className="offline">You are Offline</div>
                    </Offline>

                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Create User</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-12">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="firstName"
                                    className="form-label"
                                  >
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.firstName
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="first_name"
                                    placeholder="Enter First Name"
                                    autoFocus
                                    required
                                    {...register("firstName")}
                                  />

                                  <p className="errors">
                                    {errors.firstName?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="firstName"
                                    className="form-label"
                                  >
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.lastName
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="last_name"
                                    placeholder="Enter Last Name"
                                    required
                                    {...register("lastName")}
                                  />

                                  <p className="errors">
                                    {errors.lastName?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="firstName"
                                    className="form-label"
                                  >
                                    Email Address
                                  </label>
                                  <input
                                    type="email"
                                    className={
                                      errors.email
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="email"
                                    placeholder="Enter Email Address"
                                    required
                                    {...register("email")}
                                  />

                                  <p className="errors">
                                    {errors.email?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="firstName"
                                    className="form-label"
                                  >
                                    Phone Number
                                  </label>
                                  <div className="input-group">
                                    <PhoneInput
                                      required
                                      placeholder="Enter phone number"
                                      defaultCountry="TZ"
                                      value={phone}
                                      withCountryCallingCode={true}
                                      onChange={setPhone}
                                    />
                                  </div>
                                  {/* <input type="tel" 
                                          className={ errors.phone ? "form-control is-invalid" : "form-control"}  
                                          id="phone_number" 
                                          placeholder="Enter Phone Number"
                                          required
                                          {...register("phone")} /> */}

                                  <p className="errors">
                                    {errors.phone?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="role" className="form-label">
                                    Role
                                  </label>
                                  <select
                                    className="form-control"
                                    {...register("role")}
                                    onChange={handleRoleChange}
                                  >
                                    <option selected disabled>Select Role</option>
                                    <option value="1">Admin</option>
                                    <option value="2">Publisher</option>
                                    <option value="3">Reader</option>
                                  </select>

                                  <p className="errors">
                                    {errors.role?.message}
                                  </p>
                                </div>
                              </div>
                              {role && role === 2 ? (
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label
                                      htmlFor="role"
                                      className="form-label"
                                    >
                                      Select Company
                                    </label>
                                    <select
                                      className="form-control"
                                      {...register("companyId")}
                                      required
                                    >
                                      <option selected disabled>Select Company</option>
                                      {companies.map((c) => {
                                        return (
                                          <option key={c.id} value={c.id}>
                                            {c.companyName}
                                          </option>
                                        );
                                      })}
                                    </select>

                                    <p className="errors">
                                      {errors.role?.message}
                                    </p>
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Send Invitation
                                  </button>
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CreateUser;
