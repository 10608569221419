import React, { useEffect, useState } from 'react'
import DashboardWrapper from '../../../../components/DashboardWrapper';
import { useNavigate } from "react-router-dom";
import Navs from "../navs";
import RevenueSummary from './PublisherRevenue';

function PublisherRevenue () {
  const navigate = useNavigate();

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  return (
    <DashboardWrapper>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
          <div className="header mt-md-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard" onClick={toDashboard}>
                    Dashboard
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Publishers
                </li>
              </ol>
            </nav>

            <div className="header-body">
              <h6 className="header-pretitle">Overview</h6>

              <h1 className="header-title">Publishers</h1>
            </div>
          </div>

          <div className="content">
            <Navs></Navs>

            <RevenueSummary />
          </div>
        </div>
      </div>
    </div>
  </DashboardWrapper>
    
  )
}

export default PublisherRevenue;
