import React, { useState, useEffect,useCallback } from 'react'
import { useHistory } from 'react-router';
import { Offcanvas } from 'react-bootstrap';
import { useForm } from "react-hook-form"; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import api_url from "../../../api/api_config"

import { fetchGenres } from '../../../redux-store/Actions/genre-action';
import { updateGenreApi } from '../../../api/publisher';



const schema = yup.object({
  name: yup.string().required().min(3).max(255).label('Please provide name'),
}).required()


function GenreDetail(props) {

  const genre = props.data;
  const [show, setShow] = useState(false);
 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="me-2 text-success" onClick={handleShow}>
        View
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
        <div className="">

          <h6 className="header-pretitle">
            View
          </h6>


          <h1 className="header-title">
            Genre Details 
          </h1>

          </div>

          <hr />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={(e)=>{e.preventDefault()}}> 

            <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">Genre Name</label>
                    <input type="text" 
                        className={  "form-control"}  
                        id="name" 
                        placeholder="Enter a name"
                        value={genre.name}
                       />


                  </div>
                </div>


                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="description" className="form-label">Description</label>
                    <input type="text" 
                        className={  "form-control"}  
                        id="description" 
                        placeholder="Enter description"
                        value={genre.description} />


                  </div>
                </div>

                <div className="col-md-6">
                    <div className='col-md-8'>
                    <img className="cover-image" src={api_url.STORAGE_URL+genre.cover} />
                    </div>
                

                </div>
            </div>

              
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default GenreDetail
