import axios from "axios";
import api_config from "../api/api_config";

const baseURL = api_config.CORPORATE_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchCorporateStaffsApi = () => {
  return api({
    url: `staffs`,
    method: "GET",
  });
};

export const fetchCorporatesApi = () => {
  return api({
    url: `company`,
    method: "GET",
  });
};

export const fetchCorporateByIdApi = (id) => {
  return api({
    url: `company/${id}`,
    method: "GET",
  });
};

export const createCorporatesApi = (data, options) => {
  return api({
    url: `company`,
    data: data,
    method: "POST",
    options,
  });
};

export const deleteCorporateApi = (id) => {
  return api({
    url: `companies/`,
    data: id,
    method: "PATCH",
  });
};

export const updateCorporateStatusApi = (data, options) => {
  return api({
    url: `company/${data.id}`,
    data: data,
    method: "PUT",
  });
};
export const updateCorporatesApi = (data, options) => {
  return api({
    url: `company/${data.id}`,
    data: data,
    method: "PUT",
    options,
  });
};
export const createStaffsApi = (data, headers) => {
  return api({
    url: `staffs`,
    data: data,
    method: "POST",
    headers: headers,
  });
};
export const createStaffsApiBulk = (data, headers) => {
  return api({
    url: `staffs/bulk`,
    data: data,
    method: "POST",
    headers: headers,
  });
};

//update staff
export const updateCorporateStaffsApi = (data, headers) => {
  return api({
    url: `staffs/${data.id}`,
    data: data,
    method: "PATCH",
    headers: headers,
  });
};
//Enable Corporate Admin to Read
export const enableAdminToReadApi = (data, headers) => {
  return api({
    url: `company/enable-admin`,
    data: data,
    method: "POST",
    headers: headers,
  });
};

//subcription groups
export const fetchCorporateSubscriptionGroupsApi = () => {
  return api({
    url: `subscription-groups`,
    method: "GET",
  });
};
export const updateCorporateSubscriptionGroupsApi = (data, headers) => {
  return api({
    url: `subscription-groups/${data.id}`,
    data: data,
    method: "PUT",
    headers: headers,
  });
};
export const updateCorporateSubscriptionGroupsApi_ = (data, headers) => {
  return api({
    url: `subscription-groups/${data.id}`,
    data: data,
    method: "PUT",
    headers: headers,
  });
};
export const deleteCorporateSubscriptionGroupsApi = (data, headers) => {
  return api({
    url: `subscription-groups/${data.id}`,
    data: data,
    method: "delete",
    headers: headers,
  });
};
export const createCorporateSubscriptionGroupsApi = (data) => {
  return api({
    url: `subscription-groups`,
    method: "POST",
    data: data,
  });
};

//corporate admins
export const fetchCorporateAdminsApi = () => {
  return api({
    url: `staffs/admins`,
    method: "GET",
  });
};

//corporate readers
export const fetchCorporateReadersApi = () => {
  return api({
    url: `staffs/readers`,
    method: "GET",
  });
};

