import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Loader from 'react-loader-spinner';
import { fetchCompany } from '../../../../redux-store/Actions/company-action';
import { useDispatch, useSelector } from 'react-redux';
import { createReportRateApi } from '../../../../api/publisher';
import { toast } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';

const schema = yup
  .object({
    title: yup.string().required().min(3).max(255).label(''),
  })
  .required();

export default function CreateCorporatePrice() {
  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state.companies);

  const data = companies;

  const {
    register,
    handleSubmit,
    handleInputChange,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleCountryChange = (event) => {
    event.preventDefault();
    setSelectedOption(event.target.value);
  };
  const navigate = useNavigate();

  const handleCancelForm = (e) => {
    e.preventDefault();
    window.location.reload();
    // navigate('/settings/corporatepricing');
  };

  const onSubmit = (data, e) => {
    setLoading(true);

        const staffNo = getValues('staffNo');
        const price = getValues('price');
        const publisherId = getValues('publisherId');
        const [country, currency] = selectedOption.split('-');

        const requestData = {
            count_range: staffNo,
            publisherId: parseInt(publisherId), 
            price: parseInt(price),
            currency,
            country,
        };


    createReportRateApi(requestData)
      .then((res) => {
        setLoading(false);

        toast.success('Corporate Price Created successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(() => {
          window.location.reload();
        }, 4000);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error(error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    setLoading(false);
  };

  function toDashboard(e) {
    e.preventDefault();
    navigate('/dashboard');
  }

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    dispatch(fetchCompany());
  }, [dispatch]);

  return (
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-12 col-xl-12'>

            <div className='row mt-3'>
              <div className='col-md-12'>
                <div className='card pt-3'>
                  <div className='px-4 pt-3'>
                    <div className=''>
                      <h1 className='header-title'>Corporate Pricing</h1>
                    </div>

                    <hr />
                  </div>

                  <div className='card-body'>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className='p-3'>
                      <div className='row g-3 mb-3'>
                        <div className='col-12 col-md-6 mb-1'>
                          <label
                            htmlFor='category'
                            className='form-label'>
                            Number of staff
                          </label>
                          <select
                            {...register('staffNo')}
                            onChange={handleInputChange}
                            className='form-select'>
                            <option selected disabled>Select Category</option>
                            <option value='1-9'>1-9</option>
                            <option value='10-19'>10-19</option>
                            <option value='20-49'>20-49</option>
                            <option value='50-99'>50-99</option>
                            <option value='100-199'>100-199</option>
                            <option value='200-more'>200+</option>
                          </select>
                        </div>

                        <div className='col-12 col-md-6 mb-1'>
                          <label
                            htmlFor='price'
                            className='form-label'>
                            Price
                          </label>
                          <input
                            type='number'
                            {...register('price')}
                            className='form-control'
                            placeholder='Price'
                            required
                          />
                        </div>

                        <div className='col-12 col-md-6 mb-2'>
                          <label
                            htmlFor='currency'
                            className='form-label'>
                            Currency
                          </label>
                          <select id="country" name="country" onChange={handleCountryChange} value={selectedOption}
                            className='form-select'>
                            <option value="">Select a Country</option>
                            <option value="TZ-TZS">TZS - Tanzania</option>
                            <option value="KE-KSH">KSH - Kenya</option>
                            <option value="UG-UGX">UGX - Uganda</option>
                          </select>
                        </div>
                        <div className='col-12 col-md-6 mb-1'>
                          <label
                            htmlFor='company'
                            className='form-label'>
                            Publisher
                          </label>
                          <select
                            className='form-select'
                            autoFocus
                            {...register('publisherId')}>
                            <option selected disabled>Select Publisher</option>
                            {data.map((company) => (
                              <option
                                key={company.id}
                                value={company.id}>
                                {company.companyName}{' '}
                              </option>
                            ))}
                          </select>

                          <p className='errors'>{errors.company?.message}</p>
                        </div>
                      </div>
                      <div className='row mt-3'>
                        <div className='col'></div>
                        <div className='col-auto'>
                            <div className='d-flex justify-content-between'>
                                {!loading && (
                                    <button
                                    onClick={(e) => handleCancelForm(e)}
                                    className='btn btn-outline-secondary mx-2 fl-right'>
                                    Cancel
                                    </button>
                                )}

                            {loading ? (
                                <span className='btn btn-primary'>
                                <Loader
                                    type='TailSpin'
                                    color='#ffffff'
                                    height={20}
                                    width={20}
                                />
                                </span>
                            ) : (
                                <button
                                onClick={onSubmit}
                                className='btn btn-primary'
                                type='submit'>
                                Submit
                                </button>
                            )}
                            </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
