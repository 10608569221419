import React, { Component} from 'react';
import DashboardWrapper from '../../components/DashboardWrapper';


class Support extends Component {
  render() {
    return (
      <DashboardWrapper>
        <div className="page-content">
          veritatis! Molestiae et aut quam dignissimos provident ut atque a. Ratione ipsum laudantium nulla.
        </div>
      </DashboardWrapper>
    )
  }
}


export default Support;