import React from 'react'
import { useNavigate } from "react-router-dom";
import DashboardWrapper from '../../../components/DashboardWrapper';
import CorporateNavs from './CorporateNavs';
import CorporateStaffTable from './CorporateStaffTable';



export default function CorporateStaff() {

  const navigate = useNavigate();

  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }


  return (
    <DashboardWrapper>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">
           
            <div className="header mt-md-3">

              <nav aria-label="breadcrumb mb-2">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/dashboard" onClick={toPage('/dashboard')} >Dashboard</a>
                        
                      </li>
                      <li className="breadcrumb-item">
                        <a href="/corporates"  onClick={toPage('/corporates')} >Corporates</a>    
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">Staffs</li>
                    </ol>
              </nav>

              <div className="header-body">

      
                <h6 className="header-pretitle">
                  Overview
                </h6>

                
                <h1 className="header-title">
                  Corporate Staffs
                </h1>

              </div>
            </div>


            <div className="content">
              <CorporateNavs></CorporateNavs>

              <CorporateStaffTable />

            </div>

            </div>


          </div>
        </div>
      </DashboardWrapper>
  )
}
