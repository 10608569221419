
import { SUCCESS_NOTIFICATION,
         ERROR_NOTIFICATION,
         CLEAR_NOTIFICATION
        } from "../Actions/notification-action";


const initialState = {
  notifications: [],
  isLoading: false,
};


export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    
    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        notifications: [],
      };
    }

    case ERROR_NOTIFICATION: {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    case SUCCESS_NOTIFICATION: {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    default:
      return state;
  }
}

