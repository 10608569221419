import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchChannelDetails } from "../../../../redux-store/Actions/channel-action";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import Loader from "react-loader-spinner";
import UpdateChannelCover from "./UpdateChannelCover";
import api_config from "../../../../api/api_config";

function ChannelDetails(props) {
  const dispatch = useDispatch();
  const { channel, isLoading } = useSelector((state) => state.channelDetails);
  const navigate = useNavigate();

  const [changeVisibility, setChangeVisibility] = useState(false);

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  let { id } = useParams();

  useEffect(() => {
    dispatch(fetchChannelDetails(id));
  }, [id, dispatch]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/podcasters")}>
                      Publishers
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/podcasters/channel">Channels</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Channel Details
                  </li>
                </ol>
              </nav>
            </div>
            {/* end of header */}

            <div className="content">
              <div className="row mt-5">
                <div className="col-md-12">
                  <div className="card">
                    {isLoading ? (
                      <div className="loader-holder d-flex justify-content-center align-items-center">
                        <Loader
                          type="BallTriangle"
                          heigth="100"
                          width="100"
                          color="green"
                          ariaLabel="loading-indicator"
                        />
                      </div>
                    ) : (
                      <div>
                        {channel && (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="px-4 pt-4">
                                <h6 className="header-pretitle">Overview</h6>

                                <h1 className="header-title">
                                  Channel Information
                                </h1>

                                <hr />
                              </div>

                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="image-holder">
                                      <div className="img-place">
                                        <div
                                          className="img-cover"
                                          onMouseEnter={(e) => {
                                            setChangeVisibility(true);
                                          }}
                                          onMouseLeave={(e) => {
                                            setChangeVisibility(false);
                                          }}
                                        >
                                          <img
                                            src={
                                              channel.cover &&
                                              channel.cover.includes("https")
                                                ? `${channel.cover}`
                                                : `${api_config.STORAGE_URL}${channel.cover}`
                                            }
                                            className="img-fluid"
                                            alt={channel.channelName}
                                          />
                                        </div>

                                        <div
                                          className={
                                            changeVisibility
                                              ? "img-change"
                                              : "img-change hidden"
                                          }
                                          onMouseEnter={(e) => {
                                            setChangeVisibility(true);
                                          }}
                                          onMouseLeave={(e) => {
                                            setChangeVisibility(false);
                                          }}
                                        >
                                          <UpdateChannelCover
                                            name={channel}
                                          ></UpdateChannelCover>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <section className="detail-section">
                                  <div className="detail-title">
                                    <div className="row">
                                      <div className="col">
                                        <h3>Basic Information</h3>
                                      </div>

                                      <div className="col-auto">
                                        <Link
                                          to={{
                                            pathname: `/podcasters/channel/edit/${channel.id}`,
                                          }}
                                          state={{ data: channel }}
                                          className="btn-link"
                                        >
                                          <i className="fe fe-edit-3"></i>
                                          <span className="ml-2">Edit</span>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="detail-contents">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="dc-title">
                                          Channel Name
                                        </div>
                                        <div className="dc-body">
                                          {channel.channelName}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="dc-title">Country</div>
                                        <div className="dc-body">
                                          {channel.country}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="dc-title">
                                          Description
                                        </div>
                                        <div className="dc-body">
                                          {channel.description}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="dc-title">
                                          Created On
                                        </div>
                                        <div className="dc-body">
                                          {formatDate(channel.createdAt)}{" "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>

                                <section className="detail-section mt-4">
                                  <div className="detail-title">
                                    <div className="row">
                                      <div className="col">
                                        <h3>Billing Information</h3>
                                      </div>

                                      <div className="col-auto">
                                        <Link
                                          to={{
                                            pathname: `/podcasters/channel/edit/${channel.id}`,
                                          }}
                                          state={{ data: channel }}
                                          className="btn-link"
                                        >
                                          <i className="fe fe-edit-3"></i>
                                          <span className="ml-2">Edit</span>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="detail-contents">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="dc-title">
                                          Bank Name
                                        </div>
                                        <div className="dc-body">
                                          {channel.publisher_billing_info
                                            ? channel.publisher_billing_info
                                                .bankName
                                            : "N/A"}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="dc-title">
                                          Bank Branch
                                        </div>
                                        <div className="dc-body">
                                          {channel.publisher_billing_info
                                            ? channel.publisher_billing_info
                                                .bankBranch
                                            : "N/A"}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="dc-title">
                                          Account Name
                                        </div>
                                        <div className="dc-body">
                                          {channel.publisher_billing_info
                                            ? channel.publisher_billing_info
                                                .accountName
                                            : "N/A"}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="dc-title">
                                          Account Number
                                        </div>
                                        <div className="dc-body">
                                          {channel.publisher_billing_info
                                            ? channel.publisher_billing_info
                                                .accountNumber
                                            : "N/A"}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="dc-title">
                                          Swift Code
                                        </div>
                                        <div className="dc-body">
                                          {channel.publisher_billing_info
                                            ? channel.publisher_billing_info
                                                .swiftCode
                                            : "N/A"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default ChannelDetails;
