import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DashboardWrapper from '../../../components/DashboardWrapper';
import Dropzone from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Loader from 'react-loader-spinner';
import { updatePromotionsApi } from '../../../api/promotion';
import { fetchPromotions, fetchPromotionDetails } from '../../../redux-store/Actions/promotion-action';
import { toast } from 'react-toastify';

const schema = yup.object({
  title: yup.string().required().max(60).label('Promotion Title'),
  description: yup.string().required().max(255).label('Promotion Description'),
  segment: yup.number().required().label('User Segment'),
  action: yup.string().required().label('Promotion CTA'),
  channel: yup.string().required().label('Promotion Channel'),
  language: yup.string().required().label('Language'),
}).required();

function EditPromotion() {
  const dispatch = useDispatch();
  const location = useLocation()
  
  const { promotionId } = useParams();
  const navigate = useNavigate();
  const promotionsData = location.state && location.state.promotions ? location.state.promotions : [];
  const [promotion,setPromotion] = useState(promotionsData);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loadedImageUrl, setLoadedImageUrl] = useState(promotion.banner_link);
  const [fileInfo, setFileInfo] = useState({});

  const [loading, setLoading] = useState(false);
  const [changeVisibility, setChangeVisibility] = useState(false);
  const [promotionBanner, setPromotionBanner] = useState({});
  const [isBannerModified, setIsBannerModified] = useState(false);

  const onDropPromotionBanner = useCallback((uploadedFiles) => {
    readFiles(uploadedFiles);
    setIsBannerModified(true); // Set the banner modified state to true when the banner is changed
  }, []);

  function readFiles(files) {
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reader aborted');
      reader.onerror = () => console.log('file reader failed');
      reader.onload = () => {
        const dataUrl = reader.result;
        setPromotionBanner({
          url: dataUrl,
        });
      };

      // Read the file as a data url
      reader.readAsDataURL(file);
    });
  }

  // Submit edited data
  const onSubmit = (data, e) => {
    setLoading(true);

    const isFormModified = Object.keys(data).some((key) => data[key] !== promotion[key]);

    if (isFormModified && Object.keys(promotionBanner).length !== 0) {
      // Both form inputs and promotion cover are modified
      data.promotionBanner = promotionBanner;
    } else if (isFormModified) {
      // Only form inputs are modified
      // Remove the promotionBanner from data object
      delete data.promotionBanner;
    } else if (isBannerModified && Object.keys(promotionBanner).length !== 0) {
      // Only the promotion cover is modified
      data.promotionBanner = promotionBanner;
    } else {
      // No changes, revert to original banner
      data.banner = promotion.banner_link;
    }

    updatePromotionsApi(promotionId, data)
      .then((res) => {
        setLoading(false);
        toast.success('Promotion Updated successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch(fetchPromotions());
        navigate('/promotions');
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        // Handle error messages
      });
  };

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="/dashboard" onClick={toPage("/dashboard")} >Dashboard</a>  
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      <a href="/promotions" onClick={toPage("/promotions/")} >Promotions</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Edit Promotions</li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card mt-2">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>
                        <h1 className="header-title">Edit Promotion</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-8">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="img-place">
                                    <div
                                      className="banner-cover"
                                      onMouseEnter={(e) => {
                                        setChangeVisibility(true);
                                      }}
                                      onMouseLeave={(e) => {
                                        setChangeVisibility(false);
                                      }}
                                    >
                                      <img
                                        src={promotion.banner_link}
                                        className="img-fluid"
                                        alt={promotion.title}
                                      />
                                    </div>

                                    <div
                                      className={
                                        changeVisibility
                                          ? 'img-change'
                                          : 'img-change hidden'
                                      }
                                      onMouseEnter={(e) => {
                                        setChangeVisibility(true);
                                      }}
                                      onMouseLeave={(e) => {
                                        setChangeVisibility(false);
                                      }}
                                    >
                                    <div className="col-md-6">
                                    <Dropzone onDrop={onDropPromotionBanner} multiple={false} accept=".png">
                                      {({ getRootProps, getInputProps }) => (
                                        <section>
                                          <div
                                              {...getRootProps({
                                                className: 'dropzone dropzone-single mb-3',
                                              })}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="upload-content p-4">
                                                <p className="btn btn-sm btn-primary">
                                                      Change image
                                                </p>
                                            </div>
                                        </div>
                                       
                                        </section>
                                      )}
                                    </Dropzone>

                                    </div>
                          
                                    </div>
                                    <div className="col-md-6 col-lg-5 ml-auto d-flex align-items-center mt-4 mt-md-0">
                                        {  (isBannerModified && promotionBanner) && (isBannerModified && promotionBanner.url) ? <img className="preview" src={promotionBanner.url} alt="Uploaded" /> :(
                                              <aside className="file-preview">
                                                {promotionBanner && (
                                                  <div>
                                                    <img className="viewer-mini" src={promotionBanner.url} style={{transform: 'scale(0.80)'}} />
                                                  </div>
                                                )}
                                              </aside>
                                            ) 
                                        }
                                    </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Promotion Title
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.title
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    id="title"
                                    placeholder="Enter Promotion Title"
                                    defaultValue={promotion.title}
                                    autoFocus
                                    required
                                    {...register('title')}
                                  />

                                  <p className="errors">{errors.title?.message}</p>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Promotion Description
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.description
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    id="description"
                                    placeholder="Enter Promotion Description"
                                    defaultValue={promotion.description}
                                    autoFocus
                                    required
                                    {...register('description')}
                                  />

                                  <p className="errors">{errors.description?.message}</p>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Select User Segment
                                  </label>
                                  <input
                                    type="number"
                                    className={
                                      errors.segment
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                    }
                                    id="segment"
                                    placeholder="Enter Promotion Title"
                                    defaultValue={promotion.segment || 0}
                                    autoFocus
                                    required
                                    {...register('segment')}
                                  />

                                  <p className="errors">{errors.segment?.message}</p>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="action" className="form-label">
                                    Promotion CTA
                                  </label>
                                  <select
                                    className="form-select form-control"
                                    defaultValue={promotion.action}
                                    {...register('action')}
                                    required
                                  >
                                    <option defaultValue="1">Subscribe Now</option>
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Promotion Channel
                                  </label>
                                  <select className="form-select form-control" 
                                    defaultValue={promotion.channel}
                                    {...register("channel")} required>
                                    <option value="" disabled selected>
                                      Select Target Channel
                                    </option>
                                    <option defaultValue="ALL">All Channels</option>
                                    <option defaultValue="ANDROID">Android</option>
                                    <option defaultValue="IOS">IOs</option>
                                    <option defaultValue="MOBILE">Mobile devices (Android and IOs)</option>
                                    <option defaultValue="WEB">Web</option>
                                  </select>


                                  <p className="errors">{errors.channel?.message}</p>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="language" className="form-label">
                                    Language
                                  </label>
                                  <select
                                    className="form-select form-control"
                                    defaultValue={promotion.language}
                                    {...register('language')}
                                    required
                                  >
                                    <option value="" disabled selected>Select Promotion Language</option>
                                    <option defaultValue="EN">English</option>
                                    <option defaultValue="SW">Swahili</option>
                                  </select>

                                  <p className="errors">{errors.language?.message}</p>
                                </div>
                              </div>
                            </div>

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button className="btn btn-primary" type="submit">
                                    Update
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default EditPromotion;
