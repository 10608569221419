import React, {useEffect, useState, useCallback, forwardRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import {fetchCompanyDetails} from "../../../../redux-store/Actions/company-action";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchCompanyIssueCategoriesApi,
  fetchCompanyIssuesApi,
  updatePublicationsApi,
} from "../../../../api/publisher";
import Dropzone from "react-dropzone";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Document, Page, pdfjs} from "react-pdf";
import api_config from "../../../../api/api_config";
import {DateTime} from "luxon";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const schema = yup
  .object({
    // publisherId: yup.integer(),required().label("Promotion Title")
  })
  .required();

export default function EditBook() {
  const dispatch = useDispatch();
  const {company} = useSelector((state) => state.company);

  const data = company;

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const location = useLocation();
  const navigate = useNavigate();

  const [ loading, setLoading ] = useState(false);
  const [ categories, setCategories ] = useState([]);
  const [ topStories, setTopStories ] = useState([ "first" ]);
  const [ showAddStoriesButton, setshowAddStoriesButton ] = useState(true);
  const [ progressValue, setProgressValue ] = useState(0);
  const [ publication, setPublication ] = useState(location.state);
  const [ selectedIssue, setselectedIssue ] = useState();
  const [ cat, setCat ] = useState();
  const [ issues, setIssues ] = useState([]);
  const [ issueNo, setIssueNo ] = useState();

  const [ publishDate, setPublishDate ] = useState();

  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const updatePublication = async (data) => {
    const config = {
      headers: {Authorization: `Bearer ${user.token}`},
    };
    let result = await updatePublicationsApi(data, config);

    return result;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {};
    data.releaseDate = publishDate;
    data.publisherId = company.id;
    data.id = publication.id;
    data.categoryId = cat && parseInt(cat) > 0 ? cat : publication.categoryId;
    data.issueId = selectedIssue ? selectedIssue.id : publication.issue.id;
    data.issueNo = issueNo ? issueNo : publication.issueNo;

    if (topStories && topStories.length > 0) {
      for (let i = 0; i < topStories.length; i++) {
        let tp = topStories[ i ];
        if (publication.publication_stories.length > 0) {
          let mystory = publication.publication_stories[ i ];
          let story = document.getElementById(`${tp}${i}`).value.trim();
          if (story && story.length > 0 && story != mystory) {
            data[ `${tp}Story` ] = story;

          }
        }
      }
    }
    console.log("🚀 ~ file: EditPublication.js:114 ~ onSubmit ~ data", data);

    if (
      !data.publisherId ||
      !data.issueId ||
      !data.categoryId ||
      data.categoryId == "0" ||
      data.issueId == "0"
    ) {
      toast.error("Please provide all required information", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    } else {
      updatePublication(data)
        .then((res) => {
          console.log("res: ", res.data);

          e.target.reset();
          setLoading(false);
          let message = "";
          if (res.data.status == 403 || res.data.status == 409) {
            message = res.data.error;
            toast.error(message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            message = res.data.message;
            toast.success(message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            navigate("/publishers/publications");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          toast.error("Failed to upate publication", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    let pub = location.state;
    console.log("🚀 ~ file: EditPublication.js:158 ~ useEffect ~ pub:", pub);

    setPublication(pub);
    dispatch(fetchCompanyDetails(pub.publisherId));
    fetchCompanyIssueCategoriesApi(pub.publisherId).then((result) => {
      console.log(result.data.data);
      const newCats = result.data.data;
      //set categories
      setCategories(newCats);
      // setIssues(issues.filter(is=>is.categoryId == cat))
    });
    fetchCompanyIssuesApi(pub.publisherId, pub.categoryId).then((result) => {
      console.log(result.data.data);
      const newIssues = result.data.data.filter(
        (i) => i.categoryId == pub.categoryId,
      );
      //set data
      setIssues(newIssues);
      setselectedIssue(newIssues.length > 0 ? newIssues[ 0 ] : publication.issue);
    });
  }, [ dispatch ]);

  useEffect(() => {
    topStories.length === 3
      ? setshowAddStoriesButton(false)
      : setshowAddStoriesButton(true);
  }, [ topStories.length ]);

  const findIssue = (issues, id) => {
    return issues.find((element) => {
      return element.id === parseInt(id);
    });
  };

  const addStory = () => {
    console.log(topStories.length);

    switch (topStories.length) {
      case 1:
        setTopStories([ ...topStories, "second" ]);
        break;

      case 2:
        setTopStories([ ...topStories, "third" ]);
        break;

      default:
        break;
    }
  };

  const handleCancelForm = (e) => {
    e.preventDefault();
    navigate("/publishers/publications");
  };

  const catChange = (e) => {
    console.log(e.target.value);
    const catId = e.target.value;
    setCat(catId);
    setIssues([]);
    // fetch categories in the pubs
    fetchCompanyIssuesApi(data.id, catId).then((result) => {
      console.log(result.data.data);
      const newIssues = result.data.data;
      //set data
      setIssues(newIssues);
      setselectedIssue(newIssues.length > 0 ? newIssues[ 0 ] : publication.issue);
    });
  };

  const issueChange = (e) => {
    var selected = e.target.value;
    const iss = findIssue(issues, selected);
    setselectedIssue(iss);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const filterPassedDate = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    return currentDate.getTime() - 24 * 3600 * 1000 <= selectedDate.getTime();
  };

  const CustomDatePicker = () => {
    const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
      <input
        type='text'
        className='example-custom-input form-control'
        onClick={onClick}
        ref={ref}
        value={value}
        onChange={() => {}}
      />
    ));
    return (
      <DatePicker
        selected={new Date(publication.publication_timestamp)}
        dateFormat={"dd-MM-yyyy HH:mm"}
        timeFormat={"HH:mm"}
        minDate={new Date().getTime}
        showTimeSelect
        timeIntervals={5}
        onChange={(date) => setPublishDate(date)}
        filterDate={filterPassedDate}
        filterTime={filterPassedTime}
        customInput={<ExampleCustomInput />}
      />
    );
  };
  return (
    <DashboardWrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12'>
            <div className='header mt-md-3 mb-5'>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li
                    className='breadcrumb-item'
                    aria-current='page'
                    onClick={toPage("/publishers/publications")}>
                    Publications
                  </li>
                  <li
                    className='breadcrumb-item active'
                    aria-current='page'>
                    Edit Publication
                  </li>
                </ol>
              </nav>
            </div>

            <div className='row mt-3'>
              <div className='col-md-12'>
                <div className='card pt-3'>
                  <div className='card-heade px-4 pt-4'>
                    <div className=''>
                      <h6 className='header-pretitle'>Overview</h6>

                      <h1 className='header-title'>Edit Publication</h1>
                    </div>

                    <hr />
                  </div>

                  <div className='card-body'>
                    {loading ? (
                      <div className='col-md-12 progress-container'>
                        <progress
                          value={progressValue}
                          max={100}
                          id='progress'>
                          {progressValue}%
                        </progress>{" "}
                        <label>{progressValue}%</label>
                      </div>
                    ) : null}

                    <form
                      onSubmit={handleSubmit}
                      className='p-3'>
                      <div className='row mb-3'>
                        <div className='col-md-6'>
                          {publication && publication.cover ? (
                            <img
                              className='viewer-mini'
                              src={`${api_config.STORAGE_URL}${publication.cover}`}
                              alt={publication.issue.title}
                            />
                          ) : null}
                        </div>
                      </div>

                      <div className='row g-3 mb-3'>
                        <div className='col-md-4 mb-3'>
                          <div className='form-group'>
                            <label
                              htmlFor='company'
                              className='form-label'>
                              Publisher
                            </label>
                            <select
                              disabled
                              value={data.id}
                              className='form-select'>
                              {
                                <option
                                  key={company.id}
                                  value={company.id}>
                                  {company.companyName}{" "}
                                </option>
                              }
                            </select>
                          </div>
                        </div>

                        <div className='col-12 col-md-4 mb-3'>
                          <label
                            htmlFor='validationServer02'
                            className='form-label'>
                            Category
                          </label>
                          <select
                            onChange={catChange}
                            className='form-select'
                            defaultValue={cat}>
                            {categories.map((category) => (
                              <option
                                value={parseInt(category.categoryId)}
                                key={category.categoryId}>
                                {" "}
                                {category.Name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className='col-12 col-md-4 mb-3'>
                          <label
                            htmlFor='validationServer02'
                            className='form-label'>
                            Title
                          </label>
                          <select
                            defaultValue={selectedIssue ? selectedIssue.id : -1}
                            onChange={issueChange}
                            className='form-select'>
                            <option
                              value={"0"}
                              disabled>
                              Select Issue Title
                            </option>
                            {issues.map((issue) => (
                              <option
                                key={issue.id}
                                value={issue.id}>
                                {" "}
                                {issue.title}{" "}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className='col-12 col-md-4 mb-3'>
                          <label
                            htmlFor='validationServer02'
                            className='form-label'>
                            Date and Time of Release
                          </label>
                          <CustomDatePicker
                            className='form-control'
                            dateFormat={"dd-MM-yyyy H:mm:ss"}
                            selected={publishDate}
                            minDate={new Date().getTime}
                            showTimeSelect
                            timeIntervals={5}
                            onChange={(date) => setPublishDate(date)}
                            filterDate={filterPassedDate}
                            filterTime={filterPassedTime}
                            readOnly
                          />
                        </div>

                        <div className='col-12 col-md-4 mb-3'>
                          <label
                            htmlFor='validationServer02'
                            className='form-label'>
                            Price
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            id='validationServer02'
                            placeholder='Issue Price'
                            defaultValue={
                              selectedIssue ? selectedIssue.price : ""
                            }
                            disabled
                          />
                        </div>

                        <div className='col-12 col-md-4 mb-3'>
                          <label
                            htmlFor='validationServer02'
                            className='form-label'>
                            Issue No
                          </label>
                          <input
                            type='text'
                            onChange={(e) => {
                              setIssueNo(e.target.value.trim());
                            }}
                            defaultValue={
                              publication ? publication.issueNo : ""
                            }
                            className='form-control'
                            id='issueNo'
                            placeholder='Issue No'
                            required
                          />
                        </div>

                        {topStories.map((topStory, i) => (
                          <div
                            key={i}
                            className='col-12 col-md-12'>
                            <div className='mb-1'>
                              <label
                                htmlFor='topStory'
                                className='form-label capitalize'>
                                {topStory === "first" ? "" : topStory} Top Story
                              </label>
                              <textarea
                                type='text'
                                rows='5'
                                className='form-control'
                                id={`${topStory}${i}`}
                                placeholder='Enter top story of the newspaper'></textarea>
                            </div>
                          </div>
                        ))}

                        <div className='col-12 col-md-12'>
                          <div className=''>
                            {showAddStoriesButton && (
                              <span
                                className='btn btn-link'
                                onClick={addStory}>
                                + Add more{" "}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='row mt-3'>
                        <div className='col'></div>
                        <div className='col-auto'>
                          <div className='d-flex'>
                            {!loading && (
                              <button
                                onClick={handleCancelForm}
                                className='btn btn-outline-secondary mx-2 fl-right'>
                                Cancel
                              </button>
                            )}

                            {loading ? (
                              <button className='btn btn-primary'>
                                <Loader
                                  type='TailSpin'
                                  color='#ffffff'
                                  height={20}
                                  width={20}
                                />
                              </button>
                            ) : (
                              <button
                                className='btn btn-primary'
                                type='submit'>
                                Update
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
