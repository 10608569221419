import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { Offcanvas } from 'react-bootstrap';
import { useForm } from "react-hook-form"; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import { fetchReaderDetails, fetchReaders } from '../../../redux-store/Actions/reader-action';
import { updateCorporateReaderApi, updateReaderApi } from '../../../api/user';
import {updateCompanyStatusApi} from '../../../api/company';



const schema = yup.object({
  firstName: yup.string().required().min(3).max(255).label('First Name'),
}).required()


function UserEdit({ data, ...props }) {


  const reader = data;

  const { register, 
    handleSubmit,
    formState:{ errors }
  } = useForm({
    resolver: yupResolver(schema), 
    mode: 'onBlur'
  });




  const [loading, setLoading] = useState(false);
  const [currentRole,setCurrentRole] = useState(reader.user ? reader.user.role : reader.role);
  const [ currentStatus, setCurrentStatus ] = useState(reader.user ? reader.user.status:reader.status);

  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  var id  = parseInt(reader.id) ;

  console.log(id)


  useEffect(() => {
    dispatch(fetchReaderDetails(id));
  }, [id, dispatch]);


  const navigate = useNavigate();



  const checkRoles = role => {
    if (typeof role == "string") {
      if (role == 'user') return 'User';
      else return 'Admin';
    }
    else {
       switch(role){
      case 3: 
      return "Reader";

      case 4:
        return "Corporate Reader";

      default: 
        return "Reader";
    }
    }
   
  }



  const onSubmit = async (data) => {

    setLoading(true)
      console.log(data)
      data.userId = reader.userId;
      data.status = currentStatus;
    if (reader.companyId) {
       await updateCorporateReaderApi(data)
        var path = "/users/readers/"
    }
    else {
      await updateReaderApi(data);
    }
    
        dispatch(fetchReaders());

        navigate(path);

  }
  const handleChangeStatus = (e) => {
    setCurrentStatus(e.target.value.trim());
  }

  const handleRoleChange = (e) => {
    setCurrentRole(e.target.value.trim());
  }


  return (
    <div>
      <div className="me-2 text-success" onClick={handleShow}>
        Edit
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
        <div className="">

          <h6 className="header-pretitle">
            Edit
          </h6>


          <h1 className="header-title">
            Edit Reader 
          </h1>

          </div>

          <hr />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={handleSubmit(onSubmit)}> 

            <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="firstName" className="form-label">First Name</label>
                    <input type="text" 
                        className={ errors.firstName ? "form-control is-invalid" : "form-control"}  
                        id="first_name" 
                        placeholder="Enter First name"
                        autoFocus
                        defaultValue={reader.firstName}
                        required
                        {...register("firstName")} />

                      <p className="errors">{errors.firstName?.message}</p>

                  </div>
                </div>


                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="lastName" className="form-label">First Name</label>
                    <input type="text" 
                        className={ errors.lastName ? "form-control is-invalid" : "form-control"}  
                        id="first_name" 
                        placeholder="Enter Last name"
                        autoFocus
                        defaultValue={reader.lastName}
                        required
                        {...register("lastName")} />

                      <p className="errors">{errors.lastName?.message}</p>

                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="firstName" className="form-label">Email Address</label>
                    <input type="email" 
                          className={ errors.email ? "form-control is-invalid" : "form-control"}  
                          id="email" 
                    defaultValue={reader.user ? reader.user.email : reader.email}
                          placeholder="Enter Email Address"
                          required
                          {...register("email")} />


                      <p className="errors">{errors.email?.message}</p>

                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="firstName" className="form-label">Phone Number</label>
                    <input type="text" 
                          className={ errors.phone ? "form-control is-invalid" : "form-control"}  
                          id="phone_number" 
                    defaultValue={reader.user ? reader.user.phone : reader.phone}
                          placeholder="Enter Phone Number"
                          required
                          {...register("phone")} />

                      <p className="errors">{errors.phone?.message}</p>

                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="role" className="form-label">Role</label>
                  <select className='form-control' defaultValue={checkRoles(currentRole)} disabled={reader.user} onChange={handleRoleChange}>
                    <option value="user">Reader</option>
                    <option value={"admin"}>Admin</option>
                    </select>

                

                      <p className="errors">{errors.role?.message}</p>

                  </div>
                </div>

                <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="status" className="form-label">Account Status</label> 
                  <select id="status" className='form-control' onChange={handleChangeStatus} defaultValue={currentStatus.toUpperCase()}>
                    <option value={"ACTIVE"}>ACTIVE</option>
                    <option value={"INACTIVE"}>INACTIVE</option>
                    <option value={"DELETED"}>DELETED</option>
                  </select>
              
                </div>
                </div>


            </div>

            <div className="row my-4">
                <div className="col"></div>
                  <div className="col-auto">
                    { loading ? (
                      <button className="btn btn-primary">
                        <Loader
                          type="TailSpin" 
                          color="#ffffff"
                          height={20} 
                          width={20} />
                      </button>
                    ) : (
                      <button className="btn btn-primary" type="submit" onClick={handleSubmit(onSubmit)}>Update User</button>
                    )}
                    
                </div>
            </div>
              
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default UserEdit
