import React, { useEffect } from 'react'
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import DashboardWrapper from '../../../../components/DashboardWrapper'
import { fetchStories } from '../../../../redux-store/Actions/story-action';

// import { updateIssuesApi } from "../../../../api/publisher";




function EditStory() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params   = useParams();
  const id = params.id 

  const { stories, isLoading } = useSelector(
    (state) => state.stories
  );

  const findStory = (id) => {
    return stories.find(element => {
      return element.id === id;
    })
  }

  const story = findStory(id);

  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }


  useEffect(() => {
    dispatch(fetchStories());
  },[dispatch]);

  return (
    <DashboardWrapper>
      <div className='container'>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="header mt-md-3 mb-5">
              <nav>
                <ol className='breadcrumb'>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage('/dashboard')} >Dashboard</a>  
                 </li>
                </ol>
              </nav>
            </div>

            <div className="row">
              <div className="card">
                { isLoading ? (
                  <div className="card-body">
                    <div className='loader-holder d-flex justify-content-center align-items-center'>
                      <Loader 
                      type="BallTriangle"
                      heigth="100"
                      width="100"
                      color="green"
                      ariaLabel="loading-indicator"
                    />
                    </div>
                  </div>
                ) : (
                  <div className="card-body">
                    { story && (
                      <div>
                        <div className="p-4">
                          <div>
                            <h6 className="header-pretitle">
                              Overview
                            </h6>

                            <h1 className="header-title">
                              Title { story.title}
                            </h1>

                          </div>

                          <hr />
                        </div>

                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>


          </div>
        </div>
      </div>
    </DashboardWrapper>
  )


  // if(isLoading) {
  //   return(
  //     <DashboardWrapper>
  //      <div className="container">
  //        <div className="row justify-content-center">
  //          <div className="col-md-12">
  //            <div className="header mt-md-3 mb-5">
  //              <nav aria-label="breadcrumb">
  //                <ol className="breadcrumb">
  //                  <li className="breadcrumb-item">
  //                    <a href="/dashboard" onClick={toPage('/dashboard')} >Dashboard</a>  
  //                  </li>
  //                  <li className="breadcrumb-item" aria-current="page">Publishers</li>
  //                  <li className="breadcrumb-item" aria-current="page">Stories</li>
  //                  <li className="breadcrumb-item active" aria-current="page">Story Details</li>
  //                </ol>
  //              </nav>
  //            </div>

  //            <div className="row">
  //              <div className="card pt-3">
  //                <div className="card-body">
  //                  <div className='loader-holder d-flex justify-content-center align-items-center'>
  //                    <Loader 
  //                     type="BallTriangle"
  //                     heigth="100"
  //                     width="100"
  //                     color="green"
  //                     ariaLabel="loading-indicator"
  //                   />
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </DashboardWrapper>
  //   )
  // } else {
  //   return (
  //     <DashboardWrapper>
  //      <div className="container">
  //          <div className="row justify-content-center">
  //            <div className="col-12 col-lg-10 col-xl-12">
  //                <div className="header mt-md-3 mb-5">
  //                  <nav aria-label="breadcrumb">
  //                        <ol className="breadcrumb">
  //                          <li className="breadcrumb-item">
  //                            <a href="/dashboard" onClick={toPage('/dashboard')} >Dashboard</a>
                            
  //                          </li>
  //                          <li className="breadcrumb-item" aria-current="page">Publishers</li>
  //                          <li className="breadcrumb-item" onClick={toPage("/publishers/stories")}>Stories</li>
  //                          <li className="breadcrumb-item active" aria-current="page"> Edit </li>
  //                        </ol>
  //                  </nav>


  //                </div>
  //                { (stories && story) && (
  //                <div>here</div>
  //               )}

               


  //           </div>
  //         </div>

  //     </div>

  //     </DashboardWrapper>
  //   )
  // }


}

export default EditStory;
