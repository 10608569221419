import React, { useState } from "react";
import "./style.css";
import { ReactComponent as MootoLogo } from "../../assets/img/logo.svg";
import { ReactComponent as DashIcon } from "../../assets/img/dashboard.svg";
import { ReactComponent as UserIcon } from "../../assets/img/user.svg";
import { ReactComponent as WorkIcon } from "../../assets/img/Work.svg";
import { ReactComponent as PublisherIcon } from "../../assets/img/publisher.svg";
import { ReactComponent as PromotionIcon } from "../../assets/img/Ticket.svg";
import { ReactComponent as ReportIcon } from "../../assets/img/Chart.svg";
import { ReactComponent as AudioIcon } from "../../assets/img/audiomic.svg";
import { ReactComponent as EmailIcon } from "../../assets/img/mail-setting.svg";
import { NavLink } from "react-router-dom";

export default function Sidebar() {
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const toggleSubmenu = () => {
    console.log("Hola ")
    setSubmenuOpen(!submenuOpen);
  };
  return (
    <div className="sidenav">
      <nav
        className="navbar navbar-vertical fixed-start navbar-expand-md "
        id="sidebar"
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* <a className="navbar-brand" href=""> */}
          <MootoLogo className="mt-3" />
          {/* <img src="../../assets/img/logo.svg" className="navbar-brand-img mx-auto" alt="..." /> */}
          {/* </a> */}

          <hr className="divider" />

          <div className="collapse navbar-collapse" id="sidebarCollapse">
            <form className="mt-4 mb-3 d-md-none">
              <div className="input-group input-group-rounded input-group-merge input-group-reverse">
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <div className="input-group-text">
                  <span className="fe fe-search"></span>
                </div>
              </div>
            </form>

            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink to="/dashboard" className="nav-link">
                  <i className="fe">
                    <DashIcon />
                  </i>
                  <span>Dashboard</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/users" className="nav-link">
                  <i className="fe">
                    <UserIcon />
                  </i>
                  <span>Users</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/corporates" className="nav-link">
                  <i className="fe">
                    <WorkIcon />
                  </i>
                  <span>Corporate</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/publishers" className="nav-link">
                  <i className="fe">
                    <PublisherIcon />
                  </i>
                  <span>Publishers</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/podcasters/overview" className="nav-link">
                  <i className="fe">
                    <AudioIcon />
                  </i>
                  <span>Podcasters</span>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  to="/reports"
                  className={`nav-link ${submenuOpen ? "active" : ""}`}
                  onClick={toggleSubmenu}
                >
                  <i className="fe">
                    <ReportIcon />
                  </i>
                  <span>Reports</span>
                </NavLink>
                {submenuOpen ? (<>
                    <li className="nav-item submenu">
                      <NavLink to="/reports/topup" className="nav-link">
                        <i className="fe">
                          <PublisherIcon />
                        </i>
                        <span>Topup</span>
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink to="/reports/reversals" className="nav-link">
                        <i className="fe">
                          <PublisherIcon />
                        </i>
                        <span>Reversal</span>
                      </NavLink>
                    </li> */}
                    <li className="nav-item submenu">
                      <NavLink to="/reports/revenue" className="nav-link">
                        <i className="fe">
                          <ReportIcon />
                        </i>
                        <span>Revenue</span>
                      </NavLink>
                    </li>
                  </>):null
                }
              </li>
              <li className="nav-item">
                <NavLink to="/campaigns" className="nav-link">
                  <i className="fe">
                    <PromotionIcon />
                  </i>
                  <span>Promotions</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/emails" className="nav-link">
                  <i className="fe">
                    <EmailIcon />
                  </i>
                  <span>Emails</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    // </Router>
  );
}
