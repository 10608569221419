import React, { useEffect, useState} from 'react'
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignDetails } from '../../../../redux-store/Actions/campaign-action';
import DashboardWrapper from '../../../../components/DashboardWrapper'
import Loader from 'react-loader-spinner';
import api_config from "../../../../api/api_config"
import { DateTime } from "luxon";

function CampaignsDetails(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));

  const [changeVisibility, setChangeVisibility] = useState(false);
  let { campaignId } = useParams();
  const [isLoading]=useState();
  // const { Campaign, isLoading } = useSelector(
  //   (state) => state.Campaigns
  // );
  // const { Campaigns, isLoading } = useSelector((state) => state.Campaigns);
  const campaignsData = location.state && location.state.campaigns ? location.state.campaigns : [];
  const [campaign,setCampaign] = useState(campaignsData);

  useEffect(() => {
    dispatch(fetchCampaignDetails(campaignId, user.token));
  }, [campaignId, dispatch]);

  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }
  
  const formatDate = (dateString) => {
    return DateTime.fromISO(dateString).toLocaleString(DateTime.DATETIME_SHORT);
  };

  return (
    <DashboardWrapper>
      <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">  
              <div className="header mt-md-3">

                <nav aria-label="breadcrumb mb-2">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/dashboard" onClick={toPage("/dashboard")} >Dashboard</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="/Campaigns" onClick={toPage("/Campaigns")} >Campaigns</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">Campaign Details</li>
                    </ol>
                </nav>

              </div>
              {/* end of header */}

              <div className="content">
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="card">


                    { isLoading ? (
                      <div className='loader-holder d-flex justify-content-center align-items-center'>
                      <Loader
                        type="BallTriangle"
                        heigth="100"
                        width="100"
                        color="green"
                        ariaLabel="loading-indicator"
                      />
                    </div>
                    ) : (
                      <div>
                        { campaign && (
                          <div className="row">
                             <div className="col-md-12">
                                <div className="px-4 pt-4">

                                  <h6 className="header-pretitle">
                                    Overview
                                  </h6>

                                  <h1 className="header-title">
                                  Campaign Information
                                  </h1>

                                  <hr />

                                </div>

                                <div className="card-body">
                                  <section className="detail-section"> 
                                    <div className="detail-title">
                                      <div className="row">
                                        <div className="col">
                                          <h3>Basic Information</h3>
                                        </div>

                                        <div className="col-auto">
                                        <Link to={{ pathname: `/Campaigns/edit/${campaignId}`}} state={{campaigns:campaign }}
                                          className="btn-link">
                                          <i className="fe fe-edit-3"></i>
                                          <span className="ml-2">Edit</span>
                                        </Link>
                                        </div>
                                      </div>
                                      

                                    </div>

                                    <div className="detail-contents">
                                      <div className="row">

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Campaigns Name
                                          </div>
                                          <div className="dc-body">
                                            { campaign.title}
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Description
                                          </div>
                                          <div className="dc-body">
                                          { campaign.description}
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Campaigns Objective
                                          </div>
                                          <div className="dc-body">
                                          { campaign.objective}
                                          </div>

                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Campaigns Performance
                                          </div>
                                          <div className="dc-body">
                                          { campaign.performance}
                                          </div>

                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Campaigns Start Date
                                          </div>
                                          <div className="dc-body">
                                          {campaign.startDate}
                                          </div>

                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Campaigns End Date
                                          </div>
                                          <div className="dc-body">
                                          { campaign.endDate}
                                          </div>

                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Campaigns created Date
                                          </div>
                                          <div className="dc-body">
                                          {formatDate(campaign.createdAt)}
                                          </div>

                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Campaigns Status
                                          </div>
                                          <div className="dc-body">
                                          { campaign.status}
                                          </div>

                                        </div>

                                      </div>
                                    </div>

                                  </section>

                                </div>

                              </div>
                          </div>
                        )}
                      </div>
                    )}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>


          </div>

    </DashboardWrapper>
  )
}

export default CampaignsDetails
