import { FETCH_GENRES_LOADING, 
         FETCH_GENRES_SUCCESS
        } from "../Actions/genre-action";


const initialState = {
  genres: [],
  isLoading: false,
};


export default function genreReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GENRES_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_GENRES_SUCCESS: {
      return {
        ...state,
        genres: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

