import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import moment from 'moment';
import { DateRangePicker } from 'rsuite';



import DatePicker from 'react-datepicker'

function TableFilter ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchTitle,
  name
}) {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter)
  const [title, setTilte] = useState(searchTitle)


  let today = new Date();
  let minDate = new Date();
  minDate = minDate.setFullYear(minDate.getFullYear() - 3); 
  let lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  lastMonth.setDate(1); 


  

  console.log(minDate);

 
  

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null);
  const onChangeDates = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const { combine, allowedMaxDays, afterToday, before } = DateRangePicker;


  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 300)

  return (
    <div className='row m-0'>

      <div className='col-md-4 m-0 p-0'>
        <div className='input-group input-group-merge input-group-reverse'>
          <input
            type='text'
            value={value || ''}
            onChange={e => {
              setValue(e.target.value)
              onChange(e.target.value)
            }}
            className='form-control m-0'
            placeholder={'Search ' + name}
          />
          <div className='input-group-text' id='inputGroupReverse'>
            <span className='fe fe-search'></span>
          </div>
        </div>
      </div>

      {/* <div className="col-md-4">
      <DateRangePicker 
        appearance="default" 
        placeholder="Today"
        size="sm"
        limitstartyear={3}
        limitendyear={0}
        disabledDate={combine(before(minDate), afterToday())}
        defaultCalendarValue={[new Date(lastMonth), new Date()]}
      />
      </div> */}
    </div>
  )
}

export default TableFilter
