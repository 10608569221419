import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage  from "redux-persist/lib/storage"
import companies from "./company";
import staffs from "./staff";
import staff from './staffDetails.reducer';
import company from "./companyDetails.reducer";
import channelReducer, { channelDetailsReducer } from './channel';
import readers from "./reader";
import reader from "./readerDetails.reducer";
import admins from "./admin";
import admin from "./adminDetails.reducer";
import telcos from "./telco";
import promotions from "./promotion";
import issues from "./issue";
import issue from "./issueDetails.reducer";
import publications from "./publication";
import ratedreport from "./ratedreport";
import stories from "./story";
import notifications from "./notification";
import genres from "./genre";
import { publicationDetailsReducer as publication } from "./publication";
import corporates from './corporate';
import { transactions } from "./archive";
import {summary} from "./user";
import topup from "./topup";
import campaign from "./campaign";
import promoCode from "./promoCode";


const persistConfig = {
  key: 'root', 
  storage, 
  whiteList: [ 
    'companies', 'company', 
    'staffs', 
    'readers', 'reader',  
    'admins', 
    'promotions', 
    'issues', 'issue', 
    'publications', 'publication',  
    'admin', 
    'stories', 
    'notifications', 
    'genres',
    'ratedreport',
    'corporates', 
    'transactions', 
    'topup',
    'campaign',
    'channel',
    'promoCode',
    'summary']
}

const Reducers = combineReducers({
  companies,
  company,
  staffs,
  staff,
  readers,
  reader, 
  admins,
  admin,
  telcos,
  promotions,
  issues, 
  issue, 
  publications, 
  publication,
  stories, 
  notifications,
  genres,
  ratedreport, 
  corporates,
  transactions,
  topup,
  campaign,
  channel: channelReducer, 
  channelDetails: channelDetailsReducer, 
  promoCode,
  summary
});

export default persistReducer(persistConfig, Reducers)

// export default Reducers;
