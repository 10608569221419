import {
    FETCH_TOPUPS_LOADING,
    FETCH_TOPUPS_SUCCESS,
    FETCH_TOPUPS_FAILURE,
    FETCH_TOPUP_DETAILS_LOADING,
    FETCH_TOPUP_DETAILS_SUCCESS,
    FETCH_TOPUP_DETAILS_FAILURE
  } from '../Actions/topup-action';
  
  const initialState = {
    topups: [],
    isLoading: false,
    error: null,
  };
  
  export default function topupReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_TOPUPS_LOADING: {
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      }
      case FETCH_TOPUPS_SUCCESS: {
        return {
          ...state,
          topups: action.payload,
          isLoading: false,
          error: null,
        };
      }
      case FETCH_TOPUPS_FAILURE: {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      }
      case FETCH_TOPUP_DETAILS_LOADING:
        return { ...state, isLoading: true };
      case FETCH_TOPUP_DETAILS_SUCCESS:
        return { ...state, topups: action.payload, isLoading: false };
      case FETCH_TOPUP_DETAILS_FAILURE:
        return { ...state, error: action.payload, isLoading: false };
      default:
        return state;
    }
  }
  