import React, {useEffect, useState, useCallback, forwardRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Loader from 'react-loader-spinner';
import DashboardWrapper from '../../../../components/DashboardWrapper';
import {fetchCompany} from '../../../../redux-store/Actions/company-action';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchCompanyIssueCategoriesApi,
  fetchCompanyIssuesApi,
} from '../../../../api/publisher';
import Dropzone from 'react-dropzone';
import {toast} from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Document, Page, pdfjs} from 'react-pdf';
import PdfWebViewer from './PdfWebViewer';
import axios from 'axios';
import api_config from '../../../../api/api_config';
import {fetchPublisherApi} from '../../../../api/company';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const schema = yup
  .object({
    // publisherId: yup.integer(),required().label("Promotion Title")
  })
  .required();

export default function CreateBook() {
  const [ companies, setCompanies ] = useState([]);

  const [ loading, setLoading ] = useState(false);
  const [ publicationFile, setPublicationFile ] = useState({});
  const [ selectedIssue, setselectedIssue ] = useState({
    categoryId: 1,
    id: 1,
    price: 0,
    status: 'ACTIVE',
    title: 'Paper',
  });
  const [ loadedFile, setLoadedFile ] = useState([]);
  const [ categories, setCategories ] = useState([]);
  const [ issues, setIssues ] = useState([]);
  const [ publishDate, setPublishDate ] = useState("");
  const [ topStories, setTopStories ] = useState([ 'first' ]);
  const [ showAddStoriesButton, setshowAddStoriesButton ] = useState(true);
  const [ show, setShow ] = useState(true);
  const [ currentPage, setCurrentPage ] = useState(1);
  const [ progressValue, setProgressValue ] = useState(0);
  const [ company, setCompany ] = useState(
    companies && companies.length > 0 ? companies[ 0 ] : {},
  );
  const files = loadedFile.map((f) => <li key={f.name}>{f.name}</li>);

  const onDropPublicationFile = useCallback((uploadedFiles) => {
    readFiles(uploadedFiles);
    // console.log(loadedFile)
  }, []);

  function readFiles(files) {
    console.table(files);
    setLoadedFile(files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reader aborted');

      reader.onerror = () => console.log('file reader failed');

      reader.onload = () => {
        const dataUrl = reader.result;
        // console.log(dataUrl)
        setShow(true);
        setPublicationFile({url: dataUrl});
      };

      // Read the file as a data url
      reader.readAsDataURL(file);
    });
  }

  const navigate = useNavigate();

  const controller = new AbortController();
  const createPublicationsApi = async (data) => {
    const config = {
      signal: controller.signal,
      onUploadProgress: (progressEvent) => {
        const completed = Math.floor(
          (progressEvent.loaded / progressEvent.total) * 100,
        );
        console.log('progress: ', completed);
        setProgressValue(completed);

        if (completed >= 100) {
          setLoading(false);
          // alert('Your publication is being processed; kindly check the list after a short while');
          // showNotification(
          //   'Your publication is being processed; kindly check the list after a short while.',
          //   5000,
          //   true,
          // );
        }
      },
    };
    let result = axios.post(
      `${api_config.PUBLISHER_URL}publications`,
      data,
      config,
    );
    return result;
  };
  const cancelSubmit = () => {
    setLoading(false);
    controller.abort();
    showNotification('Operation has been cancelled', 5000);
  };
  const showNotification = (message, timeout, shouldNavigate) => {
    toast.info(message, {
      position: 'top-right',
      autoClose: timeout,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (shouldNavigate) navigate('/publishers/publications');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {};
    data.issueId = selectedIssue.id;
    data.categoryId = selectedIssue.categoryId;
    data.price = document.getElementById('price').value.trim();
    data.publicationFile = publicationFile;
    data.releaseDate = Date.parse(publishDate.toISOString());
    data.publisherId = company.id;
    data.issueNo = document.getElementById('issueNo').value.trim();
    if (topStories.length > 0) {
      topStories.forEach((tp) => {
        data[ `${tp}Story` ] = document.getElementById(`${tp}Story`).value.trim();
      });
    }

    if (
      !data.publisherId ||
      !data.issueId ||
      !data.publicationFile.url ||
      !data.categoryId
    ) {
      toast.error('Please provide all required information', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(true);
      try {
        const res = await createPublicationsApi(data);
        console.log(
          '🚀 ~ file: CreatePublication.js:170 ~ handleSubmit ~ res:',
          res,
        );

        if (res.status == 201) {
          toast.success(res.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          navigate('/publishers/publications');
        } else {
          toast.error(res.data.error, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(JSON.stringify(error));
        console.log("🚀 ~ file: CreatePublication.js:196 ~ handleSubmit ~ error:", error)
        
        if (error.message && error.message.includes('status code 409')) {
          toast.error('Duplicates are not allowed', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          // navigate('/publishers/publications');
        } else {
          setLoading(false);
          toast.error('Failed to create publication', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
    };

    function toDashboard(e) {
      e.preventDefault();
      navigate('/dashboard');
    }

    function toPage(path) {
      return function (e) {
        e.preventDefault();
        navigate(path);
      };
    }

    useEffect(async () => {
      const response = await fetchPublisherApi();
      if (response && response.status == 200 && response.data.data.length > 0) {
        const companies = response.data.data;
        setCompanies(companies)
        initializeCatIssue(companies[ 0 ])
        setCompany(companies[ 0 ])
      }
    }, []);

    useEffect(() => {
      topStories.length === 3
        ? setshowAddStoriesButton(false)
        : setshowAddStoriesButton(true);
    }, [ topStories.length ]);

    const findIssue = (issues, id) => {
      return issues.find((element) => {
        return element.id === parseInt(id);
      });
    };

    const addStory = () => {
      console.log(topStories.length);

      switch (topStories.length) {
        case 1:
          setTopStories([ ...topStories, 'second' ]);
          break;

        case 2:
          setTopStories([ ...topStories, 'third' ]);
          break;

        default:
          break;
      }
    };

    const pubChange = (e) => {
      let pubId = e.target.value;
      let selectedCompany = companies.find((c) => c.id == parseInt(pubId));
      setCompany(selectedCompany);
      initializeCatIssue(selectedCompany);
    };
    const handleCancelForm = (e) => {
      e.preventDefault();
      navigate('/publishers/publications');
    };

    const catChange = (e) => {
      console.log(e.target.value);
      const catId = parseInt(e.target.value);
      setIssues([]);
      let newIssues = company.issues.filter(
        (issue) =>
          issue.categoryId == catId && issue.status.toLowerCase() == 'active',
      );
      //set data
      setIssues(newIssues);
      if (newIssues.length > 0) setselectedIssue(newIssues[ 0 ]);
    };

    const issueChange = (e) => {
      var selected = e.target.value;
      const iss = findIssue(issues, selected);
      setselectedIssue(iss);
    };

    const filterPassedTime = (time) => {
      const currentDate = new Date();
      const selectedDate = new Date(time);

      return currentDate.getTime() < selectedDate.getTime();
    };
    const filterPassedDate = (date) => {
      const currentDate = new Date();
      const selectedDate = new Date(date);

      return currentDate.getTime() - 24 * 3600 * 1000 <= selectedDate.getTime();
    };


    const CustomDatePicker = () => {
      const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
        <input
          type='text'
          className='example-custom-input form-control'
          onClick={onClick}
          ref={ref}
          value={value}
          onChange={() => {}}
        />
      ));
      return (
        <DatePicker
          isClearable={true}
          selected={publishDate}
          dateFormat={'dd-MM-yyyy HH:mm'}
          timeFormat={'HH:mm'}
          minDate={new Date().getTime}
          showTimeSelect
          timeIntervals={30}
          onChange={(date) => setPublishDate(date)}
          filterDate={filterPassedDate}
          filterTime={filterPassedTime}
          customInput={<ExampleCustomInput />}
        />
      );
    };
    return (
      <DashboardWrapper>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-10 col-xl-12'>
              <div className='header mt-md-3 mb-5'>
                <nav aria-label='breadcrumb'>
                  <ol className='breadcrumb'>
                    <li className='breadcrumb-item'>
                      <a
                        href='/dashboard'
                        onClick={toDashboard}>
                        Dashboard
                      </a>
                    </li>
                    <li
                      className='breadcrumb-item'
                      aria-current='page'
                      onClick={toPage('/publishers/publications')}>
                      Publications
                    </li>
                    <li
                      className='breadcrumb-item active'
                      aria-current='page'>
                      Create Publication
                    </li>
                  </ol>
                </nav>
              </div>

              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div className='card pt-3'>
                    <div className='card-heade px-4 pt-4'>
                      <div className=''>
                        <h6 className='header-pretitle'>Overview</h6>

                        <h1 className='header-title'>Upload Publication</h1>
                      </div>

                      <hr />
                    </div>

                    <div className='card-body'>
                      <form
                        onSubmit={handleSubmit}
                        className='p-3'>
                        <div className='row mb-3'>
                          <div className='col-md-6'>
                            <Dropzone
                              onDrop={onDropPublicationFile}
                              multiple={false}
                              accept='.pdf'>
                              {({getRootProps, getInputProps}) => (
                                <section>
                                  <div className='mb-1'>
                                    <label className='form-label'>Upload</label>
                                  </div>
                                  <div
                                    {...getRootProps({
                                      className: 'dropzone dropzone-single mb-3',
                                    })}>
                                    <input
                                      {...getInputProps()}
                                      id='file-drop'
                                    />
                                    <div className='upload-content p-4'>
                                      <i className='fe fe-upload-cloud'></i>
                                      <p>Drag and drop or click to upload</p>
                                      <p className='btn btn-sm btn-outline-primary'>
                                        Browse from your files
                                      </p>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </Dropzone>

                            <aside>
                              <h4>Files</h4>
                              <ul>{files}</ul>
                            </aside>

                            {loadedFile ? (
                              <div>{loadedFile.name}</div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                          <div className='col-md-6'>
                            {publicationFile.url ? (
                              <Document
                                file={publicationFile.url}
                                onLoadSuccess={() => setCurrentPage(1)}>
                                <Page
                                  className='viewer-mini'
                                  scale={0.25}
                                  pageNumber={
                                    currentPage && currentPage > 0
                                      ? currentPage
                                      : 1
                                  }
                                />
                              </Document>
                            ) : null}
                          </div>
                        </div>
                        <div className='col'>
                          <div className=' mb-3'>
                            {publicationFile.url ? (
                              <PdfWebViewer
                                onHide={() => setShow(false)}
                                show={show}
                                link={publicationFile.url}
                                size={'small'}
                              />
                            ) : (
                              'No Preview'
                            )}
                          </div>
                        </div>
                        <div className='row g-3 mb-3'>
                          <div className='col-md-4 mb-3'>
                            <div className='form-group'>
                              <label
                                htmlFor='company'
                                className='form-label'>
                                Publisher
                              </label>
                              <select
                                onChange={pubChange}
                                defaultValue={company.id}
                                className='form-select'
                                id='publisherId'>
                                <option selected disabled>Select Company</option>
                                {companies.map((company) => {
                                  return (
                                    <option
                                      key={company.id}
                                      value={company.id}>
                                      {company.companyName}{' '}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className='col-12 col-md-4 mb-3'>
                            <label
                              htmlFor='validationServer02'
                              className='form-label'>
                              Category
                            </label>
                            <select
                              id='categoryId'
                              onChange={catChange}
                              className='form-select'
                              defaultValue={
                                categories.length > 0
                                  ? categories[ 0 ].categoryId
                                  : null
                              }>
                              <option selected disabled>Select Category</option>
                              {categories.map((category) => (
                                <option
                                  value={category.categoryId}
                                  key={category.categoryId}>
                                  {' '}
                                  {category.Name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className='col-12 col-md-4 mb-3'>
                            <label
                              htmlFor='validationServer02'
                              className='form-label'>
                              Title
                            </label>
                            <select
                              id='issueId'
                              defaultValue={selectedIssue.id}
                              onChange={issueChange}
                              className='form-select'>
                              <option selected disabled>Select Issue Title</option>
                              {issues.map((issue) => (
                                <option
                                  key={issue.id}
                                  value={issue.id}>
                                  {' '}
                                  {issue.title}{' '}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className='col-12 col-md-4 mb-3'>
                            <label
                              htmlFor='validationServer02'
                              className='form-label'>
                              Date and Time of Release
                            </label>
                            <CustomDatePicker
                              className='form-control'
                              dateFormat={'dd-MM-yyyy H:mm:ss'}
                              selected={publishDate}
                              minDate={new Date().getTime}
                              showTimeSelect
                              timeIntervals={15}
                              onChange={(date) => setPublishDate(date)}
                              filterDate={filterPassedDate}
                              filterTime={filterPassedTime}
                              readOnly
                              required
                            />
                          </div>

                          <div className='col-12 col-md-4 mb-3'>
                            <label
                              htmlFor='validationServer02'
                              className='form-label'>
                              Price
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Issue Price'
                              value={selectedIssue.price}
                              required
                              disabled
                              id='price'
                            />
                          </div>

                          <div className='col-12 col-md-4 mb-3'>
                            <label
                              htmlFor='validationServer02'
                              className='form-label'>
                              Issue No
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Issue No'
                              required
                              id='issueNo'
                            />
                          </div>

                          {topStories.map((topStory, i) => (
                            <div
                              key={i}
                              className='col-12 col-md-12'>
                              <div className='mb-1'>
                                <label
                                  htmlFor='validationServer02'
                                  className='form-label capitalize'>
                                  {topStory === 'first' ? '' : topStory} Top Story
                                </label>
                                <textarea
                                  type='text'
                                  rows='5'
                                  id={`${topStory}Story`}
                                  className='form-control'
                                  placeholder='Enter Top story Of the Newspaper'></textarea>
                              </div>
                            </div>
                          ))}

                          <div className='col-12 col-md-12'>
                            <div className=''>
                              {showAddStoriesButton && (
                                <span
                                  className='btn btn-link'
                                  onClick={addStory}>
                                  + Add more{' '}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col'>
                            {loading ? (
                              <div className='progress-container'>
                                <div className='mx-2 d-flex justify-content-between'>
                                  <label>Progress... {progressValue}%</label>
                                  <span
                                    className='progress-close'
                                    onClick={cancelSubmit}>
                                    x
                                  </span>
                                </div>
                                <progress
                                  value={progressValue}
                                  max={100}
                                  id='progress'>
                                  {progressValue}%
                                </progress>{' '}
                              </div>
                            ) : null}
                          </div>
                          <div className='col-auto'>
                            <div className='d-flex justify-content-between'>
                              {!loading && (
                                <button
                                  onClick={handleCancelForm}
                                  className='btn btn-outline-secondary mx-2 fl-right'>
                                  Cancel
                                </button>
                              )}

                              {loading ? (
                                <button className='btn btn-primary'>
                                  <Loader
                                    type='TailSpin'
                                    color='#ffffff'
                                    height={20}
                                    width={20}
                                  />
                                </button>
                              ) : (
                                <button
                                  className='btn btn-primary'
                                  type='submit'>
                                  Publish
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    );

    function initializeCatIssue(selectedCompany) {
      let issues = selectedCompany.issues;
      let catIds = [ ...new Set(issues.map((issue) => issue.categoryId)) ];
      let cats = catIds.map((id) => {
        let cat = {categoryId: id};
        switch (id) {
          case 1:
            cat.Name = 'Newspaper';
            break;
          case 2:
            cat.Name = 'Magazine';
            break;
          case 3:
            cat.Name = 'Book';
            break;
          case 4:
            cat.Name = 'Article';
            break;
          default:
            break;
        }
        return cat;
      });
      setCategories(cats);
      setIssues(issues);
    }
  }
