import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { updateAdminProfileApi } from "../../../api/auth";
import DashboardWrapper from "../../../components/DashboardWrapper";
import ProfileNav from "./ProfileNav";
import UpdateCompanyCover from "../publishers/company/UpdateCompanyCover";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import api_config from "../../../api/api_config";
import avatar from "./avatar.png";

const ProfileEdit = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [selectedImage, setSelectedImage] = useState();
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const company = JSON.parse(localStorage.getItem("RIFALYCOMPANY"));
  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  var user = JSON.parse(localStorage.getItem("RIFALYUSER"));

  useEffect(() => {
    let data = location.state;
    console.log("🚀 ~ file: ProfileEdit.js:38 ~ useEffect ~ data", data);

    if (data.avatar == "avatar.png") setSelectedImage(avatar);
    else setSelectedImage(`${api_config.STORAGE_URL}profiles/${data.avatar}`);
    setProfile(data);
  }, []);

  const handlePasswordChange = (e) => {
    let input = e.target;
    if (input.id == "new_password") {
      setPassword(input.value);
      if (input.value != cpassword) {
        setPasswordError("Passwords do not match");
      } else {
        setPasswordError("");
      }
    }
    if (input.id == "confirm_password") {
      setCPassword(input.value);
      if (password != input.value) {
        setPasswordError("Passwords do not match");
      } else {
        setPasswordError("");
      }
    }
    if (input.id == "old_password") {
      setOldPassword(input.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let data = {
      id: profile.id,
      companyId: profile.companyId,
    };
    let form = e.target;
    let firstName = form.firstName.value.trim();
    let lastName = form.lastName.value.trim();
    let email = form.email.value.trim();
    let role = form.role.value;
    let status = form.status.value;
    let phone = form.phone.value.trim();
    data.fullName = `${firstName} ${lastName}`;
    data.email = email;
    data.status = status;
    data.role = role;
    data.phone = phone;
    if (password.length > 0 && password == cpassword) {
      data.new_password = password;
      data.old_password = oldPassword;
    }
    console.log("🚀 ~ file: ProfileEdit.js:87 ~ handleSubmit ~ data", data);
    if (selectedImage && selectedImage.includes(";base64,"))
      data.avatar = selectedImage;
    console.log("token: ", user.token);
    let token = user.token;
    // updateAdminProfileApi(data, token)
    //   .then((result) => {
    //     console.log(
    //       "🚀 ~ file: ProfileEdit.js:100 ~ updateAdminProfileApi ~ result",
    //       result.data.data,
    //     );
    //     toast.success(result.data.message, {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     let user = result.data.data;
    //     user.token = token;
    //     localStorage.setItem("RIFALYPUBLISHER", JSON.stringify(user));
    //     navigate("/profile", { replace: true });
    //   })
    //   .catch((e) => {
    //     console.log(
    //       "🚀 ~ file: ProfileEdit.js:107 ~ handleSubmit ~ e",
    //       e.response,
    //     );
    //     toast.error(e.response.data.error, {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  };

  const handleSelectImage = (e) => {
    const file = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.addEventListener(
      "load",
      () => {
        if (fileReader.result) {
          setSelectedImage(fileReader.result);
        }
      },
      false,
    );
    if (file) {
      fileReader.readAsDataURL(file);
    }
  };
  const selectImage = () => {
    const fileInput = document.getElementById("avatar_file");
    fileInput.click();
  };
  return (
    <DashboardWrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12'>
            <div className='header mt-md-3'>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>

                  <li className='breadcrumb-item active'>
                    <a
                      href='/profile'
                      onClick={toPage("/profile")}>
                      Profile
                    </a>
                  </li>
                  <li
                    className='breadcrumb-item active'
                    aria-current='page'>
                    Edit Profile
                  </li>
                </ol>
              </nav>

              <div className='content card mt-5 p-3 px-5'>
                <div className='row d-flex align-items-end'>
                  <div className='col-md-10'>
                    <h6 className='header-pretitle'>Overview</h6>

                    <h1 className='header-title'>Edit Profile</h1>
                  </div>
                </div>
                <hr />
                {isLoading ? (
                  <div className='loader-holder d-flex justify-content-center align-items-center'>
                    <Loader
                      type='BallTriangle'
                      heigth='100'
                      width='100'
                      color='green'
                      ariaLabel='loading-indicator'
                    />
                  </div>
                ) : (
                  <div>
                    {profile ? (
                      <div className='row'>
                        <div className='m-4 col-md-12 row d-flex align-items-center'>
                          <div className='px-4 col-md-2'>
                            <div className='avatar-wrapper'>
                              <img
                                id='avatar'
                                className='avatar'
                                src={selectedImage}
                                alt={profile.fulltName}
                              />
                            </div>
                          </div>
                          <div className='col-md-4'>
                            <div
                              className='custom-btn'
                              onClick={selectImage}>
                              <span className='material-icons text-primary'>
                                upload_file
                              </span>
                              &nbsp;Change Avatar
                            </div>
                            <input
                              onChange={handleSelectImage}
                              accept='image/png,image/jpg'
                              type={"file"}
                              className='hidden'
                              name='avatar_file'
                              id='avatar_file'
                            />
                          </div>
                        </div>

                        <div className='m-4 px-4 pt-4 col-md-12 row'>
                          <form
                            onSubmit={handleSubmit}
                            className='row'>
                            <div className='form-group col-md-4'>
                              <label>First Name</label>
                              <input
                                name='firstName'
                                className='form-control'
                                defaultValue={profile.first_name}
                              />
                            </div>
                            <div className='form-group col-md-4'>
                              <label>Last Name</label>
                              <input
                                name='lastName'
                                className='form-control'
                                defaultValue={profile.last_name}
                              />
                            </div>
                            <div className='form-group col-md-4'>
                              <label>E-mail</label>
                              <input
                                name='email'
                                className='form-control'
                                defaultValue={profile.email}
                              />
                            </div>
                            <div className='form-group col-md-4'>
                              <label>Phone Number</label>
                              <input
                                name='phone'
                                id='phone'
                                className='form-control'
                                defaultValue={profile.phone}
                              />
                            </div>

                          
                            <div className='form-group col-md-4'>
                              <label>Role</label>
                              <select
                                name='role'
                                defaultValue={profile.role}
                                className='form-control'>
                                  <option disabled value={profile.role}>Admin</option>
                                
                              </select>
                            </div>
                            <div className='form-group col-md-4'>
                              <label>Status</label>
                              <select
                                disabled={profile.role != 2}
                                name='status'
                                className='form-control'>
                                <option disabled value='ACTIVE'>Active</option>
                              </select>
                            </div>
                            <div className='row col-md-12 d-flex align-items-center mt-2'>
                              <div className='col-md-12 my-4'>
                                <h3>Change Password</h3>
                              </div>
                              <div className='form-group col-md-4'>
                                <label>Current Password</label>
                                <input
                                  type={"password"}
                                  id='old_password'
                                  name='old_password'
                                  placeholder='******'
                                  className='form-control'
                                  onChange={handlePasswordChange}
                                />
                              </div>
                              <div className='form-group col-md-4'>
                                <label>New Password {password}</label>
                                <input
                                  onChange={handlePasswordChange}
                                  type={"password"}
                                  name='new_password'
                                  id='new_password'
                                  placeholder='Enter password'
                                  defaultValue=''
                                  className={
                                    "form-control " +
                                    (passwordError.length > 0
                                      ? "password-error"
                                      : "")
                                  }
                                />
                                <span className='error'>{passwordError}</span>
                              </div>
                              <div className='form-group col-md-4'>
                                <label>Confirm Password {cpassword}</label>
                                <input
                                  onChange={handlePasswordChange}
                                  type={"password"}
                                  name='confirm_password'
                                  id='confirm_password'
                                  placeholder='Re-enter password'
                                  defaultValue=''
                                  className={
                                    "form-control " +
                                    (passwordError.length > 0
                                      ? "password-error"
                                      : "")
                                  }
                                />
                              </div>
                            </div>
                            <div className='mt-4 px-2 col-md-12 d-flex align-items-center justify-content-end'>
                              <div className='col-md-2'>
                                <button
                                  className='btn btn-secondary'
                                  onClick={() => {
                                    navigate("/profile");
                                  }}>
                                  Cancel
                                </button>
                              </div>
                              <div className='col-md-2'>
                                <button
                                  className='btn btn-primary'
                                  type={"submit"}>
                                  Update
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default ProfileEdit;
