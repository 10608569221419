import { FETCH_SUMMARY_LOADING, 
         FETCH_SUMMARY_SUCCESS
        } from "../Actions/user-action";


const initialState = {
  summary: [],
  isLoading: false,
};


export function summary(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUMMARY_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_SUMMARY_SUCCESS: {
      return {
        ...state,
        summary: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

