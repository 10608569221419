import React, {useMemo, useEffect,useState } from 'react'
import { useNavigate } from "react-router-dom";
import DashboardWrapper from '../../../../components/DashboardWrapper'
import Table from '../../../../components/Table/Table';
import UserNavs from '../UserNavs';
import { fetchPublisherApi } from '../../../../api/company';
import { PUBLISHERADMINCOLUMNS } from "./PublisherAdminColumns";
import { fetchPublisherAdminsApi } from '../../../../api/user';


function PublisherAdmins() {

  const columns = useMemo(() => PUBLISHERADMINCOLUMNS, [])

  const [data,setData] = useState([]);  
  const [companies,setCompanies] = useState([])

  const navigate = useNavigate();
  
  function toDashboard(e) {
    e.preventDefault()
    navigate("/dashboard");
  }

  useEffect(() => {
    fetchPublisherAdminsApi().then(admins=>{
      fetchPublisherApi().then(companies=>{
        let data = admins.data.data.map(ad=>{
          let d = JSON.parse(JSON.stringify(ad));
          d.company = companies.data.data.find(c=>c.id == ad.companyId);
          return d;
        });

      setData(data);
    }).catch(e=>{
      console.log("xxx: ",e)
    });
    
  })}, []);




  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">

              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard} >Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Users</li>
                </ol>
              </nav>

              <div className="header-body">


                <h6 className="header-pretitle">
                  Overview
                </h6>

                
                <h1 className="header-title">
                  Users
                </h1>

              </div>

            </div>


            <div className="content">
              <UserNavs />

              <Table 
                name="Users"
                title="Registered Admins"
                columns={columns}
                data={data}
                path=""
              />
              
            </div>
          </div>
        </div>
      </div>

    </DashboardWrapper>
  )
}

export default PublisherAdmins
