import { FETCH_CORPORATES_LOADING, 
         FETCH_CORPORATES_SUCCESS 
        } from "../Actions/corporate-action";


const initialState = {
  corporates: [],
  isLoading: false,
};


export default function corporateReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CORPORATES_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_CORPORATES_SUCCESS: {
      return {
        ...state,
        companies: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
