import axios from "axios";
import url from "./api_config";

const baseURL = url.PUBLISHER_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});


export const fetchPublisherApi = ()=>{
  return api({
    url: `companies`,
    method: "GET",
  });
}

export const fetchPublisherByIdApi = (id)=>{
  return api({
    url: `companies/${id}`,
    method: "GET",
  });
}



export const createPublisherApi = (data)=>{
  return api({
    url: `companies`,
    data: data,
    method: "POST",
  });
}

export const updatePublisherApi = (id,data)=>{
  return api({
    url: `companies/${id}`,
    data: data,
    method: "PATCH",
  });
}

export const deletePublisherApi = (id)=>{
  return api({
    url: `companies/`,
    data: id,
    method: "DELETE",
  });
}


export const updateCompanyStatusApi = (id, data)=>{
  return api({
    url: `companies/${id}`,
    data: data,
    method: "PATCH",
  });
}
export const uploadCompanyDocsApi = (data)=>{
  return api({
    url: `companies/upload-docs`,
    data: data,
    method: "POST",
  });
}

export const updatePublisherCoverApi = (id, data)=>{
  return api({
    url: `companies/update-cover/${id}`,
    data: data,
    method: "PATCH",
  });
}





