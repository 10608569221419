import {
  FETCH_READERS_LOADING,
  FETCH_READERS_SUCCESS,
  FETCH_READERS_FAILURE,
  FETCH_READER_DETAILS_LOADING,
  FETCH_READER_DETAILS_SUCCESS,
  FETCH_READER_DETAILS_FAILURE,
} from "../Actions/reader-action";

const initialState = {
  readers: [],
  readerDetails: null,
  loading: false,
  error: null,
};

const readersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_READERS_LOADING:
      return { ...state, loading: true, error: null };
    case FETCH_READERS_SUCCESS:
      return { ...state, loading: false, readers: action.payload };
    case FETCH_READERS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_READER_DETAILS_LOADING:
      return { ...state, loading: true, error: null };
    case FETCH_READER_DETAILS_SUCCESS:
      return { ...state, loading: false, readerDetails: action.payload };
    case FETCH_READER_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default readersReducer;
