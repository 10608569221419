import React, { useEffect, useState, useCallback, forwardRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import Dropzone from "react-dropzone";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { updatePromocodesApi } from "../../../../api/promotion";
import {
  fetchPromocodes,
  fetchPromocodeDetails,
} from "../../../../redux-store/Actions/promo-code-action";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";

const schema = yup
  .object({
    description: yup.string().required().max(255).label("Description"),
    value: yup.number().required().label("Value (Transactional value)"),
    maxCount: yup.number().required().label("Max Count"),
    code: yup.string().required().label("Code Name"),
    type: yup.string().required().label("Promo code type"),
    status: yup.string().required().label("Promotion Code Status"),
  })
  .required();

function EditPromocode() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));

  const { promocodeId } = useParams();
  const navigate = useNavigate();

  // const { promocodes, isLoading } = useSelector((state) => state.promoCode);

  // useEffect(() => {
  //   dispatch(fetchPromocodeDetails(promocodeId, user.token));
  // }, [dispatch]);

  const promocodesData =
    location.state && location.state.promocodes
      ? location.state.promocodes
      : [];
  const [promocode, setpromocode] = useState(promocodesData);
  const [expiryDate, setExpiryDate] = useState(
    new Date(Date.parse(promocode.expiryDate))
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  // Submit edited data
  const onSubmit = (data) => {
    setLoading(true);

    data.expiryDate = DateTime.fromJSDate(expiryDate).toFormat(
      "yyyy-MM-dd HH:mm:ss"
    );
    updatePromocodesApi(promocodeId, data, user.token)
      .then((res) => {
        setLoading(false);
        toast.success("promocode Updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          dispatch(fetchPromocodes(user.token));
          navigate("/promocodes");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        // Handle error messages
      });
  };

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const filterPassedDate = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    return currentDate.getTime() - 24 * 3600 * 1000 <= selectedDate.getTime();
  };

  const CustomDatePickerExpiry = () => {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input
        type="text"
        className="example-custom-input form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        onChange={() => {}}
      />
    ));
    return (
      <DatePicker
        selected={expiryDate}
        dateFormat={"yyyy-MM-dd HH:mm"}
        timeFormat={"HH:mm"}
        minDate={new Date().getTime()}
        showTimeSelect
        timeIntervals={5}
        onChange={(date) => setExpiryDate(date)}
        filterDate={filterPassedDate}
        filterTime={filterPassedTime}
        customInput={<ExampleCustomInput />}
      />
    );
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="/promocodes" onClick={toPage("/promocodes/")}>
                      promocodes
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit promocodes
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card mt-2">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>
                        <h1 className="header-title">Edit promocode</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-8">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    htmlFor="campaignId"
                                    className="form-label"
                                  >
                                    Select Campaign Id
                                  </label>
                                  <select
                                    disabled
                                    value={promocode.campaignId}
                                    id="campaignId"
                                    className="form-select"
                                  >
                                    {
                                      <option
                                        key={promocode.campaign?.id}
                                        value={promocode.campaignId}
                                      >
                                        {promocode.campaign?.title}{" "}
                                      </option>
                                    }
                                  </select>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label htmlFor="type" className="form-label">
                                    Promo code type
                                  </label>
                                  <select
                                    className="form-select form-control"
                                    defaultValue={promocode.type}
                                    {...register("type")}
                                    required
                                  >
                                    <option disabled>Promo code type</option>
                                    <option value="1">Multi users</option>
                                    <option value="2">Single user</option>
                                  </select>

                                  <p className="errors">
                                    {errors.type?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Code Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.code
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="code"
                                    placeholder="Code Name"
                                    defaultValue={promocode.code}
                                    autoFocus
                                    required
                                    {...register("code")}
                                  />

                                  <p className="errors">
                                    {errors.code?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Value (Transactional value)
                                  </label>
                                  <input
                                    type="number"
                                    className={
                                      errors.value
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="value"
                                    placeholder="Promotion Code value"
                                    defaultValue={promocode.value}
                                    autoFocus
                                    required
                                    {...register("value")}
                                  />

                                  <p className="errors">
                                    {errors.value?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Max Count
                                  </label>
                                  <input
                                    type="number"
                                    className={
                                      errors.maxCount
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="maxCount"
                                    placeholder="Promotion Code Max Count"
                                    defaultValue={promocode.maxCount}
                                    autoFocus
                                    required
                                    {...register("maxCount")}
                                  />

                                  <p className="errors">
                                    {errors.type?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    htmlFor="redeem"
                                    className="form-label"
                                  >
                                    Code Redeem
                                  </label>
                                  <input
                                    type="number"
                                    className={
                                      errors.redeem
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="redeem"
                                    placeholder="Promotion Code Redeemed"
                                    defaultValue={promocode.redeem}
                                    autoFocus
                                    disabled
                                    required
                                    {...register("redeem")}
                                  />

                                  <p className="errors">
                                    {errors.redeem?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <label
                                  htmlFor="validationServer02"
                                  className="form-label"
                                >
                                  Expiry Date
                                </label>
                                <br />
                                <CustomDatePickerExpiry
                                  id="expiryDate"
                                  className="form-control"
                                  dateFormat={"yyyy-MM-dd H:mm:ss"}
                                  selected={expiryDate}
                                  minDate={new Date().getTime()}
                                  showTimeSelect
                                  timeIntervals={5}
                                  onChange={(date) => setExpiryDate(date)}
                                  filterDate={filterPassedDate}
                                  filterTime={filterPassedTime}
                                  readOnly={false}
                                />
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Promotion Code Status
                                  </label>
                                  <select
                                    className="form-select form-control"
                                    defaultValue={promocode.status}
                                    {...register("status")}
                                  >
                                    <option value="" disabled>
                                      Select Promotion Code Status
                                    </option>
                                    <option value="DRAFT">Draft</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="INACTIVE">InActive</option>
                                    <option value="EXPIRED">Expired</option>
                                  </select>

                                  <p className="errors">
                                    {errors.status?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-12">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Description
                                  </label>
                                  <textarea
                                    type="text"
                                    rows="3"
                                    id="description"
                                    className={
                                      errors.description
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    placeholder="Promotion Code Description"
                                    defaultValue={promocode.description}
                                    autoFocus
                                    required
                                    {...register("description")}
                                  ></textarea>
                                  <p className="errors">
                                    {errors.description?.message}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default EditPromocode;
