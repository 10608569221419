import React, {useMemo, useEffect } from 'react'
import { fetchGenres } from '../../../redux-store/Actions/genre-action';
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, usePagination, useGlobalFilter} from 'react-table';
import { GENRESCOLUMNS } from "./genresColumns";


import { Link } from 'react-router-dom'
import EmptyData from '../../../components/EmptyData/Index';
import SettingsFilter from './SettingsFilter';

function GenreTable() {


  const columns = useMemo(() => GENRESCOLUMNS, [])
  const dispatch = useDispatch();

  const { genres } = useSelector(
    (state) => state.genres
  );  

  const data = genres


  const tableInstance = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  },useGlobalFilter,  useSortBy, usePagination);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,


    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter },

  } = tableInstance

  useEffect(() => {
    dispatch(fetchGenres());
  }, [dispatch]);



  return (
    <div>
      <div className="row align-items-end my-4">
          <div className="col">
            <SettingsFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
            />
          </div>

          <div className="col-auto">

          
            <Link to="/settings/genres/create" className="btn btn-primary lift">
              <span>
                <i className="fe fe-plus"></i>
              </span>
              Create Genre
            </Link>

          </div>

      </div> 


      <div className="row mt-2 body-content">

      { genres && genres.length >= 1 ? (
        <div className="col-md-12">
          {/* the table here */}

          <div className="card">
            <div className="card-header">
              <h4 className="card-header-title">
                  Genres 
                </h4>
                
                <div>
                  <button className="btn btn-outline-primary btn-sm">Export</button>
                </div>
            </div>


            <table {...getTableProps()} className="table table-sm card-table">
                <thead>
                        {
                          headerGroups.map((headerGroups) => (
                            <tr {...headerGroups.getHeaderGroupProps()}>
                                {
                                  headerGroups.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}> 
                                      {column.render('header')}
                                    
                                    </th>
                                  ))
                                }
                                
                              </tr>
                          ))
                        }

                  </thead>

                  <tbody {...getTableBodyProps()}>
                    { page.map((row) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          { row.cells.map((cell) => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          })}
                        </tr>
                      )}
                    )}
                  </tbody>

            </table>


          </div>

          <div className='row mb-5'>
            <div className="col"></div>
            <div className="col-auto">
              <div>
                <span className="table-stat">
                  Showing  { pageIndex === 0 ? 1 : (pageIndex * pageSize) + 1 } to { pageIndex > 10 ?  genres.length  : ((pageIndex * pageSize) + pageSize) > genres.length ? genres.length : (pageIndex * pageSize) + pageSize } of { genres.length } {' '} elements 
                </span>
                <button className="btn btn-round btn-sm btn-pagination"  onClick={() => previousPage()}>
                  <i className="fe fe-chevron-left"></i>
                </button>
                <span className="table-stat">
                  Page {pageIndex + 1}
                </span>
                <button className="btn btn-round btn-sm btn-pagination" onClick={() => nextPage()}> 
                        <i className="fe fe-chevron-right"></i>
                </button>
              </div>                     
            </div>
          </div>


        </div>
      ) : (
       <EmptyData name="Genres" path="/settings/genres/create" />
      )}
      </div> 


    </div>
  )
}

export default GenreTable
