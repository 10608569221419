import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanies } from "../../../../redux-store/Actions/company-action";
import { useTable, useSortBy, usePagination, useGlobalFilter} from 'react-table';
import { COLUMNS } from "./CompanyColumns";
import PageLoader  from "../../../../components/PageLoader";
import {CSVLink} from "react-csv";
import { Link } from 'react-router-dom';
import EmptyData from '../../../../components/EmptyData/Index';
import CompanyFilter from './CompanyFilter';
 


function CompanyTable() {


  const dispatch = useDispatch();


  const { companies, isLoading } = useSelector(
    (state) => state.companies
  );  


  const columns = useMemo(() => COLUMNS, [])
  const data = companies || [];


  const tableInstance = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  },useGlobalFilter, useSortBy, usePagination);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,
  

    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter },

  } = tableInstance



  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);


  function fileName(){
    let date = Number(new Date());
    return 'companies_'+date+'.csv'
  }




  return (
    <div>

        <div className="row align-items-end my-4">
          <div className="col">
            <CompanyFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                setGlobalFilter={setGlobalFilter}
                globalFilter={globalFilter}
              />

          </div>

          <div className="col-auto">

                
            <Link to="/publishers/company/create" className="btn btn-primary lift">
              <span>
                <i className="fe fe-plus"></i>
              </span>
              Create Company
            </Link>

          </div>
        </div> 

        <div className="row mt-2 body-content">
        { companies && companies.length >= 1 ? (
           <div className='col-md-12'>

           <div className="card">
 
             <div className="card-header">
               <h4 className="card-header-title">
                 Registered Companies
               </h4>
               
               <div>
             
                 <CSVLink
                    data={companies}
                    filename={fileName()}
                    header={columns}
                    className="btn btn-outline-primary btn-sm"
                    target="_blank">
                        Export
                      </CSVLink>
           
                
               </div>
             
             </div>

            
             
 
               
               <div className="row align-items-center overlay-down">
                 <div className="col-12">
 
                   <table {...getTableProps()} className="table table-sm card-table table-nowrap">
                     <thead>
                       {
                         headerGroups.map((headerGroups) => (
                           <tr {...headerGroups.getHeaderGroupProps()}>
                             {
                               headerGroups.headers.map(column => (
                                 <th {...column.getHeaderProps(column.getSortByToggleProps())}> 
                                   {column.render('header')}
                                 
                                 </th>
                               ))
                             }
                             
                           </tr>
                         ))
                       }
                     </thead>
 
                     <tbody {...getTableBodyProps()}>
                      {/* <AnimatePresence> */}
                        {
                          
                          page.map((row) => {
                            prepareRow(row)
                            return(
                              <tr {...row.getRowProps()}>
                                {
                                  row.cells.map((cell) => {
                                    return(
                                      <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    )
                                  
                                  })
                                }
                                
                              </tr>
                            )
                          })
                        }
                        {/* </AnimatePresence> */}
                     </tbody>
 
 
 
           
 
 
                   </table>
 
 
           
                 </div>
               </div>
                 {/* end of row*/}

                 { isLoading && (
                   <div className="row overlay-up">
                     <div>
                       <PageLoader />
                     </div>
                   </div>
                 )}

                 {/* <div className="row overlay-up">
                   <div> */}
                    {/* { isLoading && <PageLoader />} */}
                   {/* </div>
                </div> */}
 
       
           </div>
 
           <div className='row mb-5'>
             <div className="col"></div>
 
     
 
                 <div className="col-auto">
                   <div>
                     <span className="table-stat">
                       Showing  { pageIndex === 0 ? 1 : (pageIndex * pageSize) + 1 } to { pageIndex === 0 ? 10 : ((pageIndex * pageSize) + pageSize) > companies.length ? companies.length : (pageIndex * pageSize) + pageSize } of { companies.length } {' '} elements 
                     </span>
                     <button className="btn btn-round btn-sm btn-pagination"  onClick={() => previousPage()}>
                       <i className="fe fe-chevron-left"></i>
                     </button>
                     <span className="table-stat">
                       Page {pageIndex + 1}
                     </span>
                     <button className="btn btn-round btn-sm btn-pagination" onClick={() => nextPage()}> 
                             <i className="fe fe-chevron-right"></i>
                     </button>
                   </div>
                   
                     
               </div>
           </div>
         </div>
        ) : (
        <EmptyData name="Company" path="/publishers/company/create" />
        )}
        </div>

      
    </div>
  )
}

export default CompanyTable
