import React from "react";
// import { identifier } from "@babel/types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export const CORPORATECOLUMNS = [
  {
    header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {" "}
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    header: "Company Name",
    accessor: "companyName",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    header: "Address",
    accessor: "address",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    header: "Contact Person",
    accessor: "contactPerson",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },

  {
    header: "Contact Email",
    accessor: "email",
  },
  {
    header: "status",
    accessor: "status",
    Cell: (props) => <div>{checkStatus(props.value)}</div>,
  },
  {
    header: "action",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic"
          >
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            <Link
              to={{ pathname: `/corporates/companies/show`}} state={{data:props.row.original }}

              className="dropdown-item"
            >
              View
            </Link>

            <Link
              to={{ pathname: `/corporates/companies/edit`}} state={{data:props.row.original }}
              className="dropdown-item"
            >
              Edit
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

function checkStatus(status) {
  switch (status.toUpperCase()) {
    case "ACTIVE":
      return (
        <div className="status">
          <div className="dot"></div>
          Active
        </div>
      );
    case "TRIAL":
      return (
        <div className="status">
          <div className="dot"></div>
          On Trial
        </div>
      );
    case "PROMOTION":
      return (
        <div className="status">
          <div className="dot"></div>
          On Promotion
        </div>
      );
    default:
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Inactive
        </div>
      );
  }
}
