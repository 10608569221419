import React from 'react'
import { NavLink } from 'react-router-dom'
import "./style.css";

function DashboardNav() {
  return (
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <NavLink to="/dashboard/users" className="nav-link">Users</NavLink>
      </li>

      <li className="nav-item">
        <NavLink to="/dashboard/revenue" className="nav-link">Revenue</NavLink>
      </li>


     
    </ul>
  )
}

export default DashboardNav
