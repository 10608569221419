import React, {useMemo, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import DashboardWrapper from '../../../../components/DashboardWrapper'
import UserNavs from '../UserNavs'
import { READERCOLUMNS } from "./ReaderColumns";
import Table from '../../../../components/Table/Table';
import { fetchCorporateReadersApi } from '../../../../api/corporate';

function Readers() {


  const columns = useMemo(() => READERCOLUMNS, [])

  const [readers,setReaders] = useState([]);  
  const navigate = useNavigate();
  
  function toDashboard(e) {
    e.preventDefault()
    navigate("/dashboard");
  }


  useEffect(() => {
    fetchCorporateReadersApi().then(result => {
     setReaders(result.data.data)
   })
  }, []);


  return (
    <DashboardWrapper>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">
          <div className="header mt-md-3">

            <nav aria-label="breadcrumb mb-2">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard" onClick={toDashboard} >Dashboard</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Users</li>
              </ol>
            </nav>

            <div className="header-body">


              <h6 className="header-pretitle">
                Overview
              </h6>

              
              <h1 className="header-title">
                Users
              </h1>

            </div>

          </div>


          <div className="content">

            
            <UserNavs />


            <Table
                name="Users"
                title="Registered Users"
                columns={columns}
                data={readers}
                path=""
              />


          </div>
        </div>
      </div>
    </div>

  </DashboardWrapper>
  )
}

export default Readers
