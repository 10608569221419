import React, {useMemo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTable, useSortBy, usePagination, useGlobalFilter} from 'react-table';
import EmptyData from '../../../../components/EmptyData/Index';
import StoryFilter from './StoryFilter';
import { CSVLink } from "react-csv";
import moment from "moment";
import { AnimatePresence, motion } from "framer-motion"


function StoriesTable({name, title, columns, data, raw, onPageChange, path }) {
  const [currentPage, setCurrentPage] = useState(1);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,

    // Get the state from the instance
    state: { globalFilter },
  } = tableInstance;

  const spring = useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
      rotate: 360,
    }),
    []
  );

  function fileName() {
    let date = new Date();
    let dateString = moment(date).format("YYYYMMDDHHmm");
    return name + "_" + dateString + ".csv";
  }

  const nextPage = () => {
    const next = currentPage + 1;
    onPageChange(next);
    setCurrentPage(next);
  };

  const previousPage = () => {
    const prev = currentPage - 1;
    onPageChange(prev);
    setCurrentPage(prev);
  };

  return (
    <div>
      <div className="row align-items-end my-4">
        <div className="col">
          <StoryFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
              name={name}
            />

        </div>

        <div className="col-auto">
          <Link to="/podcasters/stories/create" className="btn btn-primary lift">
            <span>
              <i className="fe fe-plus"></i>
            </span>
            Create {name}
          </Link>
        </div>

      </div> 


      <div className="row mt-2 body-content">
        { data && data.length >= 1 ? ( 
          <div className="col-md-12">
          
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">
                      {title} 
                      </h4>
                      
                    
                      <div>
                      <div>
                        <CSVLink
                          data={data} 
                          filename={fileName()}
                          header={columns}
                          className="btn btn-outline-primary btn-sm"
                          target="_blank"
                        >
                          Export
                        </CSVLink>
                      </div>
                    </div>
                  </div>

                  <table {...getTableProps()} className="table table-sm card-table">
                      <thead>
                              {
                                headerGroups.map((headerGroups) => (
                                  <tr {...headerGroups.getHeaderGroupProps()}>
                                      {
                                        headerGroups.headers.map(column => (
                                          <th {...column.getHeaderProps(column.getSortByToggleProps())}> 
                                            {column.render('header')}
                                          
                                          </th>
                                        ))
                                      }
                                      
                                    </tr>
                                ))
                              }

                        </thead>

                        <tbody {...getTableBodyProps()}>
                        <AnimatePresence>
                          { rows.map((row) => {
                            prepareRow(row)
                            return (
                              <motion.tr {...row.getRowProps({
                                layoutTransition: spring,
                                exit: { opacity: 0, maxHeight: 0 },
                              })}>
                                { row.cells.map((cell) => {
                                  return <motion.td {...cell.getCellProps()}>{cell.render('Cell')}</motion.td>
                                })}
                              </motion.tr>
                            )}
                          )}
                          </AnimatePresence>
                        </tbody>

                  </table>

                </div>
              </div>
            </div>

            <div className='row mb-5'>
              <div className="col"></div>
              <div className="col-auto">
              <div>
                  <span className="table-stat">
                    Showing {raw.pageSize} of {raw.totalItems} elements
                  </span>
                  {raw.hasPrevious ? (
                    <button
                      className="btn btn-round btn-sm btn-pagination"
                      onClick={previousPage}
                    >
                      <i className="fe fe-chevron-left"></i>
                    </button>
                  ) : null}
                  <span className="table-stat">
                    Page {raw.currentPage}
                  </span>
                  {raw.hasNext ? (
                    <button
                      className="btn btn-round btn-sm btn-pagination"
                      onClick={nextPage}
                    >
                      <i className="fe fe-chevron-right"></i>
                    </button>
                  ) : null}
                </div>                    
              </div>
            </div>

          </div>
        ) : (
          <EmptyData name={name} path={path + "/" + name + "/create"} />
        )}
        </div> 

    </div>
  )
}

export default StoriesTable
