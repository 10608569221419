import React from "react";
import { Dropdown } from "react-bootstrap";
import UpdateUserStatus from "../UpdateUserStatus";
import DeleteAdmin from "./DeleteAdmin";
import EditAdmin from "./EditAdmin";

export const ADMINCOLUMNS = [
  {
    header: "id",
    accessor: "id",
    Cell: (props) => <div>{props.row.index + 1}</div>,
  },
  {
    header: "first name",
    accessor: "firstName",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    header: "last name",
    accessor: "lastName",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    header: "email",
    accessor: "email",
  },
  {
    header: "mobile",
    accessor: "phone",
  },
  {
    header: "role",
    accessor: "role",
    Cell: (props) => <div className="text-capitalize">Admin</div>,
  },
  {
    header: "status",
    accessor: "status",
    Cell: (props) => <div>{checkStatus(props.row.original.status)}</div>,
  },
  {
    header: "action",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic"
          >
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            {/* <Link to={`/users/admins/${props.row.original.id}`} className="dropdown-item">
            View
          </Link> */}

            <Dropdown.Item>
              <EditAdmin placement="end" data={props.row.original} />
            </Dropdown.Item>

            <div
              className={
                props.row.original.status === "ACTIVE"
                  ? "dropdown-item disabled hidden"
                  : "dropdown-item"
              }
            >
              <UpdateUserStatus
                data={props.row.original}
                type="1"
              ></UpdateUserStatus>
            </div>

            <div
              className={
                props.row.original.status !== "ACTIVE"
                  ? "dropdown-item disabled hidden"
                  : "dropdown-item"
              }
            >
              <UpdateUserStatus
                data={props.row.original}
                type="1"
              ></UpdateUserStatus>
            </div>

            <div className="dropdown-item text-danger">
              <DeleteAdmin data={props.row.original}></DeleteAdmin>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

function checkStatus(status) {
  switch (status) {
    case "ACTIVE":
      return (
        <div className="status">
          <div className="dot"></div>
          Active
        </div>
      );

    case "PENDING":
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Pending
        </div>
      );
    case "DELETED":
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Deleted
        </div>
      );
    default:
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Inactive
        </div>
      );
  }
}
