import React, { useEffect, useState, useCallback, forwardRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { updateCampaignsApi } from "../../../../api/promotion";
import { fetchcampaigns } from "../../../../redux-store/Actions/campaign-action";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

const schema = yup
  .object({
    title: yup.string().required().max(60).label("Campaign Name"),
    description: yup.string().required().label("Campaign Description"),
    objective: yup.string().required().label("Campaign Objective"),
    status: yup.string().required().label("Status"),
  })
  .required();

function EditCampaign() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));

  const { campaignId } = useParams();
  const navigate = useNavigate();
  const campaignsData =
    location.state && location.state.campaigns ? location.state.campaigns : [];
  const [campaign, setCampaign] = useState(campaignsData);
  const [startDate, setStartDate] = useState(
    new Date(Date.parse(campaign.startDate))
  );
  const [endDate, setEndDate] = useState(
    new Date(Date.parse(campaign.endDate))
  );
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data, e) => {
    e?.preventDefault();
    setLoading(true);

    data.startDate = DateTime.fromJSDate(startDate).toFormat(
      "yyyy-MM-dd HH:mm:ss"
    );
    data.endDate = DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd HH:mm:ss");

    updateCampaignsApi(campaignId, data, user.token)
      .then((res) => {
        setLoading(false);
        toast.success("Campaign Updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          dispatch(fetchcampaigns());
          navigate("/Campaigns");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error updating campaign:", error);
        toast.error("Error updating campaign. Please try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const filterPassedDate = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    return currentDate.getTime() - 24 * 3600 * 1000 <= selectedDate.getTime();
  };

  const CustomDatePickerStart = () => {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input
        type="text"
        className="example-custom-input form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        onChange={() => {}}
      />
    ));
    return (
      <DatePicker
        selected={startDate}
        dateFormat={"yyyy-MM-dd HH:mm"}
        timeFormat={"HH:mm"}
        minDate={new Date().getTime()}
        showTimeSelect
        timeIntervals={5}
        onChange={(date) => setStartDate(date)}
        filterDate={filterPassedDate}
        filterTime={filterPassedTime}
        customInput={<ExampleCustomInput />}
      />
    );
  };
  const CustomDatePickerEnd = () => {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input
        type="text"
        className="example-custom-input form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        onChange={() => {}}
      />
    ));
    return (
      <DatePicker
        selected={endDate}
        dateFormat={"yyyy-MM-dd HH:mm"}
        timeFormat={"HH:mm"}
        minDate={new Date().getTime()}
        showTimeSelect
        timeIntervals={5}
        onChange={(date) => setEndDate(date)}
        filterDate={filterPassedDate}
        filterTime={filterPassedTime}
        customInput={<ExampleCustomInput />}
      />
    );
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="/Campaigns" onClick={toPage("/Campaigns/")}>
                      Campaigns
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Campaigns
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card mt-2">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>
                        <h1 className="header-title">Edit Campaign</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-8">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="title" className="form-label">
                                    Campaign Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.title
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="title"
                                    placeholder="Enter Campaign Title"
                                    defaultValue={campaign.title}
                                    autoFocus
                                    required
                                    {...register("title")}
                                  />

                                  <p className="errors">
                                    {errors.title?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="objective"
                                    className="form-label"
                                  >
                                    Campaign Objective
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.objective
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="objective"
                                    placeholder="Enter Campaign Objective"
                                    defaultValue={campaign.objective}
                                    autoFocus
                                    required
                                    {...register("objective")}
                                  />

                                  <p className="errors">
                                    {errors.objective?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="status"
                                    className="form-label"
                                  >
                                    Campaign Status
                                  </label>
                                  <select
                                    className="form-select form-control"
                                    defaultValue={campaign.status}
                                    {...register("status")}
                                    required
                                  >
                                    <option value="" disabled>
                                      Select Campaign Status
                                    </option>
                                    <option value="DRAFT">Draft</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="INACTIVE">InActive</option>
                                    <option value="EXPIRED">Expired</option>
                                  </select>

                                  <p className="errors">
                                    {errors.status?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-6"></div>

                              <div className="col-md-6">
                                <label
                                  htmlFor="startDate"
                                  className="form-label"
                                >
                                  Campaign Start Date
                                </label>
                                <br />
                                <CustomDatePickerStart
                                  type="date"
                                  id="startDate"
                                  className="form-control"
                                  dateFormat={"yyyy-MM-dd H:mm:ss"}
                                  selected={startDate}
                                  minDate={new Date().getTime()}
                                  showTimeSelect
                                  timeIntervals={5}
                                  onChange={(date) => setStartDate(date)}
                                  filterDate={filterPassedDate}
                                  filterTime={filterPassedTime}
                                  readOnly={false}
                                />
                              </div>

                              <div className="col-md-6">
                                <label htmlFor="endDate" className="form-label">
                                  Campaign End Date
                                </label>
                                <br />
                                <CustomDatePickerEnd
                                  type="date"
                                  id="endDate"
                                  className="form-control"
                                  dateFormat={"yyyy-MM-dd H:mm:ss"}
                                  selected={endDate}
                                  minDate={new Date().getTime()}
                                  showTimeSelect
                                  timeIntervals={5}
                                  onChange={(date) => setEndDate(date)}
                                  filterDate={filterPassedDate}
                                  filterTime={filterPassedTime}
                                  readOnly={false}
                                />
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <label
                                    htmlFor="description"
                                    className="form-label"
                                  >
                                    campaign Description
                                  </label>
                                  <textarea
                                    type="text"
                                    rows="3"
                                    id="description"
                                    className={
                                      errors.description
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    placeholder="Enter campaign Description"
                                    defaultValue={campaign.description}
                                    autoFocus
                                    required
                                    {...register("description")}
                                  ></textarea>
                                  <p className="errors">
                                    {errors.description?.message}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default EditCampaign;
