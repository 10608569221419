import React, { useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import DashboardWrapper from '../../../components/DashboardWrapper';
import SettingsNav from './SettingsNav';

function Settings() {
 const navigate = useNavigate();
  const [changeVisibility, setChangeVisibility] = useState(false)
  const [isEdit,setIsEdit] = useState(false);
  const [isEditBilling,setIsEditBilling] = useState(false)
  const [isEditDocs,setIsEditDocs] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [company,setCompany] = useState({});

    function toPage (path){
      return function (e){
        e.preventDefault()
        navigate(path);
      }
    }
    
  return (
    <DashboardWrapper>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-12">

          <div className="header mt-md-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard" onClick={toPage("/dashboard")} >Dashboard</a>  
                </li>
            
                <li className="breadcrumb-item active" aria-current="page">Settings</li>
              </ol>
            </nav>

            <div className="header-body">
              <h6 className="header-pretitle">
                Overview
              </h6>

              <h1 className="header-title">
                Settings
              </h1>

            </div>

          </div>


          <div className="content">
             <SettingsNav></SettingsNav>

            </div>
        

        </div>

        
      </div>
    </div>
</DashboardWrapper>);
}

export default Settings