import { FETCH_TRANSACTION_ARCHIVES_LOADING, 
         FETCH_TRANSACTION_ARCHIVES_SUCCESS
        } from "../Actions/archive-action";


const initialState = {
  transactions: [],
  isLoading: false,
};


export function transactions(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRANSACTION_ARCHIVES_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_TRANSACTION_ARCHIVES_SUCCESS: {
      return {
        ...state,
        admins: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

