import React, { useEffect, useMemo, useState } from "react";
import Loader from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { fetchCompany } from "../../../../redux-store/Actions/company-action";
import * as yup from "yup";
import { updateIssuesApi } from "../../../../api/publisher";
import { fetchGenres } from "../../../../redux-store/Actions/genre-action";
import PUBLICATIONFREQUENCY from "../../../../config/PublicationFrequency.json";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";

const schema = yup
  .object({
    title: yup.string().required().min(3).max(255).label(""),
  })
  .required();

const EditIssue = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const id = params.id;
  console.log(id);
  const countries = localStorage.getItem("RIFALYCOUNTRIES")
    ? JSON.parse(localStorage.getItem("RIFALYCOUNTRIES"))
    : [];
  const [issue, setIssue] = useState(location.state);
  const [isGlobal, setIsGlobal] = useState(issue.visibility);
  const { companies } = useSelector((state) => state.companies);
  const { genres } = useSelector((state) => state.genres);
  const [loading, setLoading] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState(
    issue.genres.map((g) => {
      return { label: g.name, value: g.id };
    })
  );
  const [country, setCountry] = useState(
    countries.find((c) => c.countryCode == issue.country) ?? {
      countryCode: "TZ",
      name: "Tanzania",
      currency: "TZS",
    }
  );
  console.log(companies);
  const publicationFrequency = useMemo(() => PUBLICATIONFREQUENCY, []);

  const categories = [
    { id: 1, name: "NewsPaper" },
    { id: 2, name: "Magazine" },
    { id: 3, name: "Book" },
    { id: 4, name: "Article" },
  ];
  const handleCountryChange = (e) => {
    let c = countries.find((c) => c.countryCode == e.target.value);
    setCountry(c);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    console.log("please: ", location.state);
    setIssue(location.state);
    dispatch(fetchCompany());
    dispatch(fetchGenres());
  }, [dispatch]);

  const onSubmit = (data, e) => {
    setLoading(true);
    data.id = id;
    data.genreId = selectedGenre.map((g) => g.value);
    console.log("id :", data);

    updateIssuesApi(id, data)
      .then((res) => {
        toast.success("Issue updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/publishers/issues");
      })
      .catch((e) => {
        console.log("errors: ", e);
        toast.fail("Could not update issue", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!issue) {
    return (
      <section>
        <h2>Issue not found!</h2>
      </section>
    );
  }

  if (issue === {}) {
    return (
      <section>
        <h2>Issue not found!</h2>
      </section>
    );
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3 mb-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>

                  <li
                    className="breadcrumb-item "
                    onClick={toPage("/publishers")}
                  >
                    Publishers
                  </li>
                  <li
                    className="breadcrumb-item "
                    onClick={toPage("/publishers/issues")}
                  >
                    Issues
                  </li>
                  {/* <li className="breadcrumb-item active" aria-current="page">{issue.title}</li> */}
                </ol>
              </nav>
            </div>

            {/* end of content */}

            {/* Content cards */}
            <div className="row mt-2 body-content">
              <div className="col-md-12">
                <div className="card">
                  {/* card head */}
                  <div className="px-4 pt-3">
                    <div className="">
                      <h6 className="header-pretitle">Overview</h6>

                      <h1 className="header-title">Edit Issue</h1>
                    </div>

                    <hr />
                  </div>
                  {/* end of card header */}

                  <div className="card-body">
                    <div>
                      {issue && issue.publisher && (
                        <div className="row">
                          <div className="col-md-12">
                            <form
                              onSubmit={handleSubmit(onSubmit)}
                              className="p-3 mx-2"
                            >
                              <div className="row g-3 mb-3">
                                <div className="col-12 col-md-4 mb-1">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Publisher Company
                                  </label>
                                  <select
                                    defaultValue={issue.publisherId}
                                    className="form-select"
                                    {...register("publisherId")}
                                  >
                                    {companies.map((company) => (
                                      <option
                                        key={company.id}
                                        value={company.id}
                                      >
                                        {company.companyName}{" "}
                                      </option>
                                    ))}
                                  </select>

                                  <p className="errors">
                                    {errors.company?.message}
                                  </p>
                                </div>

                                <div className="col-12 col-md-4 mb-1">
                                  <label htmlFor="title" className="form-label">
                                    Issue Title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("title")}
                                    defaultValue={issue.title}
                                  />

                                  <p className="errors">
                                    {errors.title?.message}
                                  </p>
                                </div>

                                <div className="col-12 col-md-4 mb-1">
                                  <label
                                    htmlFor="category"
                                    className="form-label"
                                  >
                                    Category
                                  </label>
                                  <select
                                    className="form-control"
                                    {...register("category")}
                                    defaultValue={issue.categoryId}
                                  >
                                    {categories.map((cat) => {
                                      return (
                                        <option value={cat.id} key={cat.id}>
                                          {cat.name}
                                        </option>
                                      );
                                    })}
                                  </select>

                                  <p className="errors">
                                    {errors.category?.message}
                                  </p>
                                </div>

                                <div className="col-12 col-md-4 mb-1">
                                  <label
                                    htmlFor="language"
                                    className="form-label"
                                  >
                                    Language
                                  </label>
                                  <select
                                    {...register("language")}
                                    defaultValue={issue.language}
                                    className="form-select"
                                  >
                                    <option>Select Language</option>
                                    <option value="EN">English</option>
                                    <option value="SW">Swahili</option>
                                  </select>

                                  <p className="errors">
                                    {errors.company?.message}
                                  </p>
                                </div>

                                <div className="col-12 col-md-4 mb-1">
                                  <label
                                    htmlFor="category"
                                    className="form-label"
                                  >
                                    Price
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register("price")}
                                    defaultValue={issue.price}
                                  />

                                  <p className="errors">
                                    {errors.price?.message}
                                  </p>
                                </div>

                                <div className="col-12 col-md-4 mb-1">
                                  <label
                                    htmlFor="country"
                                    className="form-label"
                                  >
                                    Country
                                  </label>
                                  <select
                                    {...register("country")}
                                    defaultValue={issue.country}
                                    className="form-select"
                                    onChange={handleCountryChange}
                                  >
                                    <option disabled>Select Country</option>
                                    {countries.map((country) => {
                                      return (
                                        <option
                                          value={country.countryCode}
                                          key={country.countryCode}
                                        >
                                          {country.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="col-12 col-md-4 mb-2">
                                  <label
                                    htmlFor="currency"
                                    className="form-label"
                                  >
                                    Currency
                                  </label>
                                  <select
                                    {...register("currency")}
                                    value={country.currency}
                                    className="form-select"
                                  >
                                    {countries.map((country) => {
                                      return (
                                        <option
                                          key={country.countryCode}
                                          value={country.currency}
                                        >
                                          {country.currency}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="col-12 col-md-4 mb-1">
                                  <label
                                    htmlFor="frequency"
                                    className="form-label"
                                  >
                                    Publication Frequency *
                                    {/* <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                            <span className='pop'>?</span>
                                          </OverlayTrigger> */}
                                  </label>
                                  <select
                                    defaultValue={issue.frequency}
                                    {...register("frequency")}
                                    className="form-control form-select"
                                  >
                                    <option disabled>Select Frequency</option>
                                    {publicationFrequency.map((frequency) => (
                                      <option
                                        key={frequency.title}
                                        value={frequency.title}
                                      >
                                        {frequency.title}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="col-12 col-md-4 mb-1">
                                  <label
                                    htmlFor="genreId"
                                    className="form-label"
                                  >
                                    Genre *
                                    {/* <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                            <span className='pop'>?</span>
                                          </OverlayTrigger> */}
                                  </label>
                                  <MultiSelect
                                    options={genres.map((g) => {
                                      g.label = g.name;
                                      g.value = g.id;
                                      return g;
                                    })}
                                    value={selectedGenre}
                                    onChange={setSelectedGenre}
                                    labelledBy="Select"
                                  />
                                </div>

                                {/* <div className="col-12 col-md-4 mb-2">
                                  <label
                                    htmlFor="availability"
                                    className="form-label"
                                  >
                                    Publication Availability
                                  </label>
                                  <div className="form-group">
                                    <input
                                      type="checkbox"
                                      className="form-control custom-switch"
                                      id="customSwitches"
                                    />
                                  </div>
                                </div> */}
                              </div>
                              <div className="row mt-3">
                                <div className="col"></div>
                                <div className="col-auto">
                                  {loading ? (
                                    <span className="btn btn-primary">
                                      <Loader
                                        type="TailSpin"
                                        color="#ffffff"
                                        height={20}
                                        width={20}
                                      />
                                    </span>
                                  ) : (
                                    <button
                                      className="btn btn-primary"
                                      type="submit"
                                    >
                                      Submit
                                    </button>
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* end of data row */}
                  </div>
                  {/* end of card body */}
                </div>
              </div>
              {/* end of large col */}
            </div>
            {/*  end of body content */}
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default EditIssue;
