import React, {useState, useEffect, startTransition} from 'react'
import '../dashboard/style.css'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  LineElement,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js'
import {Doughnut, Bar, Line} from 'react-chartjs-2'
import api from '../../../api/api_config';
import axios from 'axios';
import {DateTime} from 'luxon';
import Loader from 'react-loader-spinner';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  LineElement,
  ArcElement,
  Tooltip,
  Legend
)

function RevenueSummary(props) {
  const [ summary, setSummary ] = useState({topups: 0, direct: 0, subscriptions: 0, total: 0})
  const [ lineData, setLineData ] = useState({topup: [], direct: [], subscriptions: []})
  const [ pieData, setPieData ] = useState({Android: 0, iOS: 0, Web: 0})
  const [ barData, setBarData ] = useState({airtel: 0, mpesa: 0, tigopesa: 0, dpoMobile: 0, dpoCard: 0})
  const [ subscriptionData, setSubscriptionData] = useState({daily: [], weekly: [], economy: [], business: []})
  const [ period, setPeriod ] = useState({end: DateTime.fromMillis(Date.now()).toISO({includeOffset: false}), start: DateTime.fromMillis(Date.now()).startOf('day').toISO({includeOffset: false})});
  const [ year, setYear ] = useState((new Date()).getFullYear());
  const [ start, setStart ] = useState((new Date()).toISOString());
  const [ end, setEnd ] = useState((new Date()).toISOString());
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem('RIFALYUSER'));
  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: '#BCBCBC',
          font: {
            size: 14
          },
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true
        }
      }
    }
  }

  const data = {
    labels: Object.keys(pieData),
    datasets: [
      {
        label: 'Revenue in TZS',
        data: Object.values(pieData),
        backgroundColor: [ '#2BC38C', '#A5A6F6', '#C2590B' ],
        radius: 120,
        weight: 1,
        cutout: 120,
        borderWidth: 1,
        hoverOffset: 7
      }
    ]
  }

  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'end',
        labels: {
          color: '#BCBCBC',
          font: {
            size: 13
          },
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true
        }
      },
      title: {
        display: false
      }
    },
    elements: {
      bar: {
        borderRadius: 10
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          padding: {top: 20, left: 0, right: 0, bottom: 0}
        },
        grid: {
          display: false,
          borderColor: 'transparent'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Revenue in TZS'
        },
        grid: {
          offset: false,
          borderDash: [ 1, 3 ],
          color: '#E0E6ED',
          borderColor: 'transparent',
          tickColor: 'transparent',
          categoryPercentage: 0.4
        }
      }
    }
  }

  const dataLine = {
    labels,
    padding: 10,
    datasets: [
      {
        label: 'Direct Download',
        data: lineData.direct,
        borderColor: '#C2590B',
        backgroundColor: '#C2590B',
        tension: 0.4,
        barThickness: 10,
        borderRadius: 10,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        offset: false
      },
      {
        label: 'Subscriptions',
        data: lineData.subscriptions,
        borderColor: '#A5A6F6',
        backgroundColor: '#A5A6F6',
        tension: 0.4,
        barThickness: 10,
        borderRadius: 10,
        barPercentage: 1.0,
        categoryPercentage: 0.4,
        offset: false
      },
      {
        label: 'Topups',
        data: lineData.topup,
        borderColor: '#4FB5B1',
        backgroundColor: '#4FB5B1',
        tension: 0.4,
        barThickness: 10,
        borderRadius: 10,
        barPercentage: 1.0,
        categoryPercentage: 0.4,
        offset: false
      }
    ]
  }

  const barOptions = {
    indexAxis: 'y',
    layout: {
      padding: 10
    },
    responsive: true,
    elements: {
      bar: {
        borderWidth: 1,
        barPercentage: 0.5
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        display: false
      },
      datalabels: {
        display: true
      },
      title: {
        display: true,
        text: 'Transaction by Payment Method',
        position: 'top',
        align: 'start',
        padding: 16
      },
    }
  };
  const colors = Object.keys(barData).map(d => `#${Math.floor(Math.random() * 16777215).toString(16)}`);

  const barInfo = {
    labels: Object.keys(barData),
    datasets: [
      {
        data: Object.values(barData), borderColor: colors,
        backgroundColor: colors, barPercentage: 0.4, categoryPercentage: 1
      }
    ]
  }


  const subscriptionOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'end',
        labels: {
          color: '#BCBCBC',
          font: {
            size: 13
          },
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true
        }
      },
      title: {
        display: false
      }
    },
    elements: {
      bar: {
        borderRadius: 10
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Type of Subscription',
          padding: {top: 20, left: 0, right: 0, bottom: 0}
        },
        grid: {
          display: false,
          borderColor: 'transparent'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Amount in (TZS)'
        },
        grid: {
          offset: false,
          borderDash: [ 1, 3 ],
          color: '#E0E6ED',
          borderColor: 'transparent',
          tickColor: 'transparent',
          categoryPercentage: 0.4
        }
      }
    }
  }

  const subscriptionLine = {
    labels,
    padding: 10,
    datasets: [
      {
        label: 'Daily Subscription',
        data: subscriptionData.daily,
        borderColor: '#2BC38C',
        backgroundColor: '#2BC38C',
        tension: 0.4,
        barThickness: 10,
        borderRadius: 10,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        offset: false
      },
      {
        label: 'Weekly Subscription',
        data: subscriptionData.weekly,
        borderColor: '#F34E4E',
        backgroundColor: '#F34E4E',
        tension: 0.4,
        barThickness: 10,
        borderRadius: 10,
        barPercentage: 1.0,
        categoryPercentage: 0.4,
        offset: false
      },
      {
        label: 'Economy Monthly Subscription',
        data: subscriptionData.economy,
        borderColor: '#A5A6F6',
        backgroundColor: '#A5A6F6',
        tension: 0.4,
        barThickness: 10,
        borderRadius: 10,
        barPercentage: 1.0,
        categoryPercentage: 0.4,
        offset: false
      },
      {
        label: 'Business Monthly Subscription',
        data: subscriptionData.business,
        borderColor: '#90ED2D',
        backgroundColor: '#90ED2D',
        tension: 0.4,
        barThickness: 10,
        borderRadius: 10,
        barPercentage: 1.0,
        categoryPercentage: 0.4,
        offset: false
      }
    ]
  }
  const thousandSeparator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  async function handleSearch(startDate,endDate) {
    setLoading(true);
    const annual = {
      start: DateTime.utc((new Date()).getFullYear()).startOf('year').toISO({includeOffset: false}),
      end: DateTime.utc((new Date()).getFullYear()).endOf('year').toISO({includeOffset: false})
    }
    try {
      const [ response, response_ ] = await Promise.all([ axios.get(`${api.BILLING_URL}billing/reports/revenue/?startDate=${start}&endDate=${end}`, {headers: {'Authorization': `Bearer ${user.token}`}}), axios.get(`${api.BILLING_URL}billing/reports/revenue-graph/?startDate=${annual.start}&endDate=${annual.end}`, {headers: {'Authorization': `Bearer ${user.token}`}}) ]);
      const _summary = response.data.data;
      const chartData = response_.data.data;

      setSummary(_summary);
      setLineData(chartData.line);
      setPieData(chartData.pie);
      setBarData(chartData.methods);
      // setSubscriptionData(chartData.subscriptionInfo);

      localStorage.setItem('RIFALYSUMMARY', JSON.stringify({summary: _summary, lineData: chartData.line, pieData: chartData.pie, barData: chartData.methods}));
      setLoading(false);
    }
    catch (e) {
      console.log(e);
      setLoading(false);
    }

  }

  const handleDateChange1 = (e) => {
    if (e.target.id === 'start') setStart((new Date(e.target.value.trim())).toISOString());
    else setEnd((new Date(e.target.value.trim())).toISOString());
  };
  const handleDateChange = (e) => {
    let p = {}
    switch (parseInt(e.target.value)) {
      case 30: //month
        p.end = DateTime.fromMillis(Date.now()).toISO({includeOffset: false})
        p.start = DateTime.fromMillis(Date.now()).minus({day: 30}).toISO({includeOffset: false})
        break;
      case 14: //two weeks
        p.end = DateTime.fromMillis(Date.now()).toISO({includeOffset: false})
        p.start = DateTime.fromMillis(Date.now()).minus({day: 14}).toISO({includeOffset: false})
        break;
      case 7: //week
        p.end = DateTime.fromMillis(Date.now()).toISO({includeOffset: false})
        p.start = DateTime.fromMillis(Date.now()).minus({day: 7}).toISO({includeOffset: false})
        break;
      case 1: //yesterday
        p.end = DateTime.fromMillis(Date.now()).minus({day: 1}).endOf('day').toISO({includeOffset: false})
        p.start = DateTime.fromMillis(Date.now()).minus({day: 1}).startOf('day').toISO({includeOffset: false})
        break;
      case 0: //today
        p.end = DateTime.fromMillis(Date.now()).toISO({includeOffset: false})
        p.start = DateTime.fromMillis(Date.now()).startOf('day').toISO({includeOffset: false})

      default:
        p.end = DateTime.fromMillis(Date.now()).toISO({includeOffset: false})
        p.start = DateTime.fromMillis(Date.now()).startOf('day').toISO({includeOffset: false})

        break;

    }

    setPeriod(p);
    refresh(p)
  }

  const handleYearChange = async (e) => {
    const y = parseInt(e.target.value);

    await loadAnnual(y);
    setYear(y);
  }
  useEffect(async () => {
    await loadData();
    if (window.performance) {
      if (performance.navigation.type == 1) {
        await refresh(period);
      }
    }
  }, [])
  const years = [];
  for (let i = 0; i < 15; i++) {
    years.push((new Date()).getFullYear() - i);
  }
  return (
    <div className='row'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='form-group'>
                <label htmlFor='platform' className='form-label'>
                  Year
                </label>
                <select className='form-select' defaultValue={(new Date()).getFullYear()} onChange={handleYearChange}>
                  {years.map(y => {
                    return <option key={y} value={y}>{y}</option>
                  })}
                </select>
              </div>
            </div>

            <div className='col-md-8'>
              <div className='form-group'>
                <label htmlFor='range' className='form-label'>
                  Date Range
                </label>
                <div>
                <div className='row'>
                  <div className='col-md-3'>
                    <input type="date" className="form-control" placeholder="Select Start Date" defaultValue={start} onChange={handleDateChange1} id='start' />

                  </div>
                  <div className='col-md-3'>
                    <input type="date" className="form-control" placeholder="Select End Date" defaultValue={end} onChange={handleDateChange1} id='end' />

                  </div>
                  <div className='col-md-2'>
                    <button
                      className='btn btn-primary'
                      onClick={handleSearch}>
                      <span className='fe fe-search'></span>&nbsp;Search
                    </button>
                  </div>
                </div>
                </div>
                {/* <select className='form-select' onChange={handleDateChange} disabled>
                  <option value='0'>Today</option>
                  <option value='1'>Yesterday</option>
                  <option value='7'>Last 7 Days</option>
                  <option value='14'>Last 14 Days</option>
                  <option value='30'>Last 30 Days</option>
                </select> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ?  (
          <div className="col-md-12 text-center">
            <Loader type="TailSpin" color="#00B17A" height={50} width={50} />
            <p>Loading data...</p>
          </div>
        ) 
      : 
      <div className='col-md-12 my-4'>
      <div className='row'>
        <div className='col-12 col-lg-6 col-xl'>
          <div className='card'>
            <div className='card-body'>
              <div className='row align-items-center gx-0'>
                <div className='col'>
                  <h6 className='text-capitalize text-muted mb-3'>
                    Wallet Topups
                  </h6>

                  <span className='h2 mb-0 mr-4'>{thousandSeparator(summary.topups)}</span>

                  <span className='badge bg-success-soft mr-2 mt-n1'>
                    +3.5%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-12 col-lg-6 col-xl'>
          <div className='card'>
            <div className='card-body'>
              <div className='row align-items-center gx-0'>
                <div className='col'>
                  <h6 className='text-capitalize text-muted mb-3'>
                    Direct Download Revenue
                  </h6>

                  <span className='h2 mb-0'>{thousandSeparator(summary.direct)}</span>

                  <span className='badge bg-success-soft mr-2 mt-n1'>
                    +3.5%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-12 col-lg-6 col-xl'>
          <div className='card'>
            <div className='card-body'>
              <div className='row align-items-center gx-0'>
                <div className='col'>
                  <h6 className='text-capitalize text-muted mb-3'>
                    Subscription Revenue
                  </h6>

                  <span className='h2 mb-0'>{thousandSeparator(summary.subscriptions)}</span>

                  <span className='badge bg-success-soft mr-2 mt-n1'>
                    +3.5%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-12 col-lg-6 col-xl'>
          <div className='card'>
            <div className='card-body'>
              <div className='row align-items-center gx-0'>
                <div className='col'>
                  <h5 className='text-capitalize text-muted mb-3'>
                    Total Revenue
                  </h5>

                  <span className='h2 mb-0'>{thousandSeparator(summary.total)}</span>

                  <span className='badge bg-success-soft mr-2 mt-n1'>
                    +3.5%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-xl-8'>
          <div className='card card-fill-sm'>
            <div className='card-header'>
              <h4 className='card-header-title'>Revenue Summary</h4>
            </div>
            <div className='card-body'>
              <Line options={optionsLine} data={dataLine} />
            </div>
          </div>
        </div>

        <div className='col-12 col-xl-4'>
          <div className='card card-fill-sm'>
            <div className='card-header'>
              <h4 className='card-header-title'>Revenue by Platform</h4>
            </div>
            <div className='card-body relative'>
              <Doughnut data={data} options={options} />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-xl-12 col-md-12'>
          <div className='card card-fill-sm'>

            <div className='card-body'>
              <Bar options={barOptions} data={barInfo} height={300} />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-xl-12 col-md-12'>
          <div className='card card-fill-sm'>

            <div className='card-body'>
              <Line options={subscriptionOptions} data={subscriptionLine} height={300} />
            </div>
          </div>
        </div>
      </div>

      </div>
    }
    </div>
  )

  async function loadData() {
    const storedData = localStorage.getItem('RIFALYSUMMARY') ? JSON.parse(localStorage.getItem('RIFALYSUMMARY')) : null;

    if (storedData) {
      setSummary(storedData.summary);
      setLineData(storedData.lineData);
      setPieData(storedData.pieData);
      setBarData(storedData.barData);
     // setSubscriptionData(chartData.subscriptionInfo);

    }
    else {
      await refresh(period);
    }
  }
  async function refresh(options) {

    const annual = {
      start: DateTime.utc((new Date()).getFullYear()).startOf('year').toISO({includeOffset: false}),
      end: DateTime.utc((new Date()).getFullYear()).endOf('year').toISO({includeOffset: false})
    }
    try {
      const [ response, response_ ] = await Promise.all([ axios.get(`${api.BILLING_URL}billing/reports/revenue/?startDate=${options.start}&endDate=${options.end}`, {headers: {'Authorization': `Bearer ${user.token}`}}), axios.get(`${api.BILLING_URL}billing/reports/revenue-graph/?startDate=${annual.start}&endDate=${annual.end}`, {headers: {'Authorization': `Bearer ${user.token}`}}) ]);
      const _summary = response.data.data;
      const chartData = response_.data.data;

      setSummary(_summary);
      setLineData(chartData.line);
      setPieData(chartData.pie);
      setBarData(chartData.methods);
      // setSubscriptionData(chartData.subscriptionInfo);

      localStorage.setItem('RIFALYSUMMARY', JSON.stringify({summary: _summary, lineData: chartData.line, pieData: chartData.pie, barData: chartData.methods}));
    }
    catch (e) {
      console.log(e);
    }

  }
  async function loadAnnual(selectedYear) {
    const annual = {
      start: DateTime.fromObject({year: selectedYear}).startOf('year').toISO({includeOffset: false}),
      end: DateTime.fromObject({year: selectedYear}).endOf('year').toISO({includeOffset: false})
    }

    try {
      const response_ = await axios.get(`${api.BILLING_URL}billing/reports/revenue-graph/?startDate=${annual.start}&endDate=${annual.end}`, {headers: {'Authorization': `Bearer ${user.token}`}});

      const chartData = response_.data.data;

      setLineData(chartData.line);
      setPieData(chartData.pie);
      setBarData(chartData.methods);
      // setSubscriptionData(chartData.subscriptionInfo);

      var storedData = JSON.parse(localStorage.getItem('RIFALYSUMMARY'));
      storedData.lineData = chartData.line;
      storedData.pieData = chartData.pie;
      storedData.BarData = chartData.methods;
      // storedData.subscriptionData = chartData.subscriptionInfo;
      localStorage.setItem('RIFALYSUMMARY', JSON.stringify(storedData));
    }
    catch (e) {
      console.log(e);
    }
  }
}

export default RevenueSummary
