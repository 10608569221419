import {
    FETCH_CAMPAIGNS_LOADING,
    FETCH_CAMPAIGNS_SUCCESS,
    FETCH_CAMPAIGNS_FAILURE,
    FETCH_CAMPAIGN_DETAILS_LOADING,
    FETCH_CAMPAIGN_DETAILS_SUCCESS,
    FETCH_CAMPAIGN_DETAILS_FAILURE
  } from '../Actions/campaign-action';
  
  const initialState = {
    campaigns: [],
    isLoading: false,
    error: null,
  };
  
  export default function CampaignReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_CAMPAIGNS_LOADING: {
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      }
      case FETCH_CAMPAIGNS_SUCCESS: {
        return {
          ...state,
          campaigns: action.payload,
          isLoading: false,
          error: null,
        };
      }
      case FETCH_CAMPAIGNS_FAILURE: {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      }
      case FETCH_CAMPAIGN_DETAILS_LOADING:
        return { ...state, isLoading: true };
      case FETCH_CAMPAIGN_DETAILS_SUCCESS:
        return { ...state, campaigns: action.payload, isLoading: false };
      case FETCH_CAMPAIGN_DETAILS_FAILURE:
        return { ...state, error: action.payload, isLoading: false };
      default:
        return state;
    }
  }
  