import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchStories } from "../../../../redux-store/Actions/story-action";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import Navs from "../navs";
import { STORIESCOLUMNS } from "./StoryColumns";
import StoriesTable from "./StoriesTable";
import {
  fetchReportSummary,
  fetchReportPlays,
  fetchReportImpressions,
} from "../../../../api/reports";
import { fetchStoriesApi } from "../../../../api/publisher";
import Loader from "react-loader-spinner";


export default function Stories() {
  const columns = useMemo(() => STORIESCOLUMNS, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [summaryData, setSummaryData] = useState();
  const [allPlaysData, setAllPlaysData] = useState([]);
  const [impressionsData, setImpressionsData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [stories, setStory] = useState(null);
  const [page, setPage] = useState(1);
  const [summaryDataList, setSummaryDataList] = useState([]);
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));

  // const storyState = useSelector((state) => state.stories);
  // console.log("🚀 ~ Stories ~ storyState:", storyState)
  // const { stories } = storyState;
  // console.log("🚀 ~ Stories ~ stories:", stories)

  const thousandSeparator = (x) => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "n/a";
  };

  useEffect(() => {
    fetchData();
  }, [page, user.token]);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const [
        summaryResponse,
        allPlaysResponse,
        allImpressionsResponse,
        revenueResponse,
        storiesResponse,
      ] = await Promise.all([
        fetchReportSummary({
          token: { Authorization: `Bearer ${user.token}` },
        }),
        fetchReportPlays({
          reportType: "audioId",
          token: { Authorization: `Bearer ${user.token}` },
        }),
        fetchReportImpressions({
          reportType: "audioId",
          token: { Authorization: `Bearer ${user.token}` },
        }),
        fetchReportPlays({
          reportType: "revenue",
          token: { Authorization: `Bearer ${user.token}` },
        }),
        fetchStoriesApi(page),
      ]);
      

      setSummaryData(summaryResponse.data);
      setAllPlaysData(allPlaysResponse.data);
      setImpressionsData(allImpressionsResponse.data);
      setRevenueData(revenueResponse.data);
      setStory(storiesResponse.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }

  };


  const combinedData = stories?.data.map((story) => {
    const audioId = story.id;

    const playCount = parseInt(
      allPlaysData.find((item) => item.audioId === audioId)?.playCount || 0,
      10
    );

    const impressionCount = parseInt(
      impressionsData.find((item) => item.audioId === audioId)
        ?.impressionCount || 0,
      10
    );

    const revenue = parseFloat(
      revenueData.find((item) => item.audioId === audioId)?.revenue || 0
    );

    return {
      ...story,
      playCount,
      impressionCount,
      revenue,
    };
  });

  const handlePageChange = (newPage) => {
    if (newPage && newPage < 1) newPage = 1;
    setPage(newPage);
    dispatch(fetchStories(newPage));
  };

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard} >Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Podcasters
                  </li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Stories</h1>
              </div>
            </div>

            <div className="content">
              <Navs></Navs>
              {!combinedData || combinedData.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <Loader
                    type="TailSpin"
                    color="#00b17a"
                    height={50}
                    width={50}
                  />
                </div>
              ) : (
                <StoriesTable
                name="Stories"
                title="Uploaded Stories"
                columns={columns}
                // data={stories?.data || []}
                data={combinedData}
                raw={stories}
                summary={summaryDataList}
                onPageChange={handlePageChange}
              />
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
