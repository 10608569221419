import { 
          FETCH_ADMIN_DETAILS_LOADING,
          FETCH_ADMIN_DETAILS_SUCCESS
       } from "../Actions/admin-action";


const initialState = {
  admin: {},
  isLoading: false,
};


export default function adminDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ADMIN_DETAILS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_ADMIN_DETAILS_SUCCESS: {
      console.log("we are here : admin")
      return {
        ...state,
        admin: action.payload,
        isLoading: false,
      };
    }
    default:
    return state;
  }
}

