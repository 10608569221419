import React from 'react';
import { DateTime } from 'luxon';

export const EMAILCOLUMNS = [
  {
    Header: 'S/NO',
    accessor: 'id',
    Cell: (props) => (
      <div>
        {(props.row.index + 1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    Header: 'Title',
    accessor: 'subject',
    Cell: (props) => <div className='text-capitalize'>{props.value}</div>,
  },
  {
    Header: 'Name',
    accessor: 'type',
    Cell: (props) => <div className='text-capitalize'>{props.value}</div>,
  },
  {
    Header: 'Audience',
    accessor: 'destination',
    Cell: (props) => <div className='text-capitalize'>{props.value}</div>,
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
    Cell: (props) => <div>{formatDate(props.value)}</div>,
  },
  // Uncomment if needed
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   Cell: (props) => <div>{checkStatus(props.value)}</div>,
  // },
  // {
  //   Header: 'Action',
  //   accessor: 'action',
  //   Cell: (props) => (
  //     <div>
  //       <Dropdown>
  //         <Dropdown.Toggle
  //           as='a'
  //           className='dropdown-ellipses icon-dropdown'
  //           id='dropdown-basic'>
  //           <i className='fe fe-more-horizontal'></i>
  //         </Dropdown.Toggle>
  //         <Dropdown.Menu className='dropdown-menu-end'>
  //           <Link
  //             to={`/publishers/publications/${props.row.original.id}`}
  //             className='dropdown-item'>
  //             View
  //           </Link>
  //           <Link
  //             to={`/publishers/publications/edit/${props.row.original.id}`} state={props.row.original}
  //             className='dropdown-item'>
  //             Edit
  //           </Link>
  //           <div className='dropdown-item text-danger'>
  //             {/* <DeletePublication data={props.row.original}></DeletePublication> */}
  //           </div>
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     </div>
  //   ),
  // },
];

function formatDate(isoString) {
  if (isoString) {
    return DateTime.fromISO(isoString)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toLocaleString(DateTime.DATE_MED);
  } else {
    return isoString;
  }
}
