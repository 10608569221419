import { FETCH_TELCOS_LOADING, 
         FETCH_TELCOS_SUCCESS
        } from "../Actions/telco-action";


const initialState = {
  telcos: [],
  isLoading: false,
};


export default function telcoReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TELCOS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_TELCOS_SUCCESS: {
      return {
        ...state,
        telcos: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

