import { FETCH_STAFFS_LOADING, 
         FETCH_STAFFS_SUCCESS
        } from "../Actions/staff-action";


const initialState = {
  staffs: [],
  isLoading: false,
};


export default function staffReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STAFFS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_STAFFS_SUCCESS: {
      return {
        ...state,
        staffs: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

