import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import DashboardWrapper from "../../../components/DashboardWrapper";
import ContentHighlights from "./ContentHighlights";
import DormantUserReactivation from "./DormantUserReactivation";
import FeatureTutorial from "./FeatureTutorial";
import WelcomeEmailCreation from "./WelcomeEmailCreation";

const schema = yup
  .object({
    emailType: yup
      .string()
      .required("Email Type is required")
  })
  .required();

function CreateEmail() {
  const navigate = useNavigate();
  const [emailType, setEmailType] = useState("");
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const handleEmailTypeChange = (e) => {
    setEmailType(e.target.value);
  };

  const toPage = (path) => (e) => {
    e.preventDefault();
    navigate(path);
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/emails" onClick={toPage("/emails")}>
                      Emails
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create Email
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="card pt-2 mt-2">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>
                        <h1 className="header-title">Create Email</h1>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-md-12">
                          <FormProvider {...methods}>
                              <div className="row mb-2">
                                <div className="col-md-12">
                                  <label
                                    htmlFor="emailType"
                                    className="form-label"
                                  >
                                    Create Email Campaign
                                  </label>
                                  <select
                                    {...methods.register("emailType")}
                                    onChange={handleEmailTypeChange}
                                    className="form-select"
                                  >
                                    <option value="" disabled selected>
                                      Select Type of Email
                                    </option>
                                    {/* <option value="1">Welcome Email</option> */}
                                    <option value="2">
                                      Dormant user reactivation
                                    </option>
                                    <option value="3">Feature Tutorial</option>
                                    <option value="4">
                                      Content Highlights
                                    </option>
                                  </select>
                                  {methods.formState.errors.emailType && (
                                    <p className="text-danger">
                                      {
                                        methods.formState.errors.emailType
                                          .message
                                      }
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="row mb-4">
                                {emailType === "1" && (
                                  <WelcomeEmailCreation emailType={emailType} />
                                )}
                                {emailType === "2" && (
                                  <DormantUserReactivation
                                    emailType={emailType}
                                  />
                                )}
                                {emailType === "3" && (
                                  <FeatureTutorial emailType={emailType} />
                                )}
                                {emailType === "4" && (
                                  <ContentHighlights emailType={emailType} />
                                )}
                              </div>
                          </FormProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CreateEmail;
