import { fetchTopUpsApi, fetchTopupDetailsApi } from "../../api/reports";

export const FETCH_TOPUPS_LOADING = "FETCH_TOPUPS_LOADING";
export const FETCH_TOPUPS_SUCCESS = "FETCH_TOPUPS_SUCCESS";
export const FETCH_TOPUPS_FAILURE = "FETCH_TOPUPS_FAILURE";

export const FETCH_TOPUP_DETAILS_LOADING = "FETCH_TOPUP_DETAILS_LOADING";
export const FETCH_TOPUP_DETAILS_SUCCESS = "FETCH_TOPUP_DETAILS_SUCCESS";
export const FETCH_TOPUP_DETAILS_FAILURE = "FETCH_TOPUP_DETAILS_FAILURE";

export const fetchTopUps = () => {
  return (dispatch) => {
    dispatch(fetchTopupsLoading());
    return fetchTopUpsApi()
      .then((res) => {
        console.log(res.data.data);
        const data = res.data.data || [];
        dispatch(fetchTopupsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchTopupsFailure(error.message));
      });
  };
};

const fetchTopupsLoading = () => {
  return {
    type: FETCH_TOPUPS_LOADING,
  };
};

const fetchTopupsSuccess = (data) => {
  return {
    type: FETCH_TOPUPS_SUCCESS,
    payload: data,
  };
};

const fetchTopupsFailure = (error) => {
  return {
    type: FETCH_TOPUPS_FAILURE,
    payload: error,
  };
};

export const fetchTopUpDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchTopupDetailsLoading());
    return fetchTopupDetailsApi(id)
      .then((res) => {
        console.log(res)
        const data = (res.data.data) || [];
        console.log(data)
        dispatch(fetchTopupDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchTopupDetailsFailure(error.message));
      });
  };
};

const fetchTopupDetailsLoading = () => {
  return {
    type: FETCH_TOPUP_DETAILS_LOADING,
  };
};

const fetchTopupDetailsSuccess = (data) => {
  return {
    type: FETCH_TOPUP_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchTopupDetailsFailure = (error) => {
  return {
    type: FETCH_TOPUP_DETAILS_FAILURE,
    payload: error,
  };
};
