import React from 'react'
import { Link,useNavigate } from "react-router-dom";
import  DashboardWrapper  from "../../../../components/DashboardWrapper";
import Navs from "../navs";
import StaffTable from './StaffTable';



export default function Staff() {

  const navigate = useNavigate();
  function toDashboard(e) {
    e.preventDefault()
    navigate("/dashboard");
  }


  return (
    <DashboardWrapper>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">
           
            <div className="header mt-md-3">

              <nav aria-label="breadcrumb mb-2">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/dashboard" onClick={toDashboard} >Dashboard</a>
                        
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">Publishers</li>
                    </ol>
              </nav>

              <div className="header-body">

      
                <h6 className="header-pretitle">
                  Overview
                </h6>

                
                <h1 className="header-title">
                  Staffs
                </h1>

              </div>
            </div>


            <div className="content">
              <Navs></Navs>

              <div className="row align-items-end my-4">
                <div className="col">

               
                  {/* <form action="" className="form d-flex">
                    <input type="text" className="form-control" />

                    <input type="text" className="form-control" />
                  </form> */}

                </div>

                <div className="col-auto">

                
                  <Link to="/publishers/staff/create" className="btn btn-primary lift">
                    <span>
                      <i className="fe fe-plus"></i>
                    </span>
                    Create Staff
                  </Link>

                </div>
              </div> 

              <div className="row mt-2 body-content">
                <div className="col-md-12">
                
                  <StaffTable />
                </div>
              </div>


            </div>

            </div>


          </div>
        </div>
      </DashboardWrapper>
  )
}
