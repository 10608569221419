import React from 'react'
// import { identifier } from "@babel/types";
import { Dropdown} from "react-bootstrap";
import { Link } from "react-router-dom";
import {DateTime} from 'luxon';
import DeletePromocode from "./DeletePromocode";

export const PROMOCODECOLUMNS = [
  {
    header: 'id',
    accessor: 'id',
    Cell: (props) => <div>{ props.row.index + 1}</div>
  }, 
  {
    header: 'Campaign Id',
    accessor: 'campaignId',
    Cell: (props) => (
      <div className="text-capitalize">
        <Link to={`/analytics`}>
          {/* to={`/analytics/${props.row.original.id}`}> */}
          {props.value}
        </Link>
      </div>
    ),  },
  {
    header: 'Code type',
    accessor: 'type',
    Cell: (props) => (
      <div className="text-capitalize">
        {props.value === 1 ? 'Multi-user' : props.value === 2 ? 'Single-user' : 'Unknown type'}
      </div>
    )
  },  
  {
    header: 'Code name ',
    accessor: 'code',
    Cell: (props) => <div className="text-capitalize">{ props.value} </div>
  },
  {
    header: 'Value',
    accessor: 'value',
    Cell: (props) => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'Max count',
    accessor: 'maxCount',
    Cell: (props) => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'Description',
    accessor: 'description',
    Cell: (props) => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'Redeem',
    accessor: 'redeem',
    Cell: (props) => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'Created Date',
    accessor: 'createdAt',
    Cell: (props) => <div>{formatDate(props.value)}</div>,
  },
  {
    header: 'Expiry Date',
    accessor: 'expiryDate',
    Cell: (props) => <div>{formatDate(props.value)}</div>,
  },
  {
    header: 'Status',
    accessor: 'status',
    Cell: (props) => <div>{checkStatus("ACTIVE")}</div>,
  },
  {
    header: 'action',
    accessor: 'action',
    Cell: (props) => <div>
 <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic"
          >
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            <Link
              to={{ pathname: `/promocodes/view/${props.row.original.id}`}}
              className="dropdown-item"
            >
              View
            </Link>
            {/* <Link
              to={{ pathname: `/promocodes/edit/${props.row.original.id}`}} state={{promocodes:props.row.original }}
              className="dropdown-item"
            > 
              Edit
            </Link> */}
            {/* <div className="dropdown-item text-danger">
              <DeletePromocode name={props.row.original}></DeletePromocode>
            </div> */}
          </Dropdown.Menu>
        </Dropdown>
    </div>
  }
 
]

function checkStatus(status) {
  switch (status) {
    case "ACTIVE":
      return (
        <div className="status">
          <div className="dot"></div>
          Active
        </div>
      );

    case "INACTIVE":
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Suspended
        </div>
      );

    default:
      return (
        <div className="status">
          <div className="dot inactive"></div>
          {status}
        </div>
      );
  }
}

const formatDate = (dateString) => {
  if (dateString) {
    let date;
    
    // Check if the date string is in ISO format
    if (dateString.includes("T")) {
      date = DateTime.fromISO(dateString);
    } else {
      // Assuming the other format is "30-01-2024 00:00:00"
      date = DateTime.fromFormat(dateString, "yyyy-MM-dd HH:mm:ss");
    }

    return date.toFormat("dd LLL yyyy");
  } else {
    return dateString;
  }
};

