import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export const SHARELINKSCOLUMNS = [
  {
    id: 0,
    header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    id: 1,
    header: "link",
    accessor: "link",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    id: 2,
    header: "ShareNo",
    accessor: "shares",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
];
