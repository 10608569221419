import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../containers/domains/dashboard/Index";
import Support from "../containers/Support";
import Validation from "../containers/Validation";
import Reports from "../containers/domains/reports/";
import Corporates from "../containers/domains/corporates/";
import Issues from "../containers/domains/publishers/issue/Index";
import Staff from "../containers/domains/publishers/staff/Staff";
import Publication from "../containers/domains/publishers/publication/Publication";
import Company from "../containers/domains/publishers/company/Company";
import Create from "../containers/domains/publishers/issue/Create";
import { Issue } from "../containers/domains/publishers/issue/Issue";
import CreateCompany from "../containers/domains/publishers/company/CreateCompany";
import CompanyDetails from "../containers/domains/publishers/company/CompanyDetails";
import EditCompany from "../containers/domains/publishers/company/editCompany";
import Admins from "../containers/domains/users/admins/Index";
import Readers from "../containers/domains/users/readers/Index";
import Telcos from "../containers/domains/users/telcos/Index";
import NotFound from "../components/NotFound/Index";
import CreateStaff from "../containers/domains/publishers/staff/CreateStaff";
import CreateUser from "../containers/domains/users/CreateUser";

import Campaigns from "../containers/domains/promotions/campaigns/Index";
import CreateCampaign from "../containers/domains/promotions/campaigns/CreateCampaign";
import EditCampaign from "../containers/domains/promotions/campaigns/EditCampaign";
import CampaignsDetails from "../containers/domains/promotions/campaigns/viewCampaignDetails";
import CampaignAnalytics from "../containers/domains/promotions/campaigns/CampaignAnalytics";
import DeleteCampaign from "../containers/domains/promotions/campaigns/DeleteCampaign";
import Promocodes from "../containers/domains/promotions/promocode/Index";
import CreatePromocode from "../containers/domains/promotions/promocode/CreatePromocode";
import EditPromocode from "../containers/domains/promotions/promocode/EditPromocode";
import PromocodesDetails from "../containers/domains/promotions/promocode/viewPromocodeDetails";
import DeletePromocode from "../containers/domains/promotions/promocode/DeletePromocode";
import Promotions from "../containers/domains/promotions/Index";
import CreatePromotion from "../containers/domains/promotions/CreatePromotion";
import EditPromotion from "../containers/domains/promotions/EditPromotions";
import PromotionsDetails from "../containers/domains/promotions/viewPromotionDetails";
import DeletePromotion from "../containers/domains/promotions/DeletePromotion";

import Emails from "../containers/domains/emails/index";
import CreateEmail from "../containers/domains/emails/createEmail";


import Login from "../containers/Home/Login";
import CreatePublication from "../containers/domains/publishers/publication/CreatePublication";
import Publications from "../containers/domains/publishers/publication/Index";
import TestPub from "../containers/domains/publishers/publication/TestPub";
import EditPublication from "../containers/domains/publishers/publication/EditPublication";
import EditIssue from "../containers/domains/publishers/issue/EditIssue";
import UserDetails from "../containers/domains/users/UserDetails";
import Genres from "../containers/domains/settings/Genres";
import PublisherRevenue from "../containers/domains/publishers/publishersRevenue/index";
import RevenueDetails from "../containers/domains/publishers/publishersRevenue/PublishRevenueDetails";

import PodcasterHome from "../containers/domains/podcaster/overview/main";
import Channel from "../containers/domains/podcaster/channel/Channel";
import ChannelDetails from "../containers/domains/podcaster/channel/ChannelDetails";
import EditChannel from "../containers/domains/podcaster/channel/editChannel";
import Podcasters from "../containers/domains/podcaster/index";
import Stories from "../containers/domains/podcaster/stories/Stories";
import CreateStory from "../containers/domains/podcaster/stories/CreateStory";
import EditStory from "../containers/domains/podcaster/stories/EditStory";
import StoryDetail from "../containers/domains/podcaster/stories/StoryDetail";
import PerformancePerEpisodeReport from "../containers/domains/podcaster/performance_per_episode/index";

// import Route from "../components/Routes/Route";
import AdminDetails from "../containers/domains/users/admins/AdminDetails";
// import GuestRoute from "../components/GuestRoutes/GuestRoute";
import CreateCorporate from "../containers/domains/corporates/CreateCorporate";
import CorporateStaff from "../containers/domains/corporates/CorporateStaff";
import CreateCorporateStaff from "../containers/domains/corporates/CreateCorporateStaff";
import Revenue from "../containers/domains/dashboard/Revenue";
import Activity from "../containers/domains/reports/Activity";
import Content from "../containers/domains/reports/Content";
import PublisherAdmins from "../containers/domains/users/publishers/Index";
import CorporateAdmins from "../containers/domains/users/corporates/Index";
import PublisherAdminDetails from "../containers/domains/users/publishers/PublisherAdminDetails";
import Settings from "../containers/domains/settings/Settings";
import Categories from "../containers/domains/settings/categories";
import GenreDetail from "../containers/domains/settings/genre_detail";
import GenreCreate from "../containers/domains/settings/create_genre";
import CorporatePricing from "../containers/domains/settings/reports/CorporatePricing";
import CorporateSubscriptions from "../containers/domains/corporates/CorporateSubscriptions";
import CreateCorporateSubscription from "../containers/domains/corporates/createSubscriptionGroup";
import CorporateSubscriptionDetail from "../containers/domains/corporates/subscriptionGroupDetails";
import SubscriptionGroupEdit from "../containers/domains/corporates/SubscriptionGroupEdit";
import CorporateDetails from "../containers/domains/corporates/CorporateDetails";
import CorporateReaders from "../containers/domains/users/corporate_readers/Index";
import StaffDetails from "../containers/domains/corporates/CorporateStaffDetails";
import CorporateEdit from "../containers/domains/corporates/corporateEdit";
import CorporateReports from "../containers/domains/corporates/Reports";
import CorporateSummaryReports from "../containers/domains/corporates/SummaryReports";
import TopUps from "../containers/domains/reports/topups/index";
import TopUpsReports from "../containers/domains/reports/topups/createtopup";
import Profile from "../containers/domains/profile/Profile";
import ProfileEdit from "../containers/domains/profile/ProfileEdit";
import ShareReport from "../containers/domains/publishers/shareLinks/shareReport";
import Book from "../containers/domains/publishers/books/Books";
import CreateBook from "../containers/domains/publishers/books/CreateBook";
// import DeleteBook from "../containers/domains/publishers/books/DeleteBook";
import EditBook from "../containers/domains/publishers/books/EditBook";
import ViewBook from "../containers/domains/publishers/books/ViewBook";

const CustomRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact strict path="/dashboard/revenue" element={<Revenue />} />
        <Route exact strict path="/dashboard/users" element={<Home />} />
        <Route path="/dashboard" element={<Home />}></Route>
        <Route exact strict path="/profile" element={<Profile />} />
        <Route exact strict path="/profile/edit" element={<ProfileEdit />} />
        <Route
          exact
          strict
          path="/settings/genres/:genreId"
          element={<GenreDetail />}
        />
        <Route
          exact
          strict
          path="/settings/genres/create"
          element={<GenreCreate />}
        />
        <Route exact strict path="/settings/genres" element={<Genres />} />
        <Route
          exact
          strict
          path="/settings/categories"
          element={<Categories />}
        />
        <Route exact strict path="/settings" element={<Settings />} />
        <Route
          exact
          strict
          path="/settings/corporatepricing"
          element={<CorporatePricing />}
        />

        <Route path="/users/create" element={<CreateUser />}></Route>
        <Route path="/users/admins/:id" element={<AdminDetails />}></Route>
        <Route path="/users/admins" element={<Admins />}></Route>
        <Route path="/users/publishers" element={<PublisherAdmins />}></Route>
        <Route path="/users/corporates" element={<CorporateAdmins />}></Route>
        <Route
          path="/users/publishers/:id"
          element={<PublisherAdminDetails />}
        ></Route>

        <Route exact path="/users/readers" element={<Readers />}></Route>
        <Route
          path="/users/corporate/readers"
          element={<CorporateReaders />}
        ></Route>
        <Route path="/users" element={<Admins />}></Route>
        <Route path="/users/readers/:ref" element={<UserDetails />}></Route>
        <Route exact path="/publishers/issues/test" element={<Issue />}></Route>
        <Route
          exact
          path="/publishers/issues/edit/:id"
          element={<EditIssue />}
        ></Route>
        <Route
          exact
          path="/publishers/issues/create"
          element={<Create />}
        ></Route>
        <Route
          exact
          path="/publishers/issues/show/:id"
          element={<Issue />}
        ></Route>
        <Route exact path="/publishers/issues" element={<Issues />}></Route>

        {/* <Route path="/podcasters" element={<Podcasters />}></Route> */}
        <Route path="/podcasters/overview" element={<PodcasterHome />}></Route>
        <Route
          path="/podcasters/channel/:id"
          element={<ChannelDetails />}
        ></Route>
        <Route
          path="/podcasters/channel/edit/:id"
          element={<EditChannel />}
        ></Route>
        <Route path="/podcasters/channels" element={<Channel />}></Route>
        <Route path="/podcasters/create" element={<Create />}></Route>
        <Route
          exact
          path="/podcasters/stories/show/:id"
          element={<StoryDetail />}
        ></Route>
        <Route
          exact
          path="/podcasters/stories/edit/:id"
          element={<EditStory />}
        ></Route>
        <Route
          exact
          path="/podcasters/stories/create"
          element={<CreateStory />}
        ></Route>
        <Route exact path="/podcasters/stories" element={<Stories />}></Route>
        <Route
          path="/reports/performance_per_episode/:id"
          element={<PerformancePerEpisodeReport />}
        />

        <Route
          exact
          path="/publishers/staff/create"
          element={<CreateStaff />}
        ></Route>
        <Route exact path="/publishers/staff" element={<Staff />}></Route>

        <Route
          exact
          path="/publishers/publications/edit/:id"
          element={<EditPublication />}
        ></Route>
        <Route
          exact
          path="/publishers/publications/test"
          element={<TestPub />}
        ></Route>
        <Route
          exact
          path="/publishers/publications/create"
          element={<CreatePublication />}
        ></Route>
        <Route
          exact
          path="/publishers/publications/:id"
          element={<Publication />}
        ></Route>

        <Route
          exact
          path="/publishers/publications"
          element={<Publications />}
        ></Route>

        <Route
          path="/publishers/company/create"
          element={<CreateCompany />}
        ></Route>
        <Route
          path="/publishers/company/:id"
          element={<CompanyDetails />}
        ></Route>
        <Route
          path="/publishers/company/edit/:id"
          element={<EditCompany />}
        ></Route>
        <Route path="/publishers/company" element={<Company />}></Route>
        <Route path="/publishers/create" element={<Create />}></Route>
        <Route path="/publishers" element={<Company />}></Route>
        <Route path="/publishers/publishersRevenue" element={<PublisherRevenue/>}></Route>
        <Route path="/publishers/publishersRevenueDetails/:id" element={<RevenueDetails/>}></Route>
        <Route path="/publishers/shareReport" element={<ShareReport/>}></Route>
        <Route
          exact
          path="/campaigns/create"
          element={<CreateCampaign />}
        ></Route>
        <Route exact path="/campaigns" element={<Campaigns />}></Route>
        <Route
          exact
          path="/campaigns/edit/:campaignId"
          element={<EditCampaign />}
        ></Route>
        <Route
          exact
          path="/campaigns/view/:campaignId"
          element={<CampaignsDetails />}
        ></Route>
        <Route exact path="/analytics" element={<CampaignAnalytics />}></Route>
        <Route
          exact
          path="/campaigns/:campaignId"
          element={<DeleteCampaign />}
        ></Route>
        <Route
          exact
          path="/promocodes/create"
          element={<CreatePromocode />}
        ></Route>
        <Route exact path="/promocodes" element={<Promocodes />}></Route>
        <Route
          exact
          path="/promocodes/edit/:promocodeId"
          element={<EditPromocode />}
        ></Route>
        <Route
          exact
          path="/promocodes/view/:promocodeId"
          element={<PromocodesDetails />}
        ></Route>
        <Route
          exact
          path="/promocodes/:promocodeId"
          element={<DeletePromocode />}
        ></Route>
        <Route
          exact
          path="/promotions/create"
          element={<CreatePromotion />}
        ></Route>
        <Route exact path="/promotions" element={<Promotions />}></Route>
        <Route
          exact
          path="/promotions/edit/:promotionId"
          element={<EditPromotion />}
        ></Route>
        <Route
          exact
          path="/promotions/view/:promotionId"
          element={<PromotionsDetails />}
        ></Route>
        <Route
          exact
          path="/promotions/:promotionId"
          element={<DeletePromotion />}
        ></Route>
        <Route
          exact
          path="/corporates/company/create"
          element={<CreateCorporate />}
        ></Route>
        <Route
          exact
          path="/corporates/companies"
          element={<Corporates />}
        ></Route>
        <Route exact path="/corporates" element={<Corporates />}></Route>
        <Route
          exact
          path="/corporates/companies/edit"
          element={<CorporateEdit />}
        ></Route>
        <Route
          exact
          path="/corporates/companies/show"
          element={<CorporateDetails />}
        ></Route>
        <Route
          exact
          path="/corporates/staffs/show"
          element={<StaffDetails />}
        ></Route>
        <Route
          exact
          path="/corporates/staffs/create"
          element={<CreateCorporateStaff />}
        ></Route>
        <Route
          exact
          path="/corporates/staffs"
          element={<CorporateStaff />}
        ></Route>
        <Route
          exact
          path="/corporates/subscription-groups/show"
          element={<CorporateSubscriptionDetail />}
        ></Route>
        <Route
          exact
          path="/corporates/subscription-groups/edit"
          element={<SubscriptionGroupEdit />}
        ></Route>
        <Route
          exact
          path="/corporates/subscription-groups"
          element={<CorporateSubscriptions />}
        ></Route>
        <Route
          path="/corporates/subscription-groups/create"
          element={<CreateCorporateSubscription />}
        ></Route>
        <Route
          path="/corporates/reports"
          element={<CorporateReports />}
        ></Route>
        <Route
          path="/corporates/summary_reports"
          element={<CorporateSummaryReports />}
        ></Route>
        <Route exact path="/reports/users" element={<Reports />}></Route>
        <Route exact path="/reports/revenue" element={<Revenue />}></Route>
        <Route exact path="/reports/activity" element={<Activity />}></Route>
        <Route exact path="/reports/content" element={<TopUps />}></Route>
        <Route path="/reports" element={<Revenue />}></Route>
        <Route path="/reports/topup" element={<TopUps />}></Route>
        <Route path="/reports/createtopup" element={<TopUpsReports />}></Route>
        <Route exact path="/publishers/books" element={<Book />}></Route>
        <Route
          exact
          path="/publishers/books/edit/:id"
          element={<EditBook />}
        ></Route>
        <Route
          exact
          path="/publishers/books/create"
          element={<CreateBook />}
        ></Route>
        <Route
          exact
          path="/publishers/books/show/:id"
          element={<ViewBook />}
        ></Route>

        <Route path="/emails" element={<Emails />}></Route>
        <Route path="/emails/create" element={<CreateEmail />}></Route>

        <Route exact path="/support" element={<Support />}></Route>
        <Route exact path="/validation" element={<Validation />}></Route>
        <Route exact path="/" element={<Login />}></Route>

        <Route exact path="/tests" element={<TestPub />}></Route>

        <Route element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default CustomRoutes;
