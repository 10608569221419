import { fetchPromocodesApi, fetchPromocodeDetailsApi } from "../../api/promotion";

export const FETCH_PROMOCODES_LOADING = "FETCH_PROMOCODES_LOADING";
export const FETCH_PROMOCODES_SUCCESS = "FETCH_PROMOCODES_SUCCESS";
export const FETCH_PROMOCODES_FAILURE = "FETCH_PROMOCODES_FAILURE";

export const FETCH_PROMOCODE_DETAILS_LOADING = "FETCH_PROMOCODE_DETAILS_LOADING";
export const FETCH_PROMOCODE_DETAILS_SUCCESS = "FETCH_PROMOCODE_DETAILS_SUCCESS";
export const FETCH_PROMOCODE_DETAILS_FAILURE = "FETCH_PROMOCODE_DETAILS_FAILURE";

export const fetchPromocodes = () => {
  return (dispatch) => {
    dispatch(fetchPromocodesLoading());
    return fetchPromocodesApi()
      .then((res) => {
        console.log(res.data.data);
        const data = res.data.data || [];
        dispatch(fetchPromocodesSuccess(data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchPromocodesFailure(error.message));
      });
  };
};

const fetchPromocodesLoading = () => {
  return {
    type: FETCH_PROMOCODES_LOADING,
  };
};

const fetchPromocodesSuccess = (data) => {
  return {
    type: FETCH_PROMOCODES_SUCCESS,
    payload: data,
  };
};

const fetchPromocodesFailure = (error) => {
  return {
    type: FETCH_PROMOCODES_FAILURE,
    payload: error,
  };
};

export const fetchPromocodeDetails = (id, token) => {
  return (dispatch) => {
    dispatch(fetchPromocodeDetailsLoading());
    return fetchPromocodeDetailsApi(id, token)
      .then((res) => {
        console.log(res)
        const data = (res.data.data) || {};
        dispatch(fetchPromocodeDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchPromocodeDetailsFailure(error.message));
      });
  };
};

const fetchPromocodeDetailsLoading = () => {
  return {
    type: FETCH_PROMOCODE_DETAILS_LOADING,
  };
};

const fetchPromocodeDetailsSuccess = (data) => {
  return {
    type: FETCH_PROMOCODE_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchPromocodeDetailsFailure = (error) => {
  return {
    type: FETCH_PROMOCODE_DETAILS_FAILURE,
    payload: error,
  };
};
