import React from "react";

export const PRDETAILSCOLUMNS = [
  {
    Header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {" "}
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    Header: "Publisher",
    accessor: "companyName",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    Header: "Newspaper Issue",
    accessor: "title",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    Header: "Total Revenue",
    accessor: "amount",
    Cell: (props) => (
      <div className="capitalize">{formatAmount(props.value)}</div>
    ),
  },
  {
    Header: "Commission",
    accessor: "commission",
    Cell: (props) => <div>{formatAmount(props.value)}</div>,
  },
  {
    Header: "Commission Rate",
    accessor: "commissionRate",
    Cell: (props) => <div>{formatAmount(props.value)}</div>,
  },
  {
    Header: "Revenue",
    accessor: (row) => row.amount - row.commission,
    Cell: (props) => <div>{formatAmount(props.value)}</div>,
  },
  {
    Header: "Currency",
    accessor: "currency",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    Header: "Reads",
    accessor: "downloadCount",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
];

const formatAmount = (x) => {
  if (x === undefined || x === null) {
    return "0";
  }
  const number = parseFloat(x).toFixed(0);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
