import React, { useEffect, useState} from 'react'
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPromotionDetails } from '../../../redux-store/Actions/promotion-action';
import DashboardWrapper from '../../../components/DashboardWrapper'
import Loader from 'react-loader-spinner';
import api_config from "../../../api/api_config"


function PromotionsDetails(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()

  const [changeVisibility, setChangeVisibility] = useState(false);
  let { promotionId } = useParams();
  const [isLoading]=useState();
  // const { promotion, isLoading } = useSelector(
  //   (state) => state.promotions
  // );
  // const { promotions, isLoading } = useSelector((state) => state.promotions);
  const promotionsData = location.state && location.state.promotions ? location.state.promotions : [];
  const [promotion,setPromotion] = useState(promotionsData);

  useEffect(() => {
    dispatch(fetchPromotionDetails(promotionId));
  }, [promotionId, dispatch]);

  
  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }

  return (
    <DashboardWrapper>
      <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">  
              <div className="header mt-md-3">

                <nav aria-label="breadcrumb mb-2">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/dashboard" onClick={toPage("/dashboard")} >Dashboard</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="/promotions" onClick={toPage("/promotions")} >Promotions</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">Promotion Details</li>
                    </ol>
                </nav>

              </div>
              {/* end of header */}

              <div className="content">
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="card">


                    { isLoading ? (
                      <div className='loader-holder d-flex justify-content-center align-items-center'>
                      <Loader
                        type="BallTriangle"
                        heigth="100"
                        width="100"
                        color="green"
                        ariaLabel="loading-indicator"
                      />
                    </div>
                    ) : (
                      <div>
                        { promotion && (
                          <div className="row">
                             <div className="col-md-12">
                                <div className="px-4 pt-4">

                                  <h6 className="header-pretitle">
                                    Overview
                                  </h6>

                                  <h1 className="header-title">
                                  Promotion Information
                                  </h1>

                                  <hr />

                                </div>

                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className='image-holder'>

                                        <div className='img-place'>
                                          <div className="banner-cover" 
                                                onMouseEnter={e => {
                                                  setChangeVisibility(true);
                                                }}
                                                onMouseLeave={e => {
                                                  setChangeVisibility(false)
                                                }}
                                                >
                                            <img src={promotion.banner_link} className="img-fluid" alt={promotion.title} />
                                          </div>
                                        </div>

                                      </div>
                                      
                                    </div>
 
                                  </div>
                                  <section className="detail-section"> 
                                    <div className="detail-title">
                                      <div className="row">
                                        <div className="col">
                                          <h3>Basic Information</h3>
                                        </div>

                                        <div className="col-auto">
                                        <Link to={{ pathname: `/promotions/edit/${promotionId}`}} state={{promotions:promotion }}
                                          className="btn-link">
                                          <i className="fe fe-edit-3"></i>
                                          <span className="ml-2">Edit</span>
                                        </Link>
                                        </div>
                                      </div>
                                      

                                    </div>

                                    <div className="detail-contents">
                                      <div className="row">

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          promotions Name
                                          </div>
                                          <div className="dc-body">
                                            { promotion.title}
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Description
                                          </div>
                                          <div className="dc-body">
                                          { promotion.description}
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          promotions channel
                                          </div>
                                          <div className="dc-body">
                                          { promotion.channel}
                                          </div>

                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          promotions segment
                                          </div>
                                          <div className="dc-body">
                                          { promotion.segment}
                                          </div>

                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Language
                                          </div>
                                          <div className="dc-body">
                                          { promotion.language}
                                          </div>

                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Action
                                          </div>
                                          <div className="dc-body">
                                          { promotion.action}
                                          </div>

                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Promotions created Date
                                          </div>
                                          <div className="dc-body">
                                          { promotion.createdAt}
                                          </div>

                                        </div>

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                          Promotions Status
                                          </div>
                                          <div className="dc-body">
                                          { promotion.status}
                                          </div>

                                        </div>

                                      </div>
                                    </div>

                                  </section>

                                </div>

                              </div>
                          </div>
                        )}
                      </div>
                    )}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>


          </div>

    </DashboardWrapper>
  )
}

export default PromotionsDetails
