import React , { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPublications } from '../../../../redux-store/Actions/publication-action';



function TestPub() {

  const dispatch = useDispatch();

  const { publications } = useSelector(
    (state) => state.publications.publications
  );  

  useEffect(() => {
    dispatch(fetchPublications())
  }, [dispatch])
  

  return (
    <div className='container mt-6'>
    

        { publications && (
          <div className="row">
            { publications.map(publication => (
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <img className='img-fluid shadow rounded'  
                         src={`https://rifaly.s3.us-west-2.amazonaws.com/${publication.cover}`} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
         
        )}
        
    </div>
  )
}

export default TestPub
