import { fetchCampaignsApi, fetchCampaignDetailsApi } from "../../api/promotion";

export const FETCH_CAMPAIGNS_LOADING = "FETCH_CAMPAIGNS_LOADING";
export const FETCH_CAMPAIGNS_SUCCESS = "FETCH_CAMPAIGNS_SUCCESS";
export const FETCH_CAMPAIGNS_FAILURE = "FETCH_CAMPAIGNS_FAILURE";

export const FETCH_CAMPAIGN_DETAILS_LOADING = "FETCH_CAMPAIGN_DETAILS_LOADING";
export const FETCH_CAMPAIGN_DETAILS_SUCCESS = "FETCH_CAMPAIGN_DETAILS_SUCCESS";
export const FETCH_CAMPAIGN_DETAILS_FAILURE = "FETCH_CAMPAIGN_DETAILS_FAILURE";

export const fetchcampaigns = (token) => {
  return (dispatch) => {
    dispatch(fetchCampaignsLoading());
    return fetchCampaignsApi(token)
      .then((res) => {
        console.log(res.data.data);
        const data = res.data.data || [];
        dispatch(fetchCampaignsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchCampaignsFailure(error.message));
      });
  };
};

const fetchCampaignsLoading = () => {
  return {
    type: FETCH_CAMPAIGNS_LOADING,
  };
};

const fetchCampaignsSuccess = (data) => {
  return {
    type: FETCH_CAMPAIGNS_SUCCESS,
    payload: data,
  };
};

const fetchCampaignsFailure = (error) => {
  return {
    type: FETCH_CAMPAIGNS_FAILURE,
    payload: error,
  };
};

export const fetchCampaignDetails = (id, token) => {
  return (dispatch) => {
    dispatch(fetchCampaignDetailsLoading());
    return fetchCampaignDetailsApi(id, token)
      .then((res) => {
        console.log(res)
        const data = (res.data.data) || [];
        console.log(data)
        dispatch(fetchCampaignDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchCampaignDetailsFailure(error.message));
      });
  };
};

const fetchCampaignDetailsLoading = () => {
  return {
    type: FETCH_CAMPAIGN_DETAILS_LOADING,
  };
};

const fetchCampaignDetailsSuccess = (data) => {
  return {
    type: FETCH_CAMPAIGN_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchCampaignDetailsFailure = (error) => {
  return {
    type: FETCH_CAMPAIGN_DETAILS_FAILURE,
    payload: error,
  };
};
