import React from "react";
// import { identifier } from "@babel/types";
import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";

export const PRCOLUMNS = [
  {
    Header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {" "}
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    Header: "Publisher",
    accessor: "companyName",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    Header: "Total Revenue",
    accessor: "amount",
    Cell: (props) => <div className="capitalize">{formatAmount(props.value)}</div>,
  },
  {
    Header: "Commission",
    accessor: "commission",
    Cell: (props) => <div>{formatAmount(props.value)}</div>,
  },
  {
    Header: "Commission Rate",
    accessor: "commissionRate",
    Cell: (props) => <div>{formatAmount(props.value)}</div>,
  },
  {
    Header: "Revenue",
    accessor: (row) => row.amount - row.commission,
    Cell: (props) => (
      <div>{formatAmount(props.value)}</div>
    ),
  },
  {
    Header: "Currency",
    accessor: "currency",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    Header: "status",
    accessor: "status",
    Cell: (props) => <div>{checkStatus(props.value)}</div>,
  },
  {
    Header: "action",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic"
          >
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            <Link
              to={`/publishers/publishersRevenueDetails/${props.row.original.publisherId}`} state={props.row.original}
              className="dropdown-item"
            >
              View
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

function checkStatus(status) {
  switch (status) {
    case "ACTIVE":
      return (
        <div className="status">
          <div className="dot"></div>
          Active
        </div>
      );

    default:
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Inactive
        </div>
      );
  }
}
  const formatAmount = (x) => {
    if (x === undefined || x === null) {
      return "0";
    }
    const number = parseFloat(x).toFixed(0);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };