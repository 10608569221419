import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { fetchCompany } from "../../../redux-store/Actions/company-action";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCorporateSubscriptionGroupsApi,
  createCorporateSubscriptionGroupsApi,
  createStaffsApi,
  fetchCorporateSubscriptionGroupsApi,
  fetchCorporatesApi,
} from "../../../api/corporate";
import DashboardWrapper from "../../../components/DashboardWrapper";
import { fetchIssues } from "../../../redux-store/Actions/issue-action";
import Checkbox from "./customCheckbox";
import { fetchIssuesApi } from "../../../api/publisher";
import { toast } from "react-toastify";
import { useLocation} from "react-router-dom";

function SubscriptionGroupEdit(props) {

  const location = useLocation();
  const values = location.state.data;
  const [thedata, setData] = useState(values);
  console.log("props values",values);

  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [issues, setIssues] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [id, setSGid] = useState(thedata.id);
  const [papers, setPapers] = useState([thedata.papers]);
  const [name, setName] = useState(thedata.name );
  const [companyId, setCompanyId] = useState(thedata.companyId );
  const [loading, setLoading] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {};
    data.companyId = companyId;
    data.papers = papers;
    data.id = id;
    data.name = name;
    data.status = "ACTIVE";
    console.log("checkingsubmit: ", data);
    if (companyId && name.length > 3 && papers.length > 0) {
      let data = {};
      data.companyId = companyId;
      data.papers = papers;
      data.name = name;
      data.id = id;
      data.status = "ACTIVE";
      console.log("submit: ", data);
      updateCorporateSubscriptionGroupsApi(data)
        .then((res) => {
          setLoading(false);
          console.log(res.data);

          var path = "/corporates/subscription-groups";
          navigate(path);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      toast.error("Please fill in required information", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const navigate = useNavigate();

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    fetchIssuesApi().then((result) => {
      console.log("iss: ", result.data.data);
      let myPapers = [];
      if (thedata) {
        myPapers = thedata.papers;
      }
      let filteredCategory = result.data.data.filter(
        (Onlynewspapers) => Onlynewspapers.categoryId === 1,
      );
      if (myPapers.length > 0) {
        let joinedPapers = filteredCategory.map((issue) => {
          let mypaper = myPapers.find((paper) => paper.id === issue.id);
          let item = issue;
          item.isSelected = mypaper ? true : false;
          return item;
        });
  
        setIsCheck(joinedPapers.map((paper) => paper.isSelected));
      } else {
        setIsCheck(new Array(filteredCategory.length).fill(false));
      }
      setIssues(filteredCategory);
      // setIssues(result.data.data);
      // setIsCheck(new Array(result.data.data.length).fill(false));
    });
    fetchCorporatesApi().then((result) => {
      setCompanies(result.data.data);
    });
  }, []);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    let vals = issues.map((li) => {
      return { id: li.id, title: li.title };
    });

    // let x = vals;
    setIsCheck(new Array(issues.length).fill(true));
    if (!e.target.checked) {
      setIsCheck(new Array(issues.length).fill(false));
      vals = [];
    }
    setPapers(vals);
    setSubmitEnabled(false);
    
  };
  const handleCheckChange = (index) => {
    let mychecks = isCheck.map((c, i) => (i === index ? !c : c));

    setIsCheck(mychecks);
    let x = mychecks
      .map((y, z) => (y ? z : -1))
      .filter((y) => y >= 0)
      .map((p) => {
        let m = issues.find((l, i) => i === p);
        return { id: m.id, title: m.title };
      });
      if (
        x &&
        x.length > 0 &&
        x !== issues.papers
      ) {
        setPapers(x);
        setSubmitEnabled(false);

      }
  };

  const handleNameChange = (e) => {
    e.preventDefault();
    let groupname= e.target.value.trim();
    if (groupname && groupname.length > 0 && groupname !== thedata.name) {
      let data = groupname;
      setName(data);
      setSubmitEnabled(false);
    } else setSubmitEnabled(true);
  };
  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/corporates" onClick={toPage("/corporates")}>
                      Corporates
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a
                      href="/dashboard"
                      onClick={toPage("/corporates/subscription-groups")}
                    >
                      Subscription Groups
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                      Edit Subscription Group
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card pt-2 mt-2">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">
                            Edit Subscription Group 
                        </h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-12">
                          <form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group"> 
                                  <label htmlFor="name" className="form-label">
                                    Group Name
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    id="name"
                                    defaultValue={thedata.name}
                                    onChange={(e) => handleNameChange(e)}
                                    placeholder="Enter group name"
                                    autoFocus
                                    required
                                    name="name"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="name" className="form-label">
                                    Company
                                  </label>
                                  <input type={"text"}
                                      className="form-control"
                                      id="companyId"
                                      name="companyId"
                                      disabled={true}
                                      defaultValue={thedata.company ? thedata.company.companyName : thedata.companyId}
                                   />
                                  {/* <select
                                    defaultValue={thedata.company ? thedata.company.companyName : thedata.companyId}
                                    onChange={(e) => {
                                      setCompanyId(e.target.value);
                                    }}
                                    className="form-control"
                                    id="companyId"
                                    name="companyId"
                                  >
                                    {companies.map((company) => {
                                      return (
                                        <option
                                          key={company.id}
                                          value={company.id}
                                        >
                                          {company.companyName}
                                        </option>
                                      );
                                    })}
                                  </select> */}
                                </div>
                              </div>
                              <div className="col-md-12 row">
                                <div className="col-md-12">
                                  <Checkbox
                                    name="Select all"
                                    id="all"
                                    onClick={handleSelectAll}
                                    checked={isCheckAll}
                                  />
                                </div>
                                {issues.map((issue, index) => {
                                  return (
                                    <div key={issue.id} className="col-md-3">
                                      <Checkbox
                                        id={issue.id}
                                        name={issue.title}
                                        onClick={() => handleCheckChange(index)}
                                        checked={isCheck[index]}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    disabled={submitEnabled}
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default SubscriptionGroupEdit;

