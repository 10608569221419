import React from "react"
import Loader from "react-loader-spinner"
export default function  PageLoader(){
    return (
        <Loader type="TailSpin" color="#34CCA1" height={40} style={{
            margin: "0 auto",
            height: "100%",
            // backgroundColor: "#807c7c36",
            width: "100%"
        }}  />
    )

}