import React from "react";
// import { identifier } from "@babel/types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteStory from "./DeleteStory";
import UpdateStory from "./UpdateStory";
import { DateTime } from "luxon";

export const STORIESCOLUMNS = [
  {
    header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {" "}
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    header: "Episode Title",
    accessor: "title",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    header: "Publisher",
    accessor: "channel.channelName",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    header: "Date published",
    accessor: "releaseDate",
    Cell: (props) => (
      <div className="capitalize">
        {formatDate(props.value) ?? "01-Jan-2023"}
      </div>
    ),
  },
  {
    header: "Impressions",
    accessor: "impressionCount",
    Cell: (props) => <div className="text-capitalize">{props.value ?? 0}</div>,
  },
  {
    header: "Plays",
    accessor: "playCount",
    Cell: (props) => <div className="text-capitalize">{props.value ?? 0}</div>,
  },
  {
    header: "Revenue (TZS)",
    accessor: "revenue",
    Cell: (props) => (
      <div className="text-capitalize">
        {props.value > 0
          ? thousandSeparator(parseFloat(props.value).toFixed(0))
          : 0}
      </div>
    ),
  },
  {
    header: "status",
    accessor: "status",
    Cell: (props) => <div>{checkStatus(props.value)}</div>,
  },
  {
    header: "action",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic"
          >
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            <Link
              to={`/podcasters/stories/show/${props.row.original.id}`}
              className="dropdown-item"
            >
              View
            </Link>
            {/* 
          <Link to={`/podcasters/stories/edit/${props.row.original.id}`} className="dropdown-item">
            Edit
          </Link> */}

            <Link
              to={`/reports/performance_per_episode/${props.row.original.id}`}
              state={props.row.original}
              className="dropdown-item"
            >
              Report
            </Link>

            <div
              className={
                props.row.original.status === "PUBLISHED"
                  ? "dropdown-item disabled hidden"
                  : "dropdown-item"
              }
            >
              <UpdateStory name={props.row.original}></UpdateStory>
            </div>

            <div
              className={
                props.row.original.status !== "PUBLISHED"
                  ? "dropdown-item disabled hidden"
                  : "dropdown-item"
              }
            >
              <UpdateStory name={props.row.original}></UpdateStory>
            </div>

            <div className="dropdown-item text-danger">
              <DeleteStory name={props.row.original}></DeleteStory>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

function formatDate(inputDate) {
  const dateObj = DateTime.fromISO(inputDate);
  return dateObj.toFormat("dd-LLL-yyyy");
}

function checkStatus(status) {
  if (status && typeof status == "string") {
    switch (status.toUpperCase()) {
      case "PUBLISHED":
        return (
          <div className="status">
            <div className="dot"></div>
            Published
          </div>
        );

      case "DRAFT":
        return (
          <div className="status">
            <div className="dot inactive"></div>
            Draft
          </div>
        );

      case "UNPUBLISHED":
        return (
          <div className="status">
            <div className="dot inactive"></div>
            Unpublished
          </div>
        );

      case "DELETED":
        return (
          <div className="status">
            <div className="dot deleted"></div>
            Deleted
          </div>
        );

      default:
        return (
          <div className="status">
            <div className="dot inactive"></div>
            Inactive
          </div>
        );
    }
  } else
    return (
      <div className="status">
        <div className="dot inactive"></div>
        Unknown
      </div>
    );
}

const thousandSeparator = (x) => {
  return typeof x == "string" || !isNaN(x)
    ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : x;
};
