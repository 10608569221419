import React from "react";
// import { identifier } from "@babel/types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteSubscriptionGroup from "./DeleteSubscriptionGroup";
import UpdateSubscriptionGroup from "./UpdateSubscriptionGroup";

export const SUBSCRIPTIONCOLUMNS = [
  {
    header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {" "}
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    header: "Name",
    accessor: "name",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },

  {
    header: "Company",
    accessor: "company.companyName",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },
  {
    header: "Number of Papers",
    accessor: "papers",
    Cell: (props) => <div>{props.value.length}</div>,
  },

  {
    header: "status",
    accessor: "status",
    Cell: (props) => <div>{checkStatus(props.value)}</div>,
  },
  {
    header: "action",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic"
          >
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            <Link
              to={{ pathname: `/corporates/subscription-groups/show`}} state={{ data:props.row.original }}
              className="dropdown-item"
            >
              View
            </Link>

            <Link
              // to={`/corporates/subscription-groups/edit/${props.row.original.id}`}
              to={{ pathname: `/corporates/subscription-groups/edit`}} state={{ data:props.row.original }}
              className="dropdown-item"
            >
              Edit
            </Link>
            <div
              className={
                props.row.original.status === "ACTIVE"
                  ? "dropdown-item disabled hidden"
                  : "dropdown-item"
              }
            >
              <UpdateSubscriptionGroup data={props.row.original}></UpdateSubscriptionGroup>
            </div>

            <div
              className={
                props.row.original.status !== "ACTIVE"
                  ? "dropdown-item disabled hidden"
                  : "dropdown-item"
              }
            >
              <UpdateSubscriptionGroup data={props.row.original}></UpdateSubscriptionGroup>
            </div>

            <div className="dropdown-item text-danger">
              <DeleteSubscriptionGroup data={props.row.original}></DeleteSubscriptionGroup>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

function checkStatus(status) {
  switch (status) {
    case "ACTIVE":
      return (
        <div className="status">
          <div className="dot"></div>
          Active
        </div>
      );

    default:
      return (
        <div className="status">
          <div className={
                status == "DELETED"? "dot deleted": "dot inactive"}></div>
          {status}
        </div>
      );
  }
}

function checkCategory(categoryId) {
  switch (categoryId) {
    case 1:
      return <div className="text-capitalize">NewsPaper</div>;

    case 2:
      return <div className="text-capitalize">Magazine</div>;

    default:
      return <div className="text-capitalize">Book</div>;
  }
}

