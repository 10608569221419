import React, { useState } from 'react';
import { useAsyncDebounce } from "react-table";
import '../styles.css';

function ArchiveFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {

  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);


  return (
      <div className='row mr-0'>
        
  

        <div className="col-md-4">
          <div className='input-group input-group-merge'>
          
            <div className="input-group-text input-group-prepend">
              <i className='fe fe-search'></i>
            </div>
           
            <input  type="text" 
                    value={value || ""}
                    onChange={(e) => {
                      setValue(e.target.value);
                      onChange(e.target.value);
                    }}
                    className='form-control' placeholder='Search Archives' />
          
            <div className="input-group-text input-group-append">
              <span className='search-place'>All</span><i className='fe fe-chevron-down'></i>
            </div>
          
          </div>
        </div>




        <div className="col-md-4">
          <select name="uses" className='form-select'>
            <option value="1">Last 28 Days</option>
            <option value="1">Last 14 Days</option>
            <option value="1">Last 7 Days</option>
            <option value="1">Today</option>
          </select>
        </div>

      </div>
  );
}

export default ArchiveFilter;
