import axios from "axios";
import baseUrl from "./api_config";

const url = baseUrl.AUTH_URL;

const api = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
});

export const loginApi = (data) => {
  return api({
    url: `/admin/login`,
    data: data,
    method: "POST",
  });
};

export const createAdminUserApi = (data) => {
  return api({
    url: `admin/create`,
    data: data,
    method: "POST",
  });
};

export const createPublisherUserApi = (data, token) => {
  return api({
    url: `/publisher/register?who=1`,
    data: data,
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
  });
};
