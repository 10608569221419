import { fetchReadersApi, fetchReaderDetailsApi } from "../../api/user";

// Action Types
export const FETCH_READERS_LOADING = "FETCH_READERS_LOADING";
export const FETCH_READERS_SUCCESS = "FETCH_READERS_SUCCESS";
export const FETCH_READERS_FAILURE = "FETCH_READERS_FAILURE";

export const FETCH_READER_DETAILS_LOADING = "FETCH_READER_DETAILS_LOADING";
export const FETCH_READER_DETAILS_SUCCESS = "FETCH_READER_DETAILS_SUCCESS";
export const FETCH_READER_DETAILS_FAILURE = "FETCH_READER_DETAILS_FAILURE";

// Thunks
export const fetchReaders = (page) => {
  return async (dispatch) => {
    dispatch(fetchReadersLoading());
    try {
      const res = await fetchReadersApi(page);
      const data = res.data.data || [];
      dispatch(fetchReadersSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(fetchReadersFailure(error));
    }
  };
};

export const fetchReaderDetails = (id) => {
  return async (dispatch) => {
    dispatch(fetchReaderDetailsLoading());
    try {
      const res = await fetchReaderDetailsApi(id);
      const data = res.data.data || [];
      dispatch(fetchReaderDetailsSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(fetchReaderDetailsFailure(error));
    }
  };
};

// Actions
const fetchReadersLoading = () => ({
  type: FETCH_READERS_LOADING,
});

const fetchReadersSuccess = (data) => ({
  type: FETCH_READERS_SUCCESS,
  payload: data,
});

const fetchReadersFailure = (error) => ({
  type: FETCH_READERS_FAILURE,
  payload: error,
});

const fetchReaderDetailsLoading = () => ({
  type: FETCH_READER_DETAILS_LOADING,
});

const fetchReaderDetailsSuccess = (data) => ({
  type: FETCH_READER_DETAILS_SUCCESS,
  payload: data,
});

const fetchReaderDetailsFailure = (error) => ({
  type: FETCH_READER_DETAILS_FAILURE,
  payload: error,
});
