import React, { Component} from 'react';
import HomeWrapper from '../../components/HomeWrapper';


class Validation extends Component {
  render() {
    return (
      <HomeWrapper>
        <div className="d-flex justify-content-start align-items-center h-full ps-5">
          <div className="page-wrapper ps-5">
            <div className="page-title">
                <p>Enter Password <br />to login</p>
            </div>  
            <div className="page-form">
              <form action="">
                <div className="form-title">
                  <p>Confirm the password you entered above</p>
                </div>

                <div className="form-group">
                  <input type="password" required autoFocus className="form-control" />
                </div>


                <div className="form-group mt-4 w-full">
                  <button className="btn btn-primary btn-app"> Get Started</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </HomeWrapper>
    )
  }
}


export default Validation;