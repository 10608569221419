import React from "react";
import { NavLink } from "react-router-dom";
import "./style.css";

function ProfileNav() {
  return (
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <NavLink to="/settings/account" className="nav-link">
          Profile
        </NavLink>
      </li>

      {/* <li className="nav-item">
        <NavLink to="/settings/notifications" className="nav-link">Notification</NavLink>
      </li> */}
    </ul>
  );
}

export default ProfileNav;
