import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import Loader from "react-loader-spinner";
import { fetchCompany } from "../../../../redux-store/Actions/company-action";
import { useDispatch, useSelector } from "react-redux";
import { createStaffsApi } from "../../../../api/publisher";

const schema = yup
  .object({
    firstName: yup.string().required().min(3).max(255).label("First Name"),
    lastName: yup.string().required().min(3).max(255).label("Last Name"),
    email: yup.string().required().min(3).max(255).label("Email Address"),
    phoneNumber: yup.string().required().min(3).max(255).label("Phone Number"),
  })
  .required();

function CreateStaff() {
  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state.companies);

  const data = companies;
  console.log("c: ", data);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    console.log(data);

    createStaffsApi(data)
      .then((res) => {
        setLoading(false);
        console.log(res.data);

        var path = "/publishers/staff/";
        navigate(path);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const navigate = useNavigate();

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    dispatch(fetchCompany());
  }, [dispatch]);

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/publishers")}>
                      Publishers
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/publishers/staff")}>
                      Staff
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create Staff
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card pt-2 mt-2">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Create Staff</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-12">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="firstName" className="form-label">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.firstName
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="first_name"
                                    placeholder="Enter First name"
                                    autoFocus
                                    required
                                    {...register("firstName")}
                                  />

                                  <p className="errors">
                                    {errors.firstName?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="firstName" className="form-label">
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.lastName
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="last_name"
                                    placeholder="Enter Last name"
                                    required
                                    {...register("lastName")}
                                  />

                                  <p className="errors">
                                    {errors.lastName?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="company" className="form-label">
                                    Select Company
                                  </label>
                                  <select
                                    className="form-control"
                                    {...register("company")}
                                  >
                                    <option>Select Company</option>
                                    {data.map((company) => (
                                      <option
                                        key={company.id}
                                        value={company.id}
                                      >
                                        {company.companyName}{" "}
                                      </option>
                                    ))}
                                  </select>

                                  <p className="errors">
                                    {errors.company?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="firstName" className="form-label">
                                    Email Address
                                  </label>
                                  <input
                                    type="email"
                                    className={
                                      errors.email
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="email"
                                    placeholder="Enter Email Address"
                                    required
                                    {...register("email")}
                                  />

                                  <p className="errors">
                                    {errors.email?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="firstName" className="form-label">
                                    Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.phoneNumber
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="phone_number"
                                    placeholder="Enter Phone Number"
                                    required
                                    {...register("phoneNumber")}
                                  />

                                  <p className="errors">
                                    {errors.phoneNumber?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label for="role" className="form-label">
                                    Role
                                  </label>
                                  <select
                                    className="form-control"
                                    {...register("role")}
                                  >
                                    <option>Select Role</option>
                                    <option value="1">Uploader</option>
                                    <option value="2">Finance</option>
                                    <option value="3">Adminstraror</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CreateStaff;
