import React from 'react'
// import { identifier } from "@babel/types";
import { Dropdown} from "react-bootstrap";
import StaffEdit from "./StaffEdit";


export const STAFFCOLUMNS = [
  {
    header: 'id',
    accessor: 'id',
    Cell: props => <div>{ props.row.index + 1}</div>
  }, 
  {
    header: 'first name',
    accessor: 'first_name',
    Cell: props => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'last name',
    accessor: 'last_name',
    Cell: props => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'email',
    accessor: 'email_address',
  },
  {
    header: 'mobile',
    accessor: 'phone_number'
  },
  {
    header: 'role',
    accessor: 'role',
    Cell: props => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'status',
    accessor: 'status',
    Cell: props => <div>{ checkStatus(props.value) }</div>
  },
  {
    header: 'action',
    accessor: 'action',
    Cell: props => <div>
      <Dropdown>
       
        <Dropdown.Toggle as="a" className="dropdown-ellipses icon-dropdown" id="dropdown-basic">
        <i className="fe fe-more-horizontal"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-end">
          <Dropdown.Item href="#/action-1">
            <StaffEdit placement="end" name={props.row.original.user_id} />
          </Dropdown.Item>
          <Dropdown.Item href="#/action-2">Activate</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Deactivate</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  }
 
]




function checkStatus(status) {
  switch (status) {
    case "ACTIVE":
      return <div className="status">
              <div className="dot"></div>
                Active
            </div>  
  
    default:
      return <div className="status">
              <div className="dot inactive"></div>
                Inactive
            </div> 
  }

}


