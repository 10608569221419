import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardWrapper from "../../../components/DashboardWrapper";
import DashboardNav from "./DashboardNav";
import UserSummary from "./UserSummary";

import {fetchSummary} from "../../../redux-store/Actions/user-action";

function Home() {
  const dispatch = useDispatch();

  let countries = [];

  const { summary, isLoading } = useSelector((state) => state.summary);
  let now = Date.now();
  let data = {
    startDate: new Date(),
    endDate: new Date(now - 30 * 24 * 60 * 60 * 1000),
    platform: "",
  };

  useEffect(() => {

    dispatch(fetchSummary(data));
  }, [dispatch]);

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Dashboard</h1>
              </div>
            </div>

            <div className="content">

              <DashboardNav></DashboardNav>

              <UserSummary summary={summary}></UserSummary>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default Home;
