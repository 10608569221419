import React,{useState} from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
const CustomDatePicker = (props) => {
  return (
    <div className="modal show" style={{zIndex:"9999999999999999999"}}>
      <Modal {...props} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Pick Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateRangePicker
            ranges={props.range}
            onChange={props.onChange}
            moveRangeOnFirstSelection={true}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default CustomDatePicker;