import React from "react";
import { NavLink } from "react-router-dom";

export default function CorporateNavs() {
  return (
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <NavLink to="/corporates/companies" className="nav-link">
          Companies
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink to="/corporates/subscription-groups" className="nav-link">
          Subscription Groups
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink to="/corporates/staffs" className="nav-link">
          Staffs
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/corporates/reports" className="nav-link">
          Usage Reports
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/corporates/summary_reports" className="nav-link">
          Summary Reports
        </NavLink>
      </li>
    </ul>
  );
}
