import React from 'react'
import { useNavigate } from "react-router-dom";

function NotFound() {

  const navigate = useNavigate();
  
  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }


  return (
    <div className="h-full d-flex align-items-center bg-auth">
    <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 col-md-5 col-xl-4 my-5">
        
        <div className="text-center">
        
       
          <h6 className="text-uppercase text-muted mb-4">
            404 error
          </h6>

         
          <h1 className="display-4 mb-3">
            There’s no page here <span role="img" aria-label="crying ">😭</span>
          </h1>

        
          <p className="text-muted mb-4">
            Looks like you ended up here by accident? 
          </p>

         
          <a href="/dashboard" onClick={toPage("/dashboard")} className="btn btn-lg btn-primary">
            Return to your dashboard
          </a>
        
        </div>

      </div>
    </div>
  </div>
  </div>
  )
}

export default NotFound
