import React, { useMemo, useEffect } from 'react';
import { fetchRatedReport } from '../../../../redux-store/Actions/ratedreport-action';
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { CORPORATEPRICINGCOLUMNS } from "./CorporatePricingColumns";
import { Link } from 'react-router-dom';
import EmptyData from '../../../../components/EmptyData/Index';
import SettingsFilter from '../SettingsFilter';
import CreateCorporatePrice from './createCorporatePrice';

function CorporatePricingTable() { 

  const columns = useMemo(() => CORPORATEPRICINGCOLUMNS, [])
  const dispatch = useDispatch();

  const { ratedreports } = useSelector(
    (state) => state.ratedreport
  );  

  const data = ratedreports

  const tableInstance = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  },useGlobalFilter,  useSortBy, usePagination);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,


    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter },

  } = tableInstance

  useEffect(() => {
    dispatch(fetchRatedReport());
  }, [dispatch]);

  return (
    <div>
      <div className="row align-items-end my-4">
        <CreateCorporatePrice />
      </div> 


      <div className="row mt-2 body-content">

      { ratedreports && ratedreports.length >= 1 ? (
        <div className="col-md-12">
          {/* the table here */}

          <div className="card">

            <table {...getTableProps()} className="table table-sm card-table">
                <thead>
                        {
                          headerGroups.map((headerGroups) => (
                            <tr {...headerGroups.getHeaderGroupProps()}>
                                {
                                  headerGroups.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}> 
                                      {column.render('header')}
                                    
                                    </th>
                                  ))
                                }
                                
                              </tr>
                          ))
                        }

                  </thead>

                  <tbody {...getTableBodyProps()}>
                    { page.map((row) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          { row.cells.map((cell) => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          })}
                        </tr>
                      )}
                    )}
                  </tbody>

            </table>


          </div>

          <div className='row mb-5'>
            <div className="col"></div>
            <div className="col-auto">
              <div>
                <span className="table-stat">
                  Showing  { pageIndex === 0 ? 1 : (pageIndex * pageSize) + 1 } to { pageIndex > 10 ?  ratedreports.length  : ((pageIndex * pageSize) + pageSize) > ratedreports.length ? ratedreports.length : (pageIndex * pageSize) + pageSize } of { ratedreports.length } {' '} elements 
                </span>
                <button className="btn btn-round btn-sm btn-pagination"  onClick={() => previousPage()}>
                  <i className="fe fe-chevron-left"></i>
                </button>
                <span className="table-stat">
                  Page {pageIndex + 1}
                </span>
                <button className="btn btn-round btn-sm btn-pagination" onClick={() => nextPage()}> 
                        <i className="fe fe-chevron-right"></i>
                </button>
              </div>                     
            </div>
          </div>


        </div>
      ) : (
       <EmptyData name="Genres" path="/settings/genres/create" />
      )}
      </div> 


    </div>
  )
}

export default CorporatePricingTable
