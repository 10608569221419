import React from 'react'
import "./styles.css";

import  { Tabs, Tab } from 'react-bootstrap';
import TransactionArchiveTable from './archives/TransactionArchiveTable';
import SubscriptionArchiveTable from './archives/SubscriptionArchiveTable';
import DownloadArchiveTable from './archives/DownloadArchiveTable';

function UserDetailNavs(props) {

  return (
      <Tabs
        defaultActiveKey="transaction"
        transition={false}
        id="userDetailNavs"
        className="mb-3"
      >
        <Tab eventKey="transaction" title="Transaction Archive">
          <TransactionArchiveTable />
        </Tab>
        <Tab eventKey="download" title="Download Archive">
          <DownloadArchiveTable />
        </Tab>
        <Tab eventKey="subscription" title="Subscription Archive">
          <SubscriptionArchiveTable />
        </Tab>
      </Tabs>
  )
}

export default UserDetailNavs
