import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function PromotionNavs() {

  return (
    <>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink to="/campaigns" className="nav-link">
            Campaigns
          </NavLink>
        </li>

        <li className="nav-item">
          <NavLink to="/promocodes" className="nav-link">
            Promotion codes
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/promotions" className="nav-link">
            Promotions
          </NavLink>
        </li>
      </ul>
    </>
  );
}
