import { 
        FETCH_ISSUE_DETAILS_LOADING,
         FETCH_ISSUE_DETAILS_SUCCESS
        } from "../Actions/issue-action";


const initialState = {
  issue: {},
  isLoading: false,
};


export default function issueDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ISSUE_DETAILS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_ISSUE_DETAILS_SUCCESS: {
      console.log("we are here : issues")
      return {
        ...state,
        issue: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

