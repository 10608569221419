import React from 'react'
import { NavLink } from 'react-router-dom'

function ReportsNav() {
  return (
      <ul className="nav nav-tabs">
          <li className="nav-item">
              <NavLink to="/reports/users" className="nav-link">Users</NavLink>
          </li>
          <li className="nav-item">
              <NavLink to="/reports/revenue" className="nav-link">Revenue</NavLink>
          </li>
          
          <li className="nav-item">
            <NavLink to="/reports/activity" className="nav-link">Activity</NavLink>
          </li>
          
          <li className="nav-item">
            <NavLink to="/reports/content" className="nav-link">Content</NavLink>
          </li>
      </ul>
  )
}

export default ReportsNav