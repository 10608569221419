import axios from "axios";
import url from "./api_config";
const baseURL = url.USERS_URL;
const corporateURL = url.CORPORATE_URL;
const corporateAPI = axios.create({baseURL: corporateURL, headers: {'Content-type': 'application/json'}})
const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchReadersApi = (currentPage) => {
  return api({
    url: `readers?page=${currentPage}`,
    method: "GET",
  });
};

export const fetchReaderDetailsApi = (id) => {
  return api({
    url: `readers/${id}`,
    method: "GET",
  });
};

// archives
export const fetchReaderTransactionArchivesApi = (id) => {
  return api({
    url: `readers/archive/${id}/transaction-archive`,
    method: "GET",
  });
};

export const fetchReaderDownloadArchivesApi = (id) => {
  return api({
    url: `readers/archive/${id}/download-archive`,
    method: "GET",
  });
};

export const fetchReaderSubscriptionArchivesApi = (id) => {
  return api({
    url: `readers/archive/${id}/subscription-archive`,
    method: "GET",
  });
};

export const fetchTelcosApi = () => {
  return api({
    url: `telcos`,
    method: "GET",
  });
};

export const fetchTelcoDetailsApi = (id) => {
  return api({
    url: `telcos/${id}`,
    method: "GET",
  });
};

/*
  Admin apis
*/

export const fetchAdminsApi = () => {
  return api({
    url: `admins`,
    method: "GET",
  });
};

export const fetchAdminDetailsApi = (id) => {
  return api({
    url: `admins/${id}`,
    method: "GET",
  });
};

export const updateAdminStatusApi = (data) => {
  return api({url: `admins/status`, data: data, method: "POST"});
};

export const updateAdminApi = (data) => {
  return api({
    url: `admins`,
    data: data,
    method: "PUT",
  });
};

export const updateUserStatusApi = (data) => {
  return api({
    url: `/update-status`,
    data: data,
    method: "PATCH",
  });
};

export const deleteAdminApi = (id) => {
  return api({
    url: `admins`,
    data: id,
    method: "DELETE",
  });
};

// user Summary
export const userSummaryApi = (data) => {
  return api({
    url: `/user-summary`,
    data: data,
    method: "POST",
  });
};

export const createUserApi = (data) => {
  return api({
    url: `/`,
    data: data,
    method: "POST",
  });
};

export const deleteUserApi = (id) => {
  return api({
    url: `/`,
    data: id,
    method: "DELETE",
  });
};

export const updateReaderApi = (data) => {
  return api({
    url: `/readers/update`,
    data: data,
    method: "PATCH",
  });
};
export const updateCorporateReaderApi = (data) => {
  return corporateAPI({
    url: `/staffs/${data.userId}`,
    data: data,
    method: "POST",
  });
};
export const updateReadingStatusApi = (data) => {
  return api({url: `/enable-corporate-admin`, data: {email:data.email,staffId:data.id}, method: 'POST'})
}
//publisher admins
export const fetchPublisherAdminsApi = () => {
  return api({
    url: `publishers`,
    method: "GET",
  });
};
export const fetchPublisherAdminDetailsApi = (id) => {
  return api({
    url: `publishers/${id}`,
    method: "GET",
  });
};

export const updatePublisherAdminApi = (id, data, token) => {
  return api({
    url: `publishers/${id}`,
    method: "PUT",
    data: data, headers: {Authorization: `Bearer ${token}`},
  });
};
