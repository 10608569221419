import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import HomeWrapper from '../../components/HomeWrapper';
import Loader from 'react-loader-spinner';
import {loginApi} from '../../api/auth';
import {toast, Slide} from 'react-toastify';
import {fetchExchangeRatesApi} from '../../api/reports';
import api_url from '../../api/api_config'

const billingURL = api_url.BILLING_URL;
const schema = yup.object({
  email: yup.string().email('Please Enter a valid Email Address').required().max(255).label("Email Address or Phone"),
  password: yup.string().required()
}).required()


export default function Login() {

  const navigate = useNavigate();

  const clearWaitingQueue = () => {
    // Easy, right 😎
    toast.clearWaitingQueue();
  }



  const {register,
    handleSubmit,
    setError,
    formState: {errors}
  } = useForm({
    resolver: yupResolver(schema)
  });

  const [ loading, setLoading ] = useState(false);

  const onSubmit = data => {
    setLoading(true)
    // post data to API
    loginApi(data).then((response) => {

      localStorage.setItem("RIFALYUSER", JSON.stringify(response.data.data));
      fetch(`${billingURL}billing/countries/supported`).then(res => res.json()).then(result => {
        localStorage.setItem("RIFALYCOUNTRIES", JSON.stringify(result.data))
      })
      fetchExchangeRatesApi("TZS").then(result => {
        localStorage.setItem("RIFALYCURRENCYRATES", JSON.stringify(result.data));
      }).catch(e => {
          console.log(e);
        })
        .finally(() => {
          navigate('/dashboard');
        })
   

    }).catch(error => {
      console.log(error)
      setLoading(false)

      if (error.response) {
        if (parseInt(error.response.status) === 401) {

          setError("email", {
            type: "manual",
            message: "Invalid login ID and password Combination!",
          });

          console.log("User not found")
          toast.error(<div className='small'> Unauthorised ! <br /> Invalid Credentials. </div>, {
            position: "top-right",
            autoClose: 5000,
            transition: Slide,
            theme: "colored",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            draggable: true
          });
          clearWaitingQueue()
        }

        if (error.response.status === 422) {

          setError("login_id", {
            type: "manual",
            message: "Invalid Email Address",
          });

          console.log(error.response)
        }



        if (error.response.status === 500) {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }

        
      } else {
        console.log(error.message);
      }


    });
    // get response
    // if successfull store token in localstorage
    // redirect user to login

  };


    return (
      <HomeWrapper>
       <h3 className="text-muted mb-3">
            Hello Admin!
        </h3>
        <p className="text- mb-5">
        Please login to your dashboard to continue
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input type="email" 
            className={errors.email ? "form-control is-invalid" : "form-control"}
                   autoFocus 
                   placeholder="Enter your email"
                   {...register("email")} />

            <p className="errors">{errors.email?.message}</p>

          </div>

          <div className="form-group">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input type="password" 
                   className="form-control" 
                   placeholder="Enter your Password"
            {...register('password')} />
          </div>

          <div className="form-group row">
            <div className="col-md-12">
            {loading ? (
                <span className="btn btn-primary">
                  <Loader
                    type="TailSpin" 
                    color="#ffffff"
                    height={20} 
                    width={20} />
                </span>
              ) : (
                <button className="btn btn-primary w-100" type="submit">Log In</button>
              )}
            </div>


          </div>

          
        </form>
      </HomeWrapper>
    )

}

