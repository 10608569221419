import React, { useMemo, useEffect, useState } from "react";
import moment from "moment";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import { AnimatePresence, motion } from "framer-motion";

import { SUMMARYREPORTCOLUMNS } from "./SummaryReportColumns";

import EmptyData from "../../../components/EmptyData/Index";
import CorporateFilter from "./CorporateFilter";
import {
  fetchSummaryReports,
  fetchSummaryReportsByDate,
} from "../../../api/reports";
import {fetchCorporatesApi} from "../../../api/corporate";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import CustomDatePicker from "./CustomDatePicker";
import Loader from 'react-loader-spinner';


function SummaryReportTable() {
  const columns = useMemo(() => SUMMARYREPORTCOLUMNS, []);

  const [month,setMonth] = useState((new Date()).getMonth() + 1);
  const [ companies, setCompanies ] = useState([]);
  const [ start, setStart ] = useState((new Date()).toISOString());
  const [ end, setEnd ] = useState((new Date()).toISOString());
  const [ selectedCorporate, setSelectedCorporate ] = useState(11);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,

    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;
  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    let exportData = data.map((d) => {
      let r = JSON.parse(JSON.stringify(d));
      
      const item = {
        date:r.deliveryDate,paper:r.paperTitle,company:r.companyName,staffCount:r.staffCount
      }
      return item;
    });
    let ws = XLSX.utils.json_to_sheet(exportData);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `Rifaly Corporate Reading ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };
  const handleSelectCorporate = (e) => {
      setSelectedCorporate(e.target.value)
    }

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchDate = async () => {
      const response = await fetchCorporatesApi();
      const corporate = response.data.data[ 0 ];
      setCompanies(response.data.data)
      setSelectedCorporate(corporate.id);
      fetchSummaryReports(user.token, {startDate: start, endDate: end, id: corporate.id}).then((result) => {
        let data = result.data.data;
        setData(data);
      });
     }
    fetchDate()

  }, []);

  const spring = useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
      rotate: 360,
    }),
    [],
  );
   
  const handleSearch = () => {
    setLoading(true);
      let startDate = moment(start).format("YYYY-MM-DD");
      let endDate = moment(end).format("YYYY-MM-DD");
      let options = {startDate: startDate, endDate: endDate, id: selectedCorporate};
    fetchSummaryReports(user.token, options)
        .then((result) => {
          console.log(
            "🚀 ~ file: SummaryReportTable.js:119 ~ .then ~ result",
            result,
          );

          let data = result.data.data;
          setData(data);
          setLoading(false);
          })
        .catch((e) => {
          console.log("errror: ", e);
          setLoading(false);
        });
    
  };
  const handleDateChange = (e) => {
    if (e.target.id === 'start') setStart((new Date(e.target.value.trim())).toISOString());
    else setEnd((new Date(e.target.value.trim())).toISOString());
  };
  return (
    <div>
      <div className='row align-items-start my-4'>
        <div className='col-md-3'>
          <select className="form-control" id='corporate' onChange={handleSelectCorporate} value={selectedCorporate}>
            <option disabled>Select Company</option>
            {companies.map(company => {
              return <option key={company.id} value={company.id}>{company.companyName}</option>
            })}
          </select>
        </div>
        <div className='col-md-3'>
          <input type="date" className="form-control" placeholder="Select Start Date" defaultValue={start} onChange={handleDateChange} id='start' />

        </div>
        <div className='col-md-3'>
          <input type="date" className="form-control" placeholder="Select End Date" defaultValue={start} onChange={handleDateChange} id='end' />

        </div>
        <div className='col-md-3'>
          <button
            className='btn btn-primary'
            onClick={handleSearch}>
            <span className='fe fe-search'></span>&nbsp;Search
          </button>
        </div>

      </div>

      <div className='row mt-2 body-content'>
      {loading ?  (
          <div className="col-md-12 text-center">
            <Loader type="TailSpin" color="#00B17A" height={50} width={50} />
            <p>Loading data...</p>
          </div>
        ) 
      : (
        data && data.length >= 1 ? (
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h4 className='card-header-title'>
                      Corporate Summary Report
                    </h4>

                    <div>
                      <button
                        className='btn btn-outline-primary btn-sm'
                        onClick={handleExport}>
                        Export
                      </button>
                    </div>
                  </div>

                  <table
                    {...getTableProps()}
                    className='table table-sm card-table'>
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                              )}>
                              {column.render("header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      <AnimatePresence>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps({
                                layouttransition: spring,
                                exit: { opacity: 0, maxHeight: 0 },
                              })}>
                              {row.cells.map((cell) => {
                                return (
                                  <motion.td
                                    {...cell.getCellProps({
                                      layouttransition: spring,
                                      exit: { opacity: 0.5, maxHeight: 0 },
                                    })}>
                                    {cell.render("Cell")}
                                  </motion.td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </AnimatePresence>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='row mb-5'>
              <div className='col'></div>
              <div className='col-auto'>
                <div>
                  <span className='table-stat'>
                    Showing {pageIndex === 0 ? 1 : pageIndex * pageSize + 1} to{" "}
                    {pageIndex > 10
                      ? data.length
                      : pageIndex * pageSize + pageSize > data.length
                      ? data.length
                      : pageIndex * pageSize + pageSize}{" "}
                    of {data.length} elements
                  </span>
                  <button
                    className='btn btn-round btn-sm btn-pagination'
                    onClick={() => previousPage()}>
                    <i className='fe fe-chevron-left'></i>
                  </button>
                  <span className='table-stat'>Page {pageIndex + 1}</span>
                  <button
                    className='btn btn-round btn-sm btn-pagination'
                    onClick={() => nextPage()}>
                    <i className='fe fe-chevron-right'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData
            name='Summary Reports'
            path=''
          />
        )
      )}
      </div>
    </div>
  );
}

export default SummaryReportTable;
