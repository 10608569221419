import axios from "axios";
import url from "./api_config";

const baseURL = url.EMAIL_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const createWelcomeEmailApi = (data) => {
  return api({
    url: `welcome-email`,
    data: data,
    method: "POST",
  });
};

export const createDormantEmailApi = (data) => {
  return api({
    url: `dormant-email`,
    data: data,
    method: "POST",
  });
};

export const createFeatureEmailApi = (data) => {
  return api({
    url: `feature-email`,
    data: data,
    method: "POST",
  });
};

export const createHighlightEmailApi = (data) => {
  return api({
    url: `highlights-email`,
    data: data,
    method: "POST",
  });
};

export const fetchEmailApi = (startDate, endDate, currentPage) => {
  return api({
    url: `reports?startDate=${startDate}&endDate=${endDate}&take=10&page=${currentPage}`,
    method: "GET",
  });
};

//report for calling link shares data.
export const fetchLinkSharesApi = (data) => {
  return api({
    url: `report-shares?startDate=${data.startDate}&endDate=${data.endDate}`,
    method:"GET",
  });
};

export const fetchLinkSharesPerPublisherApi = (data) => {
  return api({
    url: `report-shares/owner/${data.id}?startDate=${data.startDate}&endDate=${data.endDate}`,
    method:"GET",
  });
};