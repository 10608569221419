import React, { useState, useCallback } from "react";
import Dropzone from "react-dropzone";
import Loader from "react-loader-spinner";
import DashboardWrapper from "../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import { createCorporatesApi } from "../../../api/corporate";
import PhoneInput from "react-phone-number-input";
import COUNTRIES from "../../../config/COUNTRIES.json";
import { toast } from "react-toastify";

function CreateCorporate() {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState();
  const [status, setStatus] = useState("ACTIVE");
  const [showTrialSection, setShowTrialSection] = useState(false);
  const countries = COUNTRIES;
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    var inputs = Array.from(e.target.elements);
    let data = {};
    inputs.forEach((i) => {
      let key = i.id;
      let val = i.value;

      if (key && key.length > 0) data[key] = val;
    });
    data.status = status;
    data.phone = phone;
    if (status == "ACTIVE") delete data.expire;
    delete data.trial;
    console.log("submitting... ", data);
    var options = {
      headers: {
        Authorization: "Bearer " + user.token,
      },
    };
    createCorporatesApi(data, options)
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/corporates");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response) {
          toast.error(error.response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });

    // console.log(data)
  };

  const navigate = useNavigate();

  const handleStatusChange = (e) => {
    const selectedValue = e.target.value;
    
    switch (selectedValue) {
      case "ACTIVE":
        setStatus("ACTIVE");
        setShowTrialSection(false);
        break;
      case "PROMOTION":
        setStatus("PROMOTION");
        setShowTrialSection(false);
        break;
      case "TRIAL":
        setStatus("TRIAL");
        setShowTrialSection(true);
        break;
      default:
        setStatus("ACTIVE");
        setShowTrialSection(false);
        break;
    }
  };
  

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/corporates")}>
                      Corporates
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create Company
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card pt-3">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Create Company</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-12">
                          <form onSubmit={handleSubmit}>
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <div className="form-head">
                                  <p className="form-title">
                                    Basic Information
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Company Name
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    id="companyName"
                                    placeholder="Enter Company Name"
                                    autoFocus
                                    required
                                    name="companyName"
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Name of Contact Person
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    id="contactPerson"
                                    placeholder="Enter Contact Person's Name"
                                    required
                                    name="contactPerson"
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Email Address
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder="Enter Company Email Address"
                                    required
                                    id="contactEmail"
                                    name="contactEmail"
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="phone" className="form-label">
                                    Phone
                                  </label>
                                  <PhoneInput
                                    placeholder="Enter phone number"
                                    defaultCountry="TZ"
                                    value={phone}
                                    withCountryCallingCode={true}
                                    onChange={setPhone}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="country"
                                    className="form-label"
                                  >
                                    Country
                                  </label>

                                  <select
                                    className="form-select"
                                    id="country"
                                    name="country"
                                    defaultValue={"TZ"}
                                  >
                                    {countries.map((country) => (
                                      <option
                                        key={country.code}
                                        value={country.code}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="city" className="form-label">
                                    City / Town
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    name="city"
                                    required
                                    placeholder="Enter City / Town"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="address"
                                    className="form-label"
                                  >
                                    Company Address
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="companyAddress"
                                    name="companyAddress"
                                    required
                                    placeholder="Enter Company Address"
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                              <div className="form-group">
                                  <label
                                    htmlFor="status"
                                    className="form-label"
                                  >
                                    Company Status
                                  </label>
                                  <select
                                    name="status"
                                    className="form-control"
                                    id="status"
                                    value={status}
                                    onChange={handleStatusChange}
                                  >
                                    <option selected disabled>Select Status</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="PROMOTION">Promotion</option>
                                    <option value="TRIAL">Trial</option>
                                  </select>
                                </div>
                              </div>
                              {showTrialSection && (
                              <div className="col-md-4" id="expire-ct">
                                <div className="form-group">
                                  <label
                                    htmlFor="expire"
                                    className="form-label"
                                  >
                                    End of Trial
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="expire"
                                    name="expire"
                                  />
                                </div>
                              </div>
                              )}

                              {/* <div className="col-md-12">
                                <div className="form-head">
                                  <p className="form-title">
                                    Business Information
                                  </p>
                                </div>
                              </div> */}

                              {/* <div className="col-md-12">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      htmlFor="company"
                                      className="form-label"
                                    >
                                      Enter Company’s TIN Number *
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        errors.tin
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      id="tin"
                                      {...register("tin")}
                                      required
                                      placeholder="Enter your company’s TIN Number"
                                    />
                                    <p className="errors">
                                      {errors.tin?.message}
                                    </p>
                                  </div>
                                </div>
                              </div> */}
                              {/* <div className="col-md-6">
                                <Dropzone
                                  onDrop={onDropIncoporationCertificate}
                                  multiple={false}
                                  accept=".pdf"
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div className="mb-1">
                                        <label className="form-label">
                                          Upload a copy of your Business Licence
                                        </label>
                                      </div>
                                      <div
                                        {...getRootProps({
                                          className:
                                            "dropzone dropzone-single mb-3",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="upload-content p-4">
                                          <i className="fe fe-upload-cloud"></i>
                                          <p>
                                            Drag and drop or click to upload
                                          </p>
                                          <p className="btn btn-sm btn-outline-primary">
                                            Browse from your files
                                          </p>
                                        </div>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                              <div className="col-md-6">
                                <Dropzone
                                  onDrop={onDropNationalIdLetter}
                                  multiple={false}
                                  accept=".pdf"
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section>
                                      <div className="mb-1">
                                        <label className="form-label">
                                          Upload a copy of ID
                                        </label>
                                      </div>
                                      <div
                                        {...getRootProps({
                                          className:
                                            "dropzone dropzone-single mb-3",
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="upload-content p-4">
                                          <i className="fe fe-upload-cloud"></i>
                                          <p>
                                            Drag and drop or click to upload
                                          </p>
                                          <p className="btn btn-sm btn-outline-primary">
                                            Browse from your files
                                          </p>
                                        </div>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div> */}
                            </div>
                            {/* end of form row */}

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <input
                                    className="btn btn-primary"
                                    type="submit"
                                    value="Submit"
                                  />
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CreateCorporate;
