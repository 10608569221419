import React, { useState, useCallback } from "react";
import Dropzone from "react-dropzone";
import Loader from "react-loader-spinner";
import DashboardWrapper from "../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import { createCorporatesApi } from "../../../api/corporate";
import PhoneInput from "react-phone-number-input";
import COUNTRIES from "../../../config/COUNTRIES.json";
import { toast } from "react-toastify";
import { useLocation} from "react-router-dom";


function CorporateDetails(props) {

  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const location = useLocation();
  const data = location.state.data;

  const navigate = useNavigate();
  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/corporates")}>
                      Corporates
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Company Details
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card pt-3">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Company Details</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-12">
                          <form >
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <div className="form-head">
                                  <p className="form-title">
                                    Basic Information
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Company Name
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    id="companyName"
                                    placeholder="Enter Company Name"
                                    disabled
                                                                      name="companyName"
                                                                      defaultValue={data.companyName}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Name of Contact Person
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    id="contactPerson"
                                    placeholder="Enter Contact Person's Name"
                                    disabled
                                                                      name="contactPerson"
                                                                      defaultValue={data.contactPerson}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Email Address
                                  </label>
                                  <input
                                                                      type="text"
                                                                      className={"form-control"}
                                                                      placeholder="Enter Company Email Address"
                                                                      disabled
                                                                      id="contactEmail"
                                                                      name="contactEmail" defaultValue={data.email}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="phone" className="form-label">
                                    Phone
                                  </label>
                                  <PhoneInput
                                    placeholder="Enter phone number"
                                    defaultCountry="TZ"
                                    defaultValue={data.phone}
                                    withCountryCallingCode={true}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="country"
                                    className="form-label"
                                  >
                                    Country
                                  </label>

                                  <input
                                                                      className="form-select"
                                                                      id="country"
                                                                      name="country" defaultValue={data.country}
                                  />
                                   
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="city" className="form-label">
                                    City / Town
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    name="city"
                                                                      disabled defaultValue={data.city}
                                    placeholder="Enter City / Town"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="address"
                                    className="form-label"
                                  >
                                    Company Address
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="companyAddress"
                                    name="companyAddress"
                                                                      disabled
                                                                      defaultValue={data.address}
                                    placeholder="Enter Company Address"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                                             <div className="form-group">
                                                                  <label
                                                                          htmlFor="expire"
                                                                          className="form-label"
                                                                      >
                                                                          Status
                                                                      </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="trial"
                                    name="trial"
                                    defaultValue={data.status && data.status.toUpperCase()}
                                  />
                                 
                                </div>
                                                          </div>
                                                          {data.status && data.status.toLowerCase() == "trial" ?
                                                              <div className={"col-md-4 "} id="expire-ct">
                                                                  <div className="form-group">
                                                                      <label
                                                                          htmlFor="expire"
                                                                          className="form-label"
                                                                      >
                                                                          End of Trial
                                                                      </label>
                                                                      <input
                                                                          type="text"
                                                                          className="form-control"
                                                                          id="expire"
                                                                          name="expire"
                                                                          defaultValue={data.trialExpiration.split("T")[0]}
                                                                      />
                                                                  </div>
                                                              </div> : null}

                           </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CorporateDetails;
