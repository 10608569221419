import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import { createWelcomeEmailApi } from "../../../api/email";

function WelcomeEmailCreation({ emailType }) {
  const { register, handleSubmit } = useFormContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    console.log("these are the submitted data", data);
    try {
      const res = await createWelcomeEmailApi({ ...data });
      if (res.status === 201) {
        toast.success(res.data.message);
        navigate("/emails");
      } else {
        toast.error(res.data.error);
      }
    } catch (error) {
      toast.error("Failed to create email");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelForm = (e) => {
    e.preventDefault();
    navigate("/emails");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row g-3 mb-3">
        <div className="col-12 col-md-12 mb-1">
          <label htmlFor="title" className="form-label">Email Heading</label>
          <input
            type="text"
            className="form-control"
            id="title"
            placeholder="Enter Email Heading"
            {...register("title")}
            required
          />
        </div>
        <div className="col-12 col-md-12 mb-1">
          <label htmlFor="message" className="form-label">Message</label>
          <textarea
            className="form-control"
            id="message"
            placeholder="Enter Message"
            {...register("message")}
            required
          />
        </div>
        <div className="col-12 col-md-12 mb-1">
          <label htmlFor="CTA" className="form-label">CTA</label>
          <input
            type="text"
            className="form-control"
            id="CTA"
            placeholder="Enter CTA"
            {...register("CTA")}
            required
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col"></div>
        <div className="col-auto">
          <div className="d-flex justify-content-between">
            {!loading && (
              <button
                onClick={handleCancelForm}
                className="btn btn-outline-secondary mx-2"
              >
                Cancel
              </button>
            )}
            {loading ? (
              <button className="btn btn-primary">
                <Loader
                  type="TailSpin"
                  color="#ffffff"
                  height={20}
                  width={20}
                />
              </button>
            ) : (
              <button className="btn btn-primary" type="submit">
                Publish
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default WelcomeEmailCreation;
