import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import { useForm } from "react-hook-form"; 
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import { fetchStaffDetails } from '../../../../redux-store/Actions/staff-action';
import { updateStaffsApi } from '../../../../api/publisher';



const schema = yup.object({
  firstName: yup.string().required().min(3).max(255).label('First Name'),
  lastName: yup.string().required().min(3).max(255).label('Last Name'),
  email: yup.string().required().min(3).max(255).label('Email Address'),
  phoneNumber: yup.string().required().min(3).max(255).label('Phone Number')
}).required()


function StaffEdit({ name, ...props }) {


  const { staff } = useSelector(
    (state) => state.staff
  );

  // const data = staff

  const { register, 
    handleSubmit,
    formState:{ errors }
  } = useForm({
    resolver: yupResolver(schema), 
    mode: 'onBlur'
  });




  const [loading, setLoading] = useState(false);

  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  var id  = parseInt(name) ;

  console.log(id)


  useEffect(() => {
    dispatch(fetchStaffDetails(id));
  }, [ id, dispatch]);


  const navigate = useNavigate();



  const onSubmit = data => {
    setLoading(true)
      console.log(data)

      updateStaffsApi(data).then(res => {
        setLoading(false)
        console.log(res.data)

        var path = "/publishers/staff/"
        navigate(path);

      }).catch( error => {
        console.log(error)
      });
  }




  return (
    <div>
      <div className="me-2 text-success" onClick={handleShow}>
        Edit
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
        <div className="">

          <h6 className="header-pretitle">
            Edit
          </h6>


          <h1 className="header-title">
            Edit Staff { staff.last_name}
          </h1>

          </div>

          <hr />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="firstName" className="form-label">First Name</label>
                      <input type="text" 
                            className={ errors.firstName ? "form-control is-invalid" : "form-control"}  
                            id="first_name" 
                            placeholder="Enter First name"
                            autoFocus
                            defaultValue={staff.first_name}
                            required
                            {...register("firstName")} />

                        <p className="errors">{errors.firstName?.message}</p>

                    </div>
                  </div>


                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="firstName" className="form-label">Last Name</label>
                        <input type="text" 
                              className={ errors.lastName ? "form-control is-invalid" : "form-control"}  
                              id="last_name" 
                              placeholder="Enter Last name"
                              value={staff.last_name}
                              required
                              {...register("lastName")} />

                          <p className="errors">{errors.lastName?.message}</p>

                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="company" className="form-label">Company</label>
                        <select  
                              className="form-control"
                              {...register("company")}>

                                  <option value={staff.publisher_id}> Fake Company name </option>
                                )
                              </select>

                          <p className="errors">{errors.company?.message}</p>

                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="firstName" className="form-label">Email Address</label>
                        <input type="email" 
                              className={ errors.email ? "form-control is-invalid" : "form-control"}  
                              id="email" 
                              placeholder="Enter Email Address"
                              value={staff.email_address}
                              required
                              {...register("email")} />

                          <p className="errors">{errors.email?.message}</p>

                      </div>
                    </div>


                    



                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="firstName" className="form-label">Phone Number</label>
                        <input type="text" 
                              className={ errors.phoneNumber ? "form-control is-invalid" : "form-control"}  
                              id="phone_number" 
                              placeholder="Enter Phone Number"
                              required
                              value={staff.phone_number}
                              {...register("phoneNumber")} />

                          <p className="errors">{errors.phoneNumber?.message}</p>

                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="role" className="form-label">Role</label>
                        <select className="form-control" 
                                {...register("role")} >
                          <option>Select Role</option>
                          <option value="1" >Finance</option>
                          <option value="2">Uploader</option>
                          <option value="3">Adminstraror</option>
                        </select>

                          <p className="errors">{errors.firstName?.message}</p>

                      </div>
                    </div>


                                
                  </div>

                  <div className="row my-4">
                                <div className="col-12 edit-btns">
                                  <button className="btn btn-outline-secondary mr-2" type="submit">Delete Account</button>
                            
                                    { loading ? (
                                      <button className="btn btn-primary ml-2">
                                        <Loader
                                          type="TailSpin" 
                                          color="#ffffff"
                                          height={20} 
                                          width={20} />
                                      </button>
                                    ) : (
                                      <button className="btn btn-primary ml-2" type="submit">Update Staff</button>
                                    )}
                                    {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                                </div>
                              </div>



          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default StaffEdit
