import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Loader from 'react-loader-spinner';
import { fetchCompany } from '../../../redux-store/Actions/company-action';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCorporateSubscriptionGroupsApi,
  createStaffsApi,
  fetchCorporatesApi,
} from '../../../api/corporate';
import DashboardWrapper from '../../../components/DashboardWrapper';
import { fetchIssues } from '../../../redux-store/Actions/issue-action';
import Checkbox from './customCheckbox';
import { fetchIssuesApi } from '../../../api/publisher';
import { toast } from 'react-toastify';

function CreateCorporateSubscription(props) {
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [issues, setIssues] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [papers, setPapers] = useState([]);
  const [name, setName] = useState('');
  const [companyId, setCompanyId] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (companyId && name.length > 3 && papers.length > 0) {
      let data = {};
      data.companyId = companyId;
      data.papers = papers;
      data.name = name;
      data.status = 'ACTIVE';
      console.log('submit: ', data);
      createCorporateSubscriptionGroupsApi(data)
        .then((res) => {
          setLoading(false);
          console.log(res.data);

          var path = '/corporates/subscription-groups';
          navigate(path);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else {
      toast.error('Please fill in required information', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const navigate = useNavigate();

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    // let papers = [] //you get this from props or location.state
    // let issues = []; /// you get this from api

    // let newSet = issues.map((issue) => {
    //   let item = issue;
    //   let paper = papers.find((p) => p.id == item.id);
    //   item.selected = paper && paper.id == item.id;
    //   return item;
    // });

    // setIsCheck(newSet.map((issue) => issue.selected));

    fetchIssuesApi().then((result) => {
      console.log('iss: ', result.data.data);
      setIssues(result.data.data);
      setIsCheck(new Array(result.data.data.length).fill(false));
    });
    fetchCorporatesApi().then((result) => {
      setCompanies(result.data.data);
    });
  }, []);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    let vals = issues.map((li) => {
      return { id: li.id, title: li.title };
    });

    // let x = vals;
    setIsCheck(new Array(issues.length).fill(true));
    if (!e.target.checked) {
      setIsCheck(new Array(issues.length).fill(false));
      vals = [];
    }
    setPapers(vals);
  };
  const handleCheckChange = (index) => {
    let mychecks = isCheck.map((c, i) => (i === index ? !c : c));

    setIsCheck(mychecks);
    let x = mychecks
      .map((y, z) => (y ? z : -1))
      .filter((y) => y >= 0)
      .map((p) => {
        let m = issues.find((l, i) => i === p);
        return { id: m.id, title: m.title };
      });
    setPapers(x);
  };

  return (
    <DashboardWrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12'>
            <div className='header mt-md-3'>
              <nav aria-label='breadcrumb mb-2'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toPage('/dashboard')}>
                      Dashboard
                    </a>
                  </li>
                  <li className='breadcrumb-item'>
                    <a
                      href='/corporates'
                      onClick={toPage('/corporates')}>
                      Corporates
                    </a>
                  </li>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toPage('/corporates/subscription-groups')}>
                      Subscription Groups
                    </a>
                  </li>
                  <li
                    className='breadcrumb-item active'
                    aria-current='page'>
                    Create Corporate Subscription Group
                  </li>
                </ol>
              </nav>
            </div>

            <div className='content'>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div className='card pt-2 mt-2'>
                    <div className='px-4 pt-4'>
                      <div className=''>
                        <h6 className='header-pretitle'>Overview</h6>

                        <h1 className='header-title'>
                          Create Corporate Subscription Group
                        </h1>
                      </div>

                      <hr />

                      <div className='row'>
                        <div className='col-md-12'>
                          <form onSubmit={handleSubmit}>
                            <div className='row'>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='name'
                                    className='form-label'>
                                    Group Name
                                  </label>
                                  <input
                                    type='text'
                                    className={'form-control'}
                                    id='name'
                                    defaultValue={name}
                                    onChange={(e) => {
                                      setName(e.target.value.trim());
                                    }}
                                    placeholder='Enter group name'
                                    autoFocus
                                    required
                                    name='name'
                                  />
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='name'
                                    className='form-label'>
                                    Company
                                  </label>
                                  <select
                                    defaultValue={companyId}
                                    onChange={(e) => {
                                      setCompanyId(e.target.value);
                                    }}
                                    className='form-control'
                                    id='companyId'
                                    name='companyId'>
                                    <option selected disabled>Select Company</option>
                                    {companies.map((company) => {
                                      return (
                                        <option
                                          key={company.id}
                                          value={company.id}>
                                          {company.companyName}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className='col-md-12 row'>
                                <div className='col-md-12'>
                                  <Checkbox
                                    name='Select all'
                                    id='all'
                                    onClick={handleSelectAll}
                                    checked={isCheckAll}
                                  />
                                </div>
                                {issues.map((issue, index) => {
                                  return (
                                    <div
                                      key={issue.id}
                                      className='col-md-3'>
                                      <Checkbox
                                        id={issue.id}
                                        name={issue.title}
                                        onClick={() => handleCheckChange(index)}
                                        checked={isCheck[index]}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className='row my-4'>
                              <div className='col'></div>
                              <div className='col-auto'>
                                {loading ? (
                                  <button className='btn btn-primary'>
                                    <Loader
                                      type='TailSpin'
                                      color='#ffffff'
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className='btn btn-primary'
                                    type='submit'>
                                    Submit
                                  </button>
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CreateCorporateSubscription;
