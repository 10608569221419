import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { DateTime } from "luxon";
import Loader from "react-loader-spinner";
import { PRCOLUMNS } from "./PRColumn";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import EmptyData from "../../../../components/EmptyData/Index";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { fetchPublisherRevenueReport } from "../../../../api/reports";

function RevenueSummary() {
  const columns = useMemo(() => PRCOLUMNS, []);
  const [reportData, setReportData] = useState([]);
  const [summary, setSummary] = useState({
    original_amount: 0,
    amount: 0,
    commission: 0,
    currency: "",
    publishers: 0,
    publications: 0,
  });
  const [start, setStart] = useState(new Date().toISOString());
  const [end, setEnd] = useState(new Date().toISOString());
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [year, setYear] = useState(new Date().getFullYear());
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));

  const tableInstance = useTable(
    {
      columns,
      data: reportData,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const thousandSeparator = (x) => {
    if (x === undefined || x === null) {
      return "0";
    }
    const number = parseFloat(x).toFixed(0);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    fetchData();
  }, [user.token, start, end]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const publisherRevenueResponse = await fetchPublisherRevenueReport({
        startDate: start,
        endDate: end,
        token: { Authorization: `Bearer ${user.token}` },
      });

      setReportData(publisherRevenueResponse.data.data.details);
      setSummary(publisherRevenueResponse.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e) => {
    const { id, value } = e.target;
    if (id === "start") setStart(new Date(value).toISOString());
    else setEnd(new Date(value).toISOString());
  };

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    const data = reportData.map((d) => {
      let r = { ...d };
      delete r.publisherId;
      return r;
    });
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `My Rifaly Publisher Revenue Report ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    state: { pageIndex },
  } = tableInstance;

  return (
    <div className="row mt-2">
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="platform" className="form-label">
                  Year
                </label>
                <select
                  className="form-select"
                  defaultValue={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value="2024">2024</option>
                  <option value="2023">2023</option>
                </select>
              </div>
            </div>

            <div className="col-md-8">
              <div className="form-group">
                <label htmlFor="range" className="form-label">
                  Date Range
                </label>
                <div>
                  <div className="row">
                    <div className="col-md-3">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Select Start Date"
                        value={start.slice(0, 10)}
                        onChange={handleDateChange}
                        id="start"
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Select End Date"
                        value={end.slice(0, 10)}
                        onChange={handleDateChange}
                        id="end"
                      />
                    </div>
                    <div className="col-md-2">
                      <button className="btn btn-primary" onClick={fetchData}>
                        <span className="fe fe-search"></span>&nbsp;Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="col-md-12 text-center">
          <Loader type="TailSpin" color="#00B17A" height={50} width={50} />
        </div>
      ) : reportData && reportData.length > 0 ? (
        <>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">
                        Revenue
                      </h6>
                      <span className="h2 mb-0 mr-4">
                        {thousandSeparator(summary.amount)}
                      </span>
                      <span className="badge bg-success-soft mr-2 mt-n1">
                        +3.5%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">
                        Total Commission
                      </h6>
                      <span className="h2 mb-0">
                        {thousandSeparator(summary.commission)}
                      </span>
                      <span className="badge bg-success-soft mr-2 mt-n1">
                        +3.5%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">
                        Total Publishers
                      </h6>
                      <span className="h2 mb-0">
                        {thousandSeparator(summary.publishers)}
                      </span>
                      <span className="badge bg-success-soft mr-2 mt-n1">
                        +3.5%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h5 className="text-capitalize text-muted mb-3">
                        Total Publications
                      </h5>
                      <span className="h2 mb-0">
                        {thousandSeparator(summary.publications)}
                      </span>
                      <span className="badge bg-success-soft mr-2 mt-n1">
                        +3.5%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='card-header'>
                  <h4 className='card-header-title'>Uploaded Publications</h4>
                  <div>
                    <button
                      onClick={handleExport}
                      className='btn btn-outline-primary btn-sm'>
                      Export
                    </button>
                  </div>
                </div>

                <table
                  {...getTableProps()}
                  className='table table-sm card-table'>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className='row mb-5'>
            <div className='col'></div>
            <div className='col-auto'>
              <div>
                <button
                  className='btn btn-round btn-sm btn-pagination'
                  onClick={() => previousPage()}>
                  <i className='fe fe-chevron-left'></i>
                </button>
                <span className='table-stat'>Page {currentPage} {pageIndex + 1}</span>
                <button
                  className='btn btn-round btn-sm btn-pagination'
                  onClick={() => nextPage()}>
                  <i className='fe fe-chevron-right'></i>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="col-md-12">
          <EmptyData />
        </div>
      )}
    </div>
  );
}

export default RevenueSummary;
