import React from "react";
import  DashboardWrapper  from "../../../components/DashboardWrapper";
import UserSummary from "../dashboard/UserSummary";
import ReportsNav from "./ReportsNav";


export default function Activity(){
  return(
    <DashboardWrapper>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">
              <div className="header mt-md-3">
                <div className="header-body">
                  <h6 className="header-pretitle">
                    Overview
                  </h6>
                  <h1 className="header-title">
                    Reports
                  </h1>
                </div>
            </div>
            
            <div className="content">
              <div className="row">
               <div className="col-md-12">
                 <div className="row">
                   <div className="col-md-4">
                     <div className="form-group">
                       <label htmlFor="platform" className='form-label'>Platform</label>
                       <select className='form-select' value={"all"}>
                         <option value="all">All</option>
                         <option value="android">Android</option>
                         <option value="ios">iOS</option>
                         <option value="web">Web</option>
                       </select>
                     </div>
                   </div>

                   <div className="col-md-4">
                     <div className="form-group">
                       <label htmlFor="range" className='form-label'>Date Range</label>
                       <select className='form-select'>
                         <option value="all" selected>Last 30 Days</option>
                       </select>
                     </div>
                   </div>


                 </div>
                </div>
              </div>

              <ReportsNav />
              <UserSummary />
            </div>
            

          </div>
          </div>
        </div>
      </DashboardWrapper>
  )
}