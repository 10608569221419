import { 
  fetchReaderDownloadArchivesApi, 
  fetchReaderSubscriptionArchivesApi, 
  fetchReaderTransactionArchivesApi 
} from "../../api/user";

export const FETCH_TRANSACTION_ARCHIVES_LOADING  = "FETCH_TRANSACTION_ARCHIVES_LOADING";
export const FETCH_TRANSACTION_ARCHIVES_SUCCESS  = "FETCH_TRANSACTION_ARCHIVES_SUCCESS";
export const FETCH_DOWNLOAD_ARCHIVES_SUCCESS     = "FETCH_DOWNLOAD_ARCHIVES_SUCCESS";
export const FETCH_DOWNLOAD_ARCHIVES_LOADING     = "FETCH_DOWNLOAD_ARCHIVES_LOADING";
export const FETCH_SUBSCRIPTION_ARCHIVES_SUCCESS = "FETCH_SUBSCRIPTION_ARCHIVES_SUCCESS";
export const FETCH_SUBSCRIPTION_ARCHIVES_LOADING = "FETCH_SUBSCRIPTION_ARCHIVES_LOADING";



export const fetchReaderTransactionArchives = () => {
  return (dispatch) => {
    dispatch(fetchReaderTransactionArchivesLoading());
    return fetchReaderTransactionArchivesApi()
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchReaderTransactionArchivesSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


export const fetchReaderSubscriptionArchives = () => {
  return (dispatch) => {
    dispatch(fetchReaderSubscriptionArchivesLoading());
    return fetchReaderSubscriptionArchivesApi()
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchReaderSubscriptionArchivesSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


export const fetchReaderDownloadArchives = () => {
  return (dispatch) => {
    dispatch(fetchReaderDownloadArchigvesLoading());
    return fetchReaderDownloadArchivesApi()
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchReaderDownloadArchivesSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};



//  transactions 
const fetchReaderTransactionArchivesLoading = () => {
  return {
    type: FETCH_TRANSACTION_ARCHIVES_LOADING,
  };
};
const fetchReaderTransactionArchivesSuccess = (data) => {
  return {
    type: FETCH_TRANSACTION_ARCHIVES_SUCCESS,
    payload: data,
  };
};

// downloads
const fetchReaderDownloadArchigvesLoading = () => {
  return {
    type: FETCH_DOWNLOAD_ARCHIVES_LOADING,
  };
};
const fetchReaderDownloadArchivesSuccess = (data) => {
  return {
    type: FETCH_DOWNLOAD_ARCHIVES_SUCCESS,
    payload: data,
  };
};


// Subscription
const fetchReaderSubscriptionArchivesLoading = () => {
  return {
    type: FETCH_SUBSCRIPTION_ARCHIVES_LOADING,
  };
};
const fetchReaderSubscriptionArchivesSuccess = (data) => {
  return {
    type: FETCH_SUBSCRIPTION_ARCHIVES_SUCCESS,
    payload: data,
  };
};


