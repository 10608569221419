import { FETCH_COMPANY_DETAILS_LOADING, 
  FETCH_COMPANY_DETAILS_SUCCESS 
 } from "../Actions/company-action";


const initialState = {
  company: [],
  isLoading: true,
};


export default function fetchCompanyDetails(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY_DETAILS_LOADING: {
    return {
    ...state,
    isLoading: true,
    };
    }
    case FETCH_COMPANY_DETAILS_SUCCESS: {
    return {
    ...state,
    company: action.payload,
    isLoading: false,
    };
  }
  default:
  return state;
  }
}
