import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Smile } from "../../../../assets/smile.svg";
import { useForm } from "react-hook-form";
import { deletePublicationApi } from "../../../../api/publisher";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { fetchPublications } from "../../../../redux-store/Actions/publication-action";

function DeletePublication(props) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const initialIssue = {
    title: "Deleted Issue",
  };
  props.data.issue =
    props.data.issue === null ? initialIssue : props.data.issue;

  const { handleSubmit } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    deletePublicationApi(props.data.id,user.token)
      .then((res) => {
        handleClose();
        dispatch(fetchPublications());

        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
         toast.fail("Failed to delete publication", {
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
         });
      });
  };

  return (
    <div>
      <span
        className='delete-link'
        onClick={handleShow}>
        Delete
      </span>

      <Modal
        size='sm'
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        centered
        dialogClassName='modal-90w'>
        <div className='row'>
          <div className='col'></div>
          <div className='col-auto'>
            <div className='close-btn'>
              <button
                type='button'
                className='btn-close mr-5'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={handleClose}></button>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div className='modal-image-holder'>
            <Smile />
          </div>

          <div className='modal-details'>
            <h2>Are You Sure ?</h2>
            <p>
              Are you sure you want to delete:{" "}
              <b>
                {props.data.issue.title + " " + props.data.publication_date}{" "}
              </b>
            </p>
          </div>

          <div className='bottom-space'>
            <div className='left'>
              <button
                className='btn btn-outline-danger btn-small text-black'
                onClick={handleClose}>
                Cancel
              </button>
            </div>
            <div className='col-auto'>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className='p-3'>
                {loading ? (
                  <span className='btn-primary btn-small'>
                    <Loader
                      type='TailSpin'
                      color='#ffffff'
                      height={20}
                      width={20}
                    />
                  </span>
                ) : (
                  <button
                    className='btn btn-primary btn-small'
                    type='submit'>
                    Yes, Delete
                  </button>
                )}
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeletePublication;
