import React, {useEffect, useState} from 'react';
import {Modal, Button} from 'react-bootstrap';
import DashboardWrapper from '../../../../components/DashboardWrapper';
import {Link, useParams, useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {fetchBookDetails} from '../../../../redux-store/Actions/book-action';
import Loader from 'react-loader-spinner';
import {PDFObject} from 'react-pdfobject';
import api_config from '../../../../api/api_config';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import {publishPublicationApi} from '../../../../api/publisher';
import {DateTime} from 'luxon';
import {toast} from 'react-toastify';

function Book() {
  const params = useParams();
  const id = params.id;
  const user = JSON.parse(localStorage.getItem('RIFALYPUBLISHER'));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {publication, isLoading} = useSelector((state) => state.publication);
  const [ show, setShow ] = useState(false);

  console.log(publication);
  console.log(isLoading);

  function checkCategory(categoryId) {
    switch (categoryId) {
      case 1:
        return 'Newspaper';
      case 2:
        return 'Magazine';
      case 3:
        return 'Book';
      case 4:
        return 'Article';

      default:
        return 'Newspaper';
    }
  }

  function toDashboard(e) {
    e.preventDefault();
    navigate('/dashboard');
  }

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  var OpenParams = {
    height: '500px',
    zoom: 'scale',
    pdfOpenParams: {
      view: 'FitV',
      pagemode: 'thumbs',
      search: '',
    },
  };

  useEffect(() => {
    dispatch(fetchBookDetails(id));
  }, [ dispatch ]);

  if (!publication) {
    return (
      <section>
        <h2>Publication not found!</h2>
      </section>
    );
  }
  const ConfirmPublish = (props) => {
    return (
      <div className='modal show'>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          role='dialog'
          size='md'>
          <Modal.Header closeButton>
            <Modal.Title>{`${props.status === 0 ? 'Unpublish' : 'Publish'} Publication`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {`This will make the publication ${props.status === 0 ? 'NOT' : ''} AVAILABLE to the public`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='primary'
              onClick={() => publish(props.id)}>
              Proceed
            </Button>
            <Button
              variant='secondary'
              onClick={() => setShow(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
  const publish = async (id) => {
    setShow(false);
    const status = publication.status && publication.status.toUpperCase() === 'PUBLISHED' ? 0 : 1;
    let response = await publishPublicationApi({id: id, status: status, token: user.token});
    console.log(
      '🚀 ~ file: Publication.js:113 ~ publish ~ response',
      response.data,
    );

    if (response && response.data.status == 200) {
      console.log('test: ', response.data.data.message);
      toast.success(response.data.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(fetchBookDetails(id));
    } else console.log('publish: not successful');
  };
  return (
    <DashboardWrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12'>
            <div className='header mt-md-3 mb-5'>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li
                    className='breadcrumb-item'
                    onClick={toPage('/publishers/books')}>
                    Publications
                  </li>
                  <li
                    className='breadcrumb-item active'
                    aria-current='page'>
                    {' '}
                    Publication Details
                  </li>
                </ol>
              </nav>
            </div>

            <div className='row mt-3'>
              <div className='col-md-12'>
                {isLoading ? (
                  <div className='card pt-3'>
                    <div className='card-body'>
                      <div className='loader-holder d-flex justify-content-center align-items-center'>
                        <Loader
                          type='BallTriangle'
                          heigth='100'
                          width='100'
                          color='green'
                          ariaLabel='loading-indicator'
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {publication && publication.issue && (
                      <div className='card pt-3'>
                        <div className='p-4'>
                          <div>
                            <h6 className='header-pretitle'>Overview</h6>
                            <h1 className='header-title'>
                              {publication.issue.title}
                            </h1>
                          </div>

                          <hr />
                        </div>
                        <div className='card-body'>
                          {/*  reader here */}
                          <div className='row'>
                            <div className='col-md-12 mb-2'>
                              <div className='file-viewer mb-3'>
                                <PDFObject
                                  url={
                                    api_config.STORAGE_URL +
                                    publication.document
                                  }
                                  height='800px'
                                  pdfOpenParams={OpenParams}
                                  forcePDFJS={true}
                                />
                              </div>
                            </div>
                          </div>

                          {/* details here */}
                          <section className='detail-section'>
                            <div className='detail-title'>
                              <div className='row'>
                                <div className='col'>
                                  <h3>Publication Information</h3>
                                </div>

                                <div className='col-auto'>
                                  <Link
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate(
                                        `/publishers/books/edit/${id}`,
                                        {
                                          replace: false,
                                          state: publication,
                                        },
                                      );
                                    }}
                                    className='btn-link'>
                                    <i className='fe fe-edit-3'></i>
                                    <span className='ml-2'>Edit</span>
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div className='detail-contents'>
                              <div className='row'>
                                <div className='col-md-4'>
                                  <div className='dc-title'>Paper Category</div>
                                  <div className='dc-body'>
                                    {checkCategory(publication.categoryId)}
                                  </div>
                                </div>

                                <div className='col-md-4'>
                                  <div className='dc-title'>
                                    Publication Date
                                  </div>
                                  <div className='dc-body'>
                                    {DateTime.fromMillis(publication.publication_timestamp)
                                      .setZone(
                                        Intl.DateTimeFormat().resolvedOptions()
                                          .timeZone,
                                      )
                                      .toLocaleString(
                                        DateTime.DATETIME_SHORT_WITH_SECONDS,
                                      )}
                                  </div>
                                </div>

                                <div className='col-md-4'>
                                  <div className='dc-title'>Paper Name</div>
                                  <div className='dc-body'>
                                    {publication.issue.title}
                                  </div>
                                </div>

                                <div className='col-md-4'>
                                  <div className='dc-title'>Price</div>
                                  <div className='dc-body'>
                                    {publication.issue.currency}{' '}
                                    {publication.issue.price} /=
                                  </div>
                                </div>

                                <div className='col-md-4'>
                                  <div className='dc-title'>Issue No</div>
                                  <div className='dc-body'>
                                    {publication.issueNo}
                                  </div>
                                </div>
                                <div className='col-md-4'>
                                  <div className='dc-title'>Genre</div>
                                  <div className='dc-body'>
                                    {publication.issue.genres
                                      ? publication.issue.genres
                                        .map((g) => g.name)
                                        .join(', ')
                                      : 'No specified'}
                                  </div>
                                </div>
                                <div className='col-md-4'>
                                  <div className='dc-title'>Status</div>
                                  <div className='dc-body'>

                                    <>
                                      <span>{publication.status ? publication.status.toUpperCase() : 'UNKNOWN STATUS'}</span>&nbsp;
                                      <span
                                        className='btn text-primary'
                                        onClick={() => setShow(true)}>
                                        {publication.status && publication.status.toUpperCase() === 'PUBLISHED' ? 'Unpublish' : 'Publish'}
                                      </span>
                                    </>

                                  </div>
                                </div>

                                <ConfirmPublish id={publication.id} status={publication.status && publication.status.toUpperCase() !== 'PUBLISHED' ? 1 : 0} />
                                <div className='col-md-12 my-3'>
                                  <div className='dc-title'>Top Stories</div>

                                  {publication.publication_stories && (
                                    <>
                                      {publication.publication_stories.map(
                                        (topStory) => (
                                          <div
                                            className='dc-body my-2'
                                            key={topStory.id}>
                                            {topStory.story}
                                          </div>
                                        ),
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
  // }
}

export default Book;
