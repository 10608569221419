import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Checkbox from "./customCheckbox";
import DashboardWrapper from "../../../components/DashboardWrapper";
import { useLocation} from "react-router-dom";

function CorporateStaffDetails(props) {
  const [corporates, setCorporates] = useState([]);
  const [subs, setSubs] = useState([]);
  const [selectedSubs, setSelectedSubs] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState();

    const location = useLocation();
  const values = location.state.data;
    const [data,setData] = useState(values)
;

  const navigate = useNavigate();

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a
                      href="/corporates"
                      onClick={toPage("/corporates/companies")}
                    >
                      Corporates
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a
                      href="/corporates/staffs"
                      onClick={toPage("/corporates/staffs")}
                    >
                      Staffs
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Staff Details
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card pt-2 mt-2">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Staff Details</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-12">
                          <form >
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="firstName"
                                    className="form-label"
                                  >
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    id="firstName"
                                    placeholder="Enter First name"
                                    defaultValue={data.firstName}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="lastName"
                                    className="form-label"
                                  >
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    id="lastName"
                                    placeholder="Enter Last name"
                                    defaultValue={data.lastName}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Company
                                  </label>
                                  <input
                                    defaultValue={data.company?data.company.companyName:""}
                                    className="form-control"
                                  />
                                   
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="firstName"
                                    className="form-label"
                                  >
                                    Email Address
                                  </label>
                                  <input
                                    type="email"
                                    className={"form-control"}
                                    id="email"
                                    placeholder="Enter Email Address"
                                    defaultValue={data.email}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="phone" className="form-label">
                                    Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    id="phoner"
                                    defaultValue={data.phone}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="role" className="form-label">
                                    Role
                                  </label>
                                  <input type="text" className="form-control" defaultValue={data.role.toUpperCase()}/>
                                    
                                </div>
                              </div>
                              <div className="col-md-4" id="sg-holder">
                                <div className="form-group">
                                  <label htmlFor="role" className="form-label">
                                    Subscription Group
                                  </label>
                                  <input type={"text"} className="form-control" defaultValue={data.subscription_group ? data.subscription_group.name : ""}/>
                                    
                                </div>
                              </div>
                            </div>
<div className="col-md-12 row">
                                <div className="col-md-12">
                                  <label className="form-label">Allowed Publications</label>
                                </div>
                                {data.subscription_group && data.subscription_group.papers.length > 0 ? data.subscription_group.papers.map((issue, index) => {
                                  return (
                                    <div key={issue.id} className="col-md-3">
                                      <Checkbox
                                        id={issue.id}
                                        name={issue.title}
                                        onClick={()=>{}}
                                        checked={true}
                                      />
                                    </div>
                                  );
                                }):"No Publications allowed"}
                              </div>
                            
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CorporateStaffDetails;
