import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import DashboardWrapper from '../../../components/DashboardWrapper';
import SettingsNav from './SettingsNav';

function Categories() {

  const navigate = useNavigate();

  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }

  const [data,setData]=useState([])
  const [checkAll,setCheckAll] = useState(false);
  const [myChecks,setMyChecks] = useState([]);
  const [selections,setMySelections] = useState([])

  const selectAll=(e)=>{
    setCheckAll(!checkAll);
    let checks = Array(data.length).fill(true)
    
    if(!e.target.checked){
      checks=Array(data.length).fill(false);
    }
    setMyChecks(checks);
    getSelections(checks);
  }
  
  //extracts id's of checked items based on the true values in my checks
  const getSelections=(checks)=>{
    //extract indices of true values and puts -1 for false values
    let indices = checks.map((b,p)=>{
      if(b) return p;
      else return -1;
    });
    //use the non-negative indices values to extract ids from the data array
    let vals = data.map((d,k)=>{
      let x = d;
      x.index = indices[k];
      return x;
    }).filter((d)=>d.index >= 0);
    //set my selection to ids of selected data items
    setMySelections(vals.map(v=>v.id));
  }

  const selectOne=(p)=>{
    //when one item is checked or unchecked change it boolean value in the mychecks state
    var newChecks = myChecks.map((m,i)=>{
      return i === p ? !m : m 
    });
    //update mychecks state
    setMyChecks(newChecks);
    //get selected ids from my checks
    getSelections(newChecks);
  }

  useEffect(()=>{
    //initializa data from api and set data state
    let d = [{id:0,label:"Zero"},{id:1,label:"One"},{id:2,label:"Two"},{id:3,label:"Three"},{id:4,label:"Four"},{id:5,label:"Five"},];
    setData(d);
    //create an array of length equal to data length and fill it with false values meaning no selection
    setMyChecks(Array(d.length).fill(false));
    // setMySelections()
  },[])

  return (
  <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">

            <div className="header mt-md-3 mb-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")} >Dashboard</a>  
                  </li>
              
                  <li className="breadcrumb-item active" aria-current="page">Settings</li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">
                  Overview
                </h6>

                <h1 className="header-title">
                  Settings
                </h1>

              </div>

            </div>
            
            <div className="content">
             <SettingsNav></SettingsNav>
            <input type="checkbox" id="all" onChange={(e)=>{selectAll(e)}}/>
            <label>All</label>
            </div>
        
            {data.map((d,i)=>{
              return(<div className='' key={d.id}>
                <input type="checkbox" id={d.id} name={d.name} onChange={()=>{selectOne(i)}} checked={myChecks[i]}/>
                <label>{d.label}</label>
                </div>)
            })}

          </div>
          {selections && selections.length > 0 ? selections.join(","):"nothing"}
        </div>
      </div>
  </DashboardWrapper>);
}

export default Categories;
