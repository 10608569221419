import React from 'react'
import { useNavigate } from "react-router-dom";
import DashboardWrapper from '../../../../components/DashboardWrapper'

function AdminDetails() {


  const navigate = useNavigate();
  
  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }


  return (
    <DashboardWrapper>
    <div className="container">
     <div className="row justify-content-center">
       <div className="col-12 col-lg-10 col-xl-12">

         <div className="header mt-md-3">
           <nav aria-label="breadcrumb mb-2">
             <ol className="breadcrumb">
               <li className="breadcrumb-item">
                 <a href="/dashboard" onClick={toPage("/dashboard")} >Dashboard</a>
               </li>
               <li className="breadcrumb-item">
                 <a href="/dashboard" onClick={toPage("/users/admins")} >Users</a>
               </li>
               <li className="breadcrumb-item active" aria-current="page">Admin Details</li>
             </ol>
           </nav>
         </div>


         <div className="content">
           <div className="row mt-3">
             <div className="col-md-8">

                 <div className="card mt-2">
                   <div className="px-4 pt-4">
                     <div className="row">
                       <div className="col">

                         <h1 className="header-title">
                           User Overview
                         </h1>
                       </div>

                       <div className="col-auto">
                         <span className='badge bg-primary-soft'>Admin</span>
                       </div>
                       

                     </div>

                     
                     <hr />


                   </div>

                   <div className="card-body">
                      There is no design for what an admin will see here -
                   </div>
                 </div>

               </div>
           </div>
         </div>

       </div>
     </div>
     </div>

 </DashboardWrapper>
  )
}

export default AdminDetails