import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Smile } from "../../../../assets/smile.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { updateIssueStatusApi } from "../../../../api/publisher";
import { fetchIssues } from "../../../../redux-store/Actions/issue-action";

function UpdateIssue(props) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const action = props.data.status === "ACTIVE" ? "Suspend" : "Activate";

  console.log(props.data);

  const { handleSubmit } = useForm();

  const onSubmit = (data) => {
    setLoading(true);

    data.id = props.data.id;
    data.status = props.data.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    console.table(data);

    updateIssueStatusApi(props.data.id, data)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        handleClose();

        dispatch(fetchIssues());

        toast.success(
          `Issue ${
            action === "Suspend" ? "suspended" : "activated"
          } successfully`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div>
      <span className="update-link" onClick={handleShow}>
        {action}
      </span>

      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="modal-90w"
      >
        <div className="row">
          <div className="col"></div>
          <div className="col-auto">
            <div className="close-btn">
              <button
                type="button"
                className="btn-close mr-5"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div className="modal-image-holder">
            <Smile />
          </div>

          <div className="modal-details">
            <h2>Are You Sure ?</h2>
            <p>
              Are you sure you want to {action}: <br />
              <b>{props.data.title}</b>
            </p>
          </div>

          <div className="bottom-space">
            <div className="left">
              <button
                className="btn btn-outline-danger btn-small text-black"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
            <div className="col-auto">
              <form onSubmit={handleSubmit(onSubmit)} className="p-3">
                {loading ? (
                  <span className="btn-primary btn-small">
                    <Loader
                      type="TailSpin"
                      color="#ffffff"
                      height={20}
                      width={20}
                    />
                  </span>
                ) : (
                  <button className="btn btn-primary btn-small" type="submit">
                    Yes, {action}
                  </button>
                )}
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UpdateIssue;
