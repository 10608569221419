import React, { useState, useCallback, useEffect } from "react";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateChannelStatusApi } from "../../../../api/publisher";
import Loader from "react-loader-spinner";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import UpdateChannelCover from "./UpdateChannelCover";
import api_config from "../../../../api/api_config";

const schema = yup
  .object({
    channelName: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .max(255)
      .label("Channel Name"),
    commission: yup.number().required().min(0).max(100),
    bankName: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .max(255)
      .label("Bank Name"),
    bankBranch: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .max(255)
      .label("Branch Name"),
    accountName: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .label("Account Name"),
    accountNumber: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .label("Account Number"),
    swiftCode: yup.string().strict(false).trim().required().label("Swift Code"),
    tin: yup
      .string()
      .strict(false)
      .trim()
      .required()
      .max(60)
      .label("Tin Number"),
  })
  .required();

function EditChannel(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const location = useLocation();
  const values = location.state.data;
  const [thedata, setData] = useState(values);
  const [changeVisibility, setChangeVisibility] = useState(false);

  const [loading, setLoading] = useState(false);
  const [coverFile, setCoverFile] = useState();
  const [countries, setCountries] = useState([]);
  const [publisherId, setPublisherId] = useState(null);
  const [country, setCountry] = useState({
    name: "Tanzania",
    currency: "TZS",
    countryCode: "TZ",
  });

  function readFiles(file, type) {
    if (file[0]) {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reader aborted");

      reader.onerror = () => console.log("file reader failed");

      reader.onload = () => {
        const dataUrl = reader.result;
        switch (type) {
          case 1:
            setCoverFile({
              url: dataUrl,
            });
            break;
          default:
            break;
        }
      };

      // Read the file as a data url
      reader.readAsDataURL(file[0]);
    }
  }

  // handle su
  const onSubmit = (data) => {
    setLoading(true);
    data.token = user.token;
    if (coverFile) data.coverImage = coverFile;
    data.publisherId = publisherId;
    if (
      data.channelName &&
      data.description &&
      data.country
    ) {
      updateChannelStatusApi(thedata.id, data)
        .then((result) => {
          if (result.data.status == 200) {
            setPublisherId(result.data.data.id);
            toast.success(result.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            navigate("/podcasters/channel");
          } else {
            toast.error(result.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((e) => {
          console.log("error", e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Fill in required information", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const navigate = useNavigate();

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }
  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    let channelName = document.getElementById("channelName").value.trim();
    let description = document.getElementById("description").value.trim();
    let country = document.getElementById("country").value.trim();
    let data = {
      channelName: channelName,
      description: description,
      country: country,
      
    };
    if (coverFile) data.coverImage = coverFile;

    if (
      data.channelName &&
      data.description &&
      data.country 

    ) {
      updateChannelStatusApi(thedata.id, data)
        .then((result) => {
          if (result.data.status == 200) {
            setPublisherId(result.data.data.id);
            toast.success(result.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(result.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((e) => {
          console.log("error ", e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Fill in required information", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handleSaveDocs = (e) => {
    e.preventDefault();
    setLoading(true);
    if (publisherId) {
      let data = {
        channelId: publisherId,
      };
      if (
        data.coverFile
      ) {
        updateChannelStatusApi(thedata.id, data)
          .then((result) => {
            if (result.data.status == 200) {
              toast.success(result.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error(result.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((e) => {
            console.log("error ", e);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error("Fill in required information", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      setLoading(false);
      toast.error("Fill in required information", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  useEffect(() => {
    let countries = localStorage.getItem("RIFALYCOUNTRIES")
      ? JSON.parse(localStorage.getItem("RIFALYCOUNTRIES"))
      : [];
    setCountries(countries);
  }, []);

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/publishers")}>
                      Publishers
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a
                      href="/dashboard"
                      onClick={toPage("/podcasters/channel")}
                    >
                      Channel
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Channel
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card pt-3">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Edit Channel</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-12">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <div className="form-head">
                                  <p className="form-title">
                                    Basic Information
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="image-holder">
                                  <div className="img-place">
                                    <div
                                      className="img-cover"
                                      onMouseEnter={(e) => {
                                        setChangeVisibility(true);
                                      }}
                                      onMouseLeave={(e) => {
                                        setChangeVisibility(false);
                                      }}
                                    >
                                      <img
                                        src={
                                          thedata.cover &&
                                          thedata.cover.includes("https")
                                            ? `${thedata.cover}`
                                            : `${api_config.STORAGE_URL}${thedata.cover}`
                                        }
                                        className="img-fluid"
                                        alt={thedata.channelName}
                                      />
                                      {/* <img src={api_config.STORAGE_URL+thedata.cover} className="img-fluid" alt={thedata.channelName} /> */}
                                    </div>

                                    <div
                                      className={
                                        changeVisibility
                                          ? "img-change"
                                          : "img-change hidden"
                                      }
                                      onMouseEnter={(e) => {
                                        setChangeVisibility(true);
                                      }}
                                      onMouseLeave={(e) => {
                                        setChangeVisibility(false);
                                      }}
                                    >
                                      <UpdateChannelCover
                                        name={thedata}
                                      ></UpdateChannelCover>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="channel"
                                    className="form-label"
                                  >
                                    Channel Name
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={thedata.channelName}
                                    className={
                                      errors.channelName
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="channelName"
                                    placeholder="Enter Company name"
                                    autoFocus
                                    required
                                    {...register("channelName")}
                                  />

                                  <p className="errors">
                                    {errors.channelName?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="country"
                                    className="form-label"
                                  >
                                    Country
                                  </label>

                                  <select
                                    className="form-select"
                                    {...register("country")}
                                    id="country"
                                    defaultValue={thedata.countryCode}
                                    onChange={(e) => {
                                      setCountry(
                                        countries.find(
                                          (c) => c.countryCode == e.target.value
                                        )
                                      );
                                    }}
                                  >
                                    {countries.map((country) => (
                                      <option
                                        value={country.countryCode}
                                        key={country.countryCode}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="description"
                                    className="form-label"
                                  >
                                    Description
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={thedata.description}
                                    className="form-control"
                                    id="description"
                                    {...register("description")}
                                    required
                                    placeholder="Enter description"
                                  />
                                </div>
                              </div>

                              <div className="row my-4">
                                <div className="col"></div>
                                <div className="col-auto">
                                  {loading ? (
                                    <button className="btn btn-primary">
                                      <Loader
                                        type="TailSpin"
                                        color="#ffffff"
                                        height={20}
                                        width={20}
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleSave}
                                    >
                                      Save
                                    </button>
                                  )}
                                  {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-head">
                                  <p className="form-title">
                                    Billing Information
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="bankName"
                                    className="form-label"
                                  >
                                    Bank Name*
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={thedata.bankName}
                                    className={
                                      errors.bankName
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="bankName"
                                    {...register("bankName")}
                                    required
                                    placeholder="Enter Bank Name"
                                  />

                                  <p className="errors">
                                    {errors.bankName?.message}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="bankBranch"
                                    className="form-label"
                                  >
                                    Bank Branch*
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={thedata.bankBranch}
                                    className={
                                      errors.bankBranch
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="bankBranch"
                                    {...register("bankBranch")}
                                    required
                                    placeholder="Enter Bank Branch"
                                  />

                                  <p className="errors">
                                    {errors.bankBranch?.message}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="accountName"
                                    className="form-label"
                                  >
                                    Account Name*
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={thedata.accountName}
                                    className={
                                      errors.accountName
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="accountName"
                                    {...register("accountName")}
                                    required
                                    placeholder="Enter Account Name"
                                  />
                                  <p className="errors">
                                    {errors.accountName?.message}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="accountNumber"
                                    className="form-label"
                                  >
                                    Account Number*
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={thedata.accountNumber}
                                    className={
                                      errors.accountNumber
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="accountNumber"
                                    {...register("accountNumber")}
                                    required
                                    placeholder="Enter Account Number"
                                  />

                                  <p className="errors">
                                    {errors.accountNumber?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="accountType"
                                    className="form-label"
                                  >
                                    Swift Code*
                                  </label>
                                  <input
                                    type="text"
                                    defaultValue={thedata.swiftCode}
                                    className={
                                      errors.swiftCode
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="accountType"
                                    {...register("swiftCode")}
                                    required
                                    placeholder="Enter Swift Code"
                                  />

                                  <p className="errors">
                                    {errors.swiftCode?.message}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* end of form row */}

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default EditChannel;
