import {
  FETCH_PROMOTIONS_LOADING,
  FETCH_PROMOTIONS_SUCCESS,
  FETCH_PROMOTIONS_FAILURE,
  FETCH_PROMOTION_DETAILS_LOADING,
  FETCH_PROMOTION_DETAILS_SUCCESS,
  FETCH_PROMOTION_DETAILS_FAILURE
} from '../Actions/promotion-action';

const initialState = {
  promotions: [],
  isLoading: false,
  error: null,
};

export default function promotionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROMOTIONS_LOADING: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case FETCH_PROMOTIONS_SUCCESS: {
      return {
        ...state,
        promotions: action.payload,
        isLoading: false,
        error: null,
      };
    }
    case FETCH_PROMOTIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case FETCH_PROMOTION_DETAILS_LOADING:
      return { ...state, isLoading: true };
    case FETCH_PROMOTION_DETAILS_SUCCESS:
      return { ...state, promotions: action.payload, isLoading: false };
    case FETCH_PROMOTION_DETAILS_FAILURE:
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
}
