import React from 'react'
import { NavLink } from 'react-router-dom'

const menuItems = [
  {
    name: "Rifaly Admins",
    path: "admins"
  },
  {
    name:"Publishers",path:"publishers"
  },
  {
    name:"Corporate Admins",path:"corporates"
  },
  {
    name: "Corporate Readers",
    path: "corporate/readers"
  },
  {
    name: "Readers",
    path: "readers"
  }
]


function UserNavs() {
  return (
    <ul className="nav nav-tabs">
      { menuItems.map((menu) => (
        <li className="nav-item" key={menu.path}>
          <NavLink to={"/users/" + menu.path} className="nav-link">{ menu.name }</NavLink>
        </li>
      ))}
    </ul>
  )
}

export default UserNavs
