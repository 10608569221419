import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import DeletePublication from "./DeletePublication";
import SharePopup from "./SharePopup";

export const PUBLICATIONCOLUMNS = [
  {
    id: 0,
    header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },
  {
    id: 1,
    header: "title",
    accessor: "issue.title",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    id: 2,
    header: "Issue No.",
    accessor: "issueNo",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    id: 3,
    header: "PUBLISHER",
    accessor: "publisher.companyName",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    id: 4,
    header: "Date Published",
    accessor: "publication_timestamp",
    Cell: (props) => <div>{formatDate(props.value)}</div>,
  },
  {
    id: 5,
    header: "status",
    accessor: "status",
    Cell: (props) => <div>{checkStatus(props.value)}</div>,
  },
  {
    id: 6,
    header: "action",
    accessor: "action",
    Cell: (props) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link>
          <SharePopup data={props.row.original} />
        </Link>
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic"
          >
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            <Link
              to={`/publishers/publications/${props.row.original.id}`}
              className="dropdown-item"
            >
              View
            </Link>
            <Link
              to={`/publishers/publications/edit/${props.row.original.id}`}
              state={props.row.original}
              className="dropdown-item"
            >
              Edit
            </Link>
            <div className="dropdown-item text-danger">
              <DeletePublication data={props.row.original}></DeletePublication>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

function checkStatus(status) {
  // let releaseDate = Date.parse(pubdate.replaceAll("T"," ").split(".")[0]);
  // let now = (new Date(Date.now())).getTime();
  if (status && status.toUpperCase() === "PUBLISHED") {
    return (
      <div className="status">
        <div className="dot"></div>
        {status}
      </div>
    );
  } else {
    return (
      <div className="status">
        <div className="dot inactive"></div>
        {status}
      </div>
    );
  }
}

const formatDate = (date) => {
  if (date)
    return DateTime.fromMillis(date)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toLocaleString(DateTime.DATE_MED);
  else return date;
};
