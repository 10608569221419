import React, { useEffect, useState, useCallback, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import { createCampaignApi } from "../../../../api/promotion";
import { fetchcampaigns } from "../../../../redux-store/Actions/campaign-action";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

const schema = yup
  .object({
    title: yup.string().required().max(60).label("Campaign Name"),
  })
  .required();

function CreateCampaign() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);

  data.startDate = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd HH:mm');
  data.endDate = DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd HH:mm');

    createCampaignApi(data, user.token)
      .then((res) => {
        setLoading(false);
        e.target.reset();

        toast.success("Campaign Created successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch(fetchcampaigns());
        navigate("/campaigns");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);

        if (error.response) {
          // setApiErrors(error.response.data.error)
          // client received an error response (5xx, 4xx)

          if (error.response.status === 500) {
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (error.response.status === 422) {
            const respErrors = error.response.data.errors;

            respErrors.forEach((object, index) => {
              var values = Object.keys(object).pop();
              // console.log(`${values}:  ${object[values]}`)

              toast.error(`${values}:  ${object[values]}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
          }

          if (error.response.status === 303) {
            toast.error(error.response.data.error.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  function goBack(e) {
    e.preventDefault();
    navigate(-1);
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const filterPassedDate = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    return currentDate.getTime() - 24 * 3600 * 1000 <= selectedDate.getTime();
  };

  const CustomDatePickerStart = () => {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input
        type="text"
        className="example-custom-input form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        onChange={() => {}}
      />
    ));
    return (
      <DatePicker
        isClearable={true}
        selected={startDate}
        dateFormat={"yyyy-MM-dd HH:mm"}
        timeFormat={"HH:mm"}
        minDate={new Date().getTime}
        showTimeSelect
        timeIntervals={30}
        onChange={(date) => setStartDate(date)}
        filterDate={filterPassedDate}
        filterTime={filterPassedTime}
        customInput={<ExampleCustomInput />}
      />
    );
  };

  const CustomDatePickerEnd = () => {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input
        type="text"
        className="example-custom-input form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        onChange={() => {}}
      />
    ));
    return (
      <DatePicker
        isClearable={true}
        selected={endDate}
        dateFormat={"yyyy-MM-dd HH:mm:ss"}
        timeFormat={"HH:mm"}
        minDate={new Date().getTime}
        showTimeSelect
        timeIntervals={30}
        onChange={(date) => setEndDate(date)}
        filterDate={filterPassedDate}
        filterTime={filterPassedTime}
        customInput={<ExampleCustomInput />}
      />
    );
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="/publishers" onClick={toPage("/publishers/")}>
                      campaigns
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Upload campaigns
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card mt-2">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Create campaign</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-8">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                            <div className='col-12 col-md-6 mb-3'>
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    campaign Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.title
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="title"
                                    placeholder="Enter campaign Name"
                                    autoFocus
                                    required
                                    {...register("title")}
                                  />

                                  <p className="errors">
                                    {errors.title?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='col-12 col-md-6 mb-3'>
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    campaign Objective
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.description
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="description"
                                    placeholder="Enter campaign Objective"
                                    autoFocus
                                    required
                                    {...register("objective")}
                                  />

                                  <p className="errors">
                                    {errors.objective?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='col-12 col-md-6 mb-3'>
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Status{" "}
                                  </label>
                                  <select
                                    className="form-select form-control"
                                    {...register("status")}
                                  >
                                    <option selected disabled>
                                      Select status of Campaign
                                    </option>
                                    <option defaultValue="DRAFT">Draft</option>
                                    <option defaultValue="ACTIVE">
                                      Active
                                    </option>
                                    <option defaultValue="INACTIVE">
                                      InActive
                                    </option>
                                    <option defaultValue="EXPIRED">
                                      Expired
                                    </option>
                                  </select>

                                  <p className="errors">
                                    {errors.status?.message}
                                  </p>
                                </div>
                              </div>

                              <div className='col-12 col-md-6 mb-3'></div>

                              <div className='col-12 col-md-6 mb-3'>
                                <label
                                  htmlFor="validationServer02"
                                  className="form-label"
                                >
                                  Start Date
                                </label><br/>
                                <CustomDatePickerStart
                                  className="form-control"
                                  dateFormat={"yyyy-MM-dd HH:mm:ss"}
                                  selected={startDate}
                                  minDate={new Date().getTime}
                                  showTimeSelect
                                  timeIntervals={15}
                                  onChange={(date) => setStartDate(date)}
                                  filterDate={filterPassedDate}
                                  filterTime={filterPassedTime}
                                  readOnly
                                  required
                                />
                              </div>

                              <div className='col-12 col-md-6 mb-3'>
                                <label
                                  htmlFor="validationServer02"
                                  className="form-label"
                                >
                                  End Date
                                </label><br/>
                                <CustomDatePickerEnd
                                  className="form-control"
                                  dateFormat={"yyyy-MM-dd HH:mm:ss"}
                                  selected={endDate}
                                  minDate={new Date().getTime}
                                  showTimeSelect
                                  timeIntervals={15}
                                  onChange={(date) => setEndDate(date)}
                                  filterDate={filterPassedDate}
                                  filterTime={filterPassedTime}
                                  readOnly
                                  required
                                />
                              </div>

                              <div className='col-12 col-md-12'>
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    campaign Description
                                  </label>
                                  <textarea
                                  type='text'
                                  rows='3'
                                  id="description"
                                  className={
                                    errors.description
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  placeholder="Enter  campaign Description"
                                  autoFocus
                                  required
                                  {...register("description")}
                                  >
                                  </textarea>
                                  <p className="errors">
                                    {errors.description?.message}
                                  </p>
                                </div>
                              </div>

                            </div>

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CreateCampaign;
