import React from 'react'
import './App.css';
import CustomRoutes from './router'

function App() {
  return (
      <CustomRoutes />
  );
}

export default App;
