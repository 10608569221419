import React, { useEffect, useState, useMemo } from "react";
import {
  fetchLinkSharesApi,
  fetchLinkSharesPerPublisherApi,
} from "../../../../api/email";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import Navs from "../navs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { fetchPublisherApi } from "../../../../api/company";
import { DateTime } from "luxon";
import ShareTable from "./shareTable";
import { SHARELINKSCOLUMNS } from "./shareLinksColumn";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  LineElement,
  ArcElement,
  Tooltip,
  Legend
);

export default function ShareReport() {
  const columns = useMemo(() => SHARELINKSCOLUMNS, []);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    DateTime.fromMillis(Date.now()).startOf("day").toISODate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.fromMillis(Date.now()).toISODate()
  );

  const [linkShare, setLinkShare] = useState();
  const [linkShareForPublisher, setLinkShareForPublisher] = useState();
  const [companies, setCompanies] = useState([]);
  const [publisherId, setPublisherId] = useState(1);
  const [company, setCompany] = useState(
    companies && companies.length > 0 ? companies[0] : {}
  );

  const fetchData = async () => {
    const response = await fetchPublisherApi();
    if (response && response.status === 200 && response.data.data.length > 0) {
      const companiesData = response.data.data;
      const activeCompanies = companiesData.filter(
        (company) => company.status === "ACTIVE"
      );
      setCompanies(activeCompanies);
      setCompany(activeCompanies[0]);
    }
  };

  useEffect(() => {
    fetchData();
    handleSearch(startDate, endDate, publisherId);
  }, [startDate, endDate, publisherId]);

  const pubChange = (e) => {
    let pubId = e.target.value;
    let selectedCompany = companies.find((c) => c.id == parseInt(pubId));
    setCompany(selectedCompany);
    setPublisherId(pubId);
    handleSearch(startDate, endDate, pubId);
  };

  const handleDateChange1 = (e) => {
    if (e.target.id === "startDate")
      setStartDate(DateTime.fromISO(e.target.value).toISODate());
    else setEndDate(DateTime.fromISO(e.target.value).toISODate());
  };

  async function handleSearch(startDate, endDate, publisherId) {
    try {
      const payload = {
        id: publisherId,
        startDate: startDate,
        endDate: endDate,
      };
      const [response, response_] = await Promise.all([
        fetchLinkSharesApi({
          startDate: startDate,
          endDate: endDate,
        }),

        fetchLinkSharesPerPublisherApi(payload),
      ]);
      setLinkShare(response?.data || []);
      setLinkShareForPublisher(response_?.data || []);
    } catch (e) {
      console.log("error", e);
    }
  }

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  const optionsBar = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Links",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Shares",
        },
      },
    },
  };

  const dataBar = {
    labels: linkShareForPublisher?.map((item) => item.link) || [],
    datasets: [
      {
        label: "Link Shares per Publisher",
        data: linkShareForPublisher?.map((item) => item.shares) || [],
        backgroundColor: "#E7E7E7",
      },
    ],
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Link Shares
                  </li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Share Links</h1>
              </div>
            </div>

            <div className="content">
              <Navs></Navs>
              <div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <div className="form-group">
                      <label htmlFor="company" className="form-label">
                        Publisher
                      </label>
                      <select
                        onChange={pubChange}
                        defaultValue={company.id}
                        className="form-select"
                        id="publisherId"
                      >
                        <option selected disabled>
                          Select Company
                        </option>
                        {companies
                          .sort((a, b) => {
                            if (a.companyName < b.companyName) return -1;
                            else if (a.companyName > b.companyName) return 1;
                            else return 0;
                          })
                          .map((company) => {
                            return (
                              <option key={company.id} value={company.id}>
                                {company.companyName}{" "}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group">
                      <label htmlFor="range" className="form-label">
                        Date Range
                      </label>
                      <div>
                        <div className="row">
                          <div className="col-md-3">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Select Start Date"
                              value={startDate}
                              onChange={handleDateChange1}
                              id="startDate"
                            />
                          </div>
                          <div className="col-md-3">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Select End Date"
                              value={endDate}
                              onChange={handleDateChange1}
                              id="endDate"
                            />
                          </div>
                          <div className="col-md-2">
                            <button
                              className="btn btn-primary"
                              onClick={() => handleSearch(startDate, endDate)}
                            >
                              <span className="fe fe-search"></span>&nbsp;Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-xl-6">
                  <div className="card card-fill-sm">
                    <div className="card-header">
                      <h4 className="card-header-title">
                        Link Shares per Publisher
                      </h4>
                    </div>
                    <div className="card-body">
                      <Bar options={optionsBar} data={dataBar} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <ShareTable
                  name="Links shared"
                  title="Shared Links"
                  columns={columns}
                  data={linkShare || []}
                  path="/publishers"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
