import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import Dropzone from "react-dropzone";
import { createFeatureEmailApi } from "../../../api/email";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from "date-fns";

function FeatureTutorial({ emailType }) {
  const { register, handleSubmit } = useFormContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [features, setFeatures] = useState(["feature"]);
  const [showAddContentButton, setShowAddContentButton] = useState(true);
  const [scheduledDate, setScheduledDate] = useState(new Date());

  const handleCancelForm = (e) => {
    e.preventDefault();
    navigate("/emails");
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onDropEmailImage = useCallback(async (acceptedFiles, index) => {
    const file = acceptedFiles[0];
    const base64File = await toBase64(file);
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = { original: file, base64: base64File };
      return newFiles;
    });
  }, []);

  const addContent = () => {
    setFeatures((prevFeatures) => {
      if (prevFeatures.length < 3) {
        return [...prevFeatures, `feature${prevFeatures.length + 1}`];
      }
      return prevFeatures;
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const emailData = {
      cta: data.cta,
      subject: data.subject,
      message: data.message,
      sendDate: format(scheduledDate, "yyyy-MM-dd HH:mm:ss"),
      features: features.map((feature, index) => ({
        title: data[`${feature}title`].trim(),
        description: data[`${feature}description`].trim(),
        image: files[index]?.base64 || null,
      })),
    };

    try {
      const res = await createFeatureEmailApi(emailData);
      if (res.status === 201) {
        toast.success(res.data.message);
        navigate("/emails");
      } else {
        toast.error(res.data.error);
      }
    } catch (error) {
      toast.error("Failed to create email");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setShowAddContentButton(features.length < 3);
  }, [features]);

  const filterPassedDate = (date) => {
    const currentDate = new Date();
    return date >= currentDate;
  };
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row g-3 mb-3">
        <div className="col-12 col-md-12 mb-1">
          <label htmlFor="subject" className="form-label">
            Email Heading
          </label>
          <input
            type="text"
            className="form-control"
            id="subject"
            placeholder="Enter Email Heading"
            {...register("subject")}
            required
          />
        </div>
        <div className="col-12 col-md-12 mb-1">
          <label htmlFor="message" className="form-label">
            Message
          </label>
          <textarea
            className="form-control"
            id="message"
            placeholder="Enter Message"
            {...register("message")}
            required
          />
        </div>
        <div className="col-12 col-md-12 mb-1">
          <label htmlFor="sendDate" className="form-label">
            Date and Time of Release
          </label>
          <div className="mb-2">
            <DatePicker
              isClearable={true}
              selected={scheduledDate}
              dateFormat={"yyyy-MM-dd HH:mm:ss"}
              timeFormat={'HH:mm:ss'}
              minDate={new Date()}
              showTimeSelect
              timeIntervals={15}
              onChange={(date) => setScheduledDate(date)}
              filterDate={filterPassedDate}
              filterTime={filterPassedTime}
              customInput={
                <input
                  type="text"
                  className="form-control"
                  value={scheduledDate ? format(scheduledDate, "yyyy-MM-dd HH:mm:ss") : ""}
                  readOnly
                />
              }
              required
            />
          </div>
        </div>

        {features.map((feature, index) => (
          <div key={index} className="col-12 col-md-12">
            <h4>Feature {index + 1}</h4>
            <div className="row">
              <div className="col-12 col-md-6 mb-1">
                <label htmlFor={`${feature}title`} className="form-label">
                  Feature Title
                </label>
                <input
                  className="form-control"
                  id={`${feature}title`}
                  placeholder="Enter Feature Title"
                  {...register(`${feature}title`)}
                  required
                />
              </div>
              <div className="col-12 col-md-6 mb-1">
                <label htmlFor={`${feature}description`} className="form-label">
                  Feature Description
                </label>
                <input
                  className="form-control"
                  id={`${feature}description`}
                  placeholder="Enter Feature Description"
                  {...register(`${feature}description`)}
                  required
                />
              </div>
            </div>
            <div className="col-12 col-md-12 mb-1">
              <Dropzone
                onDrop={(acceptedFiles) =>
                  onDropEmailImage(acceptedFiles, index)
                }
                multiple={false}
                accept="image/*"
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div className="mb-1">
                      <label className="form-label">Feature Media</label>
                    </div>
                    <div
                      {...getRootProps({
                        className: "dropzone dropzone-single mb-3",
                      })}
                    >
                      <input {...getInputProps()} />
                      <div className="upload-emailcontent p-2 d-flex justify-content-between align-items-center">
                        <i className="fe fe-upload-cloud"></i>
                        <div className="col-auto mt-2">
                          <p className="bold-black">
                            Select a file or drag and drop here
                          </p>
                          <p>JPG, PNG or PDF, file size no more than 10MB</p>
                        </div>
                        <p className="btn btn-sm btn-outline-primary mb-0">
                          Select media
                        </p>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
              <aside>
                <h4>Files</h4>
                <ul>
                  {files[index] && (
                    <li>
                      {files[index].original.name} -{" "}
                      {files[index].original.size} bytes
                    </li>
                  )}
                </ul>
              </aside>
            </div>
          </div>
        ))}

        <div className="col-12 col-md-12">
          {showAddContentButton && (
            <span className="btn btn-link" onClick={addContent}>
              + Add Content
            </span>
          )}
        </div>
        <div className="col-12 col-md-12 mb-1">
          <label htmlFor="cta" className="form-label">
            CTA
          </label>
          <input
            type="text"
            className="form-control"
            id="cta"
            placeholder="Enter CTA"
            {...register("cta")}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col"></div>
        <div className="col-auto">
          <div className="d-flex justify-content-between">
            {!loading && (
              <button
                onClick={handleCancelForm}
                className="btn btn-outline-secondary mx-2"
              >
                Cancel
              </button>
            )}
            {loading ? (
              <button className="btn btn-primary">
                <Loader
                  type="TailSpin"
                  color="#ffffff"
                  height={20}
                  width={20}
                />
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={handleSubmit(onSubmit)}
                type="button"
              >
                Publish
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default FeatureTutorial;
