import React ,  { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Smile  } from '../../../assets/smile.svg';
import { useForm } from "react-hook-form"; 
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { fetchAdmins } from '../../../redux-store/Actions/admin-action';
import { updateAdminStatusApi, updateUserStatusApi } from '../../../api/user';
import { fetchReaders } from '../../../redux-store/Actions/reader-action';




function UpdateUserStatus(props) {

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const action = props.data.status === "ACTIVE" ? "Suspend" : "Activate";
  const type = props.type;


  const { 
    handleSubmit
  } = useForm();


  const updateTable = (type) => {
    switch (type){
      case 1: 
        dispatch(fetchAdmins());
        break;

      case 2: 
        dispatch(fetchAdmins());
        break;

      case 3: 
        dispatch(fetchReaders());
        break;

      default:
        dispatch(fetchReaders());
        break;

    }
  }


  const onSubmit = (data) => {
    setLoading(true);

    const userId = props.data.id;

    data.id = userId;
    data.status = (props.data.status === "ACTIVE" ? "INACTIVE" : "ACTIVE");

    if(type == 1){
      updateAdminStatusApi(data).then(res=>{
        setLoading(false);
        handleClose();
        updateTable(type);
        dispatch(fetchAdmins());
        toast.success(`User ${ action === 'Suspend' ? 'suspended' : 'activated'} successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  
      })
      .catch(e=>{
      setLoading(false)
      console.log(e)
      })
    }
    else{
      updateUserStatusApi(data).then(res => {
        setLoading(false)
        handleClose()
  
        updateTable(type);
  
        dispatch(fetchAdmins());
  
        toast.success(`User ${ action === 'Suspend' ? 'suspended' : 'activated'} successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  
  
      }).catch(error => {
        setLoading(false)
        console.log(error)
      })
    }
   
  }


  return (


  <div>
      <span className='update-link' onClick={handleShow}>
        {  action  }
      </span>

      <Modal
        size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        dialogClassName="modal-90w"
      >

        <div className="row">
          <div className="col"></div>
          <div className="col-auto">
            <div className='close-btn'>
            <button type="button" className="btn-close mr-5" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div>
          </div>
        </div>
      
         
      


        <Modal.Body>
          

          <div className='modal-image-holder'>
            <Smile />
          </div>

          <div className="modal-details">
            <h2>
              Are You Sure ?
            </h2>
            <p>Are you sure you want to {action}: <br /><b>{props.data.email}</b></p>
          </div>



          <div className="bottom-space">
            <div className="left">
              <button className='btn btn-outline-danger btn-small text-black' type='reset' onClick={handleClose}>
                Cancel
              </button>
            </div>
            <div className="col-auto">
              <form onSubmit={handleSubmit(onSubmit)} className="p-3">

                { loading ? (
                  <span className="btn-primary btn-small">
                    <Loader
                      type="TailSpin" 
                      color="#ffffff"
                      height={20} 
                      width={20} />
                  </span>
                ) : (
                  <button className="btn btn-primary btn-small" type="submit">Yes, {action}</button>
                )}

              </form>
            </div>
          </div>
          
        </Modal.Body>



     
         
         
      
      </Modal>
  </div>
  );
}

export default UpdateUserStatus;
