import { fetchChannelsApi, fetchChannelsByIdApi } from '../../api/publisher';

export const FETCH_CHANNELS_LOADING = 'FETCH_CHANNELS_LOADING';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNEL_DETAILS_LOADING = 'FETCH_CHANNEL_DETAILS_LOADING';
export const FETCH_CHANNEL_DETAILS_SUCCESS = 'FETCH_CHANNEL_DETAILS_SUCCESS';

export const fetchChannels = (page) => {
  return (dispatch) => {
    dispatch(fetchChannelsLoading());
    return fetchChannelsApi(page)
      .then((res) => { 
        const data = res.data.data || [];
        dispatch(fetchChannelsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const fetchChannelDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchChannelDetailsLoading());
    return fetchChannelsByIdApi(id)
      .then((res) => {
        const data = res.data.data || {};
        dispatch(fetchChannelDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const fetchChannelsLoading = () => {
  return {
    type: FETCH_CHANNELS_LOADING,
  };
};

const fetchChannelsSuccess = (data) => {
  return {
    type: FETCH_CHANNELS_SUCCESS,
    payload: data,
  };
};

const fetchChannelDetailsLoading = () => {
  return {
    type: FETCH_CHANNEL_DETAILS_LOADING,
  };
};

const fetchChannelDetailsSuccess = (data) => {
  return {
    type: FETCH_CHANNEL_DETAILS_SUCCESS,
    payload: data,
  };
};
  