import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { AnimatePresence, motion } from "framer-motion";
import { CSVLink } from "react-csv";
import moment from "moment";
import EmptyData from "../EmptyData/Index";
import TableFilter from "../TableFilter/TableFiler";
import { useEffect } from "react";

function Table({ name, title, columns, data, raw, onPageChange, path }) {
  const [currentPage, setCurrentPage] = useState(1);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,

    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const spring = useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
      rotate: 360,
    }),
    []
  );

  function fileName() {
    let date = new Date();
    let dateString = moment(date).format("YYYYMMDDHHmm");
    return name + "_" + dateString + ".csv";
  }

  const nextPage = () => {
    const next = currentPage + 1;
    onPageChange(next);
    setCurrentPage(next);
  };

  const previousPage = () => {
    const prev = currentPage - 1;
    onPageChange(prev);
    setCurrentPage(prev);
  };

  return (
    <div>
      <div className="row align-items-end my-4">
        <div className="col">
          <TableFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
            name={name}
          />
        </div>

        <div className="col-auto">
          <Link
            to={path + "/" + name + "/create"}
            className="btn btn-primary lift"
          >
            <span>
              <i className="fe fe-plus"></i>
            </span>
            Create {name}
          </Link>
        </div>
      </div>

      <div className="row mt-2 body-content">
        {data && data.length >= 1 ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">{title}</h4>

                    <div>
                      <div>
                        <CSVLink
                          data={data}
                          filename={fileName()}
                          header={columns}
                          className="btn btn-outline-primary btn-sm"
                          target="_blank"
                        >
                          Export
                        </CSVLink>
                      </div>
                    </div>
                  </div>

                  <table
                    {...getTableProps()}
                    className="table table-sm card-table"
                  >
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      <AnimatePresence>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps({
                                layouttransition: spring,
                                exit: { opacity: 0, maxHeight: 0 },
                              })}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <motion.td
                                    {...cell.getCellProps({
                                      layouttransition: spring,
                                      exit: { opacity: 0.5, maxHeight: 0 },
                                    })}
                                  >
                                    {cell.render("Cell")}
                                  </motion.td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </AnimatePresence>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                    Showing {raw?.pageSize ? raw?.pageSize : 20} of {raw?.totalItems} elements
                    {/* Showing {20} of {raw?.totalItems} elements */}
                  </span>
                  {raw?.hasPrevious ? (
                    <button
                      className="btn btn-round btn-sm btn-pagination"
                      onClick={previousPage}
                    >
                      <i className="fe fe-chevron-left"></i>
                    </button>
                  ) : null}
                  <span className="table-stat">
                    Page {raw?.pages ? raw?.pages?.currentPage : raw?.currentPage}
                    {/* Page {raw?.pages?.currentPage} */}
                  </span>
                  {raw?.hasNext ? (
                    <button
                      className="btn btn-round btn-sm btn-pagination"
                      onClick={nextPage}
                    >
                      <i className="fe fe-chevron-right"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name={name} path={path + "/" + name + "/create"} />
        )}
      </div>
    </div>
  );
}

export default Table;
