import { fetchPromotionsApi, fetchPromotionDetailsApi } from "../../api/promotion";

export const FETCH_PROMOTIONS_LOADING = "FETCH_PROMOTIONS_LOADING";
export const FETCH_PROMOTIONS_SUCCESS = "FETCH_PROMOTIONS_SUCCESS";
export const FETCH_PROMOTIONS_FAILURE = "FETCH_PROMOTIONS_FAILURE";

export const FETCH_PROMOTION_DETAILS_LOADING = "FETCH_PROMOTION_DETAILS_LOADING";
export const FETCH_PROMOTION_DETAILS_SUCCESS = "FETCH_PROMOTION_DETAILS_SUCCESS";
export const FETCH_PROMOTION_DETAILS_FAILURE = "FETCH_PROMOTION_DETAILS_FAILURE";

export const fetchPromotions = () => {
  return (dispatch) => {
    dispatch(fetchPromotionsLoading());
    return fetchPromotionsApi()
      .then((res) => {
        const data = res.data.data || [];
        dispatch(fetchPromotionsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchPromotionsFailure(error.message));
      });
  };
};

const fetchPromotionsLoading = () => {
  return {
    type: FETCH_PROMOTIONS_LOADING,
  };
};

const fetchPromotionsSuccess = (data) => {
  return {
    type: FETCH_PROMOTIONS_SUCCESS,
    payload: data,
  };
};

const fetchPromotionsFailure = (error) => {
  return {
    type: FETCH_PROMOTIONS_FAILURE,
    payload: error,
  };
};

export const fetchPromotionDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchPromotionDetailsLoading());
    return fetchPromotionDetailsApi(id)
      .then((res) => {
        const data = (res.data.data) || [];
        dispatch(fetchPromotionDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchPromotionDetailsFailure(error.message));
      });
  };
};

const fetchPromotionDetailsLoading = () => {
  return {
    type: FETCH_PROMOTION_DETAILS_LOADING,
  };
};

const fetchPromotionDetailsSuccess = (data) => {
  return {
    type: FETCH_PROMOTION_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchPromotionDetailsFailure = (error) => {
  return {
    type: FETCH_PROMOTION_DETAILS_FAILURE,
    payload: error,
  };
};
