import React from "react";
import "./style.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
);

function UserSummary({ summary }) {
  const monthDataActive = summary.countsActive || [];
  const monthData = summary.counts || [];

  // if(monthData)

  let monthRegistered = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
  };

  let monthUsersActive = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
  };

  for (var i = 0; i < monthDataActive.length; i++) {
    switch (monthDataActive[i].month) {
      case 1:
        monthUsersActive.Jan = monthDataActive[i].count;
        break;

      case 2:
        monthUsersActive.Feb = monthDataActive[i].count;
        break;

      case 3:
        monthUsersActive.Mar = monthDataActive[i].count;
        break;

      case 4:
        monthUsersActive.Apr = monthDataActive[i].count;
        break;

      case 5:
        monthUsersActive.May = monthDataActive[i].count;
        break;

      case 6:
        monthUsersActive.Jun = monthDataActive[i].count;
        break;

      case 7:
        monthUsersActive.Jul = monthDataActive[i].count;
        break;

      case 8:
        monthUsersActive.Aug = monthDataActive[i].count;
        break;

      case 9:
        monthUsersActive.Sep = monthDataActive[i].count;
        break;

      case 10:
        monthUsersActive.Oct = monthDataActive[i].count;
        break;

      case 11:
        monthUsersActive.Nov = monthDataActive[i].count;
        break;

      default:
        monthUsersActive.Dec = monthDataActive[i].count;
        break;
    }
  }

  for (var i = 0; i < monthData.length; i++) {
    switch (monthData[i].month) {
      case 1:
        monthRegistered.Jan = monthData[i].count;
        break;

      case 2:
        monthRegistered.Feb = monthData[i].count;
        break;

      case 3:
        monthRegistered.Mar = monthData[i].count;
        break;

      case 4:
        monthRegistered.Apr = monthData[i].count;
        break;

      case 5:
        monthRegistered.May = monthData[i].count;
        break;

      case 6:
        monthRegistered.Jun = monthData[i].count;
        break;

      case 7:
        monthRegistered.Jul = monthData[i].count;
        break;

      case 8:
        monthRegistered.Aug = monthData[i].count;
        break;

      case 9:
        monthRegistered.Sep = monthData[i].count;
        break;

      case 10:
        monthRegistered.Oct = monthData[i].count;
        break;

      case 11:
        monthRegistered.Nov = monthData[i].count;
        break;

      default:
        monthRegistered.Dec = monthData[i].count;
        break;
    }
  }

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#BCBCBC",
          font: {
            size: 14,
          },
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
        },
      },
    },
  };

  const data = {
    labels: ["Inactive", "Active", "Dormant", "Pending"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          summary.inactiveUsers,
          summary.activeUsers,
          summary.dormantUsers,
          summary.pendingUsers,
        ],
        backgroundColor: ["#E7E7E7", "#2BC38C", "#e03072", "#e69d52"],
        radius: 120,
        weight: 1,
        cutout: 120,
        borderWidth: 1,
        hoverOffset: 7,
      },
    ],
  };

  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "end",
        labels: {
          color: "#BCBCBC",
          font: {
            size: 13,
          },
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
        },
      },
      title: {
        display: false,
      },
    },
    elements: {
      bar: {
        borderRadius: 10,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
        grid: {
          display: false,
          borderColor: "transparent",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of users",
        },
        grid: {
          offset: false,
          borderDash: [1, 3],
          color: "#E0E6ED",
          borderColor: "transparent",
          tickColor: "transparent",
          categoryPercentage: 0.4,
        },
      },
    },
  };

  const dataLine = {
    labels,
    padding: 10,
    datasets: [
      {
        label: "Active",
        data: monthUsersActive,
        borderColor: "#2BC38C",
        backgroundColor: "#2BC38C",
        tension: 0.4,
        barThickness: 10,
        borderRadius: 10,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        offset: false,
      },
      {
        label: "Registered",
        data: monthRegistered,
        borderColor: "#E7E7E7",
        backgroundColor: "#E7E7E7",
        tension: 0.4,
        barThickness: 10,
        borderRadius: 10,
        barPercentage: 1.0,
        categoryPercentage: 0.4,
        offset: false,
      },
    ],
  };

  return (
    <>
      {summary.userSummary && (
        <div className='row'>
          <div className='col-md-12 my-4'>
            <div className='row'>
              <div className='col-12 col-lg-6 col-xl'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row align-items-center gx-0'>
                      <div className='col'>
                        <h6 className='text-capitalize text-muted mb-3'>
                          Registered users
                        </h6>

                        <span className='h2 mb-0 mr-4'>
                          <>{summary.userSummary.total}</>
                        </span>

                        <span
                          className={
                            summary.userSummary.performance == "down"
                              ? "badge bg-danger-soft mr-2 mt-n1"
                              : "badge bg-success-soft mr-2 mt-n1"
                          }>
                          {summary.userSummary.performance == "down"
                            ? "-"
                            : "+"}
                          {summary.userSummary.difference
                            ? summary.userSummary.difference.toFixed(2)
                            : "0"}
                          %
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-lg-6 col-xl'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row align-items-center gx-0'>
                      <div className='col'>
                        <h6 className='text-capitalize text-muted mb-3'>
                          Active Users
                        </h6>

                        <span className='h2 mb-0'>{summary.activeUsers}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-lg-6 col-xl'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row align-items-center gx-0'>
                      <div className='col'>
                        <h6 className='text-capitalize text-muted mb-3'>
                          Pending Users
                        </h6>

                        <span className='h2 mb-0'>{summary.pendingUsers}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-lg-6 col-xl'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row align-items-center gx-0'>
                      <div className='col'>
                        <h5 className='text-capitalize text-muted mb-3'>
                          Inactive Users
                        </h5>

                        <span className='h2 mb-0'>{summary.inactiveUsers}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-xl-8'>
                <div className='card card-fill-sm'>
                  <div className='card-header'>
                    <h4 className='card-header-title'>Users</h4>
                  </div>
                  <div className='card-body'>
                    <Bar
                      options={optionsLine}
                      data={dataLine}
                    />
                  </div>
                </div>
              </div>

              <div className='col-12 col-xl-4'>
                <div className='card card-fill-sm'>
                  <div className='card-header'>
                    <h4 className='card-header-title'>Users Activity</h4>
                  </div>
                  <div className='card-body relative'>
                    <Doughnut
                      data={data}
                      options={options}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserSummary;
