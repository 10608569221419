import React from "react";
// import { identifier } from "@babel/types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import DeletePromotion from "./DeletePromotion";

export const PROMOTIONCOLUMNS = [
  {
    header: "id",
    accessor: "id",
    Cell: (props) => <div>{props.row.index + 1}</div>,
  },
  {
    header: "Promotion Title",
    accessor: "title",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    header: "Description",
    accessor: "description",
    Cell: (props) => <div className="text-capitalize">{props.value} </div>,
  },
  {
    header: "Channel",
    accessor: "channel",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    header: "Segment",
    accessor: "segment",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    header: "Language",
    accessor: "language",
    Cell: (props) => <div className="text-capitalize">{props.value}</div>,
  },
  {
    header: "Created Date",
    accessor: "createdAt",
    Cell: (props) => <div>{formatDate(props.value)}</div>,
  },
  {
    header: "Status",
    accessor: "status",
    Cell: (props) => <div>{checkStatus("ACTIVE")}</div>,
  },
  {
    header: "action",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic"
          >
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            <Link
              to={{ pathname: `/promotions/view/${props.row.original.id}` }}
              state={{ promotions: props.row.original }}
              className="dropdown-item"
            >
              View
            </Link>

            <Link
              to={{ pathname: `/promotions/edit/${props.row.original.id}` }}
              state={{ promotions: props.row.original }}
              className="dropdown-item"
            >
              Edit
            </Link>

            <div className="dropdown-item text-danger">
              <DeletePromotion name={props.row.original}></DeletePromotion>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

function checkStatus(status) {
  switch (status) {
    case "ACTIVE":
      return (
        <div className="status">
          <div className="dot"></div>
          Active
        </div>
      );

    case "INACTIVE":
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Suspended
        </div>
      );

    default:
      return (
        <div className="status">
          <div className="dot inactive"></div>
          {status}
        </div>
      );
  }
}

const formatDate = (dateString) => {
  if (dateString) {
    const date = DateTime.fromISO(dateString);
    return date.toFormat("dd LLL yyyy");
  } else {
    return dateString;
  }
};