import React from 'react';



export const TRANSACTIONCOLUMNS = [
  {
    header: 'S/NO',
    accessor: 'id',
    Cell: props => <div> { (props.row.index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }</div>
  }, 
  {
    header: 'Payment method',
    accessor: 'title',
    Cell: props => <div className="capitalize">{ props.value}</div>
  },
  {
    header: 'amount',
    accessor: 'publisher.companyName',
    Cell: props => <div className="capitalize">{ props.value}</div>
  },
  {
    header: 'transaction id',
    accessor: 'categoryId',
    Cell: props => <div className="capitalize">{ checkCategory(props.value) }</div>
  },
  {
    header: 'transaction date',
    accessor: 'price',
    Cell: props => <div>{ new Intl.NumberFormat().format(props.value) }</div>
  },
  {
    header: 'platform',
    accessor: 'platform'
  },
  {
    header: 'Activity',
    accessor: 'Activity',
    Cell: props => <div>{ checkStatus(props.value) }</div>
  },
 
]




function checkStatus(status) {
  switch (status) {
    case "ACTIVE":
      return <div className="status">
              <div className="dot"></div>
                Active
            </div>  
  
    default:
      return <div className="status">
              <div className="dot inactive"></div>
                Inactive
            </div> 
  }

}


function checkCategory(categoryId) {
  switch (categoryId) {
    case 1:
      return <div className="text-capitalize">
                NewsPaper
            </div>
            
    case 2:
      return <div className="text-capitalize">
                Magazine
            </div>
  
    default:
      return <div className="text-capitalize">
                Book
            </div> 
  }

}


