import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import Dropzone from 'react-dropzone';
import DashboardWrapper from '../../../components/DashboardWrapper';
import SettingsNav from './SettingsNav';
import { fetchGenres } from '../../../redux-store/Actions/genre-action';
import { createGenreApi } from '../../../api/publisher';

const schema = yup
  .object({
    name: yup.string().required().min(3).max(255).label('Please provide name'),
  })
  .required();

function GenreCreate() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [coverFile, setCoverFile] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  function readFile(file) {
    console.log(file);
    if (file[0]) {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reader aborted');

      reader.onerror = () => console.log('file reader failed');

      reader.onload = () => {
        const dataUrl = reader.result;
        console.log(dataUrl);
        setCoverFile({ url: dataUrl });
      };

      // Read the file as a data url
      reader.readAsDataURL(file[0]);
    }
  }

  const onDropCover = useCallback((uploadedFile) => {
    readFile(uploadedFile);
  }, []);

  const handleInputChange = (e) => {
    e.preventDefault();
    let val = e.target.value.trim();
    let id = e.target.id;
    if (id == 'name' && val.length > 3) setName(val);
    if (id == 'description' && val.length > 3) setDescription(val);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setLoading(true);
    console.log(data);
    data.cover = coverFile.url;
    createGenreApi(data)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        handleClose();

        dispatch(fetchGenres());

        var path = '/settings/genres';
        navigate(path);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const toPage = (path) => {
    navigate(path);
  };
  return (
    <DashboardWrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12'>
            <div className='header mt-md-3 mb-5'>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={() => {
                        toPage('/dashboard');
                      }}>
                      Dashboard
                    </a>
                  </li>

                  <li
                    className='breadcrumb-item active'
                    aria-current='page'>
                    Settings
                  </li>
                </ol>
              </nav>

              <div className='header-body'>
                <h6 className='header-pretitle'>Overview</h6>

                <h1 className='header-title'>Genres</h1>
              </div>
            </div>

            <div className='content'>
              <SettingsNav></SettingsNav>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label
                      htmlFor='name'
                      className='form-label'>
                      Genre Name
                    </label>
                    <input
                      type='text'
                      className={
                        errors.name ? 'form-control is-invalid' : 'form-control'
                      }
                      id='name'
                      placeholder='Enter a name'
                      autoFocus
                      onChange={handleInputChange}
                      required
                      {...register('name')}
                    />

                    <p className='errors'>{errors.name?.message}</p>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='form-group'>
                    <label
                      htmlFor='description'
                      className='form-label'>
                      Description
                    </label>
                    <input
                      type='text'
                      className={'form-control'}
                      id='description'
                      placeholder='Enter description'
                      autoFocus
                      onChange={handleInputChange}
                      required
                      {...register('description')}
                    />

                    <p className='errors'>{errors.description?.message}</p>
                  </div>
                </div>

                <div className='col-md-6'>
                  <Dropzone
                    onDrop={onDropCover}
                    multiple={false}
                    accept='.png'>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div className='mb-1'>
                          <label className='form-label'>
                            Upload Cover Photo{' '}
                          </label>{' '}
                          <br />
                          <span className='form-label fl-sm text-muted text-sm'>
                            This picture will be viewed by readers and followers
                          </span>
                        </div>
                        <div
                          {...getRootProps({
                            className: 'dropzone dropzone-single mb-3',
                          })}>
                          <input {...getInputProps()} />
                          <div className='upload-content p-4'>
                            <i className='fe fe-upload-cloud'></i>
                            <p>Drag and drop</p>
                            <p className='btn btn-sm btn-outline-primary'>
                              Browse from your files
                            </p>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {coverFile ? (
                  <div className='col-md-6'>
                    <div className='mb-1'>
                      <label className='form-label'>Preview </label> <br />
                      <span className='form-label fl-sm text-muted text-sm'>
                        This is how the image will appear in the app
                      </span>
                    </div>
                    <img
                      className='cover-image'
                      src={coverFile.url}
                      alt='cover'
                    />
                  </div>
                ) : null}
              </div>

              <div className='row my-4'>
                <div className='col'></div>
                <div className='col-auto'>
                  {loading ? (
                    <button className='btn btn-primary'>
                      <Loader
                        type='TailSpin'
                        color='#ffffff'
                        height={20}
                        width={20}
                      />
                    </button>
                  ) : (
                    <button
                      className='btn btn-primary'
                      type='submit'
                      onClick={handleSubmit(onSubmit)}>
                      Create Genre
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default GenreCreate;
