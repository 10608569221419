import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchReaders } from "../../../../redux-store/Actions/reader-action";
import { READERCOLUMNS } from "./ReaderColumns";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import UserNavs from "../UserNavs";
import Table from "../../../../components/Table/Table";

function Readers() {
  const columns = useMemo(() => READERCOLUMNS, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { readers } = useSelector((state) => state.readers);
  const data = readers;

  useEffect(() => {
    dispatch(fetchReaders(page));
  }, [dispatch, page]);

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  const handlePageChange = (newPage) => {
    if (newPage && newPage < 1) newPage = 1;
    setPage(newPage);
    dispatch(fetchReaders(newPage));
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Users
                  </li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Users</h1>
              </div>
            </div>

            <div className="content">
              <UserNavs />

              <Table
                name="Users"
                title="Registered Users"
                columns={columns}
                data={data?.data || []}
                raw={data}
                onPageChange={handlePageChange}
                path=""
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default Readers;
