import React, { useMemo, useEffect, useState } from "react";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Navs from "../navs";
import Table from "../../../../components/Table/Table";
import { ISSUECOLUMNS } from "./IssueColumns";
import { fetchAllIssuesApi } from "../../../../api/publisher";

export default function Issues() {
  const columns = useMemo(() => ISSUECOLUMNS, []);
  const [issues, setIssues] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllIssuesApi().then((issues) => {
      console.log("issues: ", issues);
      setIssues(issues.data.data);
    });
  }, [dispatch]);

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Publishers
                  </li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Issues</h1>
              </div>
            </div>

            <div className="content">
              <Navs></Navs>

              <Table
                name="Issues"
                title="Registered Issues"
                columns={columns}
                data={issues}
                path="/publishers"
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
