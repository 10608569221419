import React, {useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from '../../../../components/DashboardWrapper'
import Table from '../../../../components/Table/Table';
import UserNavs from '../UserNavs';
import { fetchAdmins } from '../../../../redux-store/Actions/admin-action';

import { ADMINCOLUMNS } from "./AdminColumns";


function Admins() {

  const columns = useMemo(() => ADMINCOLUMNS, [])
  const dispatch = useDispatch();

  const { admins } = useSelector(
    (state) => state.admins
  );  

  const data = admins


  const navigate = useNavigate();
  
  function toDashboard(e) {
    e.preventDefault()
    navigate("/dashboard");
  }

  useEffect(() => {
    dispatch(fetchAdmins());
  }, [dispatch]);



  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">

              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard} >Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Users</li>
                </ol>
              </nav>

              <div className="header-body">


                <h6 className="header-pretitle">
                  Overview
                </h6>

                
                <h1 className="header-title">
                  Users
                </h1>

              </div>

            </div>


            <div className="content">
              <UserNavs />

              <Table 
                name="Users"
                title="Registered Admins"
                columns={columns}
                data={data}
                path=""
              />
              
            </div>
          </div>
        </div>
      </div>

    </DashboardWrapper>
  )
}

export default Admins
