import React, { useMemo, useEffect, useState } from "react";
import moment from "moment";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import { AnimatePresence, motion } from "framer-motion";

import { REPORTCOLUMNS } from "./ReportColumns";

import EmptyData from "../../../components/EmptyData/Index";
import CorporateFilter from "./CorporateFilter";
import {
  fetchDeliveryReports,
  fetchDeliveryReportsByDate,
} from "../../../api/reports";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import CustomDatePicker from "./CustomDatePicker";
import {fetchCorporatesApi} from "../../../api/corporate";
import Loader from 'react-loader-spinner';


const ReportTable = () => {
  const columns = useMemo(() => REPORTCOLUMNS, []);

  const [ data, setData ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ companies, setCompanies ] = useState([])
  const [ start, setStart ] = useState((new Date()).toISOString());
  const [ end, setEnd ] = useState((new Date()).toISOString())
  const [ selectedCorporate, setSelectedCorporate ] = useState(11)
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0},
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,

    // Get the state from the instance
    state: {pageIndex, pageSize, globalFilter},
  } = tableInstance;
  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    let exportData = data.map((d) => {
      let r = JSON.parse(JSON.stringify(d));
      
      const item = {
        deliveryTimestamp:r.deliveryTimestamp,paperTitle:r.paperTitle,companyName:r.companyName,staffName:r.staffName,delivery:r.delivery,read:r.read,subscription_group:r.subscription_group
      }
      return item;
    });
    let ws = XLSX.utils.json_to_sheet(exportData);
    let wb = {Sheets: {data: ws}, SheetNames: [ "data" ]};
    let buffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
    let content = new Blob([ buffer ], {type: fileType});
    let filename = `Rifaly Corporate Reading ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };
  const handleSelectCorporate = (e) => {
    setSelectedCorporate(e.target.value)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchDate = async () => {
      const response = await fetchCorporatesApi();
      const corporate = response.data.data[ 0 ];
      setCompanies(response.data.data)
      setSelectedCorporate(corporate.id);
      fetchDeliveryReports(user.token, {startDate: start, endDate: end, id: corporate.id}).then((result) => {
        let data = result.data.data;
        setData(data);
      });

        }
    fetchDate()

  }, []);

  const spring = useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
      rotate: 360,
    }),
    [],
  );

  const handleSearch = () => {
    setLoading(true);
    const startDate = start ? start : new Date();
    const endDate = end ? end : new Date();
    let options = {startDate: startDate, endDate: endDate, id: selectedCorporate};
    fetchDeliveryReports(user.token, options)
        .then((result) => {
          let data =result.data.data;
          setData(data);
          setLoading(false);
        })
        .catch((e) => {
          console.log("errror: ", e);
          setLoading(false);
        });
  }
   

  const handleDateChange = (e) => {
    const value = e.target.value.trim();
    if (e.target.id === 'start') setStart(value);
    else setEnd(value)
  };

  return (
    <div>
      <div className='row align-items-start my-4'>
        <div className='col-md-3'>
          <select className="form-control" id='corporate' onChange={handleSelectCorporate} value={selectedCorporate}>
            <option disabled>Select Company</option>
            {companies.map(company => {
              return <option key={company.id} value={company.id}>{company.companyName}</option>
            })}
          </select>
        </div>
        <div className='col-md-3'>
          <input type="date" className="form-control" placeholder="Select Start Date" defaultValue={start} onChange={handleDateChange} id='start' />

          </div>
        <div className='col-md-3'>
          <input type="date" className="form-control" placeholder="Select End Date" defaultValue={start} onChange={handleDateChange} id='end' />

            </div>
        <div className='col-md-3'>
                <button
                  className='btn btn-primary'
                  onClick={handleSearch}>
                  <span className='fe fe-search'></span>&nbsp;Search
                </button>
              </div>

      </div>

      <div className='row mt-2 body-content'>
      {loading ?  (
          <div className="col-md-12 text-center">
            <Loader type="TailSpin" color="#00B17A" height={50} width={50} />
            <p>Loading data...</p>
          </div>
        ) 
      : (
        data && data.length >= 1 ? (
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h4 className='card-header-title'>
                      Corporate Usage Report
                    </h4>

                    <div>
                      <button
                        className='btn btn-outline-primary btn-sm'
                        onClick={handleExport}>
                        Export
                      </button>
                    </div>
                  </div>

                  <table
                    {...getTableProps()}
                    className='table table-sm card-table'>
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                              )}>
                              {column.render("header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      <AnimatePresence>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps({
                                layouttransition: spring,
                                exit: {opacity: 0, maxHeight: 0},
                              })}>
                              {row.cells.map((cell) => {
                                return (
                                  <motion.td
                                    {...cell.getCellProps({
                                      layouttransition: spring,
                                      exit: {opacity: 0.5, maxHeight: 0},
                                    })}>
                                    {cell.render("Cell")}
                                  </motion.td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </AnimatePresence>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='row mb-5'>
              <div className='col'></div>
              <div className='col-auto'>
                <div>
                  <span className='table-stat'>
                    Showing {pageIndex === 0 ? 1 : pageIndex * pageSize + 1} to{" "}
                    {pageIndex > 10
                      ? data.length
                      : pageIndex * pageSize + pageSize > data.length
                      ? data.length
                      : pageIndex * pageSize + pageSize}{" "}
                    of {data.length} elements
                  </span>
                  <button
                    className='btn btn-round btn-sm btn-pagination'
                    onClick={() => previousPage()}>
                    <i className='fe fe-chevron-left'></i>
                  </button>
                  <span className='table-stat'>Page {pageIndex + 1}</span>
                  <button
                    className='btn btn-round btn-sm btn-pagination'
                    onClick={() => nextPage()}>
                    <i className='fe fe-chevron-right'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData
            name='Usage Reports'
            path=''
          />
        )
      )}
      </div>
    </div>
  );
};

export default ReportTable;
