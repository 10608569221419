import React from "react";
import { NavLink } from "react-router-dom";

export default function Navs() {
  return (
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <NavLink to="/podcasters/overview" className="nav-link">
          Overview
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink to="/podcasters/channels" className="nav-link">
          Channels
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink to="/podcasters/stories" className="nav-link">
          Stories
        </NavLink>
      </li>
    </ul>
  );
}
