import React from 'react';
// import { identifier } from "@babel/types";
import { Dropdown} from "react-bootstrap";
import { Link } from 'react-router-dom';
import DeleteUser from '../DeleteUser';
import UpdateUserStatus from '../UpdateUserStatus';
import UserEdit from "../UserEdit";



export const READERCOLUMNS = [
  {
    header: 'id',
    accessor: 'id',
    Cell: props => <div>{ props.row.index + 1}</div>
  }, 
  {
    header: 'first name',
    accessor: 'firstName',
    Cell: props => <div className="text-capitalize">{ props.value }</div>
  },
  {
    header: 'last name',
    accessor: 'lastName',
    Cell: props => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'email',
    accessor: 'email',
  },

  {
    header: 'mobile',
    accessor: 'phone'
  },
  {
    header: 'role',
    accessor: 'role',
    Cell: props => <div className="text-capitalize">Reader</div>
  },
  {
    header: 'status',
    accessor: 'status',
    Cell: props => <div>{ checkStatus(props.value) }</div>
  },
  {
    header: 'action',
    accessor: 'action',
    Cell: props => <div>
      <Dropdown>
       
        <Dropdown.Toggle as="a" className="dropdown-ellipses icon-dropdown" id="dropdown-basic">
        <i className="fe fe-more-horizontal"></i>
        </Dropdown.Toggle>

       




        <Dropdown.Menu className="dropdown-menu-end">

          <Link to={`/users/readers/${props.row.original.id}`} className="dropdown-item">
              View
          </Link>


          <Dropdown.Item>
            <UserEdit placement="end" data={props.row.original} />
          </Dropdown.Item>

          <div className={ (props.row.original.status) === "ACTIVE" ? "dropdown-item disabled hidden" : "dropdown-item" }>
            <UpdateUserStatus data={props.row.original} type="3"></UpdateUserStatus>
          </div>

          <div className={ (props.row.original.status) !== "ACTIVE" ? "dropdown-item disabled hidden" : "dropdown-item" }>
            <UpdateUserStatus data={props.row.original} type="3"></UpdateUserStatus>
          </div>


          <div className='dropdown-item text-danger'>
              <DeleteUser data={props.row.original}></DeleteUser>
          </div>


        </Dropdown.Menu>
      </Dropdown>
    </div>
  }
 
]




function checkStatus(status) {
  switch (status.toUpperCase()) {
    case "ACTIVE":
      return <div className="status">
              <div className="dot"></div>
        <span>{status.toUpperCase()}</span>
            </div>  
  
  
    default:
      return <div className="status">
              <div className="dot inactive"></div>
                <span>{status.toUpperCase()}</span>
            </div> 
    
  }

}


