import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaignDetails } from "../../../../redux-store/Actions/campaign-action";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import Loader from "react-loader-spinner";
import api_config from "../../../../api/api_config";
import Chart from "chart.js/auto";
import { NavLink } from "react-router-dom";

function CampaignAnalytics(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [changeVisibility, setChangeVisibility] = useState(false);
  let { campaignId } = useParams();
  const [isLoading] = useState();
  // const { Campaign, isLoading } = useSelector(
  //   (state) => state.Campaigns
  // );
  // const { Campaigns, isLoading } = useSelector((state) => state.Campaigns);
  const campaignsData =
    location.state && location.state.campaigns ? location.state.campaigns : [];
  const [campaign, setCampaign] = useState(campaignsData);

  //   useEffect(() => {
  //     dispatch(fetchCampaignDetails(campaignId));
  //   }, [campaignId, dispatch]);

  const chartRefCodes = useRef(null);
  const chartRefRevenue = useRef(null);

  useEffect(() => {
    // Fetch data for number of codes
    fetch("https://api.example.com/promo-code-usage")
      .then((response) => response.json())
      .then((data) => {
        const days = data.map((entry) => entry.day);
        const codes = data.map((entry) => entry.numberOfCodes);

        // Create a line chart for number of codes
        const ctxCodes = chartRefCodes.current.getContext("2d");
        new Chart(ctxCodes, {
          type: "line",
          data: {
            labels: days,
            datasets: [
              {
                label: "Number of Codes",
                data: codes,
                borderColor: "rgb(75, 192, 192)",
                tension: 0.1,
              },
            ],
          },
          options: {
            scales: {
              x: {
                type: "category",
                labels: days,
              },
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching number of codes data:", error);
      });

    // Fetch data for campaign revenue
    fetch("https://api.example.com/campaign-revenue")
      .then((response) => response.json())
      .then((data) => {
        const days = data.map((entry) => entry.day);
        const revenue = data.map((entry) => entry.amount);

        // Create a line chart for campaign revenue
        const ctxRevenue = chartRefRevenue.current.getContext("2d");
        new Chart(ctxRevenue, {
          type: "line",
          data: {
            labels: days,
            datasets: [
              {
                label: "Campaign Revenue",
                data: revenue,
                borderColor: "rgb(255, 99, 132)",
                tension: 0.1,
              },
            ],
          },
          options: {
            scales: {
              x: {
                type: "category",
                labels: days,
              },
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching campaign revenue data:", error);
      });
  }, []);

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/Campaigns" onClick={toPage("/Campaigns")}>
                      Campaigns
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Analytics
                  </li>
                </ol>
              </nav>
            </div>

            <div className="header-body">
              <h6 className="header-pretitle">Overview</h6>

              <h1 className="header-title">Campaigns</h1>
            </div>

            {/* end of header */}

            <div className="content">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <NavLink to="/campaigns" className="nav-link">
                    Campaigns
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink to="/promocodes" className="nav-link">
                    Promotion code
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink to="/promotions" className="nav-link">
                    Promotions
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink to="/analytics" className="nav-link active">
                    Analytics
                  </NavLink>
                </li>
              </ul>

              <div className="row">
                <div className="col-md-12 my-4">
                  {isLoading ? (
                    <div className="loader-holder d-flex justify-content-center align-items-center">
                      <Loader
                        type="BallTriangle"
                        heigth="100"
                        width="100"
                        color="green"
                        ariaLabel="loading-indicator"
                      />
                    </div>
                  ) : (
                    <div>
                      {campaign && (
                        <>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="card-header-title">
                                    Promo code usage
                                  </h4>
                                </div>
                                <div className="card-body">
                                  <canvas
                                    ref={chartRefCodes}
                                    width="400"
                                    height="200"
                                  ></canvas>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="card">
                                <div className="card-header">
                                  <h4 className="card-header-title">
                                    Campaign Revenue
                                  </h4>
                                </div>
                                <div className="card-body">
                                  <canvas
                                    ref={chartRefRevenue}
                                    width="400"
                                    height="200"
                                  ></canvas>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CampaignAnalytics;
