import {
  FETCH_CHANNELS_SUCCESS,
  FETCH_CHANNELS_LOADING,
  FETCH_CHANNEL_DETAILS_SUCCESS,
  FETCH_CHANNEL_DETAILS_LOADING,
} from '../Actions/channel-action';

const channelInitialState = {
  channel: {},
  isLoading: false,
};

export function channelDetailsReducer(state = channelInitialState, action) {
  switch (action.type) {
    case FETCH_CHANNEL_DETAILS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CHANNEL_DETAILS_SUCCESS:
      return {
        ...state,
        channel: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

const initialState = {
  data: [],
  isLoading: false,
};

export default function channelReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CHANNELS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CHANNELS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
