import {
    FETCH_PROMOCODES_LOADING,
    FETCH_PROMOCODES_SUCCESS,
    FETCH_PROMOCODES_FAILURE,
    FETCH_PROMOCODE_DETAILS_LOADING,
    FETCH_PROMOCODE_DETAILS_SUCCESS,
    FETCH_PROMOCODE_DETAILS_FAILURE
  } from '../Actions/promo-code-action';
  
  const initialState = {
    promocodes: [],
    isLoading: false,
    error: null,
  };
  
  export default function PromocodeReducer(state = initialState, action) {
    switch (action.type) {
      case FETCH_PROMOCODES_LOADING: {
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      }
      case FETCH_PROMOCODES_SUCCESS: {
        return {
          ...state,
          promocodes: action.payload,
          isLoading: false,
          error: null,
        };
      }
      case FETCH_PROMOCODES_FAILURE: {
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
      }
      case FETCH_PROMOCODE_DETAILS_LOADING:
        return { ...state, isLoading: true };
      case FETCH_PROMOCODE_DETAILS_SUCCESS:
        return { ...state, promocodes: action.payload, isLoading: false };
      case FETCH_PROMOCODE_DETAILS_FAILURE:
        return { ...state, error: action.payload, isLoading: false };
      default:
        return state;
    }
  }
  