import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { createDormantEmailApi } from "../../../api/email";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

function DormantUserReactivation({ emailType }) {
  const { register, handleSubmit } = useFormContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(new Date());

  const filterPassedDate = (date) => {
    const currentDate = new Date();
    return date >= currentDate;
  };
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const emailData = {
      cta: data.CTA,
      subject: data.title,
      message: data.message,
      sendDate: format(scheduledDate, "yyyy-MM-dd HH:mm:ss"),
    };
    try {
      const res = await createDormantEmailApi({ ...emailData });
      if (res.status === 201) {
        toast.success(res.data.message);
        navigate("/emails");
      } else {
        toast.error(res.data.error);
      }
    } catch (error) {
      toast.error("Failed to create email");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelForm = (e) => {
    e.preventDefault();
    navigate("/emails");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row g-3 mb-3">
        <div className="col-12 col-md-12 mb-1">
          <label htmlFor="title" className="form-label">
            Email Heading
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            placeholder="Enter Email Heading"
            {...register("title")}
            required
          />
        </div>
        <div className="col-12 col-md-12 mb-1">
          <label htmlFor="message" className="form-label">
            Message
          </label>
          <textarea
            className="form-control"
            id="message"
            placeholder="Enter Message"
            {...register("message")}
            required
          />
        </div>
        <div className="col-12 col-md-12 mb-1">
          <label htmlFor="CTA" className="form-label">
            CTA
          </label>
          <input
            type="text"
            className="form-control"
            id="CTA"
            placeholder="Enter CTA"
            {...register("CTA")}
            required
          />
        </div>
        <div className="col-12 col-md-12 mb-1">
          <label htmlFor="sendDate" className="form-label">
            Date and Time of Release
          </label>
          <div className="mb-2">
            <DatePicker
              isClearable={true}
              selected={scheduledDate}
              dateFormat={"yyyy-MM-dd HH:mm:ss"}
              timeFormat={'HH:mm:ss'}
              minDate={new Date()}
              showTimeSelect
              timeIntervals={15}
              onChange={(date) => setScheduledDate(date)}
              filterDate={filterPassedDate}
              filterTime={filterPassedTime}
              customInput={
                <input
                  type="text"
                  className="form-control"
                  value={scheduledDate ? format(scheduledDate, "yyyy-MM-dd HH:mm:ss") : ""}
                  readOnly
                />
              }
              required
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col"></div>
        <div className="col-auto">
          <div className="d-flex justify-content-between">
            {!loading && (
              <button
                onClick={handleCancelForm}
                className="btn btn-outline-secondary mx-2"
              >
                Cancel
              </button>
            )}
            {loading ? (
              <button className="btn btn-primary">
                <Loader
                  type="TailSpin"
                  color="#ffffff"
                  height={20}
                  width={20}
                />
              </button>
            ) : (
              <button className="btn btn-primary" type="submit">
                Publish
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

export default DormantUserReactivation;
