import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function CorporateFilter(
  {preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchTitle,
}) {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const [title, setTitle] = useState(searchTitle);
  
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || "");
  }, 300);
 
  return (
      <div className="col-md-12 p-0">
        <div className="input-group input-group-merge input-group-reverse">
          <input
            type="text"
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            className="form-control"
            placeholder={title}
          />
          <div className="input-group-text" id="inputGroupReverse">
            <span className="fe fe-search"></span>
          </div>
        </div>
      </div>

    
  );
}

export default CorporateFilter;


