import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import DeleteCompany from "./DeleteCompany";
import UpdateCompany from "./UpdateCompany";

export const COLUMNS = [
  {
    header: "id",
    accessor: "id",
    Cell: (props) => <div>{props.row.index + 1}</div>,
  },
  {
    header: "company name",
    accessor: "companyName",
    Cell: (props) => (
      <div className="capitalize">
        {" "}
        <Link to={"/publishers/company/" + props.row.original.id}>
          {props.value}
        </Link>{" "}
      </div>
    ),
  },
  {
    header: "company size",
    accessor: "companySize",
    Cell: (props) => <div> {checkSize(props.value)} </div>,
  },
  {
    header: "commission",
    accessor: "commission",
    Cell: (props) => <div> {props.value}% </div>,
  },
  {
    header: "city",
    accessor: "city",
  },
  {
    header: "Country",
    accessor: "country",
  },
  {
    header: "status",
    accessor: "status",
    Cell: (props) => <div> {checkStatus(props.value)} </div>,
  },
  {
    header: "action",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic"
          >
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            <Link
              to={`/publishers/company/${props.row.original.id}`}
              className="dropdown-item"
            >
              View
            </Link>

            <Link
              to={{ pathname: `/publishers/company/edit/${props.row.original.id}`}} state={{data:props.row.original }}
              className="dropdown-item"
            > 
              Edit
            </Link>

            <div
              className={
                props.row.original.status === "ACTIVE"
                  ? "dropdown-item disabled hidden"
                  : "dropdown-item"
              }
            >
              <UpdateCompany name={props.row.original}></UpdateCompany>
            </div>

            <div
              className={
                props.row.original.status !== "ACTIVE"
                  ? "dropdown-item disabled hidden"
                  : "dropdown-item"
              }
            >
              <UpdateCompany name={props.row.original}></UpdateCompany>
            </div>

            <div className="dropdown-item text-danger">
              <DeleteCompany name={props.row.original}></DeleteCompany>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

function checkStatus(status) {
  switch (status) {
    case "ACTIVE":
      return (
        <div className="status">
          <div className="dot"></div>
          Active
        </div>
      );

    case "INACTIVE":
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Suspended
        </div>
      );

      case "DELETED":
      return (
        <div className="status">
          <div className="dot deleted"></div>
          Deleted
        </div>
      );

    default:
      return (
        <div className="status">
          <div className="dot inactive"></div>
          {status}
        </div>
      );
  }
}

function checkSize(size) {
  switch (size) {
    case 1:
      return <div className="status">1 - 10</div>;

    case 2:
      return <div className="status">20 - 50</div>;

    case 3:
      return <div className="status">50 - Above</div>;

    default:
      return <div className="status">50 - Above</div>;
  }
}
