import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import {useDropzone} from 'react-dropzone';
import Dropzone from 'react-dropzone';
import Papa from "papaparse";
import Swal from "sweetalert2";
import {
  createStaffsApi,
  createStaffsApiBulk,
  fetchCorporatesApi,
  fetchCorporateSubscriptionGroupsApi,
} from "../../../api/corporate";
import DashboardWrapper from "../../../components/DashboardWrapper";

function CreateCorporateStaff() {
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const [corporates, setCorporates] = useState([]);
  const [subs, setSubs] = useState();
  const [selectedSubs, setSelectedSubs] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [formData, setformData] = useState({});
  const [loading, setLoading] = useState(false);
  const [bulkStaffAdd, setBulkStaffAdd] = useState({});
  const [createCorporateStaffBulk, setCreateCorporateStaffBulk] = useState(
    [],
  );

  var navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    let data = formData;
    let val = e.target.value.trim();
    switch (e.target.id) {
      case "firstName":
        data.firstName = val;
        break;
      case "lastName":
        data.lastName = val;
        break;
      case "email":
        data.email = val;
        break;
      case "phone":
        data.phone = val;
        break;
      case "role":
        data.role = val;
        break;
      default:
        break;
    }  
    setformData(data);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let form = e.target;
    setLoading(true);
    var data = formData;
    let options = { Authorization: `Bearer ${user.token}` };
    createStaffsApi(data, options)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(res.data);
        var path = "/corporates/staffs";
        history(path);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error(error.response.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    console.log("sub: ", data);
  };

  const history = useNavigate();

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      history(path);
    };
  }

  const [parsedCsvData, setParsedCsvData] = useState([]);

  const handleSubmitCSV = (e) => {
    e.preventDefault();
    let uploadCSV = document.getElementById("csvFileInput");
    uploadCSV.click();
    uploadCSV.addEventListener("change", (e) => {
      Papa.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          setParsedCsvData(results.data);
          console.log(results.data);
          if (results.data.length > 0) {
            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            swalWithBootstrapButtons
              .fire({
                title:
                  "Upload this file? with" +
                  " " +
                  results.data.length +
                  " " +
                  "data",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, upload file!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  handleSubmission(results.data);
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                ) {
                  swalWithBootstrapButtons.fire(
                    "Cancelled",
                    "Your File Upload is Cancelled :)",
                    "error",
                  );
                }
              });
          } else {
            toast.error("csv failed to upload", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        },
      });
    });
  };
  useEffect(() => {
    fetchCorporatesApi().then((result) => {
      setCreateCorporateStaffBulk(
        result.data.data
      );
    });
  }, []);
  const handleSubmission = (data) => {
    let accessToken = { Authorization: `Bearer ${user.token}` };
    // const accessToken = cookies.get("access-token");
    console.log("data checking values",data);
    console.log("user checking values from localstorage",user);

    console.log(createCorporateStaffBulk);
    data = data
      .map((d) => {
        console.log("🚀 ~ file: CreateCorporateStaff.js:209 ~ .map ~ d", d)
        // d.companyId = user.get("company_data").id;
        // console.log("🚀 ~ file: CreateCorporateStaff.js:211 ~ .map ~ d.companyId", d.companyId);
        let company = createCorporateStaffBulk.find(
          (g) => {
            console.log("🚀 ~ file: CreateCorporateStaff.js:214 ~ .map ~ g", g);
            return g.companyName.toLowerCase() === d.company.toLowerCase()
          }
        );
        console.log("🚀 ~ file: CreateCorporateStaff.js:218 ~ .map ~ company", company)

        if(company){
          let sg = company.subscription_groups.find(g=>g.name.toLowerCase() === d.subscriptionGroup.toLowerCase());
          d.subscriptionGroupId = sg ? sg.id : null;
          d.companyId = company.id;

          return d;
        }
        // let sg = company.find(
        //   (g) => {
        //     console.log("🚀 ~ file: CreateCorporateStaff.js:223 ~ .map ~ g", g);
        //     return g.subscription_groups.name.toLowerCase() === d.subscriptionGroup.toLowerCase()
        //   }
        // );
        // console.log("🚀 ~ file: CreateCorporateStaff.js:227 ~ .map ~ sg", sg)
        
       
      })
      .filter((d) => (d.subscriptionGroupId != null && d.companyId != null));
    // var body = { data: data };
   
    // createStaffsApiBulk()
    console.log("🚀 ~ file: CreateCorporateStaff.js:236 ~ handleSubmission ~ body", data)
    // fetch(`${createStaffsApiBulk}`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    //   data: JSON.stringify(data),
    // }) 
    
    let headers = { "Content-Type": "application/json", Authorization: `Bearer ${user.token}` };
    createStaffsApiBulk({data:data}, headers)
      // .then((response) => response.json())
      .then((response) => {
        console.log(
          "🚀 ~ file: CreateCorporateStaff.js:248 ~ .then ~ response",
          response,
        );

        if (response.status === 201) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons.fire(
            "Uploaded!",
            "Your file has been uploaded.",
            "success",
          );
          navigate("/staff", { replace: true });
        } else {
          console.log(response.message);
          toast.error(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((e) => {
        console.log("🚀 ~ file: CreateCorporateStaff.js:280 ~ handleSubmission ~ e", e);

        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };


    // submit data
    const onSubmit = (data, e) => {
      setLoading(true)
      data.bulkStaffAdd = bulkStaffAdd;

      createStaffsApi(data).then( res => {
        setLoading(false)
        console.log(data);
        e.target.reset();

        toast.success("Staff Created successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      }).catch(error => {
        setLoading(false)
        console.log(error)

        if (error.response) {
          // setApiErrors(error.response.data.error)
          // client received an error response (5xx, 4xx)

          if(error.response.status === 500){
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          }

          if (error.response.status === 422){
            const respErrors = error.response.data.errors;

            respErrors.forEach((object, index) => {
              var values = Object.keys(object).pop();
              console.log(`${values}:  ${object[values]}`)

            toast.error(`${values}:  ${object[values]}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });  

            });
           
          }

          if (error.response.status === 303){
            toast.error(error.response.data.error.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          }

        }  else {

          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }  

      });

      console.log(data)
    };


  const handleCompanyChange = (e) => {
    let id = e.target.value;
    let company = corporates.find((c) => c.id == id);
    setSelectedCompany(company);
    if (company) {
      let d = formData;
      d.companyId = company.id;
      setSelectedSubs(company.subscription_groups);
      if (company.subscription_groups.length > 0) {
        d.subscriptionGroup = company.subscription_groups[0].id;

        setSubs(company.subscription_groups[0].id);
      }

      setformData(d);
    } else setSelectedSubs([]);
  };
  const handleSubscriptionGroupChange = (e) => {
    e.preventDefault();
    setSubs(e.target.value);
    let d = formData;
    d.subscriptionGroup = e.target.value;
    setformData(d);
  };
  useEffect(() => {
    fetchCorporatesApi().then((result) => {
      setCorporates(
        result.data.data.filter(
          (d) => d.status ?? d.status.toUpperCase() == "ACTIVE",
        ),
      );
    });
  }, []);

  return (
    <DashboardWrapper>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-10 col-xl-12'>
            <div className='header mt-md-3'>
              <nav aria-label='breadcrumb mb-2'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a
                      href='/dashboard'
                      onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className='breadcrumb-item'>
                    <a
                      href='/corporates'
                      onClick={toPage("/corporates/companies")}>
                      Corporates
                    </a>
                  </li>
                  <li className='breadcrumb-item'>
                    <a
                      href='/corporates/staffs'
                      onClick={toPage("/corporates/staffs")}>
                      Staffs
                    </a>
                  </li>
                  <li
                    className='breadcrumb-item active'
                    aria-current='page'>
                    Create Corporate Staff
                  </li>
                </ol>
              </nav>
            </div>

            <div className='content'>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <div className='card pt-2 mt-2'>
                    <div className='px-4 pt-4'>
                    <div className='row'>
                      <div className='col-9'>
                        <h6 className='header-pretitle'>Overview</h6>

                        <h1 className='header-title'>Create Corporate Staff</h1>
                      </div>
                      <div className='col-3'>
                                
                                <input
                                  type={"file"}
                                  id={"csvFileInput"}
                                  accept={".csv"}
                                  name='file'
                                  style={{ display: "none" }}
                                />
                                <button
                                  className='btn btn-outline-primary btn-sm'
                                  onClick={(e) => {
                                    handleSubmitCSV(e);
                                  }}
                                  id='upload'>
                                  Upload CSV
                                </button>
                      </div>
                    </div>
                      <hr />

                      <div className='row'>
                        <div className='col-md-12'>
                          <form onSubmit={handleSubmit}>
                            <div className='row'>
                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='firstName'
                                    className='form-label'>
                                    First Name
                                  </label>
                                  <input
                                    type='text'
                                    className={"form-control"}
                                    id='firstName'
                                    placeholder='Enter First name'
                                    autoFocus
                                    required
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='lastName'
                                    className='form-label'>
                                    Last Name
                                  </label>
                                  <input
                                    type='text'
                                    className={"form-control"}
                                    id='lastName'
                                    placeholder='Enter Last name'
                                    required
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='company'
                                    className='form-label'>
                                    Company
                                  </label>
                                  <select
                                    onChange={handleCompanyChange}
                                    className='form-control'>
                                    <option selected disabled>Select Company</option>
                                    {corporates.map((company) => (
                                      <option
                                        key={company.id}
                                        value={company.id}>
                                        {company.companyName}{" "}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='firstName'
                                    className='form-label'>
                                    Email Address
                                  </label>
                                  <input
                                    type='email'
                                    className={"form-control"}
                                    id='email'
                                    placeholder='Enter Email Address'
                                    required
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='phone'
                                    className='form-label'>
                                    Phone Number
                                  </label>
                                  <input
                                    type='text'
                                    className={"form-control"}
                                    id='phone'
                                    placeholder='Enter Phone Number'
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>

                              <div className='col-md-4'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='role'
                                    className='form-label'>
                                    Role
                                  </label>
                                  <select
                                    className='form-control'
                                    onChange={handleChange}>
                                    <option selected disabled>Select Role</option>
                                    <option value='4'>User</option>
                                    <option value='5'>Admin</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                className='col-md-4'
                                id='sg-holder'>
                                <div className='form-group'>
                                  <label
                                    htmlFor='role'
                                    className='form-label'>
                                    Subscription Group
                                  </label>
                                  <select
                                    id='subscriptionGroup'
                                    className='form-control'
                                    onChange={handleSubscriptionGroupChange}>
                                    <option selected disabled>
                                      Select Subscription Group
                                    </option>
                                    {selectedSubs.map((sub) => {
                                      return (
                                        <option
                                          key={sub.id}
                                          value={sub.id}>
                                          {sub.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className='row my-4'>
                              <div className='col'></div>
                              <div className='col-auto'>
                                {loading ? (
                                  <button className='btn btn-primary'>
                                    <Loader
                                      type='TailSpin'
                                      color='#ffffff'
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className='btn btn-primary'
                                    type='submit'>
                                    Submit
                                  </button>
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CreateCorporateStaff;

