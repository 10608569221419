import moment from "moment";
import React from "react";
// import { identifier } from "@babel/types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export const SUMMARYREPORTCOLUMNS = [
  {
    header: "S/NO",
    accessor: "id",
    Cell: (props) => (
      <div>
        {" "}
        {(props.row.index + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}
      </div>
    ),
  },

  {
    header: "Company",
    accessor: "companyName",
    Cell: (props) => <div className="capitalize">{props.value}</div>,
  },

  {
    header: "Publication",
    accessor: "paperTitle",
    Cell: (props) => <div>{props.value}</div>,
  },

  {
    header: "Count",
    accessor: "staffCount",
    Cell: (props) => <div>{props.value}</div>,
  },
  {
    header: "Date",
    accessor: "deliveryDate",
    Cell: (props) => <div>{formatDate(props.value)}</div>,
  },
];

const formatDate = (date) => {
  return moment(date).format("DD MMM YYYY");
};
