import React from 'react';
import { Dropdown} from "react-bootstrap";
import { Link } from 'react-router-dom';


export const CORPORATEPRICINGCOLUMNS = [
    {
      id: 'No',
      header: 'S/N0',
      accessor: 'id',
      Cell: props => <div>{ props.row.index + 1}</div>
    }, 
    {
      id: 'staff',
      header: 'Staff',
      accessor: (row) => `${row.staffCountStart}-${row.staffCountEnd}`,
      Cell: props => <div className="text-capitalize">{ props.value }</div>
    },
    {
      id: 'Price',
      header: 'Price',
      accessor: 'price',
      Cell: props => <div className="text-capitalize">{ props.value}</div>
    }, 
    {
      id: 'Currency',
      header: 'Currency',
      accessor: 'currency',
      Cell: props => <div className="text-capitalize">{ props.value}</div>
    },
    {
      id: 'Publisher',
      header: 'Publisher',
      accessor: 'publisher.companyName',
      Cell: props => <div className="text-capitalize">{ props.value}</div>
    },
   
  ]
