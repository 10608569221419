import axios from "axios";
import api_config from "./api_config";
const baseURL = api_config.PROMOTION_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const createPromotionApi = (data)=>{
  return api({
    url: `/`,
    data: data,
    method: "POST",
  });
}
export const fetchPromotionsApi = (token)=>{
  return api({
    url: `/`,
    method: "GET",
    headers: {Authorization: `Bearer ${token}`},
  });
}
export const updatePromotionsApi = (id, data)=>{
  return api({
    url: `/${id} `,
    data: data,
    method: "PATCH",
  });
}
export const deletePromotionsApi = (id)=>{
  return api({
    url: `/${id} `,
    method: "DELETE",
  });
}
export const fetchPromotionDetailsApi = (id)=>{
  return api({
    url: `/${id} `,
    method: "GET",
  });
}


export const createPromocodeApi = (data, token)=>{
  return api({
    url: `/promo-code/`,
    data: data,
    method: "POST",
    headers: {Authorization: `Bearer ${token}`},
  });
}
export const fetchPromocodesApi = (token)=>{
  return api({
    url: `/promo-code/all`,
    method: "GET",
    headers: {Authorization: `Bearer ${token}`},
  });
}
export const fetchActivePromocodesApi = (token)=>{
  return api({
    url: `/promo-code/list`,
    method: "GET",
    headers: {Authorization: `Bearer ${token}`},
  });
}
export const updatePromocodesApi = (id, data, token)=>{
  return api({
    url: `/promo-code/${id} `,
    data: data,
    method: "PATCH",
    headers: {Authorization: `Bearer ${token}`},
  });
}
export const deletePromocodesApi = (id)=>{
  return api({
    url: `/promo-code/${id} `,
    method: "DELETE",
  });
}
export const fetchPromocodeDetailsApi = (id, token)=>{
  return api({
    url: `/promo-code/${id} `,
    method: "GET",
    headers: {Authorization: `Bearer ${token}`},
  });
}

export const createCampaignApi = (data, token)=>{
  return api({
    url: `/campaign`,
    data: data,
    method: "POST",
    headers: {Authorization: `Bearer ${token}`},
  });
}

export const fetchCampaignsApi = (token)=>{
  return api({
    url: `/campaign/all`,
    method: "GET",
    headers: {Authorization: `Bearer ${token}`},

  });
}
export const fetchActiveCampaignsApi = (token)=>{
  return api({
    url: `/campaign/list`,
    method: "GET",
    headers: {Authorization: `Bearer ${token}`},
  });
}
export const updateCampaignsApi = (id, data, token)=>{
  return api({
    url: `/campaign/${id} `,
    data: data,
    method: "PATCH",
    headers: {Authorization: `Bearer ${token}`},
  });
}
export const deleteCampaignsApi = (id)=>{
  return api({
    url: `/${id} `,
    method: "DELETE",
  });
}
export const fetchCampaignDetailsApi = (id)=>{
  return api({
    url: `/${id} `,
    method: "GET",
  });
}

