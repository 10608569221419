import { fetchBooksApi, fetchBooksDetailsApi } from "../../api/publisher";

export const FETCH_BOOKS_LOADING = "FETCH_BOOKS_LOADING";
export const FETCH_BOOKS_SUCCESS = "FETCH_BOOKS_SUCCESS";
export const FETCH_BOOK_DETAILS_LOADING = "FETCH_BOOK_DETAILS_LOADING";
export const FETCH_BOOK_DETAILS_SUCCESS = "FETCH_BOOK_DETAILS_SUCCESS";



export const fetchBooks = () => {
  return (dispatch) => {
    dispatch(fetchBooksLoading());
    return fetchBooksApi()
      .then((res) => {
        console.log(res.data.data)
        const data = (res.data.data) || [];
        dispatch(fetchBooksSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};



export const fetchBookDetails = (id) => {
  return (dispatch) => {
    dispatch(fetchBookDetailsLoading());
    return fetchBooksDetailsApi(id)
      .then((res) => {
        console.log(res.data)
        const data = (res.data.data) || [];
        dispatch(fetchBookDetailsSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};




const fetchBooksLoading = () => {
  return {
    type: FETCH_BOOKS_LOADING,
  };
};


const fetchBooksSuccess = (data) => {
  return {
    type: FETCH_BOOKS_SUCCESS,
    payload: data,
  };
};


//  story details

const fetchBookDetailsLoading = () => {
  return {
    type: FETCH_BOOK_DETAILS_LOADING,
  };
};

const fetchBookDetailsSuccess = (data) => {
  return {
    type: FETCH_BOOK_DETAILS_SUCCESS,
    payload: data,
  };
};
