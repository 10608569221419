import { FETCH_COMPANY_LOADING, 
         FETCH_COMPANY_SUCCESS 
        } from "../Actions/company-action";


const initialState = {
  companies: [],
  isLoading: false,
};


export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPANY_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_COMPANY_SUCCESS: {
      return {
        ...state,
        companies: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
