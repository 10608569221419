import { FETCH_ADMINS_LOADING, 
         FETCH_ADMINS_SUCCESS
        } from "../Actions/admin-action";


const initialState = {
  admins: [],
  isLoading: false,
};


export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ADMINS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_ADMINS_SUCCESS: {
      return {
        ...state,
        admins: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

