import React from "react";
import { Link } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import EmptyData from "../../../components/EmptyData/Index";
import EmailFilter from "./EmailFilter";
import moment from "moment";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { AnimatePresence, motion } from "framer-motion";
import { DateRangePicker } from "rsuite";

function EmailTable({
  name,
  title,
  columns,
  data,
  raw,
  onPageChange,
  onChangeDate,
}) {
  const [currentPage, setCurrentPage] = React.useState(1);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = tableInstance;

  const spring = React.useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
      rotate: 360,
    }),
    []
  );

  function fileName() {
    let date = new Date();
    let dateString = moment(date).format("YYYYMMDDHHmm");
    return name + "_" + dateString + ".csv";
  }

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    const formattedData = data.map((d) => {
      let r = { ...d };
      delete r.id;
      delete r.userId;
      delete r.updatedAt;

      const formattedDate = moment(r.createdAt).format("YYYY-MM-DD");
      return {
        Title: r.subject,
        Name: r.type,
        Audience: r.destination,
        Date_Created: formattedDate,
      };
    });
    let ws = XLSX.utils.json_to_sheet(formattedData);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `My Rifaly Emails ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };

  const nextPage = () => {
    const next = currentPage + 1;
    onPageChange(next);
    setCurrentPage(next);
  };

  const previousPage = () => {
    const prev = currentPage - 1;
    onPageChange(prev);
    setCurrentPage(prev);
  };
  
  const handleDateRangeChange = (range) => {
    if (Array.isArray(range) && range.length === 2) {
      const [startDate, endDate] = range;

      if (startDate instanceof Date && endDate instanceof Date) {
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        onChangeDate({ startDate: formattedStartDate, endDate: formattedEndDate });
      } else {
        console.error("Invalid date objects:");
      }
    } else {
      console.error("Invalid range:");
    }
  };

  return (
    <div>
      <div className="row align-items-end my-4">
        <div className="col-md-3">
          <EmailFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          />
        </div>
        <div className="col-md-3">
        </div>
        <div className="col-md-3">
          <DateRangePicker
            size="lg"
            placeholder="Select Date"
            shouldDisableDate={DateRangePicker.afterToday()}
            ranges={[]}
            onOk={handleDateRangeChange}
          />
        </div>
        <div className="col-md-3">
          <Link to="create" className="btn btn-primary lift">
            <span>
              <i className="fe fe-plus"></i>
            </span>
            Create {name}
          </Link>
        </div>
      </div>

      <div className="row mt-2 body-content">
        {data && data.length >= 1 ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">{title}</h4>
                    <div>
                      <button
                        onClick={handleExport}
                        className="btn btn-outline-primary btn-sm"
                      >
                        Export
                      </button>
                    </div>
                  </div>

                  <table
                    {...getTableProps()}
                    className="table table-sm card-table"
                  >
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      <AnimatePresence>
                        {rows.map((row) => {
                          prepareRow(row);
                          return (
                            <motion.tr
                              {...row.getRowProps({
                                layoutTransition: spring,
                                exit: { opacity: 0, maxHeight: 0 },
                              })}
                            >
                              {row.cells.map((cell) => (
                                <motion.td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </motion.td>
                              ))}
                            </motion.tr>
                          );
                        })}
                      </AnimatePresence>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                    Showing {raw.pageSize} of {raw.totalItems} elements
                  </span>
                  {raw.hasPrevious ? (
                    <button
                      className="btn btn-round btn-sm btn-pagination"
                      onClick={previousPage}
                    >
                      <i className="fe fe-chevron-left"></i>
                    </button>
                  ) : null}
                  <span className="table-stat">Page {raw.currentPage}</span>
                  {raw.hasNext ? (
                    <button
                      className="btn btn-round btn-sm btn-pagination"
                      onClick={nextPage}
                    >
                      <i className="fe fe-chevron-right"></i>
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name={name} path={"create"} />
        )}
      </div>
    </div>
  );
}

export default EmailTable;
