import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { COLUMNS } from "./ChannelColumns";
import EmptyData from "../../../../components/EmptyData/Index";
import { fetchChannels } from "../../../../redux-store/Actions/channel-action";
import ChannelFilter from "./ChannelFilter";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

function ChannelTable() {
  const columns = useMemo(() => COLUMNS, []);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1); 
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const channelsState = useSelector((state) => state.channel.data);

  useEffect(() => {
    dispatch(fetchChannels(currentPage));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (channelsState && channelsState.data) {
      const { data, total } = channelsState;
      setPageSize(data.length);
      setTotalPages(Math.ceil(total / data.length));
    }
  }, [channelsState]);

  const data = channelsState && channelsState.data ? channelsState.data : [];

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage - 1 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    const exportData = data.map((d) => {
      let r = { ...d };
      delete r.cover;
      delete r.id;
      delete r.description;
      delete r.cover_link;
      return r;
    });
    let ws = XLSX.utils.json_to_sheet(exportData);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `My Rifaly channels ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = tableInstance;

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (data.length === pageSize) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div>
      <div className='row align-items-end my-4'>
        <div className='col'>
          <ChannelFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          />
        </div>
      </div>

      <div className='row mt-2 body-content'>
        {data && data.length >= 1 ? (
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h4 className='card-header-title'>Uploaded Channels</h4>

                    <div>
                      <button
                        onClick={handleExport}
                        className='btn btn-outline-primary btn-sm'>
                        Export
                      </button>
                    </div>
                  </div>

                  <table
                    {...getTableProps()}
                    className='table table-sm card-table'>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                              )}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='row mb-5'>
              <div className='col'></div>
              <div className='col-auto'>
                <div>
                  <button
                    className='btn btn-round btn-sm btn-pagination'
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    <i className='fe fe-chevron-left'></i>Prev
                  </button>
                  <span className='table-stat'>Page {currentPage}</span>
                  <button
                    className='btn btn-round btn-sm btn-pagination'
                    onClick={handleNextPage}
                    disabled={data.length < pageSize}
                  >
                    <i className='fe fe-chevron-right'></i>Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name="Channel" />
        )}
      </div>
    </div>
  );
}

export default ChannelTable;
