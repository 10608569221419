import React, { useEffect, useState, useCallback, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../../../components/DashboardWrapper";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "react-loader-spinner";
import { createPromotionApi } from "../../../api/promotion";
import { fetchPromotions } from "../../../redux-store/Actions/promotion-action";
import { toast } from "react-toastify";

const schema = yup
  .object({
    title: yup.string().required().max(60).label("Promotion Code Name"),
  })
  .required();

function CreatePromotion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);
    data.expiryDate = Date.parse(expiryDate.toISOString());

    createPromotionApi(data)
      .then((res) => {
        setLoading(false);
        e.target.reset();

        toast.success("Promotion Created successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch(fetchPromotions());
        navigate("/promotions");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);

        if (error.response) {
          // setApiErrors(error.response.data.error)
          // client received an error response (5xx, 4xx)

          if (error.response.status === 500) {
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (error.response.status === 422) {
            const respErrors = error.response.data.errors;

            respErrors.forEach((object, index) => {
              var values = Object.keys(object).pop();
              // console.log(`${values}:  ${object[values]}`)

              toast.error(`${values}:  ${object[values]}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
          }

          if (error.response.status === 303) {
            toast.error(error.response.data.error.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  function goBack(e) {
    e.preventDefault();
    navigate(-1);
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const filterPassedDate = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    return currentDate.getTime() - 24 * 3600 * 1000 <= selectedDate.getTime();
  };

  const CustomDatePicker = () => {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input
        type="text"
        className="example-custom-input form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        onChange={() => {}}
      />
    ));
    return (
      <DatePicker
        isClearable={true}
        selected={expiryDate}
        dateFormat={"dd-MM-yyyy HH:mm"}
        timeFormat={"HH:mm"}
        minDate={new Date().getTime}
        showTimeSelect
        timeIntervals={30}
        onChange={(date) => setExpiryDate(date)}
        filterDate={filterPassedDate}
        filterTime={filterPassedTime}
        customInput={<ExampleCustomInput />}
      />
    );
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="/publishers" onClick={toPage("/publishers/")}>
                      Promotions
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Upload Promotions
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card mt-2">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Create Promotion</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-8">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            {/* <div className='col-md-4 mb-3'>
                            <div className='form-group'>
                              <label
                                htmlFor='campaign'
                                className='form-label'>
                                Select campaign
                              </label>
                              <select
                                onChange={campaignChange}
                                defaultValue={campaign.id}
                                className='form-select'
                                id='publisherId'>
                                <option selected disabled>Select Campaign</option>
                                {campaigns.sort( ( a, b ) => {
                                  if ( a.campaignName < b.campaignName ) return -1;
                                  else if ( a.campaignName > b.campaignName ) return 1;
                                  else return 0;
                                }).map((campaign) => {
                                  return (
                                    <option
                                      key={campaign.id}
                                      value={campaign.id}>
                                      {campaign.campaignName}{' '}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                          <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="type" className="form-label">Promo code type</label>
                                    <select className="form-select form-control"
                                      { ...register("type")} required>
                                      <option selected disabled>Promo code type</option>
                                      <option defaultValue="Multi">Multi users</option>
                                      <option defaultValue="Single">Single user</option>
                                    </select>

                                      <p className="errors">{errors.type?.message}</p>
                              
                                  </div>
                                </div>


                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="company" className="form-label">Code Name</label>
                                    <input type="text" 
                                          className={ errors.title ? "form-control is-invalid" : "form-control"}  
                                          id="title" 
                                          placeholder="Enter Code Name"
                                          autoFocus
                                          required
                                          {...register("title")} />

                                      <p className="errors">{errors.title?.message}</p>
                              
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="company" className="form-label">Value (Transactional value)</label>
                                    <input type="text" 
                                          className={ errors.value ? "form-control is-invalid" : "form-control"}  
                                          id="value" 
                                          placeholder="Enter Value (Transactional value)"
                                          autoFocus
                                          required
                                          {...register("value")} />

                                      <p className="errors">{errors.value?.message}</p>
                              
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="company" className="form-label">Max Count</label>
                                    <input type="text" 
                                          className={ errors.count ? "form-control is-invalid" : "form-control"}  
                                          id="count" 
                                          placeholder="Enter Max count"
                                          autoFocus
                                          required
                                          {...register("count")} />

                                      <p className="errors">{errors.count?.message}</p>
                              
                                  </div>
                                </div>

                                <div className="col-md-6">
                                <label
                                  htmlFor="validationServer02"
                                  className="form-label"
                                >
                                  Start Date
                                </label><br/>
                                <CustomDatePicker
                                  className="form-control"
                                  dateFormat={"dd-MM-yyyy H:mm:ss"}
                                  selected={expiryDate}
                                  minDate={new Date().getTime}
                                  showTimeSelect
                                  timeIntervals={15}
                                  onChange={(date) => setExpiryDate(date)}
                                  filterDate={filterPassedDate}
                                  filterTime={filterPassedTime}
                                  readOnly
                                  required
                                />
                              </div>


                              <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="company" className="form-label">Description</label>
                                    <input type="text" 
                                          className={ errors.count ? "form-control is-invalid" : "form-control"}  
                                          id="description" 
                                          placeholder="Enter Description"
                                          autoFocus
                                          required
                                          {...register("description")} />

                                      <p className="errors">{errors.description?.message}</p>
                              
                                  </div>
                                </div>

                              </div> */}

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CreatePromotion;
