import React from 'react'
import { NavLink } from 'react-router-dom'
import "./style.css";

function SettingsNav() {

  const menuItems = [
    {
      name: "Account",
      path: "account"
    },{
      name: "Genres",
      path: "genres"
    },{
      name: "Categories",
      path: "categories"
    },{
      name: "CorporatePricing",
      path: "corporatepricing"
    },  ]
  return (
    <ul className="nav nav-tabs">
      { menuItems.map((menu) => (
        <li className="nav-item" key={menu.path}>
          <NavLink to={"/settings/" + menu.path} className="nav-link">{ menu.name }</NavLink>
        </li>
      ))}
    </ul>
  )
}

export default SettingsNav
