import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "react-loader-spinner";
import DashboardWrapper from "../../../components/DashboardWrapper";
import Checkbox from "./customCheckbox";
import { fetchIssuesApi } from "../../../api/publisher";
import { toast } from "react-toastify";
import { useLocation} from "react-router-dom";


function SubscriptionGroupDetail(props) {
    // console.log(props);
    const location = useLocation();
    const values = location.state.data;
    const [data, setData] = useState(values);
        

    const navigate = useNavigate();

    function toPage(path) {
        return function (e) {
            e.preventDefault();
            navigate(path);
        };
    }


    return (
        <DashboardWrapper>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-12">
                        <div className="header mt-md-3">
                            <nav aria-label="breadcrumb mb-2">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/dashboard" onClick={toPage("/dashboard")}>
                                            Dashboard
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="/corporates" onClick={toPage("/corporates")}>
                                            Corporates
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a
                                            href="/dashboard"
                                            onClick={toPage("/corporates/subscription-groups")}
                                        >
                                            Subscription Groups
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Subscription Group Details
                                    </li>
                                </ol>
                            </nav>
                        </div>

                        <div className="content">
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <div className="card pt-2 mt-2">
                                        <div className="px-4 pt-4">
                                            <div className="">
                                                <h6 className="header-pretitle">Overview</h6>

                                                <h1 className="header-title">
                                                    Subscription Group Details
                                                </h1>
                                            </div>

                                            <hr />

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="name" className="form-label">
                                                                        Group Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control"}
                                                                        id="name"
                                                                        defaultValue={data.name}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="name" className="form-label">
                                                                        Company
                                                                    </label>
                                                                    <input type={"text"}
                                                                        className="form-control"
                                                                        id="companyId"
                                                                        name="companyId"
                                                                        defaultValue={data.company ? data.company.companyName : data.companyId}
                                                                    />
                                   
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 row">
                                                                <div className="col-md-12">
                                                                    <label className="form-label">Selected Publications</label>
                                                                </div>
                                                                {data.papers && data.papers.length > 0 ?data.papers.map((issue, index) => {
                                                                    return (
                                                                        <div key={issue.id} className="col-md-3">
                                                                            <Checkbox
                                                                                id={issue.id}
                                                                                name={issue.title}
                                                                                onClick={() => {}}
                                                                                checked={true}
                                                                            />
                                                                        </div>
                                                                    );
                                                                }):"NO PUBLICATIONS SELECTED"}
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardWrapper>
    );

}
export default SubscriptionGroupDetail;
