import React, { useEffect, useState} from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyDetails } from '../../../../redux-store/Actions/company-action';
import DashboardWrapper from '../../../../components/DashboardWrapper'
import Loader from 'react-loader-spinner';
import UpdateCompanyCover from './UpdateCompanyCover';
import api_config from "../../../../api/api_config"



function CompanyDetails(props) {

  const dispatch = useDispatch();
  const { company, isLoading } = useSelector(
    (state) => state.company
  );

  const navigate = useNavigate();

  const [changeVisibility, setChangeVisibility] = useState(false)

  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }
  function showSize(size){
    switch (size) {
      case 1:
        return "1 - 10"

      case 2:
        return "10 - 20"
      case 3:
        return "30 - 30"
      case 4:
      default:
        return "50 - Above"

    }
  }

  let { id }  = useParams();

  useEffect(() => {
    dispatch(fetchCompanyDetails(id));
  }, [id, dispatch]); 



  return (
    <DashboardWrapper>
      <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-12">  
              <div className="header mt-md-3">

                <nav aria-label="breadcrumb mb-2">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="/dashboard" onClick={toPage("/dashboard")} >Dashboard</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="/dashboard" onClick={toPage("/publishers")} >Publishers</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="/publishers/company">Companies</a>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">Company Details</li>
                    </ol>
                </nav>

              </div>
              {/* end of header */}

              <div className="content">
                <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="card">


                    { isLoading ? (
                      <div className='loader-holder d-flex justify-content-center align-items-center'>
                      <Loader
                        type="BallTriangle"
                        heigth="100"
                        width="100"
                        color="green"
                        ariaLabel="loading-indicator"
                      />
                    </div>
                    ) : (

                      <div>
                        { company && (
                          <div className="row">
                             <div className="col-md-12">
                                <div className="px-4 pt-4">

                                  <h6 className="header-pretitle">
                                    Overview
                                  </h6>


                                  <h1 className="header-title">
                                    Company Information
                                  </h1>

                                  <hr />

                                </div>

                        

                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className='image-holder'>

                                        <div className='img-place'>
                                          <div className="img-cover" 
                                                onMouseEnter={e => {
                                                  setChangeVisibility(true);
                                                }}
                                                onMouseLeave={e => {
                                                  setChangeVisibility(false)
                                                }}
                                                >
                                            <img src={(company.cover && company.cover.includes('https')) ? `${company.cover}` : `${api_config.STORAGE_URL}${company.cover}`} className="img-fluid" alt={company.companyName} />
                                          </div>

                                          

                                          <div className={ changeVisibility ? 'img-change' : 'img-change hidden'}
                                              onMouseEnter={e => {
                                                setChangeVisibility(true);
                                              }}
                                              onMouseLeave={e => {
                                                setChangeVisibility(false)
                                              }}>
                                                <UpdateCompanyCover name={company}></UpdateCompanyCover>
                                          </div>
                                        </div>

                                      </div>

                                      
                                    </div>


                                   
                                  </div>
                                  <section className="detail-section"> 
                                    <div className="detail-title">
                                      <div className="row">
                                        <div className="col">
                                          <h3>Basic Information</h3>
                                        </div>

                                        <div className="col-auto">
                                        <Link to={{ pathname: `/publishers/company/edit/${company.id}`}} state={{data:company }}
                                          className="btn-link">
                                          <i className="fe fe-edit-3"></i>
                                          <span className="ml-2">Edit</span>
                                        </Link>
                                        </div>
                                      </div>
                                      

                                    </div>

                                    <div className="detail-contents">
                                      <div className="row">

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Company Name
                                          </div>
                                          <div className="dc-body">
                                            { company.companyName}
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Company Size
                                          </div>
                                          <div className="dc-body">
                                          { showSize(company.companySize) || "50 - Above"}
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Commission
                                          </div>
                                          <div className="dc-body">
                                          { company.commission}%
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Company Address
                                          </div>
                                          <div className="dc-body">
                                          { company.address}
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Country
                                          </div>
                                          <div className="dc-body">
                                          { company.country}
                                          </div>

                                        </div>



                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            City
                                          </div>
                                          <div className="dc-body">
                                          { company.city}
                                          </div>

                                        </div>

                                        <div className="w-100">
                                          <div className="dc-title">
                                            Description
                                          </div>
                                          <div className="dc-body">
                                          { company.description}
                                          </div>

                                        </div>

                                      </div>
                                    </div>


                                  </section>

                                  <section className='detail-section mt-4'>
                                    <div className="detail-title">
                                      <div className="row">
                                        <div className="col">
                                          <h3>Business Infomation</h3>
                                        </div>

                                        <div className="col-auto">
                                        <Link to={{ pathname: `/publishers/company/edit/${company.id}`}} state={{data:company }}
                                           className="btn-link">
                                          <i className="fe fe-edit-3"></i>
                                          <span className="ml-2">Edit</span>
                                        </Link>
                                        </div>
                                      </div>

                                    </div>

                                    <div className="detail-contents">
                                      <div className="row">

                                      <div className="col-md-12">
                                          <div className="dc-title">
                                            Company TIN Number
                                          </div>
                                          <div className="col-md-12 mt-4">
                                          <div className="dc-title">
                                           {company.tin}
                                          </div>
                                          <div className="dc-body">
                                            <div className='dc-body_file'>
                                                <div className='file-thumb'>
                                                  {/* <PdfThumb /> */}
                                                  <img src="/file.png" alt="" />
                                                </div>

                                                <div className='dc-file-details'>
                                                  <p className='dc-file-details-title'>{company.publisher_attachment ? <a target="_blank" className="text-success" href={api_config.STORAGE_URL+company.publisher_attachment.tin}>TIN Certificate</a> :"TIN Certificate"}</p>
                                                  <p className='dc-file-details-size'>PDF</p>
                                                </div>
                                            </div>
                                          </div>

                                          <hr className='no-pad' />

                                        </div>

                                      </div>

                                        <div className="col-md-12 mt-4">
                                          <div className="dc-title">
                                            Business license*
                                          </div>
                                          <div className="dc-body">
                                            <div className='dc-body_file'>
                                                <div className='file-thumb'>
                                                  {/* <PdfThumb /> */}
                                                  <img src="/file.png" alt="" />
                                                </div>

                                                <div className='dc-file-details'>
                                                  <p className='dc-file-details-title'>{company.publisher_attachment ? <a target="_blank" className="text-success" href={api_config.STORAGE_URL+company.publisher_attachment.businessLicense}>Business License</a> :"Business License"}</p>
                                                  <p className='dc-file-details-size'>PDF</p>
                                                </div>
                                            </div>
                                          </div>

                                          <hr className='no-pad' />

                                        </div>


                                        <div className="col-md-12 mt-4">
                                          <div className="dc-title">
                                            Publishing Certificate
                                          </div>
                                          <div className="dc-body">
                                            <div className='dc-body_file'>
                                                <div className='file-thumb'>
                                                  {/* <PdfThumb /> */}
                                                  <img src="/file.png" alt="" />
                                                </div>

                                                <div className='dc-file-details'>
                                                  <p className='dc-file-details-title'>{company.publisher_attachment ? <a target="_blank" className="text-success" href={api_config.STORAGE_URL+company.publisher_attachment.publishingCertificate}>Publishing Certificate</a> :"Publishing Certificate"}</p>
                                                  <p className='dc-file-details-size'> PDF</p>
                                                </div>
                                            </div>
                                          </div>

                                          <hr className='no-pad' />

                                        </div>

                                        <div className="col-md-12 mt-4">
                                          <div className="dc-title">
                                            Certificate Of Incorporation
                                          </div>
                                          <div className="dc-body">
                                            <div className='dc-body_file'>
                                                <div className='file-thumb'>
                                                  {/* <PdfThumb /> */}
                                                  <img src="/file.png" alt="" />
                                                </div>

                                                <div className='dc-file-details'>
                                                  <p className='dc-file-details-title'>{company.publisher_attachment ? <a target="_blank" className="text-success" href={api_config.STORAGE_URL+company.publisher_attachment.certificateOfIncorporation}>Certificate of Incorporation</a> :"Certificate of Incorporation"}</p>
                                                  <p className='dc-file-details-size'>PDF</p>
                                                </div>
                                            </div>
                                          </div>

                                          <hr className='no-pad' />

                                        </div>

                                        <div className="col-md-12 mt-4">
                                          <div className="dc-title">
                                          National Id Letter
                                          </div>
                                          <div className="dc-body">
                                            <div className='dc-body_file'>
                                                <div className='file-thumb'>
                                                  {/* <PdfThumb /> */}
                                                  <img src="/file.png" alt="" />
                                                </div>

                                                <div className='dc-file-details'>
                                                  <p className='dc-file-details-title'>{company.publisher_attachment ? <a target="_blank" className="text-success" href={api_config.STORAGE_URL+company.publisher_attachment.nationalIdLetter}>National ID Letter</a> :"National ID Letter"}</p>
                                                  <p className='dc-file-details-size'>PDF</p>
                                                </div>
                                            </div>
                                          </div>

                                          <hr className='no-pad' />

                                        </div>


                                      </div>
                                    </div>
                                  </section>

                                  <section className="detail-section mt-4">
                                    <div className="detail-title">
                                      <div className="row">
                                        <div className="col">
                                          <h3>Billing Information</h3>
                                        </div>

                                        <div className="col-auto">
                                        <Link to={{ pathname: `/publishers/company/edit/${company.id}`}} state={{data:company }}
                                           className="btn-link">
                                          <i className="fe fe-edit-3"></i>
                                          <span className="ml-2">Edit</span>
                                        </Link>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="detail-contents">
                                      <div className="row">

                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Bank Name
                                          </div>
                                          <div className="dc-body">
                                            { company.publisher_billing_info ? company.publisher_billing_info.bankName : "N/A"}
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Bank Branch
                                          </div>
                                          <div className="dc-body">
                                            { company.publisher_billing_info ? company.publisher_billing_info.bankBranch : "N/A"}
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Account Name
                                          </div>
                                          <div className="dc-body">
                                            { company.publisher_billing_info ? company.publisher_billing_info.accountName: "N/A"}
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Account Number
                                          </div>
                                          <div className="dc-body">
                                            { company.publisher_billing_info ? company.publisher_billing_info.accountNumber : "N/A"}
                                          </div>

                                        </div>


                                        <div className="col-md-4">
                                          <div className="dc-title">
                                            Swift Code
                                          </div>
                                          <div className="dc-body">
                                            {company.publisher_billing_info ?  company.publisher_billing_info.swiftCode : "N/A"}
                                          </div>

                                        </div>

                                      </div>
                                    </div>
                                  </section>
                                </div>



                              </div>
                          </div>
                        )}
                      </div>

                    )}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>


          </div>

    </DashboardWrapper>
  )
}

export default CompanyDetails
