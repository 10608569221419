import { 
         FETCH_READER_DETAILS_LOADING,
         FETCH_READER_DETAILS_SUCCESS
        } from "../Actions/reader-action";


const initialState = {
  reader: [],
  isLoading: false,
};


export default function readerDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_READER_DETAILS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_READER_DETAILS_SUCCESS: {
      console.log("we are here")
      return {
        ...state,
        reader: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

