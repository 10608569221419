import React from "react";

// const Checkbox = ({ id, name, type, handleClick }) => {
const Checkbox = ({ id, name, onClick, checked }) => {
  
  return (
    <div className="legend">
      <input
        id={id}
        onChange={onClick}
        name={name}
        type="checkbox"
        className="checkbox"
        checked={checked}
      />
      <label htmlFor="name">{name}</label>
    </div>
  );
};

export default Checkbox;