import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import {Offcanvas} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import {useDispatch, useSelector} from 'react-redux';
import Loader from 'react-loader-spinner';
import {fetchCorporatesApi, updateCorporateStaffsApi} from "../../../api/corporate"
import {toast} from 'react-toastify';



function EditStaff(props) {

  const admin = props.data
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"))

  const [ loading, setLoading ] = useState(false);
  const [ company, setCompany ] = useState(admin.company.id);
  const [ sub, setSub ] = useState(admin.subscriptionGroupId);
  const [ show, setShow ] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var id = parseInt(props.data.id);
  const [ companies, setCompanies ] = useState([]);
  const [ subsGroups, setSubsGroups ] = useState([])



  useEffect(() => {
    fetchCorporatesApi().then((result) => {
      setSubsGroups(result.data.data.find(c => c.id == admin.company.id).subscription_groups)
      setCompanies(result.data.data);
    });
  }, []);

  const handleCompanyChange = (e) => {
    let id = e.target.value;
    let comp = companies.find(c => c.id == id);
    let subs = comp && comp.subscription_groups ? comp.subscription_groups : [];
    setSubsGroups(subs);
    setCompany(comp.id);
    if (subs.length > 0) setSub(subs[ 0 ].id)
  }
  const handleSubsChange = (e) => {
    let sub = subsGroups.find(s => s.id == e.target.value);
    setSub(sub.id);
  }
  const navigate = useNavigate();

  const handleSubmit = e => {
    let form = document.getElementById("staff-update-form");
    const status = document.getElementById('status').value;
    let data = {
      id: admin.id, companyId: company, subscriptionGroup: sub, firstName: form.firstName.value.trim(), lastName: form.lastName.value.trim(), email: form.email.value.trim(), phone: form.phone.value.trim(), status: status
    }
    console.log("data: ", data);

    setLoading(true)
    console.log(data)
    updateCorporateStaffsApi(data, {'Authorization': `Bearer ${user.token}`}).then(res => {
      setLoading(false)
      if (res.status == 200) {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        handleClose();
        navigate("/corporates/staffs");
      }
      else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }).catch(error => {
      console.log(error);
      setLoading(false)
      toast.error(error.response.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });

  }




  return (
    <div>
      <div className="me-2 text-success" onClick={handleShow}>
        Edit
      </div>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <div className="">

            <h6 className="header-pretitle">
              Edit
            </h6>


            <h1 className="header-title">
              Edit Staff
            </h1>

          </div>

          <hr />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form id="staff-update-form">

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">First Name</label>
                  <input type="text"
                    className={"form-control"}
                    name="firstName"
                    placeholder="Enter First name"
                    autoFocus
                    defaultValue={admin.firstName}
                    required
                  />

                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">Last Name</label>
                  <input type="text"
                    className={"form-control"}
                    name="lastName"
                    placeholder="Enter Last name"
                    defaultValue={admin.lastName}
                    required />


                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">Email Address</label>
                  <input type="email"
                    className={"form-control"}
                    name="email"
                    defaultValue={admin.email}
                    placeholder="Enter Email Address"
                    required />

                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">Phone Number</label>
                  <input type="text"
                    className={"form-control"}
                    name="phone"
                    defaultValue={admin.phone}
                    placeholder="Enter Phone Number"
                    required />


                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="role" className="form-label">Role </label>
                  <select name="role" className="form-control" defaultValue={admin.role}
                  >
                    <option>Select Role</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>


                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="status" className="form-label">Account Status</label>
                  <select name="status" className="form-control" id="status" defaultValue={admin.status ? admin.status.toUpperCase() : ""}>
                    <option>Select Status</option>
                    <option value="ACTIVE">Active</option>
                    <option value="PENDING">Pending</option>
                    <option value="SUSPENDED">Suspended</option>
                    <option value="DELETED">Deleted</option>
                  </select>


                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="company" className="form-label">Company</label>
                  <select name="company" onChange={handleCompanyChange} className="form-control" id="company" defaultValue={admin.company ? admin.company.id : ""}>
                    {companies.map(c => {
                      return (<option key={c.id} value={c.id}>{c.companyName}</option>)
                    })}
                  </select>


                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="subscription" className="form-label">Subscription Group</label>
                  <select onChange={handleSubsChange} className="form-control" id="status" defaultValue={admin.subscriptionGroupId}>
                    {subsGroups.map(c => {
                      return (<option key={c.id} value={c.id}>{c.name}</option>)
                    })}
                  </select>


                </div>
              </div>
            </div>

            <div className="row my-4">
              <div className="col">
              </div>
              <div className="col-auto">
                {loading ? (
                  <button className="btn btn-primary">
                    <Loader
                      type="TailSpin"
                      color="#ffffff"
                      height={20}
                      width={20} />
                  </button>
                ) : (
                  <input className="btn btn-primary" type="submit" value={"Update"} onClick={handleSubmit} />
                )}
                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
              </div>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default EditStaff
