import React from 'react'
// import { identifier } from "@babel/types";
import { Dropdown} from "react-bootstrap";
import { Link } from "react-router-dom";
import {DateTime} from 'luxon';

export const TOPUPCOLUMNS = [
  {
    header: 'id',
    accessor: 'id',
    Cell: (props) => <div>{ props.row.index + 1}</div>
  }, 
  {
    header: 'Username',
    accessor: 'username',
    Cell: (props) => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'Reference Number',
    accessor: 'referenceId',
    Cell: (props) => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'Phone Number',
    accessor: 'phone',
    Cell: (props) => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'Amount',
    accessor: 'amount',
    Cell: (props) => <div className="text-capitalize">{ props.value} </div>
  },
  {
    header: 'Currency',
    accessor: 'currency',
    Cell: (props) => <div className="text-capitalize">{ props.value} </div>
  },
  {
    header: 'Channel',
    accessor: 'channel',
    Cell: (props) => <div className="text-capitalize">{ props.value} </div>
  },
  {
    header: 'Date Created',
    accessor: 'createdAt',
    Cell: (props) => <div>{formatDate(props.value)}</div>,

  },
  {
    header: 'Description',
    accessor: 'description',
    Cell: (props) => <div className="text-capitalize">{ props.value}</div>
  },
  {
    header: 'Transaction Details Received',
    accessor: 'extra',
    Cell: (props) => <div className="text-capitalize">{ props.value}</div>
  },
//   {
//     header: 'action',
//     accessor: 'action',
//     Cell: (props) => <div>
//  <Dropdown>
//           <Dropdown.Toggle
//             as="a"
//             className="dropdown-ellipses icon-dropdown"
//             id="dropdown-basic"
//           >
//             <i className="fe fe-more-horizontal"></i>
//           </Dropdown.Toggle>

//           <Dropdown.Menu className="dropdown-menu-end">
//             <Link
//               to={{ pathname: `/promotions/view/${props.row.original.id}`}} state={{promotions:props.row.original }}
//               className="dropdown-item"
//             >
//               View
//             </Link>

//             <Link
//               to={{ pathname: `/promotions/edit/${props.row.original.id}`}} state={{promotions:props.row.original }}
//               className="dropdown-item"
//             > 
//               Edit
//             </Link>

//             <div className="dropdown-item text-danger">
//               <DeletePromotion name={props.row.original}></DeletePromotion>
//             </div>
//           </Dropdown.Menu>
//         </Dropdown>
//     </div>
//   }
 
]




function checkStatus(status) {
  switch (status) {
    case "ACTIVE":
      return (
        <div className="status">
          <div className="dot"></div>
          Active
        </div>
      );

    case "INACTIVE":
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Suspended
        </div>
      );

    default:
      return (
        <div className="status">
          <div className="dot inactive"></div>
          {status}
        </div>
      );
  }
}

const formatDate = (date) => {
  if (date) {
    return DateTime.fromISO(date, { zone: 'utc' })
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toLocaleString(DateTime.DATE_MED);
  } else {
    return date;
  }
};


