import React, { useMemo, useEffect, useState } from "react";
import DashboardWrapper from "../../../components/DashboardWrapper";
import { useNavigate } from "react-router-dom";
import { EMAILCOLUMNS } from "./EmailColumns";
import EmailTable from "./EmailTable";
import { DateTime } from "luxon";
import { fetchEmailApi } from "../../../api/email";
import moment from "moment";

export default function Emails() {
  const columns = useMemo(() => EMAILCOLUMNS, []);
  const navigate = useNavigate();

  const [emails, setEmails] = useState(null);
  const [startDate, setStart] = useState(
    DateTime.now().startOf("day").toISO({ includeOffset: false })
  );
  const [endDate, setEnd] = useState(
    DateTime.now().toISO({ includeOffset: false })
  );
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const fetchData = async (startDate, endDate, page) => {
    try {
      setIsLoading(true);
      const result = await fetchEmailApi(startDate, endDate, page);
      setEmails(result.data);
    } catch (error) {
      console.error("Error", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData(startDate, endDate, page);
  }, [page]);

  const handlePageChange = (newPage) => {
    if (newPage < 1) newPage = 1;
    setPage(newPage);
    fetchData(newPage);
  };

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  const handleDateChange = (range) => {
    if (range && range.startDate && range.endDate) {
      const { startDate, endDate } = range;
  
      const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
      const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    
      setStart(formattedStartDate);
      setEnd(formattedEndDate);
      fetchData(formattedStartDate, formattedEndDate, page);
    } else {
      console.error("Invalid date range:");
    }
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Emails
                  </li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Emails</h1>
              </div>
            </div>

            <hr />

            <div className="content">
              <EmailTable
                name="Email"
                title="Emails"
                columns={columns}
                data={emails?.data || []}
                raw={emails}
                onPageChange={handlePageChange}
                onChangeDate={handleDateChange}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
