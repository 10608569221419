import axios from "axios";
import api_url from "./api_config";

const baseURL = api_url.PUBLISHER_URL;
const billingURL = api_url.BILLING_URL;
const audioURL = api_url.AUDIO_URL;

const countryApi = axios.create({
  baseURL: api_url.COUNTRIES_URL,
  headers: { "Content-type": "application/json" },
});

const billingapi = axios.create({
  baseURL: billingURL,
  headers: { "Content-Type": "application/json" },
});

const audioApi = axios.create({
  baseURL: audioURL,
  headers: { "Content-type": "application/json" },
});

const billingApi = axios.create({
  baseURL: `${billingURL}`,
  headers: {"Content-type": "application/json"},
});
const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchBuySummaryApi = (publisherId, data) => {
  return billingapi({
    url: `billing/reports/summary/?id=${publisherId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET"
  });
};

export const fetchExchangeRatesApi = (base) => {
  return billingApi({
    url: `billing/exchange_rates/${base}`,
    method: "GET",
  });
};
export const fetchCountries = () => {
  return billingApi({
    url: `billing/countries/supported`,
    method: "GET",
  });
};

export const fetchSummaryReports = (token, data) => {
  return billingApi({
    url: `billing/corporates/summary/?id=${data.id}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET",
    headers: {Authorization: `Bearer ${token}`},
  });
};

export const fetchDeliveryReports = (token, data) => {
  return billingApi({
    url: `billing/corporates/usage/?id=${data.id}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET",
    headers: {Authorization: `Bearer ${token}`},
  });
};


export const fetchUsageReports = (data) => {
  return billingApi({url: `billing/corporates/usage/?id=${data.id}&startDate=${data.startDate}&endDate=${data.endDate}`, 
  method: "GET",
});
};
export const createTopupsApi = (data) => {
  return billingApi({
    url: `billing/topups/`,
    data: data,
    method: "POST",
  });
};
export const fetchTopUpsApi = ()=>{
  return billingApi({
    url: `billing/topups/`,
    method: "GET",
  });
}
export const fetchTopupDetailsApi = ()=>{
  return billingApi({
    url: `/`,
    method: "GET",
  });
}

export const fetchRevenueApi = (channelId, data) => {
  return api({
    url: `reports/audio-sales-reports/?id=${channelId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

export const fetchPublisherDownloadsApi = (channelId, data) => {
  return api({
    url: `reports/publisher-downloads/?id=${channelId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

export const fetchReportSummary = (data) => {
  let url = `api/v1/reports/summary`;

  if (data.audioId) {
    url += `?audioId=${data.audioId}`;
  }
  return audioApi({
    url: url,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

export const fetchReportPlays = (data) => {
  let url = `api/v1/reports/plays?reportType=${data.reportType}`;

  if (data.audioId) {
    url += `&audioId=${data.audioId}`;
  }
  return audioApi({
    url: url,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

// podcasterId=${data.podcasterId}
export const fetchReportImpressions = (data) => {
  let url = `api/v1/reports/impressions?reportType=${data.reportType}`;

  if (data.audioId) {
    url += `&audioId=${data.audioId}`;
  }
  return audioApi({
    url: url,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

export const fetchReportPlaytime = (data) => {
  return audioApi({
    url: `api/v1/reports/playtime`,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

export const fetchReportPlaytypes = (data) => {
  return audioApi({
    url: `api/v1/reports/play-types`,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

export const fetchReportSearch = (data) => {
  return audioApi({
    url: `reports/api/v1/reports/search`,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

export const fetchPublisherRevenueReport = (data) => {
  return billingapi({
    url: `api/v3/reports/publisher-sales-reports-all/?startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET",
    headers: { Authorization: `Bearer ${data.token}` },
  });
};

export const fetchPublisherRevenueDetails = (data)=>{
  return billingapi({
    url: `api/v3/reports/publications-sales-reports/?id=${data.publisherId}&startDate=${data.startDate}&endDate=${data.endDate}`,
    method: "GET",
    headers:{'Authorization':`Bearer ${data.token}`}
  });
}
