import React from 'react';
import { Dropdown} from "react-bootstrap";
import { Link } from 'react-router-dom';
// import DeleteUser from '../DeleteUser';
// import UpdateUserStatus from '../UpdateUserStatus';
import GenreEdit from "./edit_genre";
import GenreDetail from './genre_detail';



export const GENRESCOLUMNS = [
    {
      header: 'id',
      accessor: 'id',
      Cell: props => <div>{ props.row.index + 1}</div>
    }, 
    {
      header: 'Name',
      accessor: 'name',
      Cell: props => <div className="text-capitalize">{ props.value }</div>
    },
    {
      header: 'description',
      accessor: 'description',
      Cell: props => <div className="text-capitalize">{ props.value}</div>
    },
   
    {
      header: 'action',
      accessor: 'action',
      Cell: props => <div>
        <Dropdown>
         
          <Dropdown.Toggle as="a" className="dropdown-ellipses icon-dropdown" id="dropdown-basic">
          <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>
  
         
  
  
  
  
          <Dropdown.Menu className="dropdown-menu-end">
  
            <Dropdown.Item>
              <GenreDetail placement="end" data={props.row.original}/>
            </Dropdown.Item>
  
            <Dropdown.Item>
              <GenreEdit placement="end" data={props.row.original} />
            </Dropdown.Item>
  
        
  
            <div className='dropdown-item text-danger'>
                {/* <DeleteUser name={props.row.original}></DeleteUser> */}
            </div>
  
  
          </Dropdown.Menu>
        </Dropdown>
      </div>
    }
   
  ]
