import { fetchRatedReportApi } from "../../api/publisher";

export const FETCH_RATEDREPORT_LOADING = "FETCH_RATEDREPORT_LOADING";
export const FETCH_RATEDREPORT_SUCCESS = "FETCH_RATEDREPORT_SUCCESS";

export const fetchRatedReport = () => {
  return (dispatch) => {
    dispatch(fetchRatedReportLoading());
    return fetchRatedReportApi()
      .then((res) => {
        const data = res.data.data || [];
        dispatch(fetchRatedReportSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const fetchRatedReportLoading = () => {
  return {
    type: FETCH_RATEDREPORT_LOADING,
  };
};

const fetchRatedReportSuccess = (data) => {
  return {
    type: FETCH_RATEDREPORT_SUCCESS,
    payload: data,
  };
};
