import React from "react";
// import { identifier } from "@babel/types";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import DeleteCampaign from "./DeleteCampaign";

export const CAMPAIGNCOLUMNS = [
  {
    header: "id",
    accessor: "id",
    Cell: (props) => <div>{props.row.index + 1}</div>,
  },
  {
    header: "Campaign Name",
    accessor: "title",
    Cell: (props) => (
      <div className="text-capitalize">
        <Link to={`/analytics`}>
          {/* to={`/analytics/${props.row.original.id}`}> */}
          {props.value}
        </Link>
      </div>
    ),
  },
  {
    header: "Objective",
    accessor: "objective",
    Cell: (props) => <div className="text-capitalize">{props.value} </div>,
  },
  {
    header: "Performance",
    accessor: "performance",
    Cell: (props) => <div className="text-capitalize">{props.value !== null ? props.value : "0"}</div>,
  },
  {
    header: "Start Date",
    accessor: "startDate",
    Cell: (props) => <div>{formatDate(props.value)}</div>,
  },
  {
    header: "End Date",
    accessor: "endDate",
    Cell: (props) => <div>{formatDate(props.value)}</div>,
  },
  {
    header: "Description",
    accessor: "description",
    Cell: (props) => <div className="text-capitalize">{props.value} </div>,
  },
  {
    header: "Status",
    accessor: "status",
    Cell: (props) => <div>{checkStatus("ACTIVE")}</div>,
  },
  {
    header: "action",
    accessor: "action",
    Cell: (props) => (
      <div>
        <Dropdown>
          <Dropdown.Toggle
            as="a"
            className="dropdown-ellipses icon-dropdown"
            id="dropdown-basic"
          >
            <i className="fe fe-more-horizontal"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-end">
            <Link
              to={{ pathname: `/campaigns/view/${props.row.original.id}` }}
              state={{ campaigns: props.row.original }}
              className="dropdown-item"
            >
              View
            </Link>

            <Link
              to={{ pathname: `/campaigns/edit/${props.row.original.id}` }}
              state={{ campaigns: props.row.original }}
              className="dropdown-item"
            >
              Edit
            </Link>

            {/* <div className="dropdown-item text-danger">
              <DeleteCampaign name={props.row.original}></DeleteCampaign>
            </div> */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

function checkStatus(status) {
  switch (status) {
    case "ACTIVE":
      return (
        <div className="status">
          <div className="dot"></div>
          Active
        </div>
      );

    case "INACTIVE":
      return (
        <div className="status">
          <div className="dot inactive"></div>
          Suspended
        </div>
      );

    default:
      return (
        <div className="status">
          <div className="dot inactive"></div>
          {status}
        </div>
      );
  }
}

const formatDate = (dateString) => {
  if (dateString) {
    const date = DateTime.fromFormat(dateString, "yyyy-MM-dd HH:mm:ss");
    return date.toLocaleString(DateTime.DATE_MED);
  } else {
    return dateString;
  }
};
