import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import { AnimatePresence, motion } from "framer-motion";

import { STAFFCOLUMNS } from "./CorporateStaffColumns";

import EmptyData from "../../../components/EmptyData/Index";
import CorporateFilter from "./CorporateFilter";
import { fetchCorporate } from "../../../redux-store/Actions/corporate-action";
import { fetchCorporateStaffsApi } from "../../../api/corporate";

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
function CorporateStaffTable() {
  const columns = useMemo(() => STAFFCOLUMNS, []);
  const dispatch = useDispatch();

  const [staffs, setStaffs] = useState([]);

  var data = staffs;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    preGlobalFilteredRows,
    setGlobalFilter,

    // Get the state from the instance
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;
  const handleExport = () => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";
    let ext = ".xlsx";
    data = data.map((d) => {
      let r = JSON.parse(JSON.stringify(d));
      r.company = d.company ? d.company.companyName : d.companyId;
      r.subscription_group = d.subscription_group
        ? d.subscription_group.name
        : d.subscriptionGroup;
      delete r.userId;

      return r;
    });
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let content = new Blob([buffer], { type: fileType });
    let filename = `Rifaly Corporate Staffs ${Date.now()}${ext}`;
    FileSaver.saveAs(content, filename);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCorporateStaffsApi().then((result) => {
      console.log(result.data.data);
      setStaffs(result.data.data);
    });
  }, []);

  const spring = useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
      rotate: 360,
    }),
    []
  );

  return (
    <div>
      <div className="row align-items-end my-4">
        <div className="col">
          <CorporateFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
            searchTitle="Search Corporate Users"
          />
        </div>

        <div className="col-auto">
          <Link to="/corporates/staffs/create" className="btn btn-primary lift">
            <span>
              <i className="fe fe-plus"></i>
            </span>
            Create Staffs
          </Link>
        </div>
      </div>

      <div className="row mt-2 body-content">
        {staffs && staffs.length >= 1 ? (
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">Corporate Users</h4>

                    <div>
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleExport}
                      >
                        Export
                      </button>
                    </div>
                  </div>

                  <table
                    {...getTableProps()}
                    className="table table-sm card-table"
                  >
                    <thead>
                      {headerGroups.map((headerGroups) => (
                        <tr {...headerGroups.getHeaderGroupProps()}>
                          {headerGroups.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      <AnimatePresence>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps({
                                layouttransition: spring,
                                exit: { opacity: 0, maxHeight: 0 },
                              })}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <motion.td
                                    {...cell.getCellProps({
                                      layouttransition: spring,
                                      exit: { opacity: 0.5, maxHeight: 0 },
                                    })}
                                  >
                                    {cell.render("Cell")}
                                  </motion.td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </AnimatePresence>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col"></div>
              <div className="col-auto">
                <div>
                  <span className="table-stat">
                    Showing {pageIndex === 0 ? 1 : pageIndex * pageSize + 1} to{" "}
                    {pageIndex > 10
                      ? staffs.length
                      : pageIndex * pageSize + pageSize > staffs.length
                      ? staffs.length
                      : pageIndex * pageSize + pageSize}{" "}
                    of {staffs.length} elements
                  </span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => previousPage()}
                  >
                    <i className="fe fe-chevron-left"></i>
                  </button>
                  <span className="table-stat">Page {pageIndex + 1}</span>
                  <button
                    className="btn btn-round btn-sm btn-pagination"
                    onClick={() => nextPage()}
                  >
                    <i className="fe fe-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyData name="Corporate Staff" path="/corporates/staffs/create" />
        )}
      </div>
    </div>
  );
}

export default CorporateStaffTable;
