import React, { useMemo, useEffect, useState } from "react";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import { DateTime } from "luxon";
import {
  fetchReportSummary,
  fetchReportPlays,
  fetchReportImpressions,
} from "../../../../api/reports";
import Loader from "react-loader-spinner";

function PerformancePerEpisodeReportTable() {
  const { id } = useParams();
  let episodeId= id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [pieData, setPieData] = useState([]);
  const [doughnutData, setDoughnutData] = useState([]);
  const [summaryData, setSummaryData] = useState();
  const [totalPlaysData, setTotalPlaysData] = useState([]);
  const [impressionsData, setImpressionsData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [stories, setStory] = useState(null);
  const [timeFilter, setTimeFilter] = useState("monthly");
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const thousandSeparator = (x) => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "n/a";
  };
  const [data, setData] = useState([]);
  const [retry, setRetry] = useState(false);

  const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Function to generate random colors
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const fetchData = async () => {
    try {
      const [
        episodeSummaryResponse,
        episodeTotalplaysResponse,
        episodePlaysResponsePiedevices,
        episodePlaysResponsePielocation,
        episodePlaysResponseBar,
        episodeImpressionsResponse,
      ] = await Promise.all([
        fetchReportSummary({
          audioId: episodeId,
          token: { Authorization: `Bearer ${user.token}` },
        }),
        fetchReportPlays({
          audioId: episodeId,
          token: { Authorization: `Bearer ${user.token}` },
        }),
        fetchReportPlays({
          reportType: "platform",
          audioId: episodeId,
          token: { Authorization: `Bearer ${user.token}` },
        }),
        fetchReportPlays({
          reportType: "country",
          audioId: episodeId,
          token: { Authorization: `Bearer ${user.token}` },
        }),
        fetchReportPlays({
          reportType: timeFilter,
          audioId: episodeId,
          token: { Authorization: `Bearer ${user.token}` },
        }),
        fetchReportImpressions({
          reportType: timeFilter,
          audioId: episodeId,
          token: { Authorization: `Bearer ${user.token}` },
        }),
      ]);

      const pieData = episodePlaysResponsePiedevices.data.reduce(
        (acc, item) => {
          acc.labels.push(item.os !== null ? item.os : "Others");
          acc.data.push(item.playCount);
          acc.backgroundColor.push(getRandomColor());
          return acc;
        },
        { labels: [], data: [], backgroundColor: [] }
      );
      const doughnutData = episodePlaysResponsePielocation.data.reduce(
        (acc, item) => {
          acc.labels.push(item.country !== null ? item.country : "Others");
          acc.data.push(item.playCount);
          acc.backgroundColor.push(getRandomColor());
          return acc;
        },
        { labels: [], data: [], backgroundColor: [] }
      );
      setSummaryData(episodeSummaryResponse.data);
      setTotalPlaysData(episodeTotalplaysResponse.data);
      setPieData(pieData);
      setDoughnutData(doughnutData);
      setBarData(episodePlaysResponseBar.data);
      setImpressionsData(episodeImpressionsResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const barInfo = {
    labels: MONTHS,
    datasets: [
      {
        label: "Plays",
        data: MONTHS.map((month, index) => {
          let play = barData.find((item) => Number(item.date) - 1 === index);
          let count = play ? play.playCount : 0;
          return count;
        }),
        // data: barData.data,
        backgroundColor: getRandomColor(),
        borderColor: 'transparent',
        tension: 0.1,
        barThickness: 25,
        borderRadius: 0,
        barPercentage: 100.0,
        categoryPercentage: 10.0,
        offset: false
      },
    ],
  };

  const [barData2, setBarData2] = useState({
    labels: ["Red", "Blue", "Yellow"],
    datasets: [
      {
        label: "Streams",
        data: [10, 20, 30],
        backgroundColor: ["red", "blue", "yellow"],
      },
    ],
  });

  const [barData3, setBarData3] = useState({
    labels: ["Red", "Blue", "Yellow"],
    datasets: [
      {
        label: "Dataset",
        data: [10, 20, 30],
        backgroundColor: ["red", "blue", "yellow"],
      },
    ],
  });

  const optionsBar = {
    indexAxis: 'x', 
    layout: {
      padding: 10
    },
    responsive: true,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        align: 'center'
      }
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          offset: false,
          borderDash: [1, 3],
          color: '#E0E6ED',
          borderColor: 'transparent',
          tickColor: 'transparent',
          categoryPercentage: 0.4
        }
      },
      y: {
        stacked: true,
        grid: {
          display: false
        }
      },
    }
  };

  const pieInfo = {
    labels: pieData.labels,
    datasets: [
      {
        data: pieData.data,
        backgroundColor: pieData.backgroundColor,
      },
    ],
    responsive: true,
    maintainAspectRatio: true,
    radius: 120,
    weight: 1,
    borderWidth: 1,
    hoverOffset: 7,
  };

  const doughnutInfo= {
    labels: doughnutData.labels,
    datasets: [
      {
        data: doughnutData.data,
        backgroundColor: doughnutData.backgroundColor,
      },
    ],
    responsive: true,
    maintainAspectRatio: true,
    radius: 120,
    weight: 1,
    borderWidth: 1,
    hoverOffset: 7,
  };

    //css for the doughnut
    const pieChartOptions = {
      cutout:70,
      plugins: {
        legend: {
          display: true,
          position: "right",
          labels: {
            color: "#BCBCBC",
            font: {
              size: 14,
            },
            boxWidth: 8,
            boxHeight: 8,
            usePointStyle: true,
          },
        },
      },
    };

  const refresh = () => {
    const id = JSON.parse(localStorage.getItem("RIFALYPODCASTER")).channel.id;
    const endDate = DateTime.fromMillis(Date.now()).toISO();
    const startDate = DateTime.fromMillis(Date.now()).startOf("day").toISO();
    fetchData(id, startDate, endDate);
  };

  if (isLoading) {
    return (
      <div className="row">
        <div className="col-md-12 my-4 offset-4 my-4">
          <Loader type="TailSpin" color="#46E7AD" height={100} width={100} />
        </div>
      </div>
    );
  }

  if (retry) {
    return (
      <div className="row">
        <div className="col-md-4 my-4 offset-4 my-4 text-primary text-center">
          <span style={{ cursor: "pointer" }} onClick={refresh}>
            <i className="material-icons text-primary">cached</i>
          </span>
          <p>Reload</p>
        </div>
      </div>
    );
  }

  return (
    <div className="content">
      {/* Render your charts and other components here */}
      <div className="row">
        <div className="col-md-12 my-4">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">
                        Revenue
                      </h6>
                      <span className="h2 mb-0 mr-4">
                        Tzs {summaryData && summaryData.revenue
                          ? thousandSeparator(Number(summaryData.revenue).toFixed(2))
                          : 0}
                      </span>

                      <span className="badge bg-success-soft mr-2 mt-n1"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">Plays</h6>
                      <span className="h2 mb-0">
                        {summaryData && summaryData.plays
                          ? thousandSeparator(summaryData.plays)
                          : 0}
                      </span>
                      <span className="badge bg-success-soft mr-2 mt-n1"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h6 className="text-capitalize text-muted mb-3">Streams</h6>
                      <span className="h2 mb-0">
                        {summaryData && summaryData.streamsTotal
                          ? thousandSeparator(summaryData.streamsTotal)
                          : 0}
                      </span>
                      <span className="badge bg-success-soft mr-2 mt-n1"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 col-xl">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <h5 className="text-capitalize text-muted mb-3">
                        Impressions
                      </h5>

                      <span className="h2 mb-0">
                        {summaryData && summaryData.impressions
                          ? thousandSeparator(summaryData.impressions)
                          : 0}
                      </span>

                      <span className="badge bg-success-soft mr-2 mt-n1"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-xl-8">
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className="card card-fill-sm">
                    <div className="card-header">
                      <h4 className="card-header-title">Episode Performance</h4>
                    </div>
                    <div className="card-body">
                      <Bar data={barInfo} options={optionsBar} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-12 col-xl-6">
                  <div className="card card-fill-sm">
                    <div className="card-header">
                      <h4 className="card-header-title">
                      Streaming time
                      </h4>
                    </div>
                    <div className="card-body relative">
                      <Bar data={barData2} options={optionsBar} />
                    </div>
                  </div>
                </div> 
                <div className="col-12 col-xl-6">
                  <div className="card card-fill-sm">
                    <div className="card-header">
                      <h4 className="card-header-title">
                        Bar Chart Title small 2
                      </h4>
                    </div>
                    <div className="card-body relative">
                      <Bar data={barData3} options={optionsBar}/>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className="card card-fill-sm">
                    <div className="card-header">
                      <h4 className="card-header-title">Listeners Devices</h4>
                    </div>
                    <div className="card-body relative">
                      <Doughnut data={pieInfo} options={pieChartOptions} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className="card card-fill-sm">
                    <div className="card-header">
                      <h4 className="card-header-title">Listeners Location</h4>
                    </div>
                    <div className="card-body relative">
                      <Doughnut data={doughnutInfo} options={pieChartOptions} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PerformancePerEpisodeReportTable;
