import axios from 'axios';
import url from './api_config';

const baseURL = url.PUBLISHER_URL;

const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
const followersapi = axios.create({
  baseURL: "https://api.sandbox.rifaly.com/api/publishers/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchFollowersApi = (channelId) => {
  return followersapi({
    url: `audio/v3/followers/${channelId}`,
    method: "GET",
  });
};

export const fetchStaffsApi = () => {
  return api({
    url: `staffs`,
    method: 'GET',
  });
};

export const fetchStaffDetailsApi = (id) => {
  return api({
    url: `staffs/${id}`,
    method: 'GET',
  });
};

export const createStaffsApi = (data) => {
  return api({
    url: `staffs`,
    data: data,
    method: 'POST',
  });
};

export const updateStaffsApi = (data) => {
  return api({
    url: `staffs/update/`,
    data: data,
    method: 'POST',
  });
};

// issues
export const fetchIssuesApi = () => {
  return api({
    url: `issues/newspapers`,
    method: 'GET',
  });
};
// issues
export const fetchAllIssuesApi = () => {
  return api({
    url: `issues/all`,
    method: 'GET',
  });
};

export const fetchIssueDetailsApi = (id) => {
  return api({
    url: `issues/show/${id}`,
    method: 'GET',
  });
};

// issues - get Companies
export const fetchCompanyIssuesApi = (publisher, category) => {
  return api({
    url: `issues/available/?publisher=${publisher}&category=${category}`,
    method: 'GET',
  });
};

// publishers - get issue categories
export const fetchCompanyIssueCategoriesApi = (id) => {
  return api({
    url: `issues/categories/?publisher=${id}`,
    method: 'GET',
  });
};

// update issue - Activate / deactivate
export const updateIssueStatusApi = (id, data) => {
  return api({
    url: `issues/${id}`,
    data: data,
    method: 'PATCH',
  });
};

/* 

  ISSUE APIS
  manage and fetch issues APIs
*/
export const createIssuesApi = (data) => {
  return api({
    url: `issues`,
    data: data,
    method: 'POST',
  });
};

export const updateIssuesApi = (id, data) => {
  return api({
    url: `issues/${id}`,
    data: data,
    method: 'PATCH',
  });
};

export const deleteIssuesApi = (id) => {
  return api({
    url: `issues/`,
    data: id,
    method: 'DELETE',
  });
};

// fetch genres
export const fetchGenresApi = () => {
  return api({
    url: `genres/list`,
    method: 'GET',
  });
};
// UPDATE genres
export const updateGenreApi = (id, data) => {
  return api({
    url: `genres/update/${id}`,
    method: 'POST',
    data: data,
  });
};
// create genres
export const createGenreApi = (data) => {
  return api({
    url: `genres`,
    method: 'POST',
    data: data,
  });
};
/* 
  stories API
*/
export const fetchChannelsApi = (currentPage) => {
  return api({
    url: `audio/v3/channels?page=${currentPage}`,
    method: 'GET',
  });
}
export const fetchChannelsByIdApi = (cid) => {
  return api({
    url: `audio/v3/channel/view/${cid}`,
    method: 'GET',
  });
}
export const updateChannelStatusApi = (id, data)=>{
  return api({
    url: `audio/v3/channel/update/${id}`,
    data: data,
    method: "PATCH",
    headers: { Authorization: `Bearer ${data.token}` },  
  });
}
export const fetchStoriesApi = (currentPage) => {
  return api({
    url: `audio/v3/admin/all?page=${currentPage}`,
    method: 'GET',
  });
}
export const fetchStoryDetailsApi = (id) => {
  return api({
    url: `audio/v3/show/${id}`,
    method: 'GET',
  });
};

export const createStoriesApi = (data) => {
  return api({
    url: `audio/v3`,
    data: data,
    method: 'POST',
  });
};

export const createStoriesViaRSSApi = (data) => {
  return api({
    url: `audio/v3/rss-feed`,
    data: data,
    method: "POST",
  });
};
export const createStoriesSeasonsApi = (data) => {
  return api({
    url: `audio/v3`,
    data: data,
    method: "POST",
  });
};
export const fetchSeasonsDetailsApi = (id) => {
  return api({
    url: `audio/v3/collections/${id}`,
    method: "GET",
  });
};

export const deleteStoriesApi = (id) => {
  return api({
    url: `audio/v3/`,
    data: id,
    method: 'DELETE',
  });
};

export const updateStoryStatusApi = (id, data) => {
  return api({
    url: `audio/v3/${id}`,
    data: data,
    method: 'PATCH',
  });
};

// publications - changed hia
export const fetchPublicationsApi = (currentPage) => {
  return api({
    url: `publications/v2/list?page=${currentPage}`,
    method: 'GET',
  });
};

export const fetchPublicationDetailsApi = (id) => {
  return api({
    url: `publications/v2/view/details/${id}`,
    method: 'GET',
  });
};

export const createPublicationsApi = (data) => {
  return api({
    url: `publications/v2`,
    data: data,
    method: 'POST',
  });
};

export const createPublicationsApiTest = (data) => {
  return api({
    url: `publications/v2/test`,
    data: data,
    method: 'POST',
  });
};
export const updatePublicationsApi = (data, config) => {
  return api({
    url: `publications/v2/update/${data.id}`,
    data: data,
    method: 'PUT',
    headers: config.headers,
  });
};

export const publishPublicationApi = (options) => {
  return api({
    url: `publications/v2/publish/${options.id}`, data: {status: options.status},
    method: 'PATCH',
    headers: {Authorization: `Bearer ${options.token}`},
  });
};

export const deletePublicationApi = (id, token) => {
  return api({
    url: `publications/v2`,
    data: {id: id},
    method: 'DELETE',
    headers: {Authorization: `Bearer ${token}`},
  });
};

export const fetchBooksApi = () => {
  return api({
    url: `publications/v2/books`,
    method: 'GET',
  });
};

export const fetchBooksDetailsApi = (id) => {
  return api({
    url: `publications/v2/books/${id}`,
    method: 'GET',
  });
};

//fetch countries
export const fetchCountriesApi = () => {
  let api = axios.create({
    baseURL: url.BILLING_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return api({
    url: `countries/supported`,
    method: 'GET',
  });
};

export const formatDuration = (seconds) => {
  let hours = Number(seconds / 3600).toFixed(0);
  let minutes = Number((seconds % 3600) / 60).toFixed(0);
  let secs = Number(seconds - minutes * 60 - hours * 3600).toFixed(0);
  return `${hours}:${minutes}:${secs}`;
};


export const createReportRateApi = (data) => {
  return api({
    url: `pricing/v2`,
    method: 'POST',
    data: data,
  });
};

export const fetchRatedReportApi = () => {
  return api({
    url: `pricing/v2`,
    method: 'GET',
  });
};
