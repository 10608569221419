import React, { useEffect, useState, useCallback, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "react-loader-spinner";
import { createPromocodeApi } from "../../../../api/promotion";
import { fetchPromocodes } from "../../../../redux-store/Actions/promo-code-action";
import { toast } from "react-toastify";
import { fetchCampaignsApi } from "../../../../api/promotion";

const schema = yup
  .object({
    code: yup.string().required().max(60).label("Promotion Code Name"),
  })
  .required();

function CreatePromotion() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [campaign, setCampaign] = useState(
    campaigns && campaigns.length > 0 ? campaigns[0] : {}
  );
  console.log("🚀 ~ CreatePromotion ~ campaigns:", campaigns)

  const onSubmit = (data, e) => {
    e.preventDefault();
    setLoading(true);

    data.campaignId = campaign.id;
    data.expiryDate = expiryDate.toISOString();

    createPromocodeApi(data, user.token)
      .then((res) => {
        setLoading(false);
        e.target.reset();

        toast.success("Promotion Code Created successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch(fetchPromocodes(user.token));
        navigate("/promocodes");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);

        if (error.response) {
          // setApiErrors(error.response.data.error)
          // client received an error response (5xx, 4xx)

          if (error.response.status === 500) {
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          if (error.response.status === 422) {
            const respErrors = error.response.data.errors;

            respErrors.forEach((object, index) => {
              var values = Object.keys(object).pop();
              // console.log(`${values}:  ${object[values]}`)

              toast.error(`${values}:  ${object[values]}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
          }

          if (error.response.status === 303) {
            toast.error(error.response.data.error.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const fetchCampaignData = async () => {
    try {
      const response = await fetchCampaignsApi(user.token);
  
      // Check if response and response.data are defined
      if (response && response.data) {
        const campaignsData = response.data.data;
  
        // Check if campaignsData is defined
        if (campaignsData) {
          const activeCampaigns = campaignsData.filter(
            (campaign) => campaign.status.toUpperCase() === "ACTIVE"
          );
  
          if (activeCampaigns.length > 0) {
            setCampaigns(activeCampaigns);
            setCampaign(activeCampaigns[0]);
          } else {
            // Handle the case where there are no active campaigns
            setCampaigns([]);
            setCampaign({});
            console.log("No active campaigns found.");
          }
        } else {
          // Handle the case where campaignsData is undefined
          console.error("Invalid campaigns data structure");
        }
      } else {
        // Handle the case where the response or response.data is undefined
        console.error("Invalid response structure");
      }
    } catch (error) {
      // Handle other potential errors, e.g., network issues
      console.error("Error fetching campaigns data:", error);
    }
  };
  
  useEffect(() => {
    fetchCampaignData();
  }, [dispatch]);
  

  const campaignChange = (e) => {
    let campId = e.target.value;
    let selectedCampaign = campaigns.find((c) => c.id == parseInt(campId));
    setCampaign(selectedCampaign);
  };

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  function goBack(e) {
    e.preventDefault();
    navigate(-1);
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const filterPassedDate = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    return currentDate.getTime() - 24 * 3600 * 1000 <= selectedDate.getTime();
  };

  const CustomDatePickerExpiry = () => {
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
      <input
        type="text"
        className="example-custom-input form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        onChange={() => {}}
      />
    ));
    return (
      <DatePicker
        isClearable={true}
        selected={expiryDate}
        dateFormat={"yyyy-MM-dd HH:mm"}
        timeFormat={"HH:mm"}
        minDate={new Date().getTime}
        showTimeSelect
        timeIntervals={30}
        onChange={(date) => setExpiryDate(date)}
        filterDate={filterPassedDate}
        filterTime={filterPassedTime}
        customInput={<ExampleCustomInput />}
      />
    );
  };

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="/publishers" onClick={toPage("/publishers/")}>
                      Promotions
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Upload Promotions
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card mt-2">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Create Promotion</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-8">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    htmlFor="campaignId"
                                    className="form-label"
                                  >
                                    Select campaign
                                  </label>
                                  <select
                                    onChange={campaignChange}
                                    value={campaign.id}
                                    className="form-select"
                                    id="campaignId"
                                  >
                                    <option disabled>
                                      Select Campaign
                                    </option>
                                    {campaigns
                                      .sort((a, b) => {
                                        if (a.title < b.title) return -1;
                                        else if (a.title > b.title) return 1;
                                        else return 0;
                                      })
                                      .map((campaign) => {
                                        return (
                                          <option
                                            key={campaign.id}
                                            value={campaign.id}
                                          >
                                            {campaign.title}{" "}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label htmlFor="type" className="form-label">
                                    Promo code type
                                  </label>
                                  <select
                                    className="form-select form-control"
                                    {...register("type")}
                                    required
                                  >
                                    <option disabled>
                                      Promo code type
                                    </option>
                                    <option value="1">Multi users</option>
                                    <option value="2">Single user</option>
                                  </select>

                                  <p className="errors">
                                    {errors.type?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Code Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      errors.code
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="code"
                                    placeholder="Enter Code Name"
                                    autoFocus
                                    required
                                    {...register("code")}
                                  />

                                  <p className="errors">
                                    {errors.code?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Value (Transactional value)
                                  </label>
                                  <input
                                    type="number"
                                    className={
                                      errors.value
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="value"
                                    placeholder="Enter Promotion Code value"
                                    autoFocus
                                    required
                                    {...register("value")}
                                  />

                                  <p className="errors">
                                    {errors.value?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Max Count
                                  </label>
                                  <input
                                    type="number"
                                    className={
                                      errors.maxCount
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    id="maxCount"
                                    placeholder="Enter Promotion Code Max Count"
                                    autoFocus
                                    required
                                    {...register("maxCount")}
                                  />

                                  <p className="errors">
                                    {errors.type?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <label
                                  htmlFor="validationServer02"
                                  className="form-label"
                                >
                                  Expiry Date
                                </label>
                                <br />
                                <CustomDatePickerExpiry
                                  className="form-control"
                                  dateFormat={"dd-MM-yyyy H:mm:ss"}
                                  selected={expiryDate}
                                  minDate={new Date().getTime()}
                                  showTimeSelect
                                  timeIntervals={5}
                                  onChange={(date) => setExpiryDate(date)}
                                  filterDate={filterPassedDate}
                                  filterTime={filterPassedTime}
                                  readOnly
                                />
                              </div>

                              <div className="col-12 col-md-6 mb-3">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Promotion Code Status
                                  </label>
                                  <select
                                    className="form-select form-control"
                                    {...register("status")}
                                  >
                                    <option value="" disabled>
                                      Select Promotion Code Status
                                    </option>
                                    <option value="DRAFT">Draft</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="INACTIVE">InActive</option>
                                    <option value="EXPIRED">Expired</option>
                                  </select>

                                  <p className="errors">
                                    {errors.status?.message}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 col-md-12">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Description
                                  </label>
                                  <textarea
                                    type="text"
                                    rows="3"
                                    id="description"
                                    className={
                                      errors.description
                                        ? "form-control is-invalid"
                                        : "form-control"
                                    }
                                    placeholder="Enter Promotion Code Description"
                                    autoFocus
                                    required
                                    {...register("description")}
                                  ></textarea>
                                  <p className="errors">
                                    {errors.description?.message}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default CreatePromotion;
