import { FETCH_ISSUES_SUCCESS, 
         FETCH_ISSUES_LOADING,
        } from "../Actions/issue-action";


const initialState = {
  issues: [],
  isLoading: false,
};


export default function issueReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ISSUES_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_ISSUES_SUCCESS: {
      return {
        ...state,
        issues: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

