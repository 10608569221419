import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import DashboardWrapper from "../../../../components/DashboardWrapper";
import { fetchIssueDetails } from "../../../../redux-store/Actions/issue-action";
import Navs from "../navs";

export const Issue = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  console.log(params);
  const id = params.id;
  console.log(id);
  var countries = localStorage.getItem("RIFALYCOUNTRIES")
    ? JSON.parse(localStorage.getItem("RIFALYCOUNTRIES"))
    : [];
  const { issue, isLoading } = useSelector((state) => state.issue);

  // const [issue, isLoading] = useSelector((state) => state.issue);
  // const issue = [];
  console.log(issue);
  console.log(isLoading);

  function toDashboard(e) {
    e.preventDefault();
    navigate("/dashboard");
  }

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  useEffect(() => {
    console.log("please");
    dispatch(fetchIssueDetails(id));
  }, [id, dispatch]);

  function getCat(categoryId) {
    switch (categoryId) {
      case 1:
        return "NewsPaper";

      case 2:
        return "Magazine";

      case 3:
        return "Book";

      case 4:
        return "Article";

      default:
        return "NewsPaper";
    }
  }

  if (!issue) {
    return (
      <section>
        <h2>Issue not found!</h2>
      </section>
    );
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toDashboard}>
                      Dashboard
                    </a>
                  </li>

                  <li className="breadcrumb-item " aria-current="page">
                    Publishers
                  </li>
                  <li
                    className="breadcrumb-item "
                    aria-current="page"
                    onClick={toPage("/publishers/issues")}
                  >
                    issues
                  </li>
                  {/* <li className="breadcrumb-item active" aria-current="page">{issue.title}</li> */}
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">Overview</h6>

                <h1 className="header-title">Publishers</h1>
              </div>
            </div>

            <div className="content">
              <Navs></Navs>

              <div className="row align-items-end my-4">
                <div className="col"></div>

                <div className="col-auto">
                  <Link
                    to="/publishers/publications/create"
                    className="btn btn-primary lift"
                  >
                    <span>
                      <i className="fe fe-plus"></i>
                    </span>
                    Upload Publication
                  </Link>
                </div>
              </div>
              {/* end of content */}

              {/* Content cards */}
              <div className="row mt-2 body-content">
                <div className="col-md-12">
                  <div className="card">
                    {/* card head */}

                    <div className="card-header">
                      <h4 className="card-header-title">Issue information</h4>

                      <div>
                        <div className="state"> Active</div>
                      </div>
                    </div>
                    {/* end of card header */}

                    <div className="card-body">
                      {isLoading ? (
                        <div className="loader-holder d-flex justify-content-center align-items-center">
                          <Loader
                            type="BallTriangle"
                            heigth="100"
                            width="100"
                            color="green"
                            ariaLabel="loading-indicator"
                          />
                        </div>
                      ) : (
                        <div>
                          {issue && issue.publisher && (
                            <div className="row">
                              <div className="col-md-4">
                                <div className="detail">
                                  <p className="detail-title">Publisher</p>
                                  <p className="detail-data capitalize">
                                    {issue.publisher.companyName}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="detail">
                                  <p className="detail-title">Title</p>
                                  <p className="detail-data capitalize">
                                    {issue.title}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="detail">
                                  <p className="detail-title">Category</p>
                                  <p className="detail-data">
                                    {getCat(issue.categoryId)}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="detail">
                                  <p className="detail-title">Country</p>
                                  <p className="detail-data">
                                    {countries.length > 0 &&
                                    countries.find(
                                      (c) =>
                                        c.countryCode.toLowerCase() ==
                                        issue.country.toLowerCase()
                                    )
                                      ? countries.find(
                                          (c) =>
                                            c.countryCode.toLowerCase() ==
                                            issue.country.toLowerCase()
                                        ).name
                                      : issue.country}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="detail">
                                  <p className="detail-title">Price</p>
                                  <p className="detail-data">{issue.price}</p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="detail">
                                  <p className="detail-title">Currency</p>
                                  <p className="detail-data">
                                    {issue.currency}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="detail">
                                  <p className="detail-title">Language</p>
                                  <p className="detail-data">
                                    {issue.language}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="detail">
                                  <p className="detail-title">
                                    Publishing Frequency
                                  </p>
                                  <p className="detail-data">
                                    {issue.frequency}
                                  </p>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="detail">
                                  <p className="detail-title">Genre</p>
                                  <p className="detail-data">
                                    {issue.genres
                                      ? issue.genres
                                          .map((g) => g.name)
                                          .join(", ")
                                      : "None"}
                                  </p>
                                </div>
                              </div>

                              {/* <div className="col-md-4">
                                <div className="detail">
                                  <p className="detail-title">
                                  Publication Days
                                  </p>
                                  <p className="detail-data">
                                    { issue.price}
                                  </p>
                                </div>
                              </div> */}

                              <div className="col-md-4">
                                <div className="detail">
                                  <p className="detail-title">
                                    Publication availability
                                  </p>
                                  <p className="detail-data">Global</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {/* end of data row */}

                      <div className="row">
                        <div className="col"></div>
                        <div className="col-auto">
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/publishers/issues/edit/${id}`,
                                {
                                  replace: false,
                                  state: issue,
                                },
                              );
                            }}
                            className='btn-link'>
                            <i className='fe fe-edit-3'></i>
                            <span className='ml-2'>Edit</span>
                          </Link>

                        </div>
                      </div>
                    </div>
                    {/* end of card body */}
                  </div>
                </div>
                {/* end of large col */}
              </div>
              {/*  end of body content */}
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};
