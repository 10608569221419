import React from "react";
import { NavLink } from "react-router-dom";

export default function Navs() {
  return (
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <NavLink to="/publishers/company" className="nav-link">
          Companies
        </NavLink>
      </li>
      {/* 
      <li className="nav-item">
        <NavLink to="/publishers/staff" className="nav-link">Staff</NavLink>
      </li> */}

      <li className="nav-item">
        <NavLink to="/publishers/issues" className="nav-link">
          Issues
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink to="/publishers/publications" className="nav-link">
          Publications
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink to="/publishers/publishersRevenue" className="nav-link">
          Publishers’ Revenue
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/publishers/shareReport" className="nav-link">
          Share Report
        </NavLink>
      </li>
      {/* 
      <li className="nav-item">
        <NavLink to="/publishers/books" className="nav-link" >
          Books
        </NavLink>
      </li> */}
    </ul>
  );
}
