import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "react-loader-spinner";
import {
  updateCorporatesApi,
  updateCorporateStatusApi,
} from "../../../api/corporate";
import { toast } from "react-toastify";
import COUNTRIES from "../../../config/COUNTRIES.json";
import DashboardWrapper from "../../../components/DashboardWrapper";
import PhoneInput from "react-phone-input-2";
import { useLocation } from "react-router-dom";

function EditCorporate(props) {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState();
  const countries = COUNTRIES;
  const user = JSON.parse(localStorage.getItem("RIFALYUSER"));
  const location = useLocation();
  const data = location.state.data;
  const [company, setCompany] = useState(data);
  const [status, setStatus] = useState(company.status ? company.status.toUpperCase() : 'ACTIVE');
  const [showTrialSection, setShowTrialSection] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    var inputs = Array.from(e.target.elements);
    let data = {};
    inputs.forEach((i) => {
      let key = i.id;
      let val = i.value;

      if (key && key.length > 0) data[key] = val;
    });
    data.status = status;
    data.phone = phone;
    data.id = company.id;
    if (status !== "TRIAL") {
      delete data.expire;
      delete data.trial;
    }

    console.log("submitting... ", data);
    var options = {
      headers: {
        Authorization: "Bearer " + user.token,
      },
    };
    updateCorporateStatusApi(data, options)
      .then((res) => {
        console.log(res.data.data);
        setLoading(false);

        navigate("/corporates");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response) {
          toast.error(error.response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });

    console.log(data);
  };

  const navigate = useNavigate();

  const handleStatusChange = (e) => {
    const selectedValue = e.target.value;
    switch (selectedValue) {
      case "ACTIVE":
        setStatus("ACTIVE");
        setShowTrialSection(false);
        break;
      case "PROMOTION":
        setStatus("PROMOTION");
        setShowTrialSection(false);
        break;
      case "TRIAL":
        setStatus("TRIAL");
        setShowTrialSection(true);
        break;
      case "SUSPENDED":
        setStatus("SUSPENDED");
        setShowTrialSection(false);
        break;
      case "DELETED":
        setStatus("DELETED");
        setShowTrialSection(false);
        break;
      default:
        setStatus("ACTIVE");
        setShowTrialSection(false);
        break;
    }
  };

  function toPage(path) {
    return function (e) {
      e.preventDefault();
      navigate(path);
    };
  }

  return (
    <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">
            <div className="header mt-md-3">
              <nav aria-label="breadcrumb mb-2">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")}>
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/corporates")}>
                      Corporates
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Company
                  </li>
                </ol>
              </nav>
            </div>

            <div className="content">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card pt-3">
                    <div className="px-4 pt-4">
                      <div className="">
                        <h6 className="header-pretitle">Overview</h6>

                        <h1 className="header-title">Edit Company Details</h1>
                      </div>

                      <hr />

                      <div className="row">
                        <div className="col-md-12">
                          <form onSubmit={handleSubmit}>
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <div className="form-head">
                                  <p className="form-title">
                                    Basic Information
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Company Name
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    id="companyName"
                                    placeholder="Enter Company Name"
                                    defaultValue={company.companyName}
                                    required
                                    name="companyName"
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Name of Contact Person
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    id="contactPerson"
                                    placeholder="Enter Contact Person's Name"
                                    required
                                    name="contactPerson"
                                    defaultValue={company.contactPerson}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="company"
                                    className="form-label"
                                  >
                                    Email Address
                                  </label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder="Enter Company Email Address"
                                    required
                                    id="contactEmail"
                                    name="contactEmail"
                                    defaultValue={company.email}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="phone" className="form-label">
                                    Phone
                                  </label>
                                  <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    defaultValue={company.phone}
                                    onChange={(e) =>
                                      setPhone(e.target.value.trim())
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="country"
                                    className="form-label"
                                  >
                                    Country
                                  </label>

                                  <select
                                    className="form-select"
                                    id="country"
                                    name="country"
                                    defaultValue={"TZ"}
                                  >
                                    {countries.map((country) => (
                                      <option
                                        key={country.code}
                                        value={country.code}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="city" className="form-label">
                                    City / Town
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    name="city"
                                    required
                                    placeholder="Enter City / Town"
                                    defaultValue={company.city}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="address"
                                    className="form-label"
                                  >
                                    Company Address
                                  </label>
                                  <input
                                    defaultValue={company.address}
                                    type="text"
                                    className="form-control"
                                    id="companyAddress"
                                    name="companyAddress"
                                    required
                                    placeholder="Enter Company Address"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label
                                    htmlFor="status"
                                    className="form-label"
                                  >
                                    Company Status
                                  </label>
                                  <select
                                    name="status"
                                    className="form-control"
                                    id="status"
                                    onChange={handleStatusChange}
                                    defaultValue={status}
                                  >
                                    <option>Select Status</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="PROMOTION">Promotion</option>
                                    <option value="TRIAL">Trial</option>
                                    <option value="SUSPENDED">Suspended</option>
                                    <option value="DELETED">Deleted</option>
                                  </select>
                                </div>
                              </div>
                              {(showTrialSection || (company.status === "TRIAL" && status === "TRIAL")) && (
                                <div className={"col-md-4 "} id="expCont">
                                  <div className="form-group">
                                    <label
                                      htmlFor="expire"
                                      className="form-label"
                                    >
                                      End of Trial
                                    </label>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="expire"
                                      name="expire"
                                      defaultValue={
                                        company.trialExpiration
                                          ? company.trialExpiration.split(
                                              "T"
                                            )[0]
                                          : ""
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* end of form row */}

                            <div className="row my-4">
                              <div className="col"></div>
                              <div className="col-auto">
                                {loading ? (
                                  <button className="btn btn-primary">
                                    <Loader
                                      type="TailSpin"
                                      color="#ffffff"
                                      height={20}
                                      width={20}
                                    />
                                  </button>
                                ) : (
                                  <input
                                    className="btn btn-primary"
                                    type="submit"
                                    defaultValue="Submit"
                                  />
                                )}
                                {/* <button className="btn btn-primary" type="submit">Submit</button> */}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default EditCorporate;
