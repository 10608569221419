import React, { useEffect,useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import DashboardWrapper from '../../../components/DashboardWrapper';
import { GENRESCOLUMNS } from './genresColumns';
import GenreTable from './genresTable';
import SettingsNav from './SettingsNav';
import {useSelector,useDispatch} from "react-redux";

function Genres() {

  const columns = GENRESCOLUMNS;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { genres } = useSelector(
    (state) => state.genres
  );  

  function toPage (path){
    return function (e){
      e.preventDefault()
      navigate(path);
    }
  }

  return (
  <DashboardWrapper>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12">

            <div className="header mt-md-3 mb-5">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard" onClick={toPage("/dashboard")} >Dashboard</a>  
                  </li>
              
                  <li className="breadcrumb-item active" aria-current="page">Settings</li>
                </ol>
              </nav>

              <div className="header-body">
                <h6 className="header-pretitle">
                  Overview
                </h6>

                <h1 className="header-title">
                  Settings
                </h1>

              </div>

            </div>
            
            <div className="content">
             <SettingsNav></SettingsNav>

            </div>
        
            <GenreTable columns={columns} data={genres}/>

          </div>
        </div>
      </div>
  </DashboardWrapper>);
}

export default Genres;
